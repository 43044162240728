<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <h2>Privacy Policy</h2>
        <ul class="helplisting">
            <li>eatzilla.info may collect the following information from the customers who want to use our web platform or mobile app such as
                <ul>
                    <li>First Name</li>
                    <li>Last Name</li>
                    <li>Street Address</li>
                    <li>Area</li>
                    <li>City</li>
                    <li>Contact Number</li>
                    <li>Email Address</li>
                    <li>GPS Location</li>
                    <li>eatzilla.info customer’s past orders</li>
                    <li>Customer’s Favorite Restaurants</li>
                    <li>Customer Service Inquiries</li>
                    <li>Restaurants/Outlets ratings and reviews</li>
                </ul>
            </li>
            <li>eatzilla.info uses web analytics to constantly analyze the traffic on the platform.</li>
            <li>eatzilla.info uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our site and allows us to improve our site. This Cookie Policy relates to tracking technologies
                generally and therefore, for this Policy, references to “cookies” incorporate references to all types of tracking technologies. By continuing to use our website, you are agreeing to our use of cookies by this Cookie Policy.</li>
            <li>eatzilla.info reserves the right to share the basic user information with the restaurants/outlets to process the order. The information includes, First Name, Last Name, Full Address, Telephone Number.</li>
            <li>eatzilla.info does not save or have no intention of saving any credit card or debit card data as the payments are strictly processed by our third-party payment gateway providers. Your credit card number is sent in encrypted form to our credit
                card processor. This means humans cannot read it. It also means that if someone is intercepting your internet traffic the card number is scrambled. No one can read it except our credit card processing company.</li>
            <li>eatzilla.info uses secure servers (SSL) and extra strong encryption. On our site when you see the URL change from "HTTP://" to "https:// “all data transmission is encrypted so that other people snooping around the net cannot read the data.
                As a final comfort, most credit card companies will drop a credit card charge if you tell them you never made the charge. They must do this to stay in business.</li>
            <li>eatzilla.info may reveal your information to the staff or third parties involved in the delivery of your order. The sole purpose is to provide proper analysis or support regarding the desired order. </li>
            <li>eatzilla.onfo also reserves the right to disclose your information, if necessary, to protect our legal rights, If the situation somehow develops into a harmful conduct. </li>
            <li>eatzilla.info is not responsible for any information or any link provided by our third parties during the placement of the order.</li>
            <li>eatzilla.info believes that it's imperative to provide the best possible service. To achieve that, we may require your feedback and co-operation. We may also send you periodic offers, services, promotions, etc. which may be of your interest.
            </li>
            <li>eatzilla.info reserves the right to change its Privacy Policy at any time.</li>
        </ul>

    </div>
</section>
<app-footer></app-footer>