import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable(
    {providedIn: 'root'}
)
export class DataEmitterService {
     // guest user address observables
     private guestAddressObs$: BehaviorSubject<any> = new BehaviorSubject(null);

     // login observables
     private loginObs$: BehaviorSubject<any> = new BehaviorSubject(null);

     // lat and lng observables
     private latlngobs$: BehaviorSubject<any> = new BehaviorSubject(null);

      // mob number observables
      private verficationObs$: BehaviorSubject<any> = new BehaviorSubject(null);
      // order status observables
      private orderStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

/**
 * get current guest user details
 * @returns 
 */
  public getDetails(): Observable<any> {
    return this.guestAddressObs$.asObservable();
}

/**
 * set current guest user details update
 * @param data 
 */
public setDetails(data) {
    this.guestAddressObs$.next(data);
}

/**
 * get current login details
 * @returns 
 */
public getLogin(): Observable<any> {
    return this.loginObs$.asObservable();
}

/**
 * set current login update
 * @param data 
 */
public updateLogin(data) {
    this.loginObs$.next(data);
}

/**
 * get current login details
 * @returns 
 */
public getlatlng(): Observable<any> {
    return this.latlngobs$.asObservable();
}

/**
 * set current login update
 * @param data 
 */
public updateLatlng(data) {
    this.latlngobs$.next(data);
}

/**
 * get details to verify otp
 * @returns 
 */
public otpverificationGet(): Observable<any> {
    return this.verficationObs$.asObservable();
}

/**
 * update details to verify otp
 */
public otpverificationSet(data){
    this.verficationObs$.next(data);
}

/**
 * get details to verify otp
 * @returns 
 */
public orderStatusget(): Observable<any> {
    return this.orderStatus$.asObservable();
}

/**
 * update details to verify otp
 */
public orderStatusSet(data){
    this.orderStatus$.next(data);
}

}
