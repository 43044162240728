import { Component, OnInit, Inject, Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Globals } from "../../../globals";
import { TranslateService } from '@ngx-translate/core';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
import { CommonHelper } from 'src/app/common/helper';
import { COMPONENTS, URL, USER_TYPE } from 'src/app/constants/common';
import { DataEmitterService } from 'src/app/services/data-emit.service';
import { SocketService } from 'src/app/services/socket.service';
import { NUMBER_ONLY } from 'src/app/constants/pattern.constant';
declare var $: any;
declare var window: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  deviceInfo = null;
  userProfileDetails: any={};
  loggedIn: any = false;
  profileResponse: any;
  mobilecheck: boolean;
  userName: string;
  alttitle: string;
  loginResponse: any = {};
  selectedphonecode_login: any;
  lang: any;
  translatedstr: any;
  href: any;
  currency: any;
  urilang: string;
  public userIs:string = '';
  public userType = USER_TYPE;
  private zoom: number;
  private geoCoder;
  public registeringMobNum:any = null;
  public component = '';
  public formDetails:any = null;
  public mobVerified = null;
  private apiconnectService:ApiconnectService;
  private webStorageService:WebStorageService;
  private dataEmitter:DataEmitterService
  public router: Router;
  public commonHelper:CommonHelper;
  private deviceService: DeviceDetectorService;
  public urls = URL;
  public latitudeinput;
  public longtitudeinput;
  public setaddressinput;
  public counterId = 0;
  public mobOTP = null;
  public mobnoTOShow = null;

  /**
   * constructor
   * @param globals 
   * @param translate 
   * @param _document 
   * @param socketService 
   * @param injector 
   */
  constructor(
    private globals: Globals,
    private translate: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
    private socketservice: SocketService,
    private injector: Injector,
  ) {
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService); //SonarQube issue -> constructor params max. allowed 7.
    this.webStorageService = this.injector.get<WebStorageService>(WebStorageService);
    this.dataEmitter = this.injector.get<DataEmitterService>(DataEmitterService);
    this.router = this.injector.get<Router>(Router);
    this.commonHelper = this.injector.get<CommonHelper>(CommonHelper);
    this.deviceService = this.injector.get<DeviceDetectorService>(DeviceDetectorService);
    this.alttitle = this.globals.imagealt;
    this.checkDevice();
    this.setLang();
    
  }
  setLang() {
    this.translate.addLangs(['en', 'ar']);
    if (localStorage.getItem('language') == 'ar') {
      this.currency = this.globals.currency_ar;
      this.urilang = '/ar';
      this.translate.setDefaultLang('ar');
      this.translate.use('ar');
      this.lang = 'ar';
      let htmlTag = this._document.getElementsByTagName("html")[0];
      htmlTag.id = this.lang === "ar" ? "rtl" : "ltr";
      htmlTag.dir = this.lang === "ar" ? "rtl" : "ltr";
      this.changeCssFile(this.lang);
      this.checkcurrentUrl(this.lang);
    }
    else {
      this.currency = this.globals.currency;
      localStorage.setItem('language', 'en');
      this.urilang = '';
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      this.lang = 'en';
      let htmlTag = this._document.getElementsByTagName("html")[0];
      htmlTag.dir = this.lang === "ar" ? "rtl" : "ltr";
      htmlTag.id = this.lang === "ar" ? "rtl" : "ltr";
      this.changeCssFile(this.lang);
      this.checkcurrentUrl(this.lang);
    }
  }
  changeCssFile(lang: string) {
    let headTag = this._document.getElementsByTagName("head")[0];
    let existingLink = this._document.getElementById("langCss") as HTMLLinkElement;
    let bundleName = lang === "ar" ? "css-ar.css" : "css-en.css";
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this._document.createElement("link");
      newLink.rel = "stylesheet";
      newLink.id = "langCss";
      newLink.href = bundleName;
      newLink.type = 'text/css';
      newLink.media = 'all';
      headTag.appendChild(newLink);
    }
  }
  str:any;
  async ngOnInit(): Promise<void> {
    $('modal-backdrop').hide();  //hide when change route
    this.str = await this.commonHelper.translateText('Yes');
    const username = JSON.parse(localStorage.getItem('userName'));
        this.userName = username?username:'';
    if (
        this.webStorageService.getAuthId() &&
        this.webStorageService.getAuthToken() && this.webStorageService.getUserType()== USER_TYPE.LOGGED_USER // check user type with auth token and id
      ) {
        this.loggedIn = true
        if(this.loggedIn){
          this.getProfileDetails();
        }
        this.loginResponse.user_name = this.webStorageService.getUserName()
      } else if(this.webStorageService.getAuthId() &&
                this.webStorageService.getAuthToken() && this.webStorageService.getUserType()== USER_TYPE.GUEST_USER) { // check user type with auth token and id
        this.loggedIn = false
        this.webStorageService.removeData(['userPic'])
      }
     this.userIs = localStorage.getItem('user');
     this.dataEmitter.getLogin().subscribe(res=>{
      this.gotLogin(res);
     });

     //mob number observer
     this.dataEmitter.otpverificationGet().subscribe(res=>{
      this.mobNoObserve(res);
     });
     setTimeout(() => {
      $('.loading').hide();
     }, 2000);
   }
  checkcurrentUrl(lang:string){
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g?.segments;
    if (lang == 'ar'){
      if (s?.length > 1){
        const newurl = this.router.url.replace('ar/', '');
        this.router.navigate([newurl]);
      }
    }
    if (lang == 'en'){
      if (s?.length > 1){
        const newurl = this.router.url.replace('ar/', '');
        this.router.navigate([newurl]);
      }
    }
  }
  changeLang(lang) {
    localStorage.setItem('language', lang);
    this.href = this.router.url;
    if (lang == 'ar'){
      window.location = 'ar'+this.router.url;
    }
    else{
      const tree: UrlTree = this.router.parseUrl(this.router.url);
      const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
      const s: UrlSegment[] = g.segments;
      var pathlang = s[0].path;
      let newurl;
      if (pathlang == 'ar'){
        if (s.length > 1){
           newurl = this.router.url.replace('ar/', '');
        }
        else{
           newurl = this.router.url.replace('ar', '');
        }
        window.location = newurl;
      }
    }
  }

  async phonenumberdropdown(queryselector) : Promise<void>{
    var phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
    var phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
    var input = document.querySelector("." + queryselector),
      hiddenintputfield = document.querySelector("#countrycode_login"),
      errorMsg = document.querySelector("#error-msg");
      $(".iti__flag-container").remove();
    var iti = window.intlTelInput(input, {
      excludeCountries: ["il"],
      hiddenInput: "full",
      preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
      separateDialCode: true,
      initialCountry: 'bh',
      direction: 'rtl',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
      customPlaceholder: function (selectedCountryPlaceholder: any) {
        return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
      },
    });
    var reset = function () {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', async function () : Promise<void> {
      reset();
      this.values = input;
      const numberOnly = NUMBER_ONLY;
      if (this.values.value.trim()) {
        if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
          var countryData = iti.getSelectedCountryData();
          this.values = hiddenintputfield;
          this.values.value = countryData.iso2;
          this.selectedcountrycode = countryData.iso2;
          this.selectedphonecode_login = countryData.dialCode;
          $('#countrycode_login').val(countryData.iso2);
          $('#phonecode_login').val(countryData.dialCode);
        } else {
          input.classList.add("error");
          errorMsg.innerHTML = phoneplaceholdererror;
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }
  devicetype: any;
  browser: any;
  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.devicetype = this.deviceInfo.os;
    this.browser = this.deviceInfo.browser;
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice) {
      this.mobilecheck = true;

    }
  }
  closeApppopup() {
    $('.apppopuppup').slideUp();
  }
  downloadApp() {
    var urltoapp;
    if (this.devicetype == 'iOS') {
      urltoapp = "https://apps.apple.com/us/app/eatzilla-food-delivery/id1510301191?ls=1";
    }
    if (this.devicetype == 'Android') {
      urltoapp = "https://play.google.com/store/apps/details?id=com.eatzilla.user&hl=en";
    }
    window.location = urltoapp;
  }
  getProfileDetails() {
    this.apiconnectService.getRequest("/get_profile?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.profileResponse = data;
        if (this.profileResponse.status) {
          this.userProfileDetails = this.profileResponse.data[0];
          this.webStorageService.storeUserName(this.profileResponse.data[0].name);
          this.userName = this.webStorageService.getUserName();
          this.loggedIn = true;
        }
      },
      err => {
        console.log(err)
      }
    )
  }
  refreshPage() {
    this._document.defaultView.location.reload();
  }
  showRegisterbox() {
    $('#loginbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    $('#registerbox').modal('show');
  }
  showLoginbox() {
    this.phonenumberdropdown('phoneusernamelogin');
    $('#registerbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    $('#loginbox').modal('show');
  }
  showForgotpasswordbox() {
    $('#loginbox').modal('hide');
    $('#registerbox').modal('hide');
    $('#forgotpasswordbox').modal('show');
  }
  logout() {
    $('.loading').show();
    this.apiconnectService.getRequest("/logout?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      async data => {
        $('.loading').hide();
        this.webStorageService.removeData(['userName','userPic']);
          localStorage.clear();
          this.userProfileDetails = {};
          this.profileResponse ={};
          this.router.navigate([this.urilang+'home']);
          this.dataEmitter.updateLogin(false);
          this.socketservice.disconnect();
        Swal.fire({
          text: await this.commonHelper.translateText('You have been successfully logged out'),
          icon: 'success'
        }).then((result) => {
          console.log(result);
         });
      },
      err => {
        console.log(err)
      }
    )
  }

  /**
   * get deliverable address // To set default address
   */
  public getAllDeliveryAddress () {
    this.apiconnectService.getRequest("/get_delivery_address?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        if(data['status']){
          const tempData = data['data'].filter((x)=>x.is_default==1);
          const isaddresssearched = JSON.parse(localStorage.getItem('searchedaddress'));
          localStorage.setItem('nodefualtlogginaddress','false');
          if(tempData.length>0 && !isaddresssearched){
          this.webStorageService.storeLocalData({defaultaddress:1});
          this.webStorageService.storeLocalData({lat:tempData[0].lat, lng:tempData[0].lng, address:tempData[0].address})
          this.dataEmitter.updateLatlng({lat:tempData[0].lat, lng:tempData[0].lng, address:tempData[0].address})
         }else{
          if(this.userIs == this.userType.LOGGED_USER){
            localStorage.setItem('nodefualtlogginaddress',JSON.stringify(true));
          }
          }
         }
        },
      err => {
        console.log(err);
        $('.loading').hide();
      }
    )
  }

  /**
 * view all restaurants
 */
public viewAllRestaurants(){
  const curlati= JSON.parse(localStorage.getItem('lat'));
    const curlng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    if(!curlati || !curlng){ // check presence of lat and lng -> redirect to home page if not having
      this.showLoginbox();
    }else{
      this.router.navigate([this.urilang+'/'+'restaurants'])
    }
  }

  /**
   * show food search modal
   */
  public showFoodSearchModal(){
    const curlati= JSON.parse(localStorage.getItem('lat'));
    const curlng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    if(!curlati || !curlng){ // check presence of lat and lng -> redirect to home page if not having
      this.showLoginbox();
     }else{
    $('#loginbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    $('#registerbox').modal('hide');
    $('#searchDish').modal('show');
     }
  }

  /**
   * otp verification request
   * @param res 
   */
  public otpverificationRequest(res){
  
   if(res){
     if(res.component == COMPONENTS.REGISTER){
      $('#registerbox').modal('hide');
    }
    if(res.component == COMPONENTS.GUEST_LOGIN){
      $('#guestuserlogin').modal('hide');
    }
    $('#otpbox').modal('show');
    this.counterId = this.counterId+1;
    this.registeringMobNum = res.regNum;
    this.mobnoTOShow = res.mobToShow;
     this.component = res.component;
     this.formDetails = res.formData;
     this.mobOTP = res.mobVerificationOTP;
    }
  }

  /**
   * otp verification success
   * @param res 
   */
  public otpverificationSuccess(res){
    if(res){
      this.counterId = this.counterId + 1;
      $('#otpbox').modal('hide');
       this.registeringMobNum = null;
       this.component = '';
       this.formDetails = res.formData;
       this.mobVerified = {verified:res.verified, component:res.component, formData:res.formData};
    }
   }

   /**
    * got login
    * functions further do
    * @param {any} res 
    */
   private gotLogin(res){
    if(res){
      this.userIs = localStorage.getItem('user');
      if(this.userIs == this.userType.LOGGED_USER){
        this.getProfileDetails();
        this.getAllDeliveryAddress();
      }else{
        const currentuser = JSON.parse(localStorage.getItem('userName'));
        this.userName = currentuser?currentuser:'';
      }
    }else{
      this.loggedIn = false;
     }
   }

   /**
    * mob number observe
    * fuctions further do
    * @param {any} res 
    */
   private mobNoObserve(res){
    if(res != null){
      if(res.regNum){
       this.otpverificationRequest(res);
      }
      if(res.verified){
       this.otpverificationSuccess(res)
       }
     }
   }
}
