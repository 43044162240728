<app-header></app-header>
<section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                <h1 class="text-center text-light glorytext">{{'Thank you for ordering your food with Eatzilla' | translate}}</h1>
                <p class="paragraph-font text-center text-light"> {{'We hope you enjoy your meal.' | translate}} </p>
            </div>
        </div>
    </div>
</section>
<section class="thankyoucontent my-4">
    <div class="container">
        <h2 class="text-center">{{'Your Order Status' | translate}}</h2>
        <div class="trackerwrapper my-5" *ngIf="order_status">
            <ul class="trackeritems timeline" *ngIf="order_status.status != 10">
                <li class="trackitem" [ngClass]="{ active: order_status.status == 0 || order_status.status > 0}">
                    <div class="track1 trackicon">
                        <span class="fa fa-spinner ic" [ngClass]="{ bounce: order_status.status == 0}"></span>
                    </div>
                    <h5 class="text-center mt-3" [ngClass]="{ bounce: order_status.status == 0}">{{'Waiting to Accept' | translate}}</h5>
                </li>
                <li class="trackitem" [ngClass]="{ active: order_status.status == 1 || order_status.status > 1}">
                    <div class="track1 trackicon">
                        <span class="fa fa-cutlery ic" [ngClass]="{ bounce: order_status.status == 1}"></span>
                    </div>
                    <h5 class="text-center mt-3" [ngClass]="{ bounce: order_status.status == 1}">{{'Preparing Food' | translate}}</h5>
                </li>
                <li class="trackitem" [ngClass]="{ active: order_status.status == 5 || order_status.status > 5}">
                    <div class="track1 trackicon">
                        <span class="fa fa-motorcycle ic" [ngClass]="{ bounce: order_status.status == 5}"></span>
                    </div>
                    <h5 class="text-center mt-3" [ngClass]="{ bounce: order_status.status == 5}">{{'Rider on the way' | translate}}</h5>
                </li>
                <li class="trackitem" [ngClass]="{ active: order_status.status == 7}">
                    <div class="track1 trackicon">
                        <span class="fa fa-smile-o ic"></span>
                    </div>
                    <h5 class="text-center mt-3">{{'Delivered' | translate}}</h5>
                </li>
            </ul>
            <ng-container *ngIf="order_status.status == 10">
                <div class="position-center">
                    <div class="error-message">
                        <div class="track1 trackicon-red">
                             <i class="fa fa-frown-o" aria-hidden="true"></i>
                         </div>
                             <h5 class="text-center mt-3 text-danger">{{'Order Cancelled' | translate}}</h5>
                        </div>
                </div>
           </ng-container>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<section class="ordersummary my-4">
    <div class="container">
        <h2 class="text-center pb-3">{{'Your Order Summary' | translate}}
            <div *ngIf="order_status?.status == 0 && cancel" class="pull-right"><button class="btn btn-primary" (click)="cancelmyOrder(order_status?.request_id)">{{'Cancel My Order' | translate}}</button></div>
        </h2>
        <div class="row">
            <div class="col-md-6 text-left">
                <div class="row">
                    <div class="col-md-6">{{'Order Number' | translate}}</div>
                    <div class="col-md-6">{{order_status?.order_id}}</div>
                    <div class="clearfix"></div>
                    <div class="col-md-6">{{'Restaurant' | translate}}</div>
                    <div class="col-md-6">{{order_status?.restaurant_name}}</div>
                    <div class="clearfix"></div>
                    <div class="col-md-6">{{'Transaction Time' | translate}}</div>
                    <div class="col-md-6">{{order_status?.ordered_time}}</div>
                    <div class="clearfix"></div>
                    <div class="col-md-6">{{'Payment Method' | translate}}</div>
                    <div class="col-md-6">
                        <p *ngIf="order_status?.payment_type == 3">{{'Credit Card' | translate}}</p>
                        <p *ngIf="order_status?.payment_type == 2">{{'Benefit' | translate}}</p>
                        <p *ngIf="order_status?.payment_type == 1">{{'Cash' | translate}}</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="col-md-6 text-left">
                <div class="row">
                    <div class="col-md-4">{{'Address' | translate}}:</div>
                    <div class="col-md-8">{{order_status?.delivery_address}}</div>
                    <div class="clearfix"></div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class=" col-md-12 px-2">
                <table class="table font-16 text-left" aria-describedby="bill-summary">
                    <tbody>
                        <tr>
                            <th scope="col"><strong>{{'Item(s)' | translate}}</strong></th>
                            <th class="text-center" scope="col"><strong>{{'Qty' | translate}}</strong></th>
                            <th class="text-right item-price" scope="col"><strong>{{'Price' | translate}}</strong></th>
                            <th class="text-right total" scope="col"><strong>{{'Total' | translate}}</strong></th>
                        </tr>
                        <tr *ngFor="let items of order_status?.item_list">
                            <td class="v-middle">
                                <p class="p-0 m-0"><strong class="ng-binding">{{items.food_name}}</strong></p>
                                <p class="p-0 m-0" *ngIf="items.add_ons.length > 0">
                                    <span *ngIf="items?.add_ons.length>0">Choice:</span><span *ngFor="let k of items?.add_ons; let i=index">
                                       {{k.name | titlecase }}<span *ngIf="i+1 < items.add_ons.length">,</span>
                                    </span>
                                </p>
                            </td>
                            <td class="text-center v-middle">{{items.food_quantity}}</td>
                            <td class="text-right v-middle text-nowrap price">{{currency}}&nbsp;{{itemTotalPrice(items)?.toFixed(2)}}</td>
                            <td class="text-right v-middle text-nowrap">{{currency}}&nbsp;{{(itemTotalPrice(items) * items.food_quantity).toFixed(2)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="pull-right col-md-4 p-0">
            <div class="summary  p-0">
                 <h6 class="font-16" *ngIf="commonhelper?.dataChangeToNumber(order_status?.restaurant_discount) > 0">
                    <span class="pull-left">{{'Restaurant discount' | translate}}</span>
                    <span class="pull-right text-success text-right"> - {{currency}} {{commonhelper?.dataChangeToNumber(order_status?.restaurant_discount)?.toFixed(2)}}</span>
                    <div class="clearfix"></div>
                </h6>
                <h6 class="font-16" *ngIf="commonhelper?.dataChangeToNumber(order_status?.delivery_charge) > 0">
                    <span class="pull-left">{{'Delivery Fee' | translate}}</span>
                    <span class="pull-right text-dark text-right">{{currency}} {{commonhelper?.dataChangeToNumber(order_status?.delivery_charge)?.toFixed(2)}}</span>
                    <div class="clearfix"></div>
                </h6>
                <h6 class="font-16" *ngIf="commonhelper?.dataChangeToNumber(order_status?.restaurant_packaging_charge) > 0">
                    <span class="pull-left">{{'Packaging charge' | translate}}</span>
                    <span class="pull-right text-dark text-right">{{currency}} {{commonhelper?.dataChangeToNumber(order_status?.restaurant_packaging_charge)?.toFixed(2)}}</span>
                    <div class="clearfix"></div>
                </h6>
                <h6 class="font-16" *ngIf="commonhelper?.dataChangeToNumber(order_status?.tax) > 0">
                    <span class="pull-left">{{'Taxes' | translate}}</span>
                    <span class="pull-right text-dark text-right">{{currency}} {{commonhelper?.dataChangeToNumber(order_status?.tax)?.toFixed(2)}}</span>
                    <div class="clearfix"></div>
                </h6>
                <h6 class="font-16" *ngIf="commonhelper?.dataChangeToNumber(order_status?.offer_discount) > 0">
                    <span class="pull-left">{{'Coupon discount' | translate}}</span>
                    <span class="pull-right text-success text-right">- {{currency}} {{commonhelper?.dataChangeToNumber(order_status?.offer_discount)?.toFixed(2)}}</span>
                    <div class="clearfix"></div>
                </h6>
                <hr>
                <h6 class="font-16 bold">
                    <span class="pull-left text-dark">{{'Total' | translate}}</span>
                    <span class="pull-right text-primary text-right">{{currency}} {{commonhelper?.dataChangeToNumber(order_status?.bill_amount)?.toFixed(2)}}</span>
                    <div class="clearfix"></div>
                </h6>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</section>
<app-footer></app-footer>