<div class="modal-dialog otp-modal" role="document">
    <div class="modal-content p-3 px-4">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{'OTP verification' | translate}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeOtpModal('otpbox')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div>
                <p>Enter the 5-digit code sent to you at {{mobnoToShow}}</p>
               <div>
                <ng-otp-input (onInputChange)="onOtpChange($event)"  [config]="{length:5}"></ng-otp-input>
               </div>
            <div class="mt-2 mb-2">
                <div *ngIf="mobOtp">
                    <p class="text-success bold" *ngIf="otpTimer !==0 && otpTimer !==60">Resend otp in {{otpTimer}} s</p>
                    <p class="text-success bold cursor-pointer" (click)="resendOtp()" *ngIf="otpTimer ==0">Resend otp</p>
                   </div>
                <div *ngIf="otpError">
                    <p class="text-danger otp-validation"></p>
                   </div>
             </div>
            </div>
            <div class="clearfix"></div>
                <br>
             <button type="button" class="btn btn-primary btn-block" (click)="verifyOtp()">{{'Verify Otp' | translate}}</button>
         </div>
    </div>
</div>
