import {Injectable} from '@angular/core';

@Injectable()
export class WebStorageService {

   /**
    * Store auth id
    * @param {string} data 
    * @param {string} key
    */
   public storeAuthId(data, key?) {
        localStorage.setItem('authId', data);
        localStorage.setItem('isReloadHomePage', 'true');
       if(key){
        localStorage.setItem('user', key);
       }
    }

   /**
    * Store auth token
    * @param {string} data 
    */
   public storeAuthToken(data, key?) {
        localStorage.setItem('authToken', data);
        if(key){
            localStorage.setItem('user', key);
           }
    }

   /**
    * Store user name
    * @param {string} data 
    */
   public storeUserName(data) {
        localStorage.setItem('userName', JSON.stringify(data));
    }

   /**
    * Store user pic
    * @param {string} data 
    */
   public storeUserPic(data) {
        localStorage.setItem('userPic', data);
    }

   /**
    * Get auth id
    * @returns {string}  
    */
   public getAuthId() {
        return localStorage.getItem('authId');
    }

   /**
    * Get auth token
    * @returns {string}  
    */
   public getAuthToken() {
        return localStorage.getItem('authToken');
    }

  /**
   * Get user name
   * @returns{string}  
   */
  public getUserName() {
        return JSON.parse(localStorage.getItem('userName'));
    }

   /**
    * Get user pic
    * @returns {string} 
    */
   public getUserPic() {
        return localStorage.getItem('userPic');
    }

   /**
    * Removes user data
    */
   public removeUserData() {
        localStorage.removeItem('authId');
        localStorage.removeItem('authToken');
        localStorage.removeItem('userPic');
    }

 
    //remove details

    /**
     * remove storage data
     * @param {array} data 
     */
  public removeData(data){
    if(data.length>0){
        data.forEach(x=>{
            localStorage.removeItem(x); 
        })
    }
  }

  //get details

  /**
    * Get user type
    * @returns {string}  
    */
  public getUserType() {
  return localStorage.getItem('user');
}

 //store details

  /**
    * store data to key ->values stored in local storage as JSON string
    * @param {object}  ->{key:value}
    */
  public storeLocalData(data:any) {
    // Using array methods
      Object.entries(data).forEach(([key, value]) => {
        localStorage.setItem(key, JSON.stringify(value));
      });
  }
}
