<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{title}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <h3 class="hidden-xs text-left">{{'Restaurants in' | translate}} {{title}}</h3>
        <div class="row my-4">
            <div class="col-md-3 filters">
                <div class="rest-search">
                    <input class="m-b-md full-width form-control" id="restSearchBox" type="text" placeholder="{{'Search Restaurants' | translate}}" [(ngModel)]="term">
                    <span class="search-icon">
                        <span class="fa fa-search"></span>
                    </span>
                    <span class="delete-icon" style="display: none;">
                        <span class="fa fa-close"></span>
                    </span>
                </div>
                <div class="cuisineslist mt-3 hidden-xs">
                    <h5 class="text-left">{{'APPLY FILTERS' | translate}}</h5>
                   <ul class="mt-4">
                   <h5 class="text-left text-success">{{'Sort By' | translate}}</h5>
                        <ng-container *ngFor="let data of sortList; let x = index">
                            <li>
                                <div class="col-md-16 my-1 text-left dir">
                                    <label class="m-0 radiobutton col-md-12">
                                        <input class="filterCheckBox1 filterCheckBox2" 
                                            type="radio" name="sortradiosingle"
                                            value="American" (click)="sortFilterOnly(x+1)" [checked]="sortOptionKey==x+1">
                                        <span class="checkmark"></span>
                                        <span class="pl-2 text-primary">{{data | titlecase}}</span>
                                    </label>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                    <ul class="mt-4">
                        <h5 class="text-left text-success">{{'Filter By' | translate}}</h5>
                        <ng-container *ngFor="let data of filterList; let x = index">
                            <li>
                                <div class="col-md-16 my-1 text-left dir">
                                    <label class="m-0 radiobutton col-md-12">
                                        <input class="filterCheckBox1 filterCheckBox2" 
                                            type="radio" name="filterradiosingle{{x}}"
                                            value="American" (click)="filterOptionOnly(data, x+1)" [checked]="filterOptionData.indexOf(data) > -1">
                                        <span class="checkbox"></span>
                                        <span class="pl-2 text-primary">{{data | titlecase}}</span>
                                    </label>
                                </div>
                            </li>
                        </ng-container>

                    </ul>
                    <ul class="mt-4">
                        <li class="my-2 p-0 m-0 border-0 flex justify-around item-center">
                            <a class="text-uppercase clear-btn" *ngIf="sortOptionKey != 0 || filterOptionKeys.length>0" (click)="clearSingleFilter()"><strong>{{'Clear' | translate}}</strong></a>
                            <a data-toggle="modal" (click)="showAllFilterOptions()" class="text-uppercase text-size-10"><strong>{{'Show All' | translate}}</strong></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9 listingwrap">
                <h3 class="my-3 hidden-xs text-left">{{'Our Top Restaurants' | translate}}</h3>
                <!--shimmer-->
                <div>
                    <div class="sliderwrapper col-md-12 p-0 hidden-xs" *ngIf="resturantToplistResponse">
                        <ngx-slick-carousel   class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem style="cursor: pointer;" class="slide col-md-3 shadow pointer " *ngFor="let data of [].constructor(slideConfig?.slidesToShow)">
                                <div class="height-200" style="cursor: pointer">
                                    <!--shimmer loader-->
                                    <div>
                                          <ngx-shimmer-loading [shape]="'square'" [width]="'200px'"></ngx-shimmer-loading>
                                    </div>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="sliderwrapper col-md-12 p-0 hidden-xs" *ngIf="!resturantToplistResponse && restaurantlist_top.length>0">
                        <ngx-slick-carousel   class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem style="cursor: pointer;" class="slide col-md-3 shadow pointer " *ngFor="let row of restaurantlist_top" (click)="gotoMenudetails(row.name,row.id)">
                                <div class="height-200" style="cursor: pointer">
                                    <div class="item-1">
                                        <div class="list-card bg-white h-100 overflow-hidden position-relative">
                                            <div class="list-card-image">
                                                <div class="star position-absolute"></div>
                                                <div class="overlay-container top-brand-images">
                                                    <img src="{{row.image}}" alt="{{alttitle}} {{row.name}}" title="{{alttitle}} {{row.name}}" class="img-fluid item-img cus-size"/>
                                                    <div class="overlay" *ngIf="row.is_open==0">Closed</div>
                                                    <div class="overlay" *ngIf="row?.is_open==0?false:row?.is_busy==1">Busy</div>
                                                </div>
                                            </div>
                                            <div class="position-relative mt-2">
                                                <div class="list-card-body">
                                                    <h6 class="mb-1 text-center">
                                                        <a class="text-black uppercase ellipsis text-dark smaller">{{row.name}}</a>
                                                    </h6>
                                                    <h6 class="mb-1 text-center">
                                                        <a class="text-grey">
                                                            <div class="mobile_item_div__rest_adderss capitalize">
                                                                <span class="cuisinetext" *ngFor="let cuisines of row?.cuisines; let i = index">
                                                                    <span *ngIf="i < 1">{{cuisines.name | titlecase}}</span>
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </h6>
                                                 </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                    <div class="mt-4" *ngIf="!resturantToplistResponse && restaurantlist_top.length==0">
                       <h6 class="text-center" >{{'No Restaurants Found' | translate}}</h6>
                    </div>
                </div>
                <!--Favourite cuisines-->
                <h3 class="my-3 hidden-xs text-left"  *ngIf="cuisines">{{'Choose Your Favourite Cuisine' | translate}}</h3>
                <div class="sliderwrapper col-md-12 p-0 hidden-xs" *ngIf="cuisines">
                    <ngx-slick-carousel class="carousel cuisine" #slickModal="slick-carousel" [config]="cuisinesConfig">
                        <div ngxSlickItem class="slide pointer cuisine-slider" *ngFor="let row of cuisines.data">
                            <div class="cursor" (click)="selectCuisine(row)">
                                <div class="item-1">
                                    <div class="list-card bg-white h-100 overflow-hidden position-relative">
                                        <div class="list-card-image">
                                            <div class="star position-absolute"></div>
                                            <div class="overlay-container">
                                                <img src="{{cuisines.base_url+''+row.cuisine_image}}" alt="{{row.name}}" title="{{row.name}}" class="img-fluid item-img cuisine-img" />
                                                <div class="row">
                                                    <div class="col-12 flex justify-center">
                                                        <span >{{row.name}}</span>
                                                    </div>
                                                </div>
                                             </div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
                <h3 class="my-3 hidden-xs text-left">{{'Restaurants' | translate}}</h3>
               <div class="flex justify-between" *ngIf="selectedCuisineName">
                <p class="text-theme-green">{{this.selectedCuisineName}}</p>
                <p class="clear-btn" (click)="clearCuisineFilter();">All Restaurant</p>
               </div>
                <ul class="restaurantlisting mt-3" *ngIf="resturantlistResponse">
                     <!--shimmer loader-->
                     <div class="row">
                        <ngx-shimmer-loading [shape]="'rect'" [width]="'500px'" [height]="'100px'"></ngx-shimmer-loading>
                    </div>
                </ul>
                <ul class="restaurantlisting mt-3"  *ngIf="!resturantlistResponse && restaurantlist.length>0">
                    <li class="rounded border" (click)="gotoMenudetails(key.name,key.id)" *ngFor="let key of restaurantlist | filter:term">
                        <div class="restaurant-item">
                            <div class="media-left text-center">
                                <div class="rest-logo card-rest-logo small-overlay-container">
                                    <span class="valign-helper"></span>
                                    <img src="{{key.image}}" alt="{{alttitle}} {{key.name}}" title="{{alttitle}} {{key.name}}">
                                    <div class="small-overlay" *ngIf="key?.is_open==0">Closed</div>
                                    <div class="small-overlay" *ngIf="key?.is_open==0?false:key?.is_busy==1">Busy</div>
                                </div>
                            </div>
                            <div class="media-body pl-3 text-left cus-center-align">
                                <h6 class="text-dark mb-1">{{key.name}}</h6>
                                <p class="cuisinetext mb-2">
                                    <span class="cuisinetext" *ngFor="let cuisines of key?.cuisines; let i=index">
                                        <span *ngIf="i != 0 && i!=key.cuisines.length">&nbsp;•&nbsp;</span>
                                        {{cuisines.name | titlecase}}
                                     </span>
                                </p>
                                <p class="mb-1">{{'Delivery Within' | translate}} : {{key.travel_time}} {{'Mins' | translate}} &nbsp;&nbsp;&nbsp; {{'Min Order' | translate}}:
                                    {{currency}} {{key.min_order_value.toFixed(2)}}
                                </p>
                                <p class="mb-1 text-success" *ngIf="key?.offer_type==1 && key?.discount_type=='1'">
                                     {{currency}}{{key?.offer_amount}} flat offer.
                                    </p>
                                <p class="mb-1 text-success" *ngIf="key?.offer_type==1 && key?.discount_type=='2'">
                                     {{key?.offer_amount}}% offer upto {{currency}}{{key?.target_amount}}.
                                    </p>
                                <p class="mb-1 text-success" *ngIf="key?.offer_type==2 && key?.discount_type=='1'">
                                    {{currency}} {{key?.offer_amount}} flat offer on order above {{currency}}{{key?.offer_value}}.
                                 </p>
                                <p class="mb-1 text-success" *ngIf="key?.offer_type==2 && key?.discount_type=='2'">
                                    {{key?.offer_amount}}% on order above {{key?.offer_value}}.
                                        <span *ngIf="key?.target_amount">&nbsp;•&nbsp;</span>
                                        Max Discount {{currency}}{{key?.target_amount}}
                                </p>
                             </div>
                        </div>
                    </li>
                    <div class="no-data" *ngIf="term?term.trim().length>=1 && (restaurantlist | filter:term).length===0:false">
                        <div class="text-center">
                            <img src="assets/images/no-data/no-restaurant-data.png" alt="no-restaurant">
                        </div>
                        <h6 class="text-center mt-4">{{'No Restaurants Found' | translate}}</h6>
                    </div>
                </ul>
                <div class="mt-4" *ngIf="!resturantlistResponse?restaurantlist.length===0:false">
                    <div class="text-center">
                        <img src="assets/images/no-data/no-restaurant-data.png" alt="no-restaurant">
                    </div>
                    <h6 class="text-center" >{{'No Restaurants Found' | translate}}</h6>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<div class="modal fade" id="filterAll" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-3 px-4">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Filters' | translate}}</h5>
                   
               <button type="button" class="close" (click)="closeModal('#filterAll')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-end">
                        <a class="text-uppercase clear-btn" *ngIf="sortOptionKey != 0 || filterOptionKeys.length>0 || multipleCuisineOptions.length>0" (click)="clearMultipleFilter()"><strong>{{'Clear' | translate}}</strong></a>
                    </div>
                    <div class="col-12">
                        <ul class="mt-4">
                            <h3 class="text-left text-success">{{'Sort By' | translate}}</h3>
                            <ng-container *ngFor="let data of sortList; let x = index">
                                <li>
                                    <div class="col-md-16 my-1 text-left dir">
                                        <label class="m-0 radiobutton col-md-12">
                                            <input class="filterCheckBox1 filterCheckBox2" 
                                                type="radio" name="sortradio"
                                                value="American" (click)="selectSortOptions(x+1)" [checked]="x+1==sortOptionKey">
                                            <span class="checkmark"></span>
                                            <span class="pl-2 text-primary">{{data | titlecase}}</span>
                                        </label>
                                      </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                   <div class="col-12">
                    <ul class="mt-4">
                        <h3 class="text-left text-success">{{'Filter By' | translate}}</h3>
                        <ng-container *ngFor="let data of filterList; let x = index">
                            <li>
                                <div class="col-md-16 my-1 text-left dir">
                                    <label class="m-0 radiobutton col-md-12">
                                        <input class="filterCheckBox1 filterCheckBox2" id="filterradio{{x}}"
                                            type="radio" name="filterradio{{x}}"
                                            value="American" (click)="selectFilterOptions(data,x+1)" [checked]="filterOptionData.indexOf(data) > -1">
                                        <span class="checkbox"></span>
                                        <span class="pl-2 text-primary">{{data | titlecase}}</span>
                                    </label>
                                </div>
                            </li>
                        </ng-container>

                    </ul>
                   </div>
                   <div class="col-12">
                    <div class="mt-4">
                        <h3 class="text-left text-success">{{'Cuisines' | translate}}</h3>
                        <div  class="filter-cuisine-arrangements">
                            <ng-container *ngFor="let data of filters.data; let x = index" class="mb-2">
                               <div class="my-1">
                                <div class="cuisine-arrange" (click)="selectCuisineFilterOptions(data)">
                                    <span class="pl-2" [ngClass]="{'text-white bg-green': data.selected==true}">{{data?.name | titlecase}}</span>
                                </div>
                               </div>
                            </ng-container>
                        </div>
                       </div>
                   </div>
                   <div class="col-12 mt-4 flex justify-center">
                    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-xl-6 findrestaurant">
                        <button class="btn btn-primary btn-block btn-lg btn-gradient btn-banner text-uppercase" type="button" (click)="applyFilterOptions()"> {{'Apply Filters' | translate}} </button>
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>