<cookie-law position="top" expiration="100"></cookie-law>
<div class="loading">
    <h2>{{ 'Please Wait.....' | translate}}</h2>
    <div class="spinner-border text-primary mt-270" role="status">
        <span class="sr-only">{{ 'Loading...' | translate}}</span>
    </div>
</div>
<div class="container " *ngIf="mobilecheck">
    <div class="row py-2 apppopuppup">
        <div class="col-lg-12">
            <div class="d-flex align-items-center justify-content-between gap-2">
                <div class="" (click)="closeApppopup()">
                    <span class="fa fa-close" style="font-size: 20px;"></span>
                </div>
                <div class="">
                    <div class="pull-left">
                        <img src="assets/images/common/appicon.png" width="30" alt="app-icon">
                    </div>
                    <div class="pull-left">
                        <strong class="text-primary pl-2">{{'Eatzilla Food Delivery APP' | translate}}</strong>
                        <p class="m-0 pl-2">{{'Get your food on the go!' | translate}}</p>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="">
                    <button class="btn btn-primary btn-sm text-uppercase arfont-16" (click)="downloadApp()">{{'Download' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg navbar-dark bg-primary p-0">
    <div class="container">
        <a class="navbar-brand" routerLink="{{urilang}}/"><img src="assets/images/common/logo.png" height="44" alt="{{alttitle}}" title="{{alttitle}}"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor02" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse ngbCollapse" id="navbarColor02">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="{{urilang}}/"><span class="fa fa-home search-icon"></span>&nbsp;&nbsp;{{'Home' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="commonHelper.getRouteUrl()==urls.HOME || commonHelper.getRouteUrl()==urls.EMPTY">
                    <a class="nav-link" (click)="showFoodSearchModal()"><span class="fa fa-search"></span>&nbsp;&nbsp;Search</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="viewAllRestaurants()"><span class="fa fa-solid fa-utensils"></span>&nbsp;&nbsp;{{'All Restaurants' | translate}}</a>
                </li>
                <li class="nav-item" *ngIf="!loggedIn && userIs != userType.GUEST_USER">
                    <a class="nav-link" (click)="showLoginbox()"><span class="fa fa-user"></span>&nbsp;&nbsp;{{'Login' | translate}}</a>
                </li>
                <li class="nav-item dropdown" *ngIf="loggedIn && userIs != userType.GUEST_USER">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span *ngIf="userProfileDetails !== '{}'" class="fa fa-user"></span>&nbsp;&nbsp;{{userProfileDetails?.name}}
                    </a>
                    <div class="dropdown-menu text-left" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item " href="{{urilang}}/profile/orders"><span class="fa fa-shopping-cart mr-2"></span> <span>{{'Your Orders' | translate}}</span></a>
                        <a class="dropdown-item " href="{{urilang}}/profile/updateprofile"><span class="fa fa-user mr-2"></span> <span>{{'Your Profile' | translate}}</span></a>
                        <a class="dropdown-item " href="{{urilang}}/profile/savedaddresses"><span class="fa fa-map-marker mr-2"></span> <span>{{'Saved Addresses' | translate}}</span></a>
                        <a class="dropdown-item " (click)="logout()"><span class="fa fa-arrow-left mr-2"></span> <span>{{'Log Out' | translate}}</span></a>
                    </div>
                </li>
                <li class="nav-item dropdown" *ngIf="userIs == userType.GUEST_USER">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="fa fa-user"></span>&nbsp;&nbsp;{{userName}}
                    </a>
                    <div class="dropdown-menu text-left" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item text-dark" href="{{urilang}}/profile/orders"><span class="fa fa-shopping-cart mr-2"></span> <span>{{'Your Orders' | translate}}</span></a>
                        <a class="dropdown-item text-dark" (click)="showLoginbox()"><span class="fa fa-user mr-2"></span> <span>{{'Login as User' | translate}}</span></a>
                      </div>
                </li>
                <!--Later usage - language change option-->
                <!-- <li class="nav-item" *ngIf="lang == 'ar'">
                    <a class="nav-link" (click)="changeLang('en')"><i class="flagicon"><img alt="Eatzilla - English" title="Eatzilla - English" src="assets/images/icons/en_gb.png" width="15"></i> English</a>
                </li>
                <li class="nav-item" *ngIf="lang == 'en'">
                    <a class="nav-link" (click)="changeLang('ar')">العربية <i class="flagicon"><img alt="Eatzilla - English" title="Eatzilla - English" src="assets/images/icons/ar_ae.png" width="15"></i></a>
                </li> -->
            </ul>
        </div>
    </div>
</nav>
<div class="modal fade" id="loginbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <app-login></app-login>
</div>
<div class="modal fade" id="registerbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <app-register [mobVerified]="mobVerified"></app-register>
</div>
<div class="modal fade" id="forgotpasswordbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <app-forgotpassword></app-forgotpassword>
</div>
<div class="modal fade" id="guestuserlogin" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <app-guest-user-login [mobVerified]="mobVerified"></app-guest-user-login>
</div>
<div class="modal fade" id="searchDish" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <app-search-dish></app-search-dish>
</div>
<div class="modal fade" id="otpbox" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <app-otp-verification [counterId]="counterId" [mobileNumber]="registeringMobNum" [component]="component" [formDetails]="formDetails" [mobOTP]="mobOTP" [mobnoToShow]="mobnoTOShow"></app-otp-verification>
</div>

