<app-header></app-header>
<section class="breadcrumbs m-3" *ngIf="!singleRestaurant">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item"><a routerLink="/restaurants">{{'All Restaurants' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{restaurantname}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                <h1 class="text-center text-light glorytext">{{restaurantname}}</h1>
                <p class="paragraph-font text-center text-light"> {{'Select your area to see restaurant menu.' | translate}} </p>
                <div class="bannerformcontainer">
                    <form (submit)="submitSearch($event)">
                        <div class="row">
                            <div class="col-md-8 col-lg-8 pr-0 col-xs-12 col-sm-12">
                                <img class="mapicon-home" src="assets/images/icons/location.png" alt="{{alttitle}} {{restaurantname}}" title="{{alttitle}} {{restaurantname}}">
                                <input (focus)="showSavedAddressOnFocus()" class="form-control bannerinput" #agmSearch placeholder="{{'SEARCH FOR AREA, BLOCK or STREET NAME' | translate}}" type="text" autocomplete="off">
                                <input type="hidden" value="" id="lat">
                                <input type="hidden" value="" id="lng">
                                <div class="dropdownsavedlocations" style="display: none;" *ngIf="loggedIn">
                                    <ul>
                                        <li><strong>{{'Saved Addresses' | translate}}</strong></li>
                                        <ng-container *ngFor="let add of allAvailableDeliveryAddress?.data">
                                            <li (click)="selectAddress(add)">
                                                <div class="pull-left mr-2"><span class="fa fa-map-marker"></span></div>
                                                <div class="pull-left">
                                                    <h6 class="m-0">{{add.address}}</h6>
                                                    <p class="text-secondary font-12 m-0">{{add.address}}</p>
                                                </div>
                                                <div class="clearfix"></div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 findrestaurant">
                                <button class="btn btn-primary btn-block btn-lg btn-gradient btn-banner text-uppercase" type="submit">{{'Show Menu' | translate}}</button>
                            </div>
                        </div>
                    </form>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="bannerpaymentoptions col-lg-12 col-md-12 col-sm-12">
                <img class="imgpaymentoption" src="assets/images/common/paymentoptions.png" alt="Eatzilla - Payment Options" title="Eatzilla - Payment Options">
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</section>
<section class="color1 mb-5" style="background: white!important">
    <div class="container">
        <h2 class="my-3 text-center" *ngIf="freedelivery == 0">{{'Get Free delivery on all orders from' | translate}} {{restaurantname}}</h2>
        <h2 class="my-3 text-center" *ngIf="freedelivery > 0">{{'Get your Food Delivered from' | translate}} {{restaurantname}}</h2>
        <h6 class="text-center" *ngIf="singleRestaurant?.address">{{singleRestaurant?.address}}</h6>
        <p class="text-center" *ngIf="singleRestaurant?.shop_description">{{singleRestaurant?.shop_description}}</p>
        <div class="text-center">
            <span class="text-center" *ngIf="singleRestaurant?.min_order_value">
                <span class="cuisinetext">
                    Min Order: {{currency}} {{singleRestaurant?.min_order_value}}.
                  </span>
                </span>
            <span>&nbsp;•&nbsp;</span>
            <span class="text-center text-secondary ellipsis" *ngIf="singleRestaurant?.offer_type==1 && singleRestaurant?.discount_type=='1'">
                <span class="cuisinetext">
                    {{currency}}{{singleRestaurant?.offer_amount}} flat offer.
                 </span>
            </span>
            <span class="text-center text-secondary ellipsis" *ngIf="singleRestaurant?.offer_type==1 && singleRestaurant?.discount_type=='2'">
                <span class="cuisinetext">
                    {{singleRestaurant?.offer_amount}}% offer upto {{singleRestaurant?.target_amount}}.
                  </span>
            </span>
            <span class="text-center text-secondary ellipsis" *ngIf="singleRestaurant?.offer_type==2 && singleRestaurant?.discount_type=='1'">
                <span class="cuisinetext">
                    {{currency}} {{singleRestaurant?.offer_amount}} flat offer on order above {{singleRestaurant?.offer_value}}.
                 </span>
            </span>
            <span class="text-center text-secondary ellipsis" *ngIf="singleRestaurant?.offer_type==2 && singleRestaurant?.discount_type=='2'">
                <span class="cuisinetext">
                    {{singleRestaurant?.offer_amount}}% on order above {{singleRestaurant?.offer_value}}.
                    <span *ngIf="!singleRestaurant?.max_discount">&nbsp;•&nbsp;</span>
                    Max Discount {{currency}}{{singleRestaurant?.max_discount}}
                </span>
            </span>
        </div>
        <!-- End row -->

        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-4 col-xs-8 my-3" *ngFor="let key of singleRestaurant?.food_list">
                <div class="rounder border">
                    <div class="restaurantcontainerimage p-a b-b text-center ellipsis">
                        <img *ngIf="key.food_image" (error)="loadImage($event,'itemImage'+key.food_id)" id=itemImage{{key.food_id}} src="{{key.food_image}}" height="100" alt="{{alttitle}} {{key.name}}" title="{{alttitle}} {{key.name}}">
                        <img *ngIf="!key.food_image" src="assets/images/common/nofood.jpg" alt="{{alttitle}} {{key.name}}" title="{{alttitle}} {{key.name}}" />
                    </div>
                    <h6 class="text-center pt-3 ellipsis">{{key.name}}</h6>
                    <p class="text-center text-secondary ellipsis">
                        <span class="cuisinetext" *ngFor="let category of key?.category">
                            {{'FROM' | translate}} {{category.category_name | uppercase}}
                        </span>
                    </p>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>

    </div>
    <!-- End container -->
</section>
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="addressMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="singleRestaurant">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Delivery Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" (click)="resetMap()">&times;</span>
          </button>
            </div>
            <div class="modal-body" *ngIf="latitudeinput && longtitudeinput">
                <app-googlemap [restaurantname]="restaurantname" [id]="id" [componenttype]="componenttype" [setaddressinput]="setaddressinput" [latitudeinput]="latitudeinput" [longtitudeinput]="longtitudeinput"></app-googlemap>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>