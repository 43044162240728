
<ng-container *ngIf="tabKey==tab.UPCOMING">
<ng-container *ngIf="orderHistoryResponse">
    <ul>
    <li class="mb-4" (click)="viewOrderDetails(ordersu)" 
    *ngFor="let ordersu of orderHistoryResponse?.upcoming_orders; let i=index">
    <div class="row " [ngClass]="i+1 < orderHistoryResponse?.upcoming_orders.length? 'border-bottom p-3':'p-3 '">
        <div class="col-sm-3">
            <div class="thumb mb-3">
                <img src="{{ordersu.restaurant_image}}"
                    alt="Eatzilla - {{ordersu.restaurant_image}}"
                    title="Eatzilla - {{ordersu.restaurant_image}}" class="img-fluid">
            </div>
        </div>
        <div class="col-sm-9">
            <h6>{{ordersu.restaurant_name}}</h6>
            <p class="m-0">{{'Order Placed At:' | translate}} {{ordersu.ordered_on}}</p>
            <p class="m-0">{{'Order ID:' | translate}} {{ordersu.order_id}}
                <span class="text-success" *ngIf="ordersu.status == 7"><strong>{{'Delivered' |
                        translate}}</strong></span>
                <span class="text-danger" *ngIf="ordersu.status == 10"><strong>{{'Cancelled' |
                        translate}}</strong></span>
                <span class="text-primary" *ngIf="ordersu.status == 0"><strong>{{'Waiting to Accept'
                        | translate}}</strong></span>
                <span class="text-primary"
                    *ngIf="ordersu.status > 0 && ordersu.status < 5"><strong>{{'Preparing Food' |
                        translate}}</strong></span>
                <span class="text-primary"
                    *ngIf="ordersu.status == 5"><strong>{{'Rider
                        on the Way' | translate}}</strong></span>
            </p>
            <button class="btn btn-primary btn-sm mt-2"
                    (click)="trackmyorder(ordersu.request_id)"
                    *ngIf="ordersu.status != 7 && ordersu.status != 10">{{'TRACK ORDER' |
                    translate}}</button>
        </div>
        <div class="clearfix"></div>
    </div>
    </li>
</ul>
</ng-container>
<ul>
    <li class="text-center" *ngIf="orderHistoryResponse && !orderHistoryResponse['status']?true:orderHistoryResponse?.upcoming_orders?.length==0?true:false">
        <p>No orders</p>
    </li>
</ul>
</ng-container>
<ng-container *ngIf="tabKey==tab.DELIVERED">
    <ul>
    <li class="" *ngFor="let orders of orderHistoryResponse?.past_orders; let i = index">
       <div [ngClass]="i+1 < orderHistoryResponse?.upcoming_orders.length? 'border-bottom p-3':'p-3'">
        <div class="row" (click)="viewOrderDetails(orders)" >
            <div class="col-md-3">
                <div class="thumb">
                    <img src="{{orders.restaurant_image}}"
                        alt="Eatzilla - {{orders.restaurant_image}}"
                        title="Eatzilla - {{orders.restaurant_image}}">
                </div>
            </div>
            <div class="col-md-9">
                <h6>{{orders.restaurant_name}}</h6>
                <p class="m-0">{{'Order Placed At:' | translate}} {{orders.ordered_on}}</p>
                <p class="m-0">{{'Order ID:' | translate}} {{orders.order_id}}
                    <span class="text-success" *ngIf="orders.status == 7"><strong>{{'Delivered' |
                            translate}}</strong></span>
                    <span class="text-danger" *ngIf="orders.status == 10"><strong>{{'Cancelled' |
                            translate}}</strong></span>
                    <br>
                    <button class="btn btn-primary btn-sm" (click)="trackmyorder(orders.request_id)"
                        *ngIf="orders.status != 7 && orders.status != 10">{{'TRACK ORDER' |
                        translate}}</button>
                </p>
                <div class="d-flex align-items-center gap-custom mt-2" *ngIf="orders.status == 7" >
                    <button class="btn btn-primary btn-sm" (click)="reorder(orders)">{{'Re-order' | translate}}</button>
                    
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
       <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-9">
                <div class="position-relative">
                    <div class="rateorderpos">
                        <button class="btn btn-primary btn-sm cus-btn-rating" *ngIf="orders['is_rated']!=1"
                            (click)="viewRatingModal(orders)">{{'Rate my order' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        
       </div>
        </li>
    </ul>
       <ul>
        <li class="text-center" *ngIf="orderHistoryResponse && !orderHistoryResponse['status']?true:orderHistoryResponse?.past_orders?.length==0?true:false">
            <p>No orders</p>
        </li>
       </ul>
</ng-container>
<ng-container *ngIf="tabKey==tab.CANCELLED">
    <ul>
    <li class="" *ngFor="let orders of orderHistoryResponse?.cancel_orders; let i = index;">
        <div class="row" (click)="viewOrderDetails(orders)" [ngClass]="i+1 < orderHistoryResponse?.upcoming_orders.length? 'border-bottom p-3':'p-3'">
            <div class="col-md-3">
                <div class="thumb">
                    <img src="{{orders.restaurant_image}}"
                        alt="Eatzilla - {{orders.restaurant_image}}"
                        title="Eatzilla - {{orders.restaurant_image}}">
                </div>
            </div>
            <div class="col-md-9">
                <h6>{{orders.restaurant_name}}</h6>
                <p class="m-0">{{'Order Placed At:' | translate}} {{orders.ordered_on}}</p>
                <p class="m-0">{{'Order ID:' | translate}} {{orders.order_id}}
                    <span class="text-success d-block mt-1" *ngIf="orders.status == 7"><strong>{{'Delivered' |
                            translate}}</strong></span>
                    <span class="text-danger d-block mt-1" *ngIf="orders.status == 10"><strong>{{'Cancelled' |
                            translate}}</strong></span>
                    <br>
                    <button class="btn btn-primary btn-sm" (click)="trackmyorder(orders.request_id)"
                        *ngIf="orders.status != 7 && orders.status != 10">{{'TRACK ORDER' |
                        translate}}</button>
                </p>
        
            </div>
            <div class="clearfix"></div>
        </div>
        </li>
    </ul>
        <ul>
            <li class="text-center" *ngIf="orderHistoryResponse && !orderHistoryResponse['status']?true:orderHistoryResponse?.cancel_orders?.length==0?true:false">
                <p>No orders</p>
            </li>
        </ul> 
</ng-container>

<div class="modal fade" id="orderDetailsdiv" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Order Details' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-7">
                        <div class="d-flex align-items-center mb-2">
                            <h6>{{'Order Number' | translate}} :</h6>
                            <h6 class="pl-2"><strong>{{orderdetailspopup?.order_id}}</strong></h6>
                        </div>
                        <div class="d-flex alingn-items-center mb-2">
                            <h6>{{'Restaurant' | translate}} :</h6>
                            <h6 class="pl-2"><strong>{{orderdetailspopup?.restaurant_name}}</strong></h6>
                        </div>
                        <div class="d-flex alingn-items-center mb-2">
                            <h6>{{'Transaction Time' | translate}} :</h6>
                            <h6 class="pl-2"><strong>{{orderdetailspopup?.ordered_on}}</strong></h6>
                        </div>
                        <div class="d-flex alingn-items-center mb-2">
                            <h6>{{'Payment Method' | translate}} :</h6>
                            <h6 class="pl-2" *ngIf="orderdetailspopup?.payment_type == 3"><strong>{{'Credit Card' | translate}}</strong></h6>
                            <h6 class="pl-2" *ngIf="orderdetailspopup?.payment_type == 2"><strong>{{'Benefit' | translate}}</strong></h6>
                            <h6 class="pl-2" *ngIf="orderdetailspopup?.payment_type == 1"><strong>{{'Cash' | translate}}</strong></h6>    
                        </div>
                    </div>
                    <div class="col-md-5">
                        <h6>{{'Address' | translate}}:</h6>
                        <p>{{orderdetailspopup?.delivery_address}}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class=" col-md-12">
                        <table class="table text-left" aria-describedby="food-details">
                            <tbody>
                                <tr>
                                    <th scope="col"><strong>{{'Item(s)' | translate}}</strong></th>
                                    <th class="text-center" scope="col"><strong>{{'Qty' | translate}}</strong></th>
                                    <th class="text-center item-price" scope="col"><strong>{{'Price' | translate}}</strong></th>
                                    <th class="text-right total" scope="col"><strong>{{'Total' | translate}}</strong></th>
                                </tr>
                                <tr *ngFor="let items of orderdetailspopup?.item_list">
                                    <td class="v-middle">
                                        <p class="p-0 m-0"><strong class="ng-binding">{{items.food_name}}</strong></p>
                                        <p class="p-0 m-0" *ngIf="items.add_ons.length > 0">
                                            <span>Choice:</span><span *ngFor="let k of items.add_ons; let i=index">
                                                {{k.name | titlecase  }}<span *ngIf="i+1 < items.add_ons.length">,</span>
                                             </span>
                                        </p>
                                    </td>
                                    <td class="text-center v-middle">1</td>
                                    <td class="text-center v-middle text-nowrap price">{{currency}}&nbsp;
                                        {{items.item_price.toFixed(2)}}</td>
                                    <td class="text-right v-middle text-nowrap">{{currency}}&nbsp; {{(items.item_price *
                                        items.food_quantity).toFixed(2)}}</td>
                                </tr>
                                <tr  *ngIf="commonhelper?.dataChangeToNumber(orderdetailspopup?.restaurant_discount) > 0">
                                    <td colspan="2"></td>
                                    <td>
                                        <h6 class="font-16 text-right">
                                            <span >{{'Restaurant discount' | translate}}</span>
                                        </h6>               
                                    </td>
                                    <td>
                                        <span class="pull-right text-success text-right"> - {{currency}}
                                                {{commonhelper?.dataChangeToNumber(orderdetailspopup?.restaurant_discount)?.toFixed(2)}}</span>
                                    </td>
                                </tr>
                                <tr *ngIf="commonhelper?.dataChangeToNumber(orderdetailspopup?.delivery_charge) > 0">
                                    <td colspan="2"></td>
                                    <td> 
                                        <h6 class="font-16 text-right"  >
                                        <span >{{'Delivery Fee' | translate}}</span></h6>
                                    </td>
                                    <td>
                                        <span class="pull-right text-dark text-right">{{currency}}
                                            {{commonhelper?.dataChangeToNumber(orderdetailspopup?.delivery_charge)?.toFixed(2)}}
                                        </span>
                                    </td>
                                </tr>
                                <tr  *ngIf="commonhelper?.dataChangeToNumber(orderdetailspopup?.restaurant_packaging_charge) > 0">
                                    <td colspan="2"></td>
                                    <td>
                                        <h6 class="font-16 text-right">
                                            <span>{{'Packaging charge' | translate}}</span>
                                        </h6>
                                    </td>
                                    <td>
                                        <span class="pull-right text-dark text-right">{{currency}}
                                        {{commonhelper?.dataChangeToNumber(orderdetailspopup?.restaurant_packaging_charge)?.toFixed(2)}}</span>
                                    </td>
                                </tr>
                                <tr *ngIf="commonhelper?.dataChangeToNumber(orderdetailspopup?.tax) > 0">
                                    <td colspan="2"></td>
                                    <td>
                                        <h6 class="font-16 text-right" >
                                            <span >{{'Taxes' | translate}}</span>
                                        </h6>
                                    </td>
                                    <td>
                                        <span class="pull-right text-dark text-right">{{currency}}
                                        {{commonhelper?.dataChangeToNumber(orderdetailspopup?.tax)?.toFixed(2)}}</span>
                                    </td>
                                </tr>
                                <tr  *ngIf="commonhelper?.dataChangeToNumber(orderdetailspopup?.offer_discount) > 0">
                                    <td colspan="2"></td>
                                    <td>
                                        <h6 class="font-16 text-right">
                                             <span >{{'Coupon discount' | translate}}</span>
                                        </h6>
                                    </td>
                                    <td>
                                        <span class="pull-right text-success text-right"> - {{currency}}
                                            {{commonhelper?.dataChangeToNumber(orderdetailspopup?.offer_discount)?.toFixed(2)}}
                                        </span>
                                    </td>
                                </tr>
                                <tr style="border-top:1px solid #ddd">
                                    <td colspan="2"></td>
                                    <td> 
                                        <h6 class="font-16 text-right">
                                            <span>{{'Total' | translate}}</span>
                                        </h6>
                                    </td>
                                    <td>
                                        <span class="pull-right text-primary text-right">{{currency}}
                                        {{commonhelper?.dataChangeToNumber(orderdetailspopup?.bill_amount)}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="rating" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!--Rating food-->
                <div class="ordersummary rounded border mb-4" style="min-height: 100px;">
                    <h6 class="border-bottom py-2 px-3 text-left text-success text-uppercase">{{'"Rate Your Food"' |
                        translate}}: {{restaurantData['restaurant_name']?restaurantData['restaurant_name']:''}}
                    </h6>
                    <div class="col-8 col-md-8 my-1 text-left dir restaurant-rating">
                        <legend class="restaurant-rate">
                                <ng-container *ngFor="let val of [].constructor(10); let i = index;">
                                <input #ratingStarInputs type="radio" class="restaurant-rating-input" id="restaurant-rating{{10-i}}" name="restaurant-rating" value="{{(10-i)*0.5}}" (click)="starRating($event.target.value, 'full-star')" /><label for="restaurant-rating{{10-i}}" title="{{10-i}} stars" class="{{((10-i)*0.5)% 1 != 0?'half':''}} restaurant-rating-label"></label>
                                </ng-container>
                        </legend>
                     </div>
                </div>
                <!--Suggestions-->
                <div class="ordersummary rounded border mb-4" style="min-height: 100px;">
                    <h6 class="border-bottom py-2 px-3 text-left text-success text-uppercase">{{'"Your Suggestions For"'
                        | translate}}: {{restaurantData['restaurant_name']?restaurantData['restaurant_name']:''}}
                    </h6>
                    <div class="col-12 my-1 text-left dir">
                        <textarea  style="height:60px" type="text" class="suggestions-input" [(ngModel)]="suggestions"
                            placeholder="Got any other Suggestions? Let us Know...">
                            </textarea>
                    </div>
                </div>
                <!--Rating restaurant-->
                <div class="ordersummary rounded border mb-4" style="min-height: 100px;">
                    <h6 class="border-bottom py-2 px-3 text-left text-success text-uppercase">{{'"Rate Delivery Boy"' |
                        translate}}
                    </h6>
                    <div class="col-8 col-md-8 my-1 text-left dir delivery-rating">
                        <legend class="delivery-rate">
                            <ng-container *ngFor="let val of [].constructor(10); let i = index;">
                            <input #ratingStarInputs type="radio" class="delivery-rating-input" id="delivery-rating{{10-i}}" name="delivery-rating" value="{{(10-i)*0.5}}" (click)="starRating($event.target.value, 'd-star-f')" /><label for="delivery-rating{{10-i}}" title="{{10-i}} stars" 
                            class="{{((10-i)*0.5)% 1 != 0?'deli-half':''}} delivery-rating-label"></label>
                            </ng-container>
                        </legend>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button id="rating-confirm" class="btn btn-primary pull-center px-3 text-uppercase"
                    (click)="confirmRating(restaurantData['request_id'])">{{'Confirm Rating' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div class="testytesty ratingToaster" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
    <div class="toast-body">
      {{ratingToasterErrorMsg}}
    </div>
  </div>



