import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AllrestaurantsComponent } from './pages/allrestaurants/allrestaurants.component';
import { ProfileComponent} from './pages/profile/profile.component';
import { SearchresultsComponent} from './pages/searchresults/searchresults.component';
import { MenudetailsComponent} from './pages/menudetails/menudetails.component';
import { CartComponent} from './pages/cart/cart.component';
import { AboutusComponent} from './pages/aboutus/aboutus.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { BecomeapartnerComponent } from './pages/becomeapartner/becomeapartner.component';
import { SinglerestaurantComponent } from './pages/allrestaurants/singlerestaurant/singlerestaurant.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { OffersComponent } from './pages/offers/offers.component';
import { FaqsComponent } from './pages/faqs/faqs.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'ar/', component: HomeComponent },
  { path: 'ar', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'ar/home', component: HomeComponent },
  { path: 'restaurant/:id/:restaurantname', component: SinglerestaurantComponent },
  { path: 'ar/restaurant/:id/:restaurantname', component: SinglerestaurantComponent },
  { path: 'restaurants', component: AllrestaurantsComponent },
  { path: 'ar/restaurants', component: AllrestaurantsComponent },
  { path: 'hotoffers', component: OffersComponent },
  { path: 'ar/hotoffers', component: OffersComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'ar/profile', component: ProfileComponent },
  { path: 'search-results', component: SearchresultsComponent },
  { path: 'ar/search-results', component: SearchresultsComponent },
  { path: 'menu-details', component: MenudetailsComponent },
  { path: 'ar/menu-details', component: MenudetailsComponent },
  { path: 'cart', component: CartComponent },
  { path: 'ar/cart', component: CartComponent },
  { path: 'cart/:cartid', component: CartComponent },
  { path: 'ar/cart/:cartid', component: CartComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'ar/aboutus', component: AboutusComponent },
  { path: 'contactus', component: ContactusComponent },
  { path: 'ar/contactus', component: ContactusComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'ar/faqs', component: FaqsComponent },
  { path: 'privacy', component: PrivacypolicyComponent },
  { path: 'ar/privacy', component: PrivacypolicyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'ar/terms', component: TermsComponent },
  { path: 'becomeapartner', component: BecomeapartnerComponent },
  { path: 'ar/becomeapartner', component: BecomeapartnerComponent },
  { path: 'profile/:id', component: ProfileComponent },
  { path: 'ar/profile/:id', component: ProfileComponent },
  { path: 'thankyou/:id', component: ThankyouComponent },
  { path: 'ar/thankyou/:id', component: ThankyouComponent },
  { path: 'trackorder/:id', component: ThankyouComponent },
  { path: 'ar/trackorder/:id', component: ThankyouComponent },
  { path: 'menu-details/:id/:restaurantname', component: MenudetailsComponent },
  { path: 'ar/menu-details/:id/:restaurantname', component: MenudetailsComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }