import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Meta, Title } from '@angular/platform-browser';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { TranslateService } from '@ngx-translate/core';
import { EMAIL, NUMBER_ONLY } from 'src/app/constants/pattern.constant';
import { CommonHelper } from 'src/app/common/helper';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-becomeapartner',
  templateUrl: './becomeapartner.component.html',
  styleUrls: ['./becomeapartner.component.scss']
})
export class BecomeapartnerComponent implements OnInit {
  model: any = {};
  response: any;
  tranlsatedtitle: string;
  tranlsateddescription: string;
  translatedstr:string;
  public emailPattern = EMAIL;
  public selectedcountrycode: any;
  public selectedphonecode:any;
  
  constructor(
    private titleService: Title,
    private meta: Meta,
    private apiconnectService: ApiconnectService,
    private translate: TranslateService,
    private commonHelper: CommonHelper,
  ) {
    this.translate.get('Get Registered with EATZILLA Food Delivery in Middle East').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Want more sales and order as a restaurant? Get your self registered with Eatzilla right away. Or you can also contact us at contact@eatzilla.info or call us at:1700 1550').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });
    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription});
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.phonenumberdropdown('partnerphone','countrycode_partner','error-msg-phonepartner','phonecode_partner');
  }

  /**
   * submit form
   * @param {NgForm} formdata 
   */
 public submitRestaurant(formdata: NgForm) {
   $('.loading').show();
  let json = {
      name: formdata.value.fullname,
      email: formdata.value.emailaddress,
      phone: $('#phonecode_partner').val() + formdata.value.phonenumber,
      restaurant_name: formdata.value.restaurantname,
      device_type: 'web',
    }

    this.apiconnectService.postRequest(json, '/restaurant_signup_request').subscribe(
      (data) => {
        this.response = data;
        $('.loading').hide();
        if (this.response.status) {
          this.translate.get('Thank you for reaching out to us. Our team member will get back to you shortly.').subscribe((dataResponse: any) => {
            this.translatedstr = dataResponse;
          });
          Swal.fire({
            text: this.translatedstr,
            icon: 'success'
          });
        }
        else{
          this.translate.get('Something went wrong. Please try again.').subscribe((dataResponse: any) => {
            this.translatedstr = dataResponse;
          });
          Swal.fire({
            text: this.translatedstr,
            icon: 'error'
          });
        }
        $('.loading').hide();
      },
      err => {
        console.log(err);
        $('.loading').hide();
      }
    );

  }

   /**
   * Phonenumberdropdown
   * @param {string} querySelector 
   * @param {any} countrycode 
   * @param {string} error 
   * @returns {any} phonenumberdropdown 
   */
   public async phonenumberdropdown(querySelector,countrycode,error, phoneCode){
    const phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
    const phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
    const input = document.querySelector("."+querySelector),
    hiddenintputfield = document.querySelector("#"+countrycode),
    errorMsg = document.querySelector("#"+error);
    $(".iti__flag-container").remove();
    const iti = window.intlTelInput(input, {
      excludeCountries: ["il"],
      hiddenInput: "full",
      preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
      separateDialCode: true,
      initialCountry: 'bh',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
      customPlaceholder: function (selectedCountryPlaceholder: any){
        return`${phoneplaceholder} ${selectedCountryPlaceholder}`
      }
    });
    const reset = function () {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', async function () {
      reset();
      this.values = input;
      const numberOnly = NUMBER_ONLY;
      if (this.values.value.trim()) {
        if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
          var countryData = iti.getSelectedCountryData();
          this.values = hiddenintputfield;
          this.values.value = countryData.iso2;
          this.selectedcountrycode = countryData.iso2;
          this.selectedphonecode = countryData.dialCode;
          $('#'+countrycode).val(countryData.iso2);
          $('#'+phoneCode).val(countryData.dialCode);
        
        } else {
          input.classList.add("error");
          errorMsg.innerHTML = phoneplaceholdererror;
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }
}
