import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from 'src/globals';
declare var $: any;

@Component({
  selector: 'app-search-dish',
  templateUrl: './search-dish.component.html',
  styleUrls: ['./search-dish.component.scss']
})

/**
 * Component
 */
export class SearchDishComponent implements OnInit, OnDestroy {
  public filtersInput
  public restaurantlist = null
  public urilang: string;
  public currency: string;
  private globals: Globals;
  public currentLat: number = 0;
  public CurrentLng:number = 0;
  public dishname ='';
  public isSearched = false;
  public isSearching = false;
  
  /**
   * constructor
   */
  constructor(
    private router: Router,
    private apiconnectService: ApiconnectService,
  ) { 
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals?.currency_ar;
    }
    else {
      this.urilang = '';
      this.currency = this.globals?.currency;
    }
  }

  /**
   * initial loader
   */
  ngOnInit(): void {
   this.currentLat= JSON.parse(localStorage.getItem('lat'));
   this.CurrentLng = parseFloat(JSON.parse(localStorage.getItem('lng')));
  }

   /**
   * get resataurants list
   */
 public getRestaurants() {
  if(this.dishname.trim().length<3){
    this.isSearching = false;
    return
  }
  this.isSearching = true;
  this.apiconnectService.postRequest({
    'device_type': 'web',
    'key_word': this.dishname,
  }, "/search_restaurants?lat=" + this.currentLat + "&lng=" + this.CurrentLng).subscribe(
    (data) => {
      this.isSearched = true;
     if (data['status']) {
        this.restaurantlist = data;
        this.isSearching = false;
      }else {
        this.restaurantlist = false;
        this.isSearching = false;
      }
    },
    err => {
      this.restaurantlist = false;
      this.isSearching = false;
      console.log(err);
    }
  );
}

/**
 * redirect to menu details page
 * @param {string} name 
 * @param {string} id 
 */
public gotoMenudetails(name,id) {
  const replaced = name.split(' ').join('-');
  const replaced2 = replaced.split('(').join('');
  const replaced3 = replaced2.split(')').join('');
  this.router.navigate([this.urilang+"menu-details/"+id+"/"+replaced3.toLowerCase()]);
}

public closeModal(){
  this.isSearched = false;
  this.restaurantlist = false;
  this.dishname = '';
  $('#searchDish').modal('hide');
  
}

/**
 * destroy component
 */
ngOnDestroy(): void {
  this.closeModal();
}

/**
 * type dish -> accept only letters
 * @param {any} key
 */
public onTypeDish(key){
  const keycode = (key.which) ? key.which : key.keyCode;
  if ((keycode > 64 && keycode < 91) || (keycode > 96 && keycode < 123) || keycode==32){     
         return true;    
  } else {
      return false;
  }
}
}
