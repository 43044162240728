import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  tranlsatedtitle: any;
  tranlsateddescription: any;
  urilang: string;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService
  ) {
    this.translate.get('Frequently Asked Questions - EATZILLA Food Delivery in Middle East').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Eatzilla provides Free Food Delivery service in Middle East. From 5 star restaurants, fast food chains to street cafes, we’ve got it all for you. Click to know about us!').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });

    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content:  this.tranlsateddescription});
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
  }
  faq: any = [];
  ngOnInit(): void {
    window.scroll(0, 0);
    this.faq = [
      {
        "id": "1",
        "question": "What is eatzilla?",
        "answer": "eatzilla is your friendly food delivery app serving all of Middle East, where you can browse through numerous restaurant menus all in one place and get your order done in just a few minutes."
      },
      {
        "id": "2",
        "question": "What service does eatzilla offer?",
        "answer": "eatzilla offers you the chance to check all your food options when not sure what to order. Once you have selected your food outlet, and place your order, it is automatically transferred to the restaurant to be handled and delivered to your door step."
      },
      {
        "id": "3",
        "question": "Why use eatzilla?",
        "answer": "Avoid the hassle of calling and waiting to make an order, and instead have it done in just a few simple steps. Using eatzilla will allow you to find out exactly what you are craving for after checking out all the menus and food dishes available in one place along with food photos, special offers and others' ratings and reviews."
      },
      {
        "id": "4",
        "question": "Do I have to pay to use eatzilla?",
        "answer": "eatzilla is completely free. No service fees will be added to your bill."
      },
      {
        "id": "5",
        "question": "Can I pay with my credit card?",
        "answer": "Depending on the restaurant and their payment options, you will have the option to pay using your credit card upon the arrival of your order with a portable credit card machine provided by the restaurant or through our online payment portal."
      },
      {
        "id": "6",
        "question": "Any Special Offers?",
        "answer": "At eatzilla we work hard to bring the best offers out there. With our partnerships with the many restaurants, we will surely offer special discounts, coupon codes all year long. Check our Special offers Tab to browse through the great seasonal deals we have for you."
      },
      {
        "id": "7",
        "question": "How long does it take to receive my order?",
        "answer": "The delivery time will all depend on your chosen restaurant. However we are available for you to follow up and make sure your order gets to you on time."
      },
      {
        "id": "8",
        "question": "How to add or remove an item from my already submitted order?",
        "answer": "We are available round the clock through online chat, whatsapp, email and telephone to assist you in changing your order and in the case you need to remove or add an item after you have already submitted your order. We will make sure to contact the restaurant for you and inform them of your amendment."
      },
      {
        "id": "9",
        "question": "Can I cancel my order?",
        "answer": "Yes you can. However you will have 5 minutes after submission of your order to cancel. Later than 5 minutes will mean that your order has been received by the restaurant and is already in the preparation or cooking process. "
      },
      {
        "id": "12",
        "question": "How  to place an order on eatzilla?",
        "answer": "Placing an order through eatzilla is extremely simple, here are your steps: - Create an account using your email or Facebook account. You may choose to skip this step and proceed to Express checkout however when creating an account all your info will be saved for the next time which allow a faster experience. - Select your area   - Enter the type of cuisine you wish to browse and click \"find\"   - Browse through all your options in your area and click on the restaurant you wish to explore more     - Once decided, add the items you wish to order to your cart - Once done, proceed to check out to review your order   - Enter your full address and delivery time and insert the discount coupon code if available and hit \"Place Order\". Your order will then be transmitted automatically to the restaurant."
      },
      {
        "id": "13",
        "question": "Can I place an order without creating an account?",
        "answer": "Yes you can, simply by choosing the Express Checkout option available. "
      },
      {
        "id": "14",
        "question": "Can I make an order and choose a later delivery time?",
        "answer": "It's possible if the restaurant allows preorders made in advance. The option will be available to you upon checkout if the restaurant's delivery policy includes this option."
      },
      {
        "id": "15",
        "question": "What to do when my account is blocked due to a false username or password?",
        "answer": "Following 5 attempts, your account will be temporarily blocked for security reasons. You will receive an  email to verify and reactivate your account. We are also available by phone, email and chat to assist with that if needed."
      },
      {
        "id": "16",
        "question": "Can I have more than one address under the same account?",
        "answer": "Yes you may have several addresses with one account. "
      },
      {
        "id": "17",
        "question": "How to make sure you have received my order? ",
        "answer": "Under \"My orders\" you should be able to see your submitted order and its status as successful. Then you can rest assured that your order has been received. "
      },
      {
        "id": "18",
        "question": "Can I reorder the same previous order? ",
        "answer": "Under \"My order\" you should be able to view your older orders and you can click on it to reorder the same."
      },
      {
        "id": "19",
        "question": "How do I mark an item as favorite?",
        "answer": "Simply by clicking \"Add to favorites\" under the item."
      },
      {
        "id": "20",
        "question": "Who to contact when not happy with my order?",
        "answer": "You may contact us directly or send us an email to XXXXXXXXXXXXXXX for any complaint and the concerned department will contact you directly."
      },
      {
        "id": "21",
        "question": "How long does it take to get an online refund to my credit card?",
        "answer": "It usually takes 2-3 days to get your refund reflected in your bank account"
      },
      {
        "id": "22",
        "question": "Can I rate restaurants or write a review?",
        "answer": "Yes you may rate restaurants and food dishes that you have previously ordered simply by going to \"My orders\" and choosing to review or rate. Please note that all reviews are subject to review and will be visible after this process."
      },
      {
        "id": "23",
        "question": "What is eatzilla credit?",
        "answer": "eatzilla credit is a service available to you as an account holder which will allow you to make orders faster and without paying upon checkout or upon delivery. Instead you will have prepaid credit that can be used to make your order."
      },
      {
        "id": "24",
        "question": "How to get eatzilla credit and when does it expire?",
        "answer": "By clicking on \"my account\" you will have the option to get eatzilla credit. Choose \"eatzilla credit\" and select on the credit amount you wish to purchase and which you can top any time. Your credit will expire after 180 days."
      },
      {
        "id": "25",
        "question": "eatzilla credit applies to all restaurants?",
        "answer": "eatzilla credit may be used for a majority of the restaurants on eatzilla however certain restaurants may not allow this feature due to their payment policy."
      },
      {
        "id": "26",
        "question": "Where to check my eatzilla credit balance?",
        "answer": "A \"eatzilla credit statement\" is available under the \"eatzilla credit\" tab for you to check. "
      },
      {
        "id": "27",
        "question": "How to contact eatzilla?",
        "answer": "You may contact eatzilla anytime through: -live chat -our hotline number - email"
      }
    ]
  }
}