<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{'About Us' | translate}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent text-left">
    <div class="container">
        <h2>{{'About Us' | translate}}</h2>
        <div>
            <p>{{'We are one family, a team of experts, who are passionate about food and on a mission to provide you with the easiest and fastest way to get your favorite dishes delivered at any time and from anywhere in Middle East. We have partnered and continue
                to partner with the best and most favored food outlets around the country. With one goal, making it easy for you to browse various menus all in one place and get whatever you may be craving for delivered to your doorstep.' | translate}}
            </p>
            <p> {{'From 5 star restaurants, fast food chains to street cafes, we’ve got it all for you. Whether it’s a simple sandwich, just pizza, or a full fancy meal, you’ll find it all at eatzilla.' | translate}} </p>
            <p> {{'We at Eatzilla are working hard to make sure you always enjoy:' | translate}} </p>
            <ul>
                <li>{{'Access to new menus from different food locations' | translate}}</li>
                <li>{{'Free and reliable delivery' | translate}}</li>
                <li>{{'Updates on special offers and new places' | translate}}</li>
                <li>{{'Occasional discount coupon codes' | translate}}</li>
            </ul>
            <p> {{'For all you food outlet owners, join our family today, and be part of this exciting adventure.' | translate}} </p>
            <p> {{'As for all you food lovers out there, get the eatzilla app today for the best food ordering experience.' | translate}} </p>
        </div>
    </div>
</section>
<app-footer></app-footer>