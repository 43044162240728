// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: "https://v2admin.eatzilla.info/api",
  apiUrl: "https://dev-api.eatzilla.info/api",
  apiIp: "http://161.35.142.163",
  deviceToken: "sp-eatzilla-web",
  // GOOGLEAPI: "AIzaSyDJ8b9bAQgVY51g5cwQ8WpCfyraRRQxEkY"
  GOOGLEAPI: "AIzaSyA00aH-1l_ssrdeIsWyYU5h5hPxmlteLQk",
  SOCKET_URL:'https://dev-socket.eatzilla.info'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
