import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClientJsonpModule, HttpClient } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { AllrestaurantsComponent } from './pages/allrestaurants/allrestaurants.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SearchresultsComponent } from './pages/searchresults/searchresults.component';
import { MenudetailsComponent } from './pages/menudetails/menudetails.component';
import { CartComponent } from './pages/cart/cart.component';
import { AgmCoreModule } from '@agm/core';
import { GooglemapComponent } from './pages/googlemap/googlemap.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { WebStorageService } from "./services/web-storage.service";
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from "../globals";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BecomeapartnerComponent } from './pages/becomeapartner/becomeapartner.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { SinglerestaurantComponent } from './pages/allrestaurants/singlerestaurant/singlerestaurant.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieLawModule } from 'angular2-cookie-law';
import { OffersComponent } from './pages/offers/offers.component';
import { NgpSortModule } from "ngp-sort-pipe";
import { FaqsComponent } from './pages/faqs/faqs.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule  } from 'ngx-google-analytics';
import { GtagModule } from 'angular-gtag';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { OrderDetailListComponent } from './pages/profile/order-detail-list/order-detail-list.component';
import { GuestUserLoginComponent } from './pages/guest-user-login/guest-user-login.component';
import { FooditemFilter } from './pipes/fooditem-filter.pipe';
import { SearchDishComponent } from './pages/search-dish/search-dish.component';
import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
import { SocketService } from './services/socket.service';
import { OtpVerificationComponent } from './pages/otp-verification/otp-verification.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AllrestaurantsComponent,
    ProfileComponent,
    SearchresultsComponent,
    MenudetailsComponent,
    CartComponent,
    GooglemapComponent,
    AboutusComponent,
    ContactusComponent,
    PrivacypolicyComponent,
    TermsComponent,
    LoginComponent,
    RegisterComponent,
    ForgotpasswordComponent,
    BecomeapartnerComponent,
    SinglerestaurantComponent,
    ThankyouComponent,
    OffersComponent,
    FaqsComponent,
    OrderDetailListComponent,
    GuestUserLoginComponent,
    FooditemFilter,
    SearchDishComponent,
    OtpVerificationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CreditCardDirectivesModule,
    CookieLawModule,
    RecaptchaModule,
    AppRoutingModule,
    SlickCarouselModule,
    NgpSortModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DeviceDetectorModule.forRoot(),
    Ng2SearchPipeModule,
    NgOtpInputModule,
    NgxGoogleAnalyticsModule.forRoot('UA-162918874-1'),
    GtagModule.forRoot({ trackingId: 'UA-162918874-1'}),
    NgxGoogleAnalyticsRouterModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDAw17tzcCtEMjDmPv9LrRcs2QzKD0Ht30',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  NgxShimmerLoadingModule,
  ],
   providers: [
    WebStorageService,
    ApiconnectService,
    Globals,
    SocketService,
    FooditemFilter,
   ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/','.json');
}