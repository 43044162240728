<app-header></app-header>
<section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                <h1 class="text-center text-light glorytext mb-3">{{ 'Hungry? Your food just one click away' | translate}}</h1>
                <p class="paragraph-font text-center text-light">{{ 'Pick a restaurant near you to order your meal.' | translate}}</p>
                <div class="bannerformcontainer">
                    <form (submit)="submitSearch()">
                        <div class="row">
                            <div class="col-md-12 col-lg-8 pr-0 col-xs-12 col-sm-12 mb-2">
                                <div class="position-relative">
                                    <img class="mapicon-home" src="assets/images/icons/location.png" alt="Eatzilla - Map Icon" title="Eatzilla - Map Icon">
                                </div>
                                <input name="searchlocation" (focus)="showSavedAddressOnFocus()" class="form-control bannerinput" #searchlocation placeholder="{{'SEARCH FOR AREA, BLOCK or STREET NAME' | translate}}" type="text" autocomplete="off">
                                <input type="hidden" value="" id="lat">
                                <input type="hidden" value="" id="lng">
                                <div class="dropdownsavedlocations shadow" style="display: none;" *ngIf="loggedIn" (blur)="hideSavedAddressOnBlur()">
                                    <ul>
                                        <li><strong>{{'Saved Addresses' | translate}}</strong></li>
                                        <ng-container *ngFor="let address of allAvailableDeliveryAddress?.data">
                                            <li (click)="selectAddress(address)">
                                                <div class="pull-left mr-1 markerwrap"><span class="fa fa-map-marker"></span></div>
                                                <div class="pull-left addresswrap">
                                                    <h6 class="m-0 bold">{{address.address_title | titlecase}}</h6>
                                                    <p class="text-secondary font-12 m-0 ellipsis white-space-break">{{address.address}}</p>
                                                </div>
                                                <div class="clearfix"></div>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mx-auto col-xs-12 col-sm-12 findrestaurant">
                                <button class="btn btn-primary btn-block btn-lg btn-gradient btn-banner text-uppercase" type="submit"> {{'Find Restaurants' | translate}} </button>
                            </div>
                        </div>
                    </form>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="bannerpaymentoptions col-lg-12 col-md-12 col-sm-12">
                <img class="imgpaymentoption" src="assets/images/common/paymentoptions.png" alt="Eatzilla - Payment Options" title="Eatzilla - Payment Options">
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</section>
<section  class="popularbrands mt-5 mb-5" >
    
</section>
<section class="popularbrands mt-5 mb-5" >
        <div class="container loader-center" *ngIf="loaderLoading">
            <span  class="data-loader"></span>
           </div>
        <ng-container *ngIf="!loaderLoading">
            <div class="container" *ngIf="offersAndPromotionsResponse">
                <h2 class="text-center">{{'Our Offers and Promotions' | translate}}</h2>
                <div class="sliderwrapper">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideOffersPromotionsConfig">
                        <div ngxSlickItem style="cursor: pointer;" class="slide " *ngFor="let data of offersAndPromotionsResponse?.data">
                            <div class="offers_promotions1">
                                <a href="{{urilang}}menu-details/{{data.restaurant_id}}/{{data.restaurant_name.replace(' ', '-').replace('(', '-').replace(')', '-') | lowercase}}"><img alt="{{alttitle}} {{data.restaurant_name}}" title="{{alttitle}} {{data.restaurant_name}}" src="{{offersAndPromotionsResponse?.base_url+''+data.image1 }}" class="img-fluid rounded width-400 height-150" /></a>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="container" *ngIf="popularBrands">
                <h2 class="text-center">{{'Popular Brands' | translate}}</h2>
                <div class="sliderwrapper col-md-12">
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig2">
                        <div ngxSlickItem style="cursor: pointer;" class="slide col-md-3 col-6" *ngFor="let brands of popularBrands.data">
                            <div class="products-box1">
                                <a href="{{urilang}}menu-details/{{brands.restaurant_id}}/{{brands.name.replace(' ', '-').replace('(', '-').replace(')', '-') | lowercase}}"><img alt="{{alttitle}} {{brands.name}}" title="{{alttitle}} {{brands.name}}" src="{{ brands.image }}" class="img-fluid rounded popular_brands_image popular_brand_width popular_brand_height" /></a>
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="container" *ngIf="userIs && (popularBrands?popularBrands?.data?.length==0:true || offersAndPromotionsResponse?offersAndPromotionsResponse?.data?.length==0:true)">
                <div class="text-center">
                    <img src="assets/images/no-data/no-restaurant-data.png" alt="no-restaurant">
                </div>
                <h2 class="text-center py-3">Don't be mad at us...</h2>
                <p class="text-center py-3">{{"we don't deliver in your area YET, but we will be expanding real soon!" | translate}}</p>
            </div>
            <div class="container  flex justify-center py-3"  *ngIf="(!userIs?true:false) && (popularBrands?popularBrands?.data?.length==0:true) &&( offersAndPromotionsResponse?offersAndPromotionsResponse?.data?.length==0:true)">
                <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 findrestaurant">
                    <button class="btn btn-primary btn-block btn-lg btn-gradient btn-banner text-uppercase" type="button" (click)="loginWithApp()"> {{'LOGIN WITH APP' | translate}} </button>
                </div>
            </div>
        </ng-container>
    </section>

    <section class="how-it-works">
    <div class="container">
        <h2 class="text-center text-light">{{'Get your Food in just 3 Easy Steps' | translate}}</h2>
        <p class="subtitle text-center text-light">{{'With multiple payment options, Pay by cash, benefit or Credit Card' | translate}}</p>
        <div class="text-xs-center">
            <!-- 3 block sections starts -->
            <div class="row how-it-works-solution">
                <div class="col-xs-12 col-sm-12 col-md-4 how-it-works-steps white-txt col1">
                    <div class="how-it-works-wrap">
                        <div class="step step-1">
                            <div class="icon" data-step="1">
                                <span class="fa fa-home ic"></span>
                            </div>
                            <h3>{{'Select your Restaurant' | translate}}</h3>
                            <p>{{'You are just a click away from Multiple Cuisines of all over Middle East.' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 how-it-works-steps white-txt col2">
                    <div class="step step-2">
                        <div class="icon" data-step="2">
                            <span class="fa fa-cutlery ic"></span>
                        </div>
                        <h3>{{'Choose the food you Love' | translate}}</h3>
                        <p>{{'A variety of Dishes from the menu of your favourite Restaurant.' | translate}}</p>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 how-it-works-steps white-txt col3">
                    <div class="step step-3">
                        <div class="icon" data-step="3">
                            <span class="fa fa-motorcycle ic"></span>
                        </div>
                        <h3>{{'Get it Delivered or a Take Away' | translate}}</h3>
                        <p>{{'Time to get your food on your doorstep or go get it from the restaurant right away.' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="joinus pt-5 pb-7">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-3 add-title">
                <h4>{{'Add Your Restaurant' | translate}}</h4>
            </div>
            <div class="col-xs-12 col-sm-5 join-text">
                <p>{{'Join the thousands of other restaurants who benefit from having their menus on' | translate}} <span class="text-primary"><strong> Eatzilla</strong></span> </p>
            </div>
            <div class="col-xs-12 col-sm-4 join-btn text-xs-right">
               <div class="text-center">
                <a routerLink="/{{urilang}}becomeapartner" class="btn btn-primary text-light" (click)="ongotoPartnerPage()">{{'I am a Restaurant' | translate}}</a>
               </div>
            </div>
        </div>
    </div>
</section>
<section class="app-section">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-lg-4 hidden-xs">
                <div class="app-image">
                    <img src="assets/images/common/app.png" alt="Eatzilla - Mobile App" title="Eatzilla - Mobile App" class="img-fluid">
                </div>
            </div>
            <div class="col-md-8 col-lg-8 mb-5">
                <h2 class="text-light text-left">{{'The Best Food Delivery App' | translate}}</h2>
                <p class="text-light text-left">{{'Eatzilla app is now here! Discover hundreds of food outlet menus all in one place and get whatever you may be craving for delivered to your doorstep. From 5 star restaurants, fast food chains to street cafes, you have it all.' | translate}}
                </p>
                <p class="text-light text-left">{{'Browse through the various menus and get your order done in minutes and at no extra cost. Serving all of Middle East, you can now place your order through this friendly user app and rest assured your food will be delivered on time.' | translate}}</p>
                <div class="social-btns text-left">
                    <a href="https://apps.apple.com/us/app/id1525078798" rel="noopener" target="_blank" class="app-btn apple-button clearfix">
                        <div class="pull-left text-left"><span class="fa fa-apple ic"></span> </div>
                        <div class="pull-right"> <span class="text">{{'Available on the' | translate}}</span> <span class="text-2">{{'App Store' | translate}}</span> </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.eatzillaV2.user" rel="noopener" target="_blank" class="app-btn android-button clearfix">
                        <div class="pull-left text-left"><span class="fa fa-android ic"></span> </div>
                        <div class="pull-right"> <span class="text">{{'Available on the' | translate}}</span> <span class="text-2">{{'Play store' | translate}}</span> </div>
                    </a>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<div class="modal fade" data-backdrop="static" data-keyboard="false" id="addressMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Delivery Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" (click)="resetMap()">&times;</span>
          </button>
            </div>
            <div class="modal-body" *ngIf="latitudeinput && longtitudeinput">
                <app-googlemap [id]="'home1'" [componenttype]="componenttype" [setaddressinput]="setaddressinput" [latitudeinput]="latitudeinput" [longtitudeinput]="longtitudeinput"></app-googlemap>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>