import { Component, ElementRef, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { Location } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from "../../../globals";
import { environment } from '../../../environments/environment.prod';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Gtag } from 'angular-gtag';
import { USER_INSTRUCTIONS } from 'src/app/constants/cart-page.constant';
import { CommonHelper } from 'src/app/common/helper';
import { ADDRESS_TYPES } from 'src/app/constants/guest-user-login-page.constant';
import { ADDRESS_KEYS, USER_TYPE } from 'src/app/constants/common';
import { DataEmitterService } from 'src/app/services/data-emit.service';
declare var $: any;
declare var window: any;
declare var Checkout: any;
const range = (start, stop, step) =>
  Array.from(
    {
      length: (stop - start) / step + 1
    },
    (_, i) => start + i * step
  )
  enum StaticBoolean {
    True, False
  }
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
/**
 * cart component
 */
export class CartComponent implements OnInit {
  private add_ons_arr: any = []
  public userphone: any;
  private userId: any;
  private set_delivery_address: any;
  private orderplacedata: any;
  private check_restaurant_availability: any;
  public restaurantDetails: any;
  public userProfileDetails: any = {};
  public loggedIn: boolean = false;
  public componenttype = 'cart';
  public setaddressinput: any;
  private addaddressresponse: any;
  public latitudeinput: any;
  public allAvailableDeliveryAddress: Array<object>=[];
  public selectedcountrycode: any;
  public longtitudeinput: any;
  public cartItems: any;
  public subtotal = 0;
  public total = 0;
  private userName: string;
  public addaddress: any = {};
  public defauladdressobject: any = {};
  public discountamount = 0;
  public discount: any;
  public defaultaddress: any;
  private showpopup = 0;
  private newaddress: any = {};
  private loginResponse: any = {}
  private profileResponse: any;
  public model: any = {};
  private expiry_month_: number = 1
  private  expiry_year_: number = 20
  private months: any = range(1, 12, 1)
  private years: any = range(20, 40, 1)
  public currency: any;
  private  isPaymentSuccess: string;
  private  ErrorText: string;
  private resultIndicator:string;
  private sessionVersion:string;
  private  translatedstr: any;
  public urilang: string;
  public restaurantcharges = {};
  public packagingcharge = 0;
  public restaurantcommision = 0;
  private deliveryInstructions = [];
  public userInstructions = [];
  public instructions = USER_INSTRUCTIONS;
  public couponCode:string = '';
  public couponsList = [];
  public couponOfferAmount = 0;
  private mobilecheck: boolean;
  private deviceInfo:any;
  private devicetype:any;
  private browser:any;
  public deliverycharge: number = 0.000;
  private verifycreditcarddetails:any;
  private credicardresponse: any;
  private credimaxresponse: any;
  public choice: any = []
  public addressTypes = ADDRESS_TYPES;
  public selectedAddressType = '';
  public addressTypeKey = 0;
  public guestUserDetails = {};
  public userIs = '';
  public userType = USER_TYPE;
  public guestDetails:any;
  public selectedphonecode='';
  @ViewChildren("addressTypeChecks") addressTypeChecks: QueryList<ElementRef>;
  private apiconnectService:ApiconnectService;
  private webStorageService:WebStorageService;
  private dataEmitter:DataEmitterService
  public router: Router;
  public commonhelper:CommonHelper;
  private deviceService: DeviceDetectorService;
  public geoCoder:any;
  public addresskey = ADDRESS_KEYS;
  public staticbooleanKey= StaticBoolean;

  /**
   * Creates an instance of cart component.
   * @param location 
   * @param rout 
   * @param globals 
   * @param gtag 
   * @param translate 
   */
  constructor(
    private location: Location,
    private rout: ActivatedRoute,
    private globals: Globals,
    private gtag: Gtag,
    private injector: Injector,
  ) {
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService);
    this.webStorageService = this.injector.get<WebStorageService>(WebStorageService);
    this.dataEmitter = this.injector.get<DataEmitterService>(DataEmitterService);
    this.router = this.injector.get<Router>(Router);
    this.commonhelper = this.injector.get<CommonHelper>(CommonHelper);
    this.deviceService = this.injector.get<DeviceDetectorService>(DeviceDetectorService);
    this.checkDevice();
    
    if (localStorage.getItem('language') == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals.currency_ar;
    }
    else{
      this.currency = this.globals.currency;
      this.urilang = '';
    }
    
  }
  

 /**
  * Checks device
  */
 private checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.devicetype = this.deviceInfo.os;
    this.browser = this.deviceInfo.browser;
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice) {
      this.mobilecheck = true;
    }
  }

  /**
   * initial load
   */
  async ngOnInit(): Promise<void> {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
    this.restaurantDetails = JSON.parse(localStorage.getItem("cartItemRestaurant"));
    this.getRestaurantDetails(this.restaurantDetails.id); // to get updated details
    this.cartItems = JSON.parse(localStorage.getItem("cart"))?JSON.parse(localStorage.getItem("cart")):[];
    this.isPaymentSuccess = this.rout.snapshot.queryParams['isPaymentSuccess'];
    this.resultIndicator = this.rout.snapshot.queryParams['resultIndicator'];
    this.sessionVersion = this.rout.snapshot.queryParams['sessionVersion'];
    this.ErrorText = this.rout.snapshot.queryParams['ErrorText'];
    this.defaultaddress = JSON.parse(localStorage.getItem("defaultaddress"));
    this.userIs =this.webStorageService.getUserType();
     if (this.cartItems.length===0) {
      this.router.navigate([this.urilang+'home']);
    }else {
       if(this.userIs !== this.userType?.GUEST_USER){
        this.getAddress(); // get address -> initial address setting
      }
      this.checkUserLogin(); // check user login -> get and set user details if user logined else remove user details
      this.deliverycharge =  parseFloat(this.restaurantDetails.restaurant_delivery_charge);
      this.calculateCartTotal();
      this.checkout();
      this.checkPaymentSuccess(); // check payment success or not
      this.resultIndicatorCheck(); // check result indicator
      
    }
    this.checkCouponApplied();
    if(this.userIs==this.userType.GUEST_USER){
      this.mapGuestUserDetails()
    }
    this.setaddressinput = localStorage.getItem("address");
      this.latitudeinput = JSON.parse(localStorage.getItem("lat"));
      this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem("lng")));

       //update login
    this.dataEmitter.getLogin().subscribe(async res=>{
      await this.onGetLogin(res);
    })
    this.geoCoder = new google.maps.Geocoder();
    localStorage.removeItem('isproceedtocheckout');
    this.couponCode = '';
    this.couponOfferAmount =0;
  }
  
  /**
   * verify credit card payment
   */
  private verifyCreditcardpayment(){
    var total = this.total.toFixed(3);
    let jsondata = {
      transaction_id : localStorage.getItem('cc_transactionid'),
      amount : total,
      currency : this.currency,
      device_type: 'web',
    }

    this.apiconnectService.postRequest(jsondata, '/validate_credimax_transaction').subscribe(
      async (data) => {
        this.verifycreditcarddetails = data;
        if (this.verifycreditcarddetails.status) {
          this.completeOrder('3');
        }
        else {
          $('.loading').hide();
          Swal.fire({
            text: await this.commonhelper.translateText('We are unable to verify your payment. Please contact us through live chat or on contact@eatzilla.info'),
            icon: 'error'
          });
        }
      },
      async err => {
        this.errorMessageOne(err);
      }
    );
  }

  /**
   * get all delivery address
   */
 private getAllDeliveryAddress() {
    this.apiconnectService.getRequest("/get_delivery_address?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        if(data['status']){
        this.allAvailableDeliveryAddress = data['data'];
        localStorage.setItem('nodefualtlogginaddress','false');
         if(this.userIs == this.userType.LOGGED_USER){
            localStorage.setItem('nodefualtlogginaddress',JSON.stringify(true));
          }
        }else{
          this.allAvailableDeliveryAddress = [];
        }
      },
     async err => {
        console.error(err)
        if(err['status'] == 401){
          this.webStorageService.removeData(['userPic']);
          this.router.navigate([this.urilang+'home']);
          Swal.fire({
            text: await this.commonhelper.translateText('Loggedout Successfully'),
            icon: 'success'
          });
        }
      }
    )
  }

  /**
   * get profile details
   */
 private getProfileDetails() {
    this.apiconnectService.getRequest("/get_profile?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.profileResponse = data;
        if (this.profileResponse.status) {
          this.userProfileDetails = this.profileResponse.data[0];
          this.webStorageService.storeUserName(this.profileResponse.data[0].name);
          this.userName = this.webStorageService.getUserName();
          this.userphone = this.userProfileDetails.phone;
        }
      },
     async err => {
        if(err['status'] == 401){
        this.webStorageService.removeData(['userPic']);
        this.router.navigate([this.urilang+'home']);
        Swal.fire({
          text: await this.commonhelper.translateText('Loggedout Successfully'),
          icon: 'success'
        });
      }
      }
    )
  }

  /**
   * check restaturant availability
   * @param {any} datapost 
   * @returns {object}
   */
  private checkrestaurantavailability(datapost){
    return new Promise((resolve, reject) => {
      this.apiconnectService.postRequest(datapost, '/check_restaurant_availability?lat=' + datapost.lat + '&lng=' + datapost.lng).subscribe(
        async (data) => {
          this.check_restaurant_availability = data;
          resolve(this.check_restaurant_availability);
          $('.loading').hide();
          return data;
        },
        async err => {
          this.errorMessageOne(err);
        }
      );
    })
  }

  /**
   * show edit address
   */
  public showEditAddress() {
    this.addaddress = {
      addresstring: JSON.parse(localStorage.getItem("address")),
      blocknumber: '',
      building: '',
      flat_no: '',
      landmark: '',
      address_title: '',
      road_number: '',
      // area:JSON.parse(localStorage.getItem("location")).area,
    }
    var lat = JSON.parse(localStorage.getItem("lat"));
    var lng = JSON.parse(localStorage.getItem("lng"));
    let datapost = {
      current_address: JSON.parse(localStorage.getItem("address")),
      lat: lat,
      lng: lng,
      restaurant_id: this.restaurantDetails.id,
      address_id: 0,
      device_type: 'web',
    }

    this.checkAvailbility(datapost);
    
  }

  /**
   * go back
   */
  private goBack() {
    this.location.back();
  }

  /**
   * calculate cart total
   */
 private calculateCartTotal() {
  this.calculateDiscount(this.restaurantDetails.offer_type, this.restaurantDetails.discount_type);
   var total = 0;
    // Reason: old code/hide this code for future use
    // var discount = 0;
    this.cartItems.forEach(function (value) {
      total = total + (value.price);
      // Reason: old code/hide this code for future use
      // if (value.food_percentage_offer > 0){
      //   discount = discount + ((value.food_percentage_offer * value.price)/100)
      // }
    });
    this.subtotal = total;
    
  
  }

  /**
   * restaurant changes calculate
   */
  private calculateRestaurantCharges(){
    if(this.restaurantcharges){
      this.deliverycharge = this.restaurantcharges['invoice'][0]['delivery_charge'];
      this.restaurantcommision = this.restaurantcharges['invoice'][0]['restaurant_commission'];
      this.packagingcharge = this.restaurantcharges['invoice'][0]['restaurant_packaging_charge'];
      this.calculateDiscount(this.restaurantDetails.offer_type, this.restaurantDetails.discount_type);
      this.total = Number(this.subtotal) - Number(this.discountamount) + Number(this.packagingcharge) + 
      Number(this.deliverycharge) + Number(this.restaurantcommision) - Number(this.couponOfferAmount);
      $('.loading').hide();
    }
  }

  /**
   * show addredd
   */
 private showaddaddress() {
   $('#addressMap').modal('show');
  }

  /**
   *address submit 
   * @param {NgForm} formdata 
   */
 public addaddresssubmit(formdata: NgForm) {
  if(this.selectedAddressType==''){
    return;
  }else{
    $('.loading').show();
    $('#addressMap').modal('hide');
    $('#editaddressmodal').modal('hide');
    let payload = {
      landmark: formdata.value.landmark,
      flat_no: formdata.value.flat_no,
      address: this.addaddress.addresstring,
      lat: localStorage.getItem('lat'),
      lng: localStorage.getItem('lng'),
      type: this.addressTypeKey,
      address_direction: formdata.value.landmark,
      block_number: formdata.value.blocknumber,
      building: formdata.value.building,
      road_number: formdata.value.road_number,
      device_type: 'web',
      address_title:this.selectedAddressType,
      area:formdata.value.location,
    }
    this.apiconnectService.postRequest(payload, '/add_delivery_address').subscribe(
      async (data) => {
        this.addaddressresponse = data;
         if (this.addaddressresponse.status) {
          $('.loading').hide();
          formdata.resetForm();
          this.selectedAddressType = '';
          this.checkout();
          if(this.userIs == this.userType.LOGGED_USER){
            this.getDefaultAddress();
          }
          this.allAvailableDeliveryAddress.push(payload);
          this.defaultaddress = localStorage.setItem("defaultaddress", '1');
          localStorage.setItem('searchedaddress', JSON.stringify(false));
          Swal.fire({
            text: await this.commonhelper.translateText('You have successfully Added your Address'),
            icon: 'success'
          });
        }
        else {
          $('.loading').hide();
          Swal.fire({
            text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
            icon: 'error'
          });
        }
      },
      async err => {
        this.errorMessageOne(err);
      }
    );
  }
  }

  /**Later usage
   * phone number dropdown
   */
//  private  async phonenumberdropdown(querySelector?,countrycode?,error?) {
//   const phoneplaceholder = await this.commonhelper.translateText('Enter your Phone Number e.g.');
//     const phoneplaceholdererror = await this.commonhelper.translateText('Your Phone Number is Invalid');
//         let input = document.querySelector("."+querySelector);
//         input.innerHTML = this.guestDetails['phone']?this.guestDetails['phone']:'';
//         let hiddenintputfield = document.querySelector("#"+countrycode);
//         let errorMsg = document.querySelector("#"+error);
//         $(".iti__flag-container").remove();
//     var iti = window.intlTelInput(input, {
//       value:this.guestDetails['phone']?this.guestDetails['phone']:'',
//       excludeCountries: ["il"],
//       hiddenInput: "full",
//       preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
//       separateDialCode: true,
//       initialCountry: this.guestDetails['country_code']?this.guestDetails['country_code']:'bh',
//       utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
//       customPlaceholder: function (selectedCountryPlaceholder: any) {
//         return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
//       },
//     });
//     var reset = function () {
//       input.classList.remove("error");
//       errorMsg.innerHTML = "";
//       errorMsg.classList.add("hide");
//     };

//     // on blur: validate
//     input.addEventListener('blur', async function () {
//       reset();
//       this.values = input;
//       if (this.values.value.trim()) {
//         if (iti.isValidNumber()) {
//           var countryData = iti.getSelectedCountryData();
//           this.values = hiddenintputfield;
//           this.values.value = countryData.iso2;
//           this.selectedcountrycode = countryData.iso2;
//           this.selectedphonecode = countryData.dialCode;
//           $('#countrycode_guestuseredit').val(countryData.iso2);
//           $('#phonecode_guestuseredit').val(countryData.dialCode);
         
//         } else {
//           input.classList.add("error");
//           errorMsg.innerHTML = phoneplaceholdererror;
//           errorMsg.classList.remove("hide");
//         }
//       }
//     });
//     input.addEventListener('change', reset);
//     input.addEventListener('keyup', reset);
//   }

  /**
   * get default address
   */
 private getDefaultAddress() {
  $('.loading').show();
    this.apiconnectService.getRequest("/get_default_address?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      async data => {
        $('.loading').hide();
        this.defauladdressobject = data['data'];
        if (data['status']) {
          this.defaultaddress = 1;
        }
        else {
           Swal.fire({
            text: await this.commonhelper.translateText('Oops!! No default address. Please add address to deliver food.'),
            icon: 'error'
          });
        }
      },
    async err => {
        Swal.fire({
          text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
          icon: 'error'
        });
              }
    )
  }

  /**
   * show existing delivery address
   */
  public async showexistingdeliveryaddress() {
    if(this.allAvailableDeliveryAddress.length>0){
      $('#existingdeliveryaddress').modal('show');
    }else{
      Swal.fire({
        text: await this.commonhelper.translateText('Oops!! No default address. Please add address to deliver food.'),
        icon: 'error'
      });
    }
   }

  /**
   * selected existind add form
   * @param {NgForm} formdata 
   */
  public async selectedexistingaddform(formdata: NgForm) {
    $('.loading').show();
    let datapost = {
      current_address: formdata.value.selectedaddress.address,
      lat: formdata.value.selectedaddress.lat,
      lng: formdata.value.selectedaddress.lng,
      restaurant_id: this.restaurantDetails.id,
      address_id: formdata.value.selectedaddress.id,
      device_type: 'web',
    }
    await this.checkrestaurantavailability(datapost);
    if (!this.check_restaurant_availability.status) {
      $('.loading').hide();
      Swal.fire({
        text: await this.commonhelper.translateText('Oops!! The restaurant does not delivery in this location. Select another location'),
        icon: 'error'
      });
    }
    if (this.check_restaurant_availability.status) {
      this.setdeliverableaddress(datapost, formdata.value.selectedaddress);
    }
  }

  /**
   * set deliverable address
   * @param {formdata} data 
   * @param {any} formdata 
   */
  private setdeliverableaddress(data, formdata) {
    data.device_type = 'web';
    this.apiconnectService.postRequest(data, '/set_delivery_address').subscribe(
      async (dataResponse) => {
        this.set_delivery_address = dataResponse;
        if (this.set_delivery_address.status) {
          $('#existingdeliveryaddress').modal('hide');
          $('.loading').hide();
          this.defauladdressobject = formdata;
          localStorage.setItem("defaultaddress", '1');
          localStorage.setItem("searchedaddress", JSON.stringify(false));
          this.defaultaddress = 1;
          this.webStorageService.storeLocalData({lat:dataResponse['data'].lat, lng:dataResponse['data'].lng, address:dataResponse['data'].address});
          this.checkout();
          Swal.fire({
            text: await this.commonhelper.translateText('You have successfully Added your Address'),
            icon: 'success'
          });
        }
      },
      async err => {
        this.errorMessageOne(err);
      }
    );
  }

  /**
   * pay by cash
   */
  public async paybyCash() {
    const searchedaddress = JSON.parse(localStorage.getItem('searchedaddress'))
    if (searchedaddress) {
        this.searchedAddress();
    }else {
       this.noSearchedAddress();
    }
  }

  /**
   * pay with benefit
   */
 public async paywithBenefit() {
  if (this.defaultaddress == 0) {
      if (this.devicetype == 'iOS' || this.browser == 'Safari') {
        this.showEditAddress();
      }
      else{
        this.showaddaddress();
      }
    }
    else {
      Swal.fire({
        text: await this.commonhelper.translateText('You are about to place your order. You will be redirected to a secure HTTP(s) page where you will be able to complete the BENEFIT payment for your order.'),
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: await this.commonhelper.translateText('No'),
        confirmButtonText: await this.commonhelper.translateText('Yes')
      }).then((result) => {
        if (result.isConfirmed) {
          this.userId = JSON.parse(localStorage.getItem('authId'));
          window.location.href = `${environment.apiIp}/payment_request.php?amount=${this.total.toFixed(3)}&user_id=${this.userId}`;
        }
      });
    }
  }

  /**
   * generate random number
   * @param{number} n 
   * @returns {string}
   */
  private generate(n) {
    var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   

    if (n > max) {
      return this.generate(max) + this.generate(n - max);
    }

    max = Math.pow(10, n + add);
    var min = max / 10; // Math.pow(10, n) basically
    var number = Math.floor(Math.random() * (max - min + 1)) + min;

    return ("" + number).substring(add);
  }

  /**
   * pay by credit card
   */
 public async paybyCreditCard() {
  if (this.defaultaddress == 0) {
      this.showaddaddress();
    }
    else {
      if (this.total > 0) {
        var total = this.total.toFixed(3);
        Swal.fire({
          text: await this.commonhelper.translateText('You are about to place your order. You will be redirected to a secure HTTP(s) page where you will be able to complete the CREDIT CARD payment for your order.'),
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: await this.commonhelper.translateText('No'),
          confirmButtonText: await this.commonhelper.translateText('Yes')
        }).then((result) => {
          if (result.isConfirmed) {
            $('.loading').show();
            let postdata = {
              transaction_id: this.generate(20),
              amount: total,
              currency: this.currency,
              return_url: 'https://v2.eatzilla.info/'+this.urilang+'cart',
              device_type: 'web'
            }
            this.apiconnectService.postRequest(postdata, '/create_credimax_session').subscribe(
              async (data) => {
                this.credimaxresponse = data;
                if (this.credimaxresponse.status) {
                  localStorage.setItem('cc_transactionid', postdata.transaction_id);
                  localStorage.setItem('cc_session', this.credimaxresponse.result.session.id);
                  localStorage.setItem('cc_version', this.credimaxresponse.result.session.version);
                  localStorage.setItem('cc_indicator', this.credimaxresponse.result.successIndicator);
                  this.createCredimaxrequest(postdata.transaction_id,this.credimaxresponse.result.session.id,total);
                }
                else {
                  $('.loading').hide();
                  Swal.fire({
                    text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
                    icon: 'error'
                  });
                }

              },
              async err => {
               this.errorMessageOne(err);
              }
            );
          }
        });
        $('.loading').hide();
      }
    }
  }

  /**
   * create credit max request
   * @param {any} transactionid 
   * @param {any} sessionid 
   * @param {any} total 
   */
  private createCredimaxrequest(transactionid,sessionid,total) {
    var address = this.defauladdressobject;
    Checkout.configure({
      session: {
        id: sessionid
      },
      merchant: 'E11742950',
      order: {
        amount: function () {
          return total;
        },
        currency: 'BHD',
        description: 'Food Delivery from Eatzilla',
        id: transactionid
      },
      billing: {
        address: {
          street: address.address,
          city: 'Manama',
          postcodeZip: address.block_number,
          stateProvince: 'Manama',
          country: 'BHR'
        }
      },
      interaction: {
        operation: 'PURCHASE',
        merchant: {
          name: 'Eatzilla Food Delivery',
          address: {
            line1: '312 Falcon Tower Diplomatic Area',
            line2: 'Manama Middle East'
          },
          email: 'contact@eatzilla.info',
          phone: '+973 1700 1550',
          logo: 'https://v2.eatzilla.info/assets/images/common/logoorange.png'
        },
        locale: 'en_US',
        theme: 'default',
        displayControl: {
          billingAddress: 'HIDE',
          customerEmail: 'HIDE',
          orderSummary: 'SHOW',
          shipping: 'HIDE'
        }
      }
    });
    Checkout.showPaymentPage();
  }

  /**
   * complete order
   * @param {any} paymenttype 
   */
  private async completeOrder(paymenttype) {
    $('.loading').show();
    let food_id = [];
    let food_qty = [];
    let food_quantity_price = [];
    let choosen_choicesarray: Array<any> = [];
    this.cartItems.forEach(async(value, itemIndex) =>{
      food_id.push(value.item_id);
      food_qty.push(value.quantity);
      food_quantity_price.push(value.price);
      await this.completeOrderParamMap(value, choosen_choicesarray, itemIndex);
     });
    let postdata = {
      add_ons: [],
      bill_amount: this.total,
      choice: choosen_choicesarray,
      comment: $('#additionalcomments').val(),
      coupon_code: this.couponCode?this.couponCode:'NA',
      delivery_charge: this.deliverycharge,
      delivery_type: '1',
      device_type: 'web',
      food_id: food_id,
      food_qty: food_qty,
      gst: 0,
      item_total: this.subtotal,
      loyalty_discount: 0,
      offer_discount: 0,
      paid_type: paymenttype,
      pickup_dining_time: '',
      restaurant_discount: this.discountamount,
      restaurant_id: this.restaurantDetails.id,
      restaurant_packaging_charge: this.packagingcharge,
      // tax: '0',
      restaurant_commission: this.restaurantcommision,
      total_members: '',
      block_number: '',
      coupon_code_discount: this.couponOfferAmount,
      building: '',
      flat_no: '',
      road_number: '',
      driver_tip: 0,
      address_direction:'',
      food_quantity:food_qty,
      food_quantity_price:food_quantity_price,
      instruction_id:this.userInstructions,
      lat:localStorage.getItem('lat'),
      lng:localStorage.getItem('lng'),
    }
    if(this.userIs==this.userType.GUEST_USER){
      postdata['address']=JSON.parse(localStorage.getItem('address'));
    }
    this.payNow(postdata);
  }

  /**
   * checkout
   * get details to show offer details
   */
  public async checkout(){
    $('.loading').show()  // added to calculation time
    this.calculateCartTotal();
    let food_id = [];
    let food_qty = [];
    let choicesarray: Array<object> = [];
    let choosen_choicesarray: Array<object> = [];
    let mergedarray: Array<object> = [];
   this.cartItems?.forEach(async(value) =>{
      food_id.push(value.item_id);
      food_qty.push(value.quantity);
      let add_ons = [];
      let add_ons2: any;
     await this.checkoutParamMapOne(value, add_ons, add_ons2, choicesarray, choosen_choicesarray)
   });
    let merged = [];
   choosen_choicesarray.forEach(async(key, index) => {
     await this.checkoutParamMapTwo(key, index,choicesarray, merged)
    })
    mergedarray = (merged);
    let postdata = {
      // add_ons: '',
      bill_amount: this.subtotal,
      choice: mergedarray,
      // comment: $('#additionalcomments').val(),
      coupon_code: this.couponCode? this.couponCode : 'NA',
      // delivery_charge: this.deliverycharge,
      delivery_type: '1',
      device_type: 'web',
      food_id: food_id,
      food_qty: food_qty,
      // gst: '0',
      // item_total: this.subtotal,
      // loyalty_discount: '0',
      offer_amount: this.discountamount? this.discountamount:0,
      // paid_type: paymenttype,
      // pickup_dining_time: '',
      restaurant_discount: this.discountamount,
      restaurant_id: this.restaurantDetails.id,
      // restaurant_packaging_charge: 0,
      // tax: '0',
      lat:localStorage.getItem('lat'),
      lng:localStorage.getItem('lng'),
      coupon_code_discount: this.couponOfferAmount? this.couponOfferAmount: 0,
    }
    if(this.userIs==this.userType.GUEST_USER){
      postdata['d_lat']=localStorage.getItem('lat');
      postdata['d_lng']=localStorage.getItem('lng');
    }
    if(postdata['coupon_code']=='NA'){
      delete postdata['coupon_code'];
    }
    this.checkoutApi(postdata);
  }

  /**
   * calculate discount amount
   * @param {number} offer 
   * @param {string} discount 
   */
  private calculateDiscount(offerType, discountType):void{
    switch (true) {
      case (offerType===1 && discountType ==='1'):  // flat offer amount
        this.offerTypeCalOne()
        break;
      case (offerType===1 && discountType ==='2'): // offer -> offer%  upto {target amount}
        this.offerTypeCalTwo()
        break;
      case (offerType===2 && discountType ==='1'): // offer -> offer to {offer value} above the {total}
        this.offerTypeCalThree()
        break;
      case (offerType===2 && discountType ==='2'): // offer ->offer% to {offer value} above total upto {max discount}
        this.offerTypeCalFour();
        break;
      default:
      this.discountamount = 0;
      }
  }

  /**
   * Offers type calculation 1 // if (offerType===1 && discountType ==='1')
   */
  private offerTypeCalOne(){
    const offerAmount = this.restaurantDetails?.offer_amount;
    if(offerAmount){
      this.discountamount = offerAmount;
    }else{
      this.discountamount=0
    }
  }

  /**
   * Offers type calculation 2 // (offerType===1 && discountType ==='2')
   */
  private offerTypeCalTwo(){
    const targetAmount = this.restaurantDetails?.target_amount;
    const offerAmount = this.restaurantDetails?.offer_amount;
    if(targetAmount && offerAmount){
      if((offerAmount * this.subtotal) / 100<=Number(targetAmount)){
        this.discountamount= (offerAmount * this.subtotal) / 100;
      }else{
        this.discountamount=targetAmount;
      }
    }
  }

   /**
   * Offers type calculation 3 // (offerType===2 && discountType ==='1')
   */
   private offerTypeCalThree(){
    const offerAmount = this.restaurantDetails.offer_amount;
    const offerValue = this.restaurantDetails.offer_value;
    if(offerAmount && offerValue ){
      if(this.subtotal>offerValue){
        this.discountamount= offerAmount;
      }else{
        this.discountamount = 0;
      }
    }
  }

  /**
   * Offers type calculation 4 // (offerType===2 && discountType ==='2')
   */
  private offerTypeCalFour(){
    const offerAmount = this.restaurantDetails.offer_amount;
    const targetAmount = this.restaurantDetails.target_amount;
    const offerValue = this.restaurantDetails?.offer_value;
    const  isNotExceedMaxDiscount = (offerAmount * this.subtotal) / 100 <= targetAmount;
        if(offerAmount && targetAmount ){
          if(this.subtotal>offerValue){
            if(isNotExceedMaxDiscount){
              this.discountamount= (offerAmount * this.subtotal) / 100;
            }else{
              this.discountamount = targetAmount;
            }
        }else{
          this.discount = 0;
        }
  }
}

  /**
   * select user instruction
   * @param {object} data 
   */
  public selectUserInstruction(data){
    if(data?.id){
      const index = this.userInstructions.findIndex((x)=>x==data.id);
      if(index == -1){
       this.userInstructions.push(data.id);
      } else{
        this.userInstructions.splice(index,1);
      }
      
    }
  }

  /**
   * apply code
   */
  public applyCode(){
     let postdata = {
        bill_amount: this.total,
        delivery_type:1,
        restaurant_id: this.restaurantDetails.id,
        promocode:this.couponCode,
        device_type:'web',
     }
    this.apiconnectService.postRequest(postdata, '/check_promocode').subscribe(
      async (data) => {
        if(data['status']){
            this.couponOfferAmount = data['offer_amount'];
            this.checkout();
            localStorage.setItem('coupon', JSON.stringify({code:this.couponCode, offer_amount:this.couponOfferAmount}));
            $('.loading').hide();
            $('#couponsList').modal('hide');
            Swal.fire({
              text: await this.commonhelper.translateText('Coupon added Successfully'),
              icon: 'success'
            });
        }else if(!data['status']){
          $('.loading').hide();
          $('#couponsList').modal('hide');
          Swal.fire({
            text: await this.commonhelper.translateText('Oops!!'+' '+data['message']),
            icon: 'error'
          });
        }
       },
      async err => {
        $('.loading').hide();
        $('#couponsList').modal('hide');
        Swal.fire({
          text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
          icon: 'error'
        });
        console.log(err);
      }
    );
    }

  /**
   * View all coupons for particular restaurant
   * 
   */
  public viewAllCoupons(){
    let postdata = {
     restaurant_id: this.restaurantDetails.id,
   }
  this.apiconnectService.postRequest(postdata, '/view_all_coupon_code').subscribe(
    async (data) => {
      if(data['status']){
        this.couponsList = data['promoCodeList'];
        $('#couponsList').modal('show');
      }
    },
    async err => {
     this.errorMessageOne(err);
    }
  );
  }

  /**
   * add coupon from list
   * @param {string} data 
   */
 public addCoupon(code:string){
  if(code){
    this.couponCode = code;
    this.applyCode();
    $('.loading').show();
  }
 }

  /**
   * remove coupon
   * @param {string} data 
   */
  public async removeCoupon(data, key){
    const coupon =JSON.parse(localStorage.getItem('coupon'));
    const couponapplied = coupon?.code == data;
    $('.loading').show();
    if(couponapplied){
      this.couponCode = '';
      this.couponOfferAmount = 0;
      this.checkout();
      localStorage.removeItem('coupon');
      $('.loading').hide();
      if(key==StaticBoolean.True){
        Swal.fire({
          text: await this.commonhelper.translateText('Coupon removed Successfully'),
          icon: 'success'
        });
      }
    }
   }

   /**
    * get address // initially loading function
    */
   private getAddress(){
    this.setaddressinput = localStorage.getItem("address");
      this.latitudeinput = JSON.parse(localStorage.getItem("lat"));
      this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem("lng")));
      if (this.defaultaddress == 0) {
      if (this.devicetype == 'iOS' || this.browser == 'Safari') {
        this.showEditAddress();
      }
    }else {
       $('body').removeClass('modal-open');
      if(this.userIs == this.userType.LOGGED_USER && this.allAvailableDeliveryAddress.length>0){
        this.getDefaultAddress();
      }
    }
   }

   /**
    * check user login -> To get and set user details if user logined else remove user details // initially loading function
    */
   private checkUserLogin(){
    if (this.webStorageService.getAuthId() &&
    this.webStorageService.getAuthToken() && this.webStorageService.getUserType()== USER_TYPE.LOGGED_USER) {
      this.loggedIn = true;
      this.getProfileDetails();
      this.getAllDeliveryAddress();
      this.userName = this.webStorageService.getUserName()
      this.loginResponse.user_name = this.webStorageService.getUserName()
    } else if(this.webStorageService.getAuthId() &&
    this.webStorageService.getAuthToken() && this.webStorageService.getUserType()== USER_TYPE.GUEST_USER) {
      this.loggedIn = false
      this.webStorageService.removeData(['userPic']);
    }else{
      this.router.navigate([this.urilang+'home']);
    }
   }

   /**
    * check payment success or not
    */
  private async checkPaymentSuccess(){
    if (this.isPaymentSuccess == 'true') {
      $('.loading').show();
      if (this.total > 0) {
        this.completeOrder('2');
      }
   }else if (this.isPaymentSuccess == 'false') {
    Swal.fire({
      text: await this.commonhelper.translateText('Your transaction has been declined by our payment gateway. Please try again or use a different payment method'),
      icon: 'error'
    });
  }
}

private async resultIndicatorCheck(){
  if (this.resultIndicator && this.sessionVersion){
    $('.loading').show();
    if (this.total > 0 && localStorage.getItem('cc_indicator') == this.resultIndicator){
      this.verifyCreditcardpayment();
    }
    else{
      $('.loading').hide();
      Swal.fire({
        text: await this.commonhelper.translateText('Your transaction has been declined by our payment gateway. Please try again or use a different payment method'),
        icon: 'error'
      });
    }
  }
}

/**
 * check coupon applied or not - when page refresh
 */
private checkCouponApplied(){
  const coupon = JSON.parse(localStorage.getItem("coupon"));
  this.couponCode =coupon?.code ? coupon?.code: '';
  this.couponOfferAmount = coupon?.code ? coupon?.offer_amount:0;
}

 /**
   * address type choose
   * @param {any} event 
   * @param {number} key 
   */
 public chooseAddressType(event, key){
  if(event.target.checked){
    this.selectedAddressType = event.target.value
    this.addressTypeKey = key+1;
  }
}

/**
   * change area
   * @param event 
   */
public onChangeArea(event){
  $('#guestuseredit').modal('hide');
  $('.loading').show();
  this.getAddress();
   setTimeout(() => {
  $('.loading').hide();
  $('#addressMap').modal('show');
 }, 500);
 }

 /**
  * address change by guest
  * @param formData 
  */
 public guestUserAddresschange(formdata){
  if(!formdata || this.selectedAddressType==''){
    return;
  }else{
    $('.loading').show();
    let postdata = {
      name: formdata.value.name,
      phone: formdata.value.phoneguestuseredit,
      country_code: $('#countrycode_guestuseredit').val(),
      device_type: 'web',
      address_title:this.selectedAddressType,
      block_number:formdata.value.block,
      building:formdata.value.building,
      flat_no:formdata.value.flat,
      road_number:formdata.value.road,
      address_direction:formdata.value.direction,
      customer_name:formdata.value.name,
      area:formdata.value.area,
      type:this.addressTypeKey,
    }
    const data = {...this.guestDetails, ...postdata}
    localStorage.setItem('guestDetails', JSON.stringify(data));
    localStorage.setItem('defaultaddress', '1');
    localStorage.setItem('searchedaddress', JSON.stringify(false));
    this.guestDetails = JSON.parse(localStorage.getItem('guestDetails'));
    this.defauladdressobject = localStorage.getItem('address');
    this.defaultaddress = 1;
    this.checkout();
    $('#guestuseredit').modal('hide');
    $('.loading').hide();
  }
 }

 /**
 * form reset
 * @param {NgForm} form
 */
public formReset(form:NgForm){
  form.resetForm();
  // form - address type checks remove
  this.addressTypeChecks.forEach((element) => {
    element.nativeElement.checked = false;
  });
}

 /**
 * To get default address --> conditions
 */
 public toGetDefaultAddress(){
  if(this.userIs == this.userType.LOGGED_USER && this.defaultaddress != 0){
    this.getDefaultAddress();
  }else if(this.defaultaddress==0){
    this.defauladdressobject = localStorage.getItem('address');
  }
}

/**
 * confirm address
 */
public confirmDefaultAddress(){
  $('#addressConfirmation').modal('hide');
  if(this.userIs==this.userType.LOGGED_USER){
  $('#addressMap').modal('show');
  }else{
    this.refreshGuestDetails();
    this.mapGuestUserDetails();
    this.placeOrder();
  }
}

/**
 * initial guest user details
 */
public async mapGuestUserDetails(){
  const guestDetails = JSON.parse(localStorage.getItem('guestDetails'));
  const location = JSON.parse(localStorage.getItem('location'));
  if(this.defaultaddress != 0){
    this.guestDetails = guestDetails;
    this.defauladdressobject = this.guestDetails;
  }else{
    this.guestDetails = {};
    this.guestDetails['name'] = guestDetails['name'];
    this.guestDetails['phone'] = guestDetails['phone'];
    this.selectedAddressType = this.guestDetails['address_title'];
    await this.mapLocation(location, this.guestDetails);
    }
}

/**
 * refresh guest details
 */
public refreshGuestDetails(){
  if(this.guestDetails){
  this.selectedAddressType = '';
  this.guestDetails['adress_title'] = '';
  this.guestDetails['block_number'] = '';
  this.guestDetails['building'] = '';
  this.guestDetails['flat_no'] = '';
  this.guestDetails['road_number'] = '';
  this.guestDetails['address_direction'] = '';
  this.guestDetails['area'] = this.defauladdressobject;
 
  }
 
}

/**
 * error response 
 * @param {any} err
 */
public async errorMessageOne(err){ //something went wrong message
  if(err){
  $('.loading').hide();
  Swal.fire({
    text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
    icon: 'error'
  });
  }
}

/**
 * pay now --> Api 
 * @param {object} postdata
 */
public payNow(postdata){
  if(postdata){
    this.apiconnectService.postRequest(postdata, '/paynow').subscribe(
      async (data) => {
        this.orderplacedata = data;
        if (this.orderplacedata.status) {
          this.gtag.event('purchase', { 
            transaction_id: this.restaurantDetails.name + ' - ' + this.orderplacedata.request_id,
            affiliation: 'Eatzilla Food Delivery',
            value: this.total,
            currency: this.currency
          });
  
          $('.loading').hide();
          Swal.fire({
            text: await this.commonhelper.translateText('Your order has been placed successfully'),
            icon: 'success'
          }).then((result) => {
            this.webStorageService.removeData(['cart', 'restaurant_id','coupon','searchedaddress','isproceedtocheckout']);
            this.router.navigate([this.urilang+"thankyou/" + this.orderplacedata.request_id]);
           });
        }
        else {
          $('.loading').hide();
          Swal.fire({
            text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
            icon: 'error'
          });
        }
      },
      async err => {
        this.errorMessageOne(err);
      }
    );
  }
}

/**
 * checkout api 
 * @param {object} postdata 
 */
public checkoutApi(postdata){
  if(postdata){
    this.apiconnectService.postRequest(postdata, '/checkout').subscribe(
      async (data) => {
        if(data['status']){
        this.restaurantcharges = data;
        if(this.userIs == this.userType.LOGGED_USER && this.defaultaddress !== 0){
          this.getDefaultAddress();
        }
        this.calculateRestaurantCharges();
       }else{
        const errText =this.allAvailableDeliveryAddress.length==0?'!!Oops Something went wrong.':
         `Food items ordered from ${this.restaurantDetails.name}`+'.'+' '+data['message']+'.';
        Swal.fire({
            text: await this.commonhelper.translateText(errText),
            icon: 'error'
          }).then(()=>{
            this.router.navigate([this.urilang+"menu-details/"+this.restaurantDetails.id+"/"+this.restaurantDetails.name]);
          });
         
        }
      },
      async err => {
        this.checkoutApiErrorResponse(err);
      }
    );
  }
}

/**
 * check availability of restaurant and further work
 * @param {object} datapost 
 */
public async checkAvailbility(datapost){
  await this.checkrestaurantavailability(datapost);
    if (!this.check_restaurant_availability.status){
      Swal.fire({
        text: await this.commonhelper.translateText('Oops!! The restaurant does not delivery in this location. Select another location'),
        icon: 'error'
      });
      return
    }
    if (this.check_restaurant_availability.status && this.userIs == this.userType.LOGGED_USER){
      this.ifhaveAvailableRestaurant();
     }else if(this.userIs == this.userType.GUEST_USER){
      const location = JSON.parse(localStorage.getItem('location'));
      await this.mapLocation(location, this.guestDetails);
      $('#addressMap').modal('hide');
      $('#guestuseredit').modal('show');
    }
}

/**
 * map location
 * @param location 
 */
public mapLocation(location, arr): Promise<void>{
  return new Promise(resolve => {
const str = ''|| undefined || null;
 switch(true){
     case location['area'] != str:
     arr['area'] =location['area'];
      break;
      case location['sublocality_level_3'] != str:
      arr['area'] =location['sublocality_level_3'];
      break;
      case location['sublocality_level_2'] != str:
      arr['area'] =location['sublocality_level_2'];
      break;
      case location['sublocality_level_1'] != str:
      arr['area'] =location['sublocality_level_1'];
      break;
      case location['sublocality'] != str:
      arr['area'] =location['sublocality'];
      break;
      case location['address'] != str:
      arr['area'] =location['address'];
      break;
      case location['neighborhood'] != str:
      arr['neighborhood'] =location['neighborhood'];
      break;
      case location['land_mark'] != str:
      arr['land_mark'] =location['land_mark'];
      break;
      case location['route'] != str:
      arr['route'] =location['route'];
      break;
      case location['postal_code'] != str:
      arr['postal_code'] =location['postal_code'];
      break;
      default:
      arr['area'] = location['address'];
  }
  resolve();
})
}

/**
 * complete order // param map 
 * @param {any} value 
 * @param {Array} choosen_choicesarray 
 * @param {number} itemIndex 
 * @returns {boolean}
 */
async completeOrderParamMap(value, choosen_choicesarray, itemIndex): Promise<void> {
  // Simulating an asynchronous operation
  return new Promise(resolve => {
    if(value?.choosen?.length==1){
      value.choosen.forEach(async(data) => {
       this.completeOrderParamMapSub(choosen_choicesarray, data);
      });
    }else if(value?.choosen?.length==0){
      choosen_choicesarray.push('');
    }else if(value?.choosen?.length>1){
      let ids;
      value.choosen.forEach((data, index) => {
        if (data?.id) {
         ids = index == 0? data?.id.toString(): ids +','+ data?.id.toString();
         } else {
          ids = index == 0? '': ids +','+'';
        }
        choosen_choicesarray[itemIndex] = ids;
      });
    }
    resolve();
  });
}

/**
 * complete order param map // sub function // (value?.choosen?.length==1)
 * @param choosen_choicesarray 
 * @param data 
 */
private completeOrderParamMapSub(choosen_choicesarray, data){
   if (data?.id) {
      choosen_choicesarray.push(data.id);
     } else {
       choosen_choicesarray.push(0)
     }
}

/**
 * checkout // param map 
 * @param {any} value 
 * @param {Array} choosen_choicesarray 
 * @param {Array} choicesarray 
 * @param {Array} add_ons 
 * @param {any} add_ons2 
 * @returns {boolean}
 */
async checkoutParamMapOne(value, add_ons, add_ons2, choicesarray, choosen_choicesarray): Promise<void> {
  // Simulating an asynchronous operation
  return new Promise(resolve => {
    value.choosen_radio.forEach((data, index) => {
      if (data) {
        add_ons.push(data.id)
        add_ons2 = add_ons.join();
      }
    })
    if (!add_ons2) {
      add_ons2 = '';
    }
    choicesarray.push(add_ons2)
    let choosen_add_ons = [];
    let choosen_add_ons2: any;
    value.choosen.forEach(data => {
      if (data) {
        choosen_add_ons.push(data.id)
        choosen_add_ons2 = choosen_add_ons.join();
      } else {
        choosen_add_ons.push(0)
      }
    })
    if (!choosen_add_ons2) {
      choosen_add_ons2 = '';
    }
    choosen_choicesarray.push(choosen_add_ons2)
    resolve();
  });
}

/**
 * checkout // param map 
 * @param {any} key 
 * @param {number} index
 * @param {Array} choicesarray 
 * @param {Array} merged 
 * @returns {boolean}
 */
async checkoutParamMapTwo(key, index, choicesarray, merged): Promise<void> {
  // Simulating an asynchronous operation
  return new Promise(resolve => {
    if (key && choicesarray[index]) {
      merged.push(key + ',' + choicesarray[index]);
    }
    else if (!key && choicesarray[index]) {
      merged.push(choicesarray[index]);
    }
    else if (key && !choicesarray[index]) {
      merged.push(key);
    } else {
      merged.push('');
    }
    resolve();
  });
}

/**
 * on get login // map details when user login
 * @param {any} res 
 */
private async onGetLogin(res): Promise<void>{
 // Simulating an asynchronous operation
return new Promise(resolve => {
  if(res){
    this.userIs = localStorage.getItem('user');
    if(this.userIs == this.userType.GUEST_USER){
      this.guestDetails = JSON.parse(localStorage.getItem('guestDetails'));
    }else{
      this.getProfileDetails();
      this.getAllDeliveryAddress();
      if(this.couponCode){
        this.removeCoupon(this.couponCode, this.staticbooleanKey.False);
      }
    }
    this.loggedIn = true
    this.toGetDefaultAddress()
  }else{
    this.loggedIn = false;
    this.userIs = localStorage.getItem('user');
    if(this.userIs == this.userType.GUEST_USER){
      this.guestDetails = JSON.parse(localStorage.getItem('guestDetails'));
    }
    this.toGetDefaultAddress()
   }
   resolve();
  });
}

/**
 * get restaurant details // to get updated details
 */
public getRestaurantDetails(id){
  this.apiconnectService.postRequest({
     restaurant_id: id,
     device_type: 'web',
     veg_only: 0,
   }, "/single_restaurant?lat=" + localStorage.getItem('lat') + "&lng=" + localStorage.getItem('lng')).subscribe(async (data:any) => {
     if(data['status']){
       this.restaurantDetails = data['restaurants'][0];
     }
   }, err => {
     console.log(err);
     this.restaurantDetails = JSON.parse(localStorage.getItem("cartItemRestaurant"));
   });
   
 }

  /**
   * get address
   * @param latitude 
   * @param longitude 
   * @param address 
   */
  public getAddressName(latitude, longitude){
    if(latitude && longitude){
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, async (results, status) => {
        if (status === 'OK') {
           if (results[0]) {
            this.webStorageService.storeLocalData({'address':results[0].formatted_address,'lat':latitude, 'lng':longitude}) // store a add address
            await this.storeGuestArea(results[0]);
            this.showEditAddress();
           } else {
             console.log('No results found')
           }
         } else {
           console.log('Geocoder failed due to: ' + status)
         }
       })
    }
    }

       /**
   * store guest area
   * @param name 
   */
 private storeGuestArea(address):Promise<void>{
  return new Promise(resolve => {
  const location = {};
  location['address'] = address.formatted_address
  if(address.address_components.length>0){
    address.address_components.forEach((area)=> { // get area
       this.addresskey.forEach(keys=>{
        if(area.types.includes(keys.TYPES_ARRAY)){
              this.storeLocation(area, keys.KEY_TO_SAVE, location);
            }
           })
       });
  }
  resolve();
})
}

/**
 * store local data
 * @param {any} data 
 * @param {string} key 
 */
private storeLocation(data, key, place){
  const location = place;
  location[key] = data.long_name;
  this.webStorageService.storeLocalData({'location':location});
}

/**
 * if have available resturant // logged user
 */
public async ifhaveAvailableRestaurant(){
  const location = JSON.parse(localStorage.getItem("location"));
  const address = JSON.parse(localStorage.getItem("address"));
  this.latitudeinput = JSON.parse(localStorage.getItem('lat'));
  this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem('lng')));
  if (location && address){
  $('#addressMap').modal('hide');
  this.addaddress.addresstring = address;
  $('#editaddressmodal').modal('show');
  await this.mapLocation(location, this.addaddress);
  }else{
     this.getAddressName(this.latitudeinput, this.longtitudeinput);
  }
}

/**
 * default address
 */
private searchedAddress(){
  if (this.devicetype == 'iOS' || this.browser == 'Safari') {
    this.showEditAddress();
  }
  else{
     if(this.userIs == this.userType.LOGGED_USER){
      this.defauladdressobject = localStorage.getItem('address');
        this.setaddressinput = JSON.parse(localStorage.getItem('address'));
         this.latitudeinput = JSON.parse(localStorage.getItem('lat'));
         this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem('lng')));
        $('#addressConfirmation').modal('show');
      }else{
      this.defauladdressobject = localStorage.getItem('address');
      $('#addressConfirmation').modal('show');
     }
   }
}

/**
 * no defult address
 */
private async noSearchedAddress(){
  if(this.userIs == this.userType.LOGGED_USER){
    if(this.allAvailableDeliveryAddress.length>0){
      this.placeOrder();
    }else{
      Swal.fire({
        text: await this.commonhelper.translateText('Oops!! No default address. Please add address to deliver food.'),
        icon: 'error'
      }).then(()=>{
        $('#addressMap').modal('show');
      });
    }
  }else{
      this.placeOrder();
   }
}

/**
 * on show edit address modal
 */
public onShowEditModal(){
  if(this.guestDetails){
    this.selectedAddressType = this.guestDetails.address_title
  }
}

/**
 * place order
 */
private async placeOrder(){
  Swal.fire({
    text: await this.commonhelper.translateText('You are about to place your order. Are you sure you want to continue?'),
    icon: 'question',
    showCancelButton: true,
    cancelButtonText: await this.commonhelper.translateText('No'),
    confirmButtonText: await this.commonhelper.translateText('Yes')
  }).then((result) => {
    if (result.isConfirmed) {
      this.completeOrder('1');
    }
  });
}

/**
 * address confirmation
 */
public addressConfirmation(confirmation){
 if(confirmation){
    this.showEditAddress();
 }
}

/**
 * checkout Api error response
 * @param err 
 */
private async checkoutApiErrorResponse(err){
  if(err['status']==401){
    this.webStorageService.removeData(['userPic']);
    this.router.navigate([this.urilang+'home']);
    Swal.fire({
      text: await this.commonhelper.translateText('Loggedout Successfully'),
      icon: 'success'
    });
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    }else{
      $('.loading').hide();
    Swal.fire({
      text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
      icon: 'error'
    });
    }
}

}