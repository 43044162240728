<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{'Profile' | translate}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <h2 class="text-left">{{'Welcome,' | translate}} {{userName}}</h2>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-xm-6 ">
                <div class="card overflow-hidden sales-card bg-primary-gradient">
                    <div class="pl-3 pt-3 pr-3 pb-2 pt-0">
                        <div class="">
                            <h6 class="mb-3 tx-12 text-white text-left text-uppercase">{{'Total Orders' | translate}}
                            </h6>
                        </div>
                        <div class="pb-0 mt-0">
                            <div class="d-flex">
                                <div class="">
                                    <h4 class="tx-20 font-weight-bold mb-1 text-white">{{orderHistoryResponse?.message ?
                                        0:
                                        orderHistoryResponse?.past_orders.length+orderHistoryResponse?.upcoming_orders.length+orderHistoryResponse?.cancel_orders.length}}
                                    </h4>
                                </div>
                                <span class="float-right my-auto ml-auto">
                                    <span class="fa fa-shopping-cart text-white fa-3x"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-xm-6 ">
                <div class="card overflow-hidden sales-card bg-secondary">
                    <div class="pl-3 pt-3 pr-3 pb-2 pt-0">
                        <div class="">
                            <h6 class="mb-3 tx-12 text-white text-left text-uppercase">{{'Upcoming Orders' | translate}}
                            </h6>
                        </div>
                        <div class="pb-0 mt-0">
                            <div class="d-flex">
                                <div class="">
                                    <h4 class="tx-20 font-weight-bold mb-1 text-white">{{orderHistoryResponse?.message ?
                                        0:orderHistoryResponse?.upcoming_orders.length}}
                                    </h4>
                                </div>
                                <span class="float-right my-auto ml-auto">
                                    <span class="fa fa-shopping-cart text-white fa-3x"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-xm-6 ">
                <div class="card overflow-hidden sales-card bg-warning">
                    <div class="pl-3 pt-3 pr-3 pb-2 pt-0">
                        <div class="">
                            <h6 class="mb-3 tx-12 text-white text-left text-uppercase">{{'Past Orders' | translate}}
                            </h6>
                        </div>
                        <div class="pb-0 mt-0">
                            <div class="d-flex">
                                <div class="">
                                    <h4 class="tx-20 font-weight-bold mb-1 text-white">{{orderHistoryResponse?.message ?
                                        0:
                                        orderHistoryResponse?.past_orders.length}}
                                    </h4>
                                </div>
                                <span class="float-right my-auto ml-auto">
                                    <span class="fa fa-shopping-cart text-white fa-3x"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="nav flex-column nav-pills text-left col-md-3 pl-3 mb-3" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                <a class="nav-link" [ngClass]="{ active: activetab == 'orders'}" id="v-pills-home-tab"
                    data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home"
                    aria-selected="true">{{'Your Orders' | translate}}</a>
                <a class="nav-link" [ngClass]="{ active: activetab == 'updateprofile'}" id="v-pills-profile-tab"
                    data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile"
                    aria-selected="false" *ngIf="userIs == userType.LOGGED_USER">{{'Your Profile' | translate}}</a>
                <a class="nav-link" [ngClass]="{ active: activetab == 'savedaddresses'}" id="v-pills-messages-tab"
                    data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages"
                    aria-selected="false" *ngIf="userIs == userType.LOGGED_USER">{{'Saved Addresses' | translate}}</a>
                <a class="nav-link" (click)="logout()" id="v-pills-settings-tab" data-toggle="pill"
                    href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false" *ngIf="userIs == userType.LOGGED_USER">{{'Log
                    Out' | translate}}</a>
            </div>
            <div class="tab-content col-md-9" id="v-pills-tabContent">
                <div class="tab-pane fade show rounded border text-left dir"
                    [ngClass]="{ active: activetab == 'orders', show: activetab == 'orders'}" id="v-pills-home"
                    role="tabpanel" aria-labelledby="v-pills-home-tab">
                    <div class="border-bottom py-2 px-3 text-left">
                        <ul class="nav nav-pills nav-fill">
                            <li class="nav-item">
                              <a class="nav-link" [ngClass]="{ 'active': tabKey == tab?.UPCOMING}"  aria-current="page" (click)="showOrdersDetails(tab?.UPCOMING)">UpComing Orders</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" [ngClass]="{ 'active':tabKey == tab?.DELIVERED}" (click)="showOrdersDetails(tab?.DELIVERED)">Past Orders</a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" [ngClass]="{ 'active':tabKey == tab?.CANCELLED}" (click)="showOrdersDetails(tab?.CANCELLED)">Cancelled Orders</a>
                            </li>
                          </ul>
                    </div>
                    <ul class="orderlisting">
                        <app-order-detail-list [tabKey]="tabKey" [loggedIn]="loggedIn"></app-order-detail-list>
                    </ul>
                </div>
                <div class="tab-pane fade rounded border"
                    [ngClass]="{ active: activetab == 'updateprofile', show: activetab == 'updateprofile'}"
                    id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                    <div class="p-4 mb-3" *ngIf="userProfileDetails">
                        <form name="form" (ngSubmit)="updateprofile.form.valid && updateSettings(updateprofile)"
                            #updateprofile="ngForm" novalidate>
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="name">{{'Full Name' | translate}} <small
                                                class="text-danger">*</small></label>
                                        <input name="name" class="form-control" [(ngModel)]="userProfileDetails.name"
                                            #name="ngModel"
                                            [ngClass]="{ 'is-invalid': updateprofile.submitted && name.invalid }"
                                            placeholder="{{'Enter your Full Name' | translate}}" type="text" required minlength="3" maxlength="25" pattern="(?! )[A-Za-z\s]{1,}">
                                        <small id="name" class="form-text text-muted">{{'Write your Full Name' |
                                            translate}}</small>
                                        <div *ngIf="updateprofile.submitted && name.invalid" class="invalid-feedback">
                                            <div *ngIf="name.errors.required">{{'Full Name is required' | translate}}</div>
                                            <div *ngIf="name.errors.minlength && !name.errors.pattern">{{'Name must be at least 3 characters' | translate}}</div>
                                            <div *ngIf="name.errors.pattern">{{'Enter valid name. Letters and space only allowed.' | translate}}</div>
                                            <div *ngIf="name.errors.maxlength && !name.errors.pattern">{{'Maximum 25 letters allowed' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="email">{{'Email Address' | translate}} <small
                                                class="text-danger">*</small></label>
                                        <input name="email" class="form-control" [(ngModel)]="userProfileDetails.email"
                                            #email="ngModel"
                                            [ngClass]="{ 'is-invalid': updateprofile.submitted && email.invalid }"
                                            placeholder="{{'Enter your Email Address' | translate}}" type="email" required pattern="{{emailPattern}}">
                                        <small id="email" class="form-text text-muted">{{'Write your Email Address' |
                                            translate}}</small>
                                        <div *ngIf="updateprofile.submitted && email.invalid" class="invalid-feedback">
                                            <div *ngIf="email.errors.required">{{'Email Address is required' | translate}}</div>
                                            <div *ngIf="email.errors.pattern">{{'Enter valid Email Id' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 ">
                                    <div class="form-group">
                                        <label for="password">{{'Password' | translate}} <small
                                                class="text-danger">*</small></label>
                                                <div class="position-relative">
                                                <input autocomplete="off" name="password" class="form-control"
                                                    [(ngModel)]="userProfileDetails.password" #password="ngModel"
                                                    [ngClass]="{ 'is-invalid': updateprofile.submitted && password.invalid }"
                                                    placeholder="{{'Enter your New Password' | translate}}" [type]="eyeView?'text':'password'" required
                                                    minlength="8">
                                            
                                                <div class="eye-view"  (click)="eyeView = !eyeView">
                                                    <span class="fa fa-eye" aria-hidden="true" *ngIf="!eyeView && !(updateprofile.submitted && password.invalid)"></span>
                                                    <span class="fa fa-eye-slash" aria-hidden="true" *ngIf="eyeView && !(updateprofile.submitted && password.invalid)"></span>
                                                </div>
                                            </div>
                                        <div *ngIf="updateprofile.submitted && password.invalid" class="invalid-feedback">
                                            <div *ngIf="password.errors.required">{{'Password is required' | translate}}</div>
                                            <div *ngIf="password.errors.minlength">{{'Password must be at least 8 characters' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label for="phonenumber">{{'Phone Number' | translate}} <small
                                                class="text-danger">*</small></label>
                                        <input readonly type="text" value="{{userProfileDetails.phone}}" class="form-control"
                                            name="phonenumber" aria-describedby="phoneHelp"
                                            placeholder="{{'Enter your Phone Number' | translate}}">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 ml-auto ">
                                    <button type="submit" class="btn btn-primary btn-block my-3">{{'Update Profile' |
                                translate}}</button>
                                 </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="tab-pane fade rounded border"
                    [ngClass]="{ active: activetab == 'savedaddresses', show: activetab == 'savedaddresses'}"
                    id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                    <div class="p-3 mb-3">
                        <button class="btn btn-primary pull-right mb-3 text-uppercase" data-toggle="modal"
                            data-target="#addressMap"><span class="fa fa-plus"></span> &nbsp; {{'Add new Address' |
                            translate}}</button>
                        <div class="clearfix"></div>
                        <div class="row">
                            <div class="addresslisting col-md-12 text-left">
                                <ul>
                                    <li class="p-3" *ngFor="let address of allAvailableDeliveryAddress?.data">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <p>{{'Address Type' | translate}}</p>
                                                <p>{{'Address' | translate}}</p>
                                            </div>
                                            <div class="col-md-6">
                                                <p>{{address.address_title}}</p>
                                                <p>{{address.address}} {{'Block' | translate}} {{address.block_number}}
                                                    {{'Road No' | translate}} {{address.landmark}} {{'Building Number' |
                                                    translate}} {{address.building}} {{'Flat/Apt' | translate}}
                                                    {{address.flat_no}}</p>
                                                <p>{{userProfileDetails.phone}}</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p class="text-right">
                                                    <a href="javascript:void(0)" class="text-danger"
                                                        (click)="deleteAddress(address.id)">{{'DELETE' | translate}}</a>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                 </ul>
                                 <div class="flex justify-center items-center no-address-data" *ngIf="(allAvailableDeliveryAddress != null && allAvailableDeliveryAddress != undefined)?allAvailableDeliveryAddress.data.length==0:true">
                                    <p>No data found.</p>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade rounded border" id="v-pills-settings" role="tabpanel"
                    aria-labelledby="v-pills-settings-tab">...</div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<div class="modal fade" id="editaddressmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-3 px-4">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Add Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseAddressModal(addaddressform)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-left">
                <form name="form" (ngSubmit)="addaddressform.form.valid && addaddresssubmit(addaddressform)"
                    #addaddressform="ngForm" novalidate>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="location">{{'Area' | translate}} <small
                                    class="text-danger">*</small></label>
                            <input type="text" class="form-control" placeholder="Search for your location"
                                autocomplete="off" name="location" [(ngModel)]="this.addaddress.location"
                                #location="ngModel"  (focusin)="onChangeArea($event)"
                                [ngClass]="{ 'is-invalid': addaddressform.submitted && location.invalid }" required>
                            <div *ngIf="addaddressform.submitted && location.invalid" class="invalid-feedback">
                                <div *ngIf="location.errors.required">{{'Location is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="adress">{{'Address Type' | translate}} <small class="text-danger">*</small></label>
                            <div class="flex justify-around item-center">
                            <ng-container *ngFor="let type of addressTypes; let i = index;">
                            <label class="m-0 radiobutton">
                            <input name="address" type="radio" id="address{{type}}" value="{{type}}" (click)="chooseAddressType($event, i)"
                            class="check" #addressTypeChecks [checked]="type==selectedAddressType">
                            <span class="checkmark"></span>
                            <span class="pl-2">{{type}}</span>
                           </label>
                            </ng-container>
                            </div>
                            <div *ngIf="addaddressform.submitted" class="addresstype-invalid">
                                <div *ngIf="selectedAddressType==''">{{'Address Type is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Block Number' | translate}}</label>
                            <input type="text" class="form-control"
                                placeholder="{{'Enter your Block Number' | translate}}" autocomplete="off"
                                name="blocknumber" [(ngModel)]="addaddress.blocknumber" #blocknumber="ngModel"
                                [ngClass]="{ 'is-invalid': addaddressform.submitted && blocknumber.invalid }">
                        </div>
                        <div class="col">
                            <label for="staticEmail">{{'Road Number' | translate}}</label>
                            <input type="text" class="form-control"
                                placeholder="{{'Enter the Road Number' | translate}}" autocomplete="off"
                                name="road_number" [(ngModel)]="addaddress.road_number" #road_number="ngModel"
                                [ngClass]="{ 'is-invalid': addaddressform.submitted && road_number.invalid }">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Building Number' | translate}}</label>
                            <input type="text" class="form-control"
                                placeholder="{{'Enter the Building Number' | translate}}" autocomplete="off"
                                name="building" [(ngModel)]="addaddress.building" #building="ngModel"
                                [ngClass]="{ 'is-invalid': addaddressform.submitted && building.invalid }">
                          </div>
                        <div class="col">
                            <label for="staticEmail">{{'Flat Number/Office Number' | translate}}</label>
                            <input type="text" class="form-control"
                                placeholder="{{'Enter the Flat Number/Office Number' | translate}}" autocomplete="off"
                                name="flat_no" [(ngModel)]="addaddress.flat_no" #flat_no="ngModel"
                                [ngClass]="{ 'is-invalid': addaddressform.submitted && flat_no.invalid }">
                          </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Address Directions' | translate}} <small
                                class="text-danger">*</small></label>
                            <input type="text" class="form-control"
                                placeholder="{{'Directions to help the rider' | translate}}"
                                autocomplete="off" name="landmark" [(ngModel)]="addaddress.landmark"
                                #landmark="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && landmark.invalid  }" required
                                minlength="5">
                                <div *ngIf="addaddressform.submitted && landmark.invalid " class="invalid-feedback">
                                <div *ngIf="landmark.errors.required">{{'Directions is required' | translate}}
                                </div>
                                <div *ngIf="landmark.errors.minlength">{{'Directions must be atleast 5 characters' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block text-uppercase" type="submit">{{'Save Address' |
                        translate}}</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addressMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog address-modal" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel">{{'Add new Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-googlemap [id]="'profile1'" [componenttype]="componenttype" [latitudeinput]="latitudeinput"
                    [longtitudeinput]="longtitudeinput" [setaddressinput]="setaddressinput"
                    [title]="'Confirm'" (addressconfirmation)="addressConfirmation($event)"></app-googlemap>
            </div>
        </div>
    </div>
</div>
 <app-footer></app-footer>