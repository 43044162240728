export const environment = {
    production: true,
    // apiUrl: "https://v2admin.eatzilla.info/api",
  apiUrl: "https://dev-api.eatzilla.info/api",
    apiIp: "http://161.35.142.163",
    deviceToken: "sp-eatzilla-web",
    // GOOGLEAPI: "AIzaSyDJ8b9bAQgVY51g5cwQ8WpCfyraRRQxEkY"
   GOOGLEAPI: "AIzaSyA00aH-1l_ssrdeIsWyYU5h5hPxmlteLQk",
  SOCKET_URL:"https://dev-socket.eatzilla.info"
};
// export const environment = {
// production: true,
// apiUrl: "http://159.89.35.53/eatzilla/api",
// img_url: "http://159.89.35.53/web/",
// apiIp: "http://159.89.35.53/",
// deviceToken: "sp-eatzilla-web",
// GOOGLEAPI: "AIzaSyDJ8b9bAQgVY51g5cwQ8WpCfyraRRQxEkY",
// };
