import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Socket, io } from "socket.io-client";
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root',
})

/**
 * socket service
 */
export class SocketService {
  private cancelEvent = 'adminCancelRequest';
  private socketUrl = environment.SOCKET_URL;
  private socket:Socket;
  private updateEvent = 'currentRequestStatusUpdate';

  //subject
  public cancelOrder$: BehaviorSubject<string> = new BehaviorSubject('');
  public updateOrder$: BehaviorSubject<string> = new BehaviorSubject('');

  /**
   * constructor
   */
  constructor() {
    const authId = localStorage.getItem('authId');
    const authToken = localStorage.getItem('authToken');
    const socket = this.socket?!this.socket.connected:false;
    if(authId && authToken){
     this.socket = io(this.socketUrl+"?id=" + authId + "&token=" + authToken + "&type=" +1, { transports: ["polling"],forceNew: true, reconnectionDelay: 2000, timeout: 100000}); 
     console.log(this.socket)
     
    }
    if(socket){
      this.socket.connect();
      console.log(this.socket)
     }
  }

  /**
   * cancel order
   * @returns {object}
   */
  public cancelOrder = () => {
    this.socket.on(this.cancelEvent, (res) =>{
      this.cancelOrder$.next(res);
    });

    return this.cancelOrder$.asObservable();
  };

  /**
   * disconnect
   */
  public disconnect = () => {
    console.log(this.socket)
    if(this.socket){
      this.socket.disconnect();
    }
    console.log(this.socket)
  };

  /**
   * connect
   */
  public connect = () => {
    const socket = this.socket?!this.socket.connected:false;
    if(socket){
      this.socket.connect();
    }
    console.log(this.socket)
  };

  /**
   * update order
   * @returns {object}
   */
  public updateOrder = () => {
    this.socket.on(this.updateEvent, (res) =>{
      this.updateOrder$.next(res);
    });
     return this.updateOrder$.asObservable();
  };
}