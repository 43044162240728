import { Component, Injector, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from "../../../globals";
import { Meta, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
import { FILTER_LIST, SORT_LIST } from 'src/app/constants/search-result-page.constant';
declare var $: any;
enum StaticBoolean{
  True, False
}
enum StaticFilters{
  Filter, Cuisine
}
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonHelper } from 'src/app/common/helper';
@Component({
  selector: 'app-searchresults',
  templateUrl: './searchresults.component.html',
  styleUrls: ['./searchresults.component.scss']
})
export class SearchresultsComponent implements OnInit {
  lat: number;
  lng: number;
  restaurantlist: Array<object> = [];
  restaurantlist_top:Array<object> = [];
  resturantlistResponse = false;
  filters: any = [];
  cuisineInput:any = [];
  title: any;
  term: string;
  alttitle: string;
  mobilecheck:boolean;
  deviceInfo = null;
  slideConfig = {
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    rtl: false,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    responsive: [
      
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ]
  };
  currency: any;
  translatedstr: string;
  tranlsatedtitle: any;
  tranlsateddescription: any;
  urilang: string;
  public selectedCuisine = null;
  public cuisinesConfig = {
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    rtl: false,
    dots:true
  }
  public cuisines:any = [];
  public sortList = SORT_LIST;
  public filterList = FILTER_LIST;
  public selectedFilterCuisine = '';
  public sortOptionKey = 0;
  public filterOptionKeys:any = [];
  public offerKey = null;
  public isVegKey = null;
  public filterOptionData:any = [];
  public multipleCuisineOptions = [];
  public showAllFilter = false;
  private apiconnectService:ApiconnectService;
  private router: Router;
  private deviceService: DeviceDetectorService;
  private meta : Meta;
  private titleService: Title;
  public staticbooleankey = StaticBoolean;
  public resturantToplistResponse = true;
  public selectedCuisineName = '';
  public staticfilterKeys = StaticFilters;

  /**
   * constructor
   * @param router 
   * @param route 
   * @param apiconnectService 
   * @param globals 
   * @param titleService 
   * @param meta 
   * @param deviceService 
   * @param translate 
   */
  constructor(
    private route: ActivatedRoute,
    private globals: Globals,
    private translate: TranslateService,
    private injector: Injector,
    public commonHelper: CommonHelper,
  ) {
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService); //SonarQube issue -> constructor params max. allowed 7.
    this.router = this.injector.get<Router>(Router);
    this.deviceService = this.injector.get<DeviceDetectorService>(DeviceDetectorService);
    this.meta = this.injector.get<Meta>(Meta);
    this.titleService = this.injector.get<Title>(Title);
    const lang = localStorage.getItem('language');
    if (lang == 'ar'){
      this.slideConfig.rtl = true;
    }
    this.alttitle = this.globals.imagealt;
    this.translate.get('EATZILLA Food Delivery in Middle East').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Find out Multiple cuisines and your favorite restaurant on Eatzilla Now. Order your favorite American, Mexican, Pakistani, Bahraini, Indian, Italian, Lebanese or fast food with Eatzilla').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });
    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription });
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals.currency_ar;
    }
    else {
      this.urilang = '';
      this.currency = this.globals.currency;
    }
  }
  
  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice){
      this.mobilecheck = true;
    }
  }
  ngOnInit(): void {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    window.scroll(0, 0);
    this.lat = this.route.snapshot.queryParams["lat"];
    this.lng = this.route.snapshot.queryParams["lng"];
    this.title = this.route.snapshot.queryParams["address"];
    if (this.lat && this.lng) {
      this.getRestaurants(this.staticbooleankey.True);
      this.getFilters();
      this.getCuisines();
    }
    localStorage.removeItem('isproceedtocheckout');
  }
  gotoMenudetails(name,id) {
    var replaced = name.split(' ').join('-');
    var replaced2 = replaced.split('(').join('');
    var replaced3 = replaced2.split(')').join('');
    this.router.navigate([this.urilang+"menu-details/"+id+"/"+replaced3.toLowerCase( )]);
  }

  /**
   * get resataurants list
   */
 public getRestaurants(key?) {
   $('.loading').show();
   setTimeout(() => {
    $('.loading').hide();
   }, 500);
   this.resturantlistResponse = true;
     this.apiconnectService.postRequest({
      'is_offer': this.offerKey,
      'device_type': 'web',
      'is_pureveg': 0,
      'cuisines': this.showAllFilter?this.multipleCuisineOptions:this.cuisineInput,
      'sort_type': this.sortOptionKey,
      'is_filter':this.isVegKey,
    }, "/get_nearby_restaurant?lat=" + this.lat + "&lng=" + this.lng + "&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      (data) => {
       if (data['status']) {
        this.resturantlistResponse = false;
        this.restaurantlist = data['restaurants'];
        this.sortRestaurants(data['restaurants']);
         if (key !== this.staticbooleankey.False){  // key -> is filter process check
            this.arrangetopRestaurant();// refresh top restaurant list -> top restaurant list load when initialization only. 
          }
          $('.loading').hide();
          this.showAllFilter = false;
        } else {
          this.restaurantlist = [];
          this.showAllFilter = false;
          this.resturantlistResponse = false;
          this.resturantToplistResponse = false;
          if(key == this.staticbooleankey.False){
            if(this.restaurantlist.length===0){
              this.filterNoRestaurant();
            }
          }
          this.selectedCuisineName = '';
        }
      },
      err => {
        this.router.navigate([this.urilang + 'home']);
        console.log(err);
        this.showAllFilter = false;
        this.restaurantlist_top = [];
        $('.loading').hide();
        this.resturantlistResponse = false;
        this.resturantToplistResponse = false;
        this.selectedCuisineName = '';
      }
    );
  }

  /**
   * get filters // cuisines list
   */
  private getFilters() {
    $('.loading').show();
    this.apiconnectService.getRequest("/get_filter_list/1?device_type=web").subscribe(
      data => {
        this.filters = data;
        if (this.filters.status) {
          this.filters.data.forEach((element) => {
            element.selected = false;
          });
        }
        $('.loading').hide();
      },
      err => {
        console.log(err)
      }
    )
  }

/**
 * select cuisine
 * @param {object} cuisine 
 */
  public selectCuisine = (cuisine) => {
    this.selectedCuisine = cuisine.id;
    this.clearFilterIfCuisine()
    this.cuisineInput = [];
    if (cuisine.id){
      this.cuisineInput.push(cuisine.id);
      $('#cuisinesall').modal('hide');
     this.getRestaurants(this.staticbooleankey.False);
     }
     this.selectedCuisineName = cuisine.name;
   };

  /**
  * get cuisines
  */
 private getCuisines(){
   const isTrue = (!this.lat || !this.lng);
   if(isTrue)return
   this.apiconnectService.getRequest( "/get_cuisines?device_type=web&lat=" + this.lat + "&lng=" + this.lng).subscribe(
     (data) => {
       if (data['status']) {
        this.cuisines = data;
        $('.loading').hide();
       }
       else {
         $('.loading').hide();
       }
     },
     err => {
        console.log(err);
     }
   );
 }

 /**
 * select cuisine in filter options
 * @param {object} cuisine 
 */
 public selectCuisineFilterOptions = (cuisine) => {
  cuisine.selected = !cuisine.selected;
  this.pushSliceArrayFn(this.multipleCuisineOptions, cuisine.id);
 
};

/**
 * select sort options // map sort key
 * @param {number} id
 */
public selectSortOptions(id){
  if(id){
    this.sortOptionKey = id;
  }else{
    this.sortOptionKey = null;
  }
}

/**
 * select filter options // check and uncheck 
 * @param {number} index
 */
public async selectFilterOptions(data,id){
  if(data && id){
    this.pushSliceArrayFn(this.filterOptionKeys, id);
    this.pushSliceArrayFn(this.filterOptionData, data);
    this.mapFilterKeys();
   }
}

/**
 * push slice array
 * @param {array} arr
 * @param {any} key // number, string ... etc
 */
public pushSliceArrayFn(arr, key){
  const index = arr.findIndex((x)=>x==key);
  if(index != -1){
    arr.splice(index,1);
  }else{
    arr.push(key);
  }
}

/**
 * map filter keys // offer and pureveg or nonveg maping
 */
public mapFilterKeys(){
  if(this.filterOptionKeys.length>0){
    const OfferIndex = this.filterOptionKeys.findIndex((x)=>x==1);
    const vegIndex = this.filterOptionKeys.findIndex((x)=>x==2)
    const nonvegIndex = this.filterOptionKeys.findIndex((x)=>x==3)
    this.arrangeofferKeys(OfferIndex);
    this.arrangefoodKeys(vegIndex, nonvegIndex)
   
  }else{
    this.offerKey = null;
    this.isVegKey = 0;
  }
}

/**
 * map filters // offer key map
 * @param {number} offer 
 */
public arrangeofferKeys(offer){
  if(offer && offer == -1){
    this.offerKey = 0
  }else{
    this.offerKey = 1;
  }
}

/**
 * map filters // veg and nonveg key map
 * @param {number} veg
 * @param {number} nonveg  
 */
public arrangefoodKeys(veg, nonveg){
  switch(true){
    case veg != -1 && nonveg == -1:
      this.isVegKey = 1
      break;
    case veg == -1 && nonveg != -1:
      this.isVegKey = 2
      break;
    case veg != -1 && nonveg != -1:
      this.isVegKey = 3
      break;
    default:
      this.isVegKey = 0
      break;
  }
}

/**
 * apply filter
 */
public applyFilterOptions(){
  this.clearCuisineIfFilter();
  this.getRestaurants(this.staticbooleankey.False);
  $('#filterAll').modal('hide');
  this.selectedCuisineName = '';
}

/**
 * show all filters modal
 */
public showAllFilterOptions(){
this.showAllFilter = true;
  $('#filterAll').modal('show');
}

/**
 * close modal
 * @param {string} id 
 */
public closeModal(id){
  this.showAllFilter = false;
  $(id).modal('hide');
}

/**
 * sort filter only to filter restaurant
 * @param {number} id 
 */
public sortFilterOnly(id){
  if(id){
    this.clearCuisineIfFilter();
    this.sortOptionKey = id;
    this.getRestaurants(this.staticbooleankey.False);
    this.selectedCuisineName = '';
   }
}

/**
 * filter option only to filter restaurant
 * @param {string} data
 * @param {number} id
 */
public filterOptionOnly(data, id){
  if(data && id){
    this.clearCuisineIfFilter();
    this.pushSliceArrayFn(this.filterOptionKeys, id);
    this.pushSliceArrayFn(this.filterOptionData, data);
    this.mapFilterKeys();
    this.getRestaurants(this.staticbooleankey.False);
    this.selectedCuisineName = '';
   }
}

/**
 * clear single filter
 */
public clearSingleFilter(){
  this.sortOptionKey = 0;
  this.offerKey = null;
  this.isVegKey = null;
  this.filterOptionData=[];
  this.filterOptionKeys=[];
  this.getRestaurants(this.staticbooleankey.False);
}

/**
 * clear multiple filter
 */
public clearMultipleFilter(){
  this.clearSelectedCuisines(this.filters.data);
  this.multipleCuisineOptions = [];
  this.clearSingleFilter();
  $('#filterAll').modal('hide');
}

/**
 * sort restaurants
 * @param data 
 */
public sortRestaurants(data){
  if(data.length){
    this.restaurantlist.sort((a,b)=>{
      if (a['is_open'] === 1 && a['is_busy'] === 0 && (b['is_open'] !== 1 || b['is_busy'] !== 0)) {
        return -1;
    } else if (b['is_open'] === 1 && b['is_busy'] === 0 && (a['is_open'] !== 1 || a['is_busy'] !== 0)) {
        return 1;
    } else {
        return 0;
    }
   });
  }
}

/**
 * clear multiple filter
 */
private clearSelectedCuisines(orgArr){
  orgArr.forEach((key, index)=>{
    if(key.selected){
      orgArr[index].selected = false;
    }
 });
}

/**
 * top restaurant details arrange
 */
public arrangetopRestaurant(){
  this.restaurantlist_top = this.restaurantlist;
            if (this.restaurantlist_top?.length < 4){
              this.slideConfig.slidesToShow = this.restaurantlist_top.length;
            }
            this.resturantToplistResponse = false;
}

/**
 * no restaurant when filter
 */
public async filterNoRestaurant(){
  $('#filterAll').modal('hide');
  Swal.fire({
    text: await this.commonHelper.translateText('No Restaurant found'),
    icon: 'error'
  }).then(()=>{
    this.clearCuisineIfFilter();
    this.clearFilterIfCuisine();
    this.getRestaurants(this.staticbooleankey.False);
  });
}

/**
 * clear cuisine filter
 */
public clearCuisineFilter(){
  this.cuisineInput = [];
  this.getRestaurants(this.staticbooleankey.False);
  this.selectedCuisineName = '';
}

/**
 * clear filters // when cuisine filter
 */
public clearFilterIfCuisine(){
  this.offerKey = null;
  this.isVegKey = null;
  this.filterOptionData = [];
  this.multipleCuisineOptions = [];
  this.clearSelectedCuisines(this.filters.data);
  this.showAllFilter = false;
  this.sortOptionKey = 0;
  this.filterOptionKeys = [];
}

/**
 * clear cuisine filter // when filter options
 */
public clearCuisineIfFilter(){
  this.cuisineInput = [];
}
}
