import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {  Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
/**
 * common helper function
 */
export class CommonHelper {

  constructor(
    private translate: TranslateService,
    private router: Router,
  ){

  }
 /**
   * string change to number
   * @param {string} data 
   */
 public dataChangeToNumber(data:any){
  if(data){
    return typeof(data)=='string'? +data:data;
  }else{
    return null
  }
}

/**
   * Translates text
   * @param {any} str 
   * @returns {string}
   */
public translateText(str: any): any {
  return new Promise((resolve, reject) => {
    this.translate.get(str).subscribe((data: string) => {
      const translatedstr = data;
      resolve(translatedstr);
    });
  })
}

/**
   * Get current route url
   * return {string}
   */
public getRouteUrl(): any {
  return this.router.url;
}
}
