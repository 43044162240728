<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item"><a (click)="goBack()">{{title}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{restaurantDetails.name}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 mt-4">
                <div class="menu-detail-view">
                    <div>
                        <a>
                            <div class="rest-logo-bg">
                                <span class="valign-helper"></span>
                                <img src="{{restaurantDetails.image}}" alt="{{alttitle}} {{restaurantDetails.name}}" title="{{alttitle}} {{restaurantDetails.name}}" width="120">
                            </div>
                        </a>
                    </div>
                    <div *ngIf="restaurantDetails">
                        <div>
                            <h4>{{restaurantDetails.name}} <span class="btn btn-primary btn-sm" *ngIf="restaurantDetails.is_open == 0">CLOSED AT THE MOMENT</span></h4>
                        <!--offers-->
                        <p class="cuisinetext mb-1 text-success" *ngIf="restaurantDetails?.offer_type==1 && restaurantDetails?.discount_type=='1'">
                            <span class="cuisinetext">
                                {{currency}}{{restaurantDetails?.offer_amount}} flat offer.
                                </span>
                            </p>
                        <p class="cuisinetext mb-1 text-success" *ngIf="restaurantDetails?.offer_type==1 && restaurantDetails?.discount_type=='2'">
                            <span class="cuisinetext">
                                {{restaurantDetails?.offer_amount}}% offer upto {{currency}}{{restaurantDetails?.target_amount}}.
                                </span>
                            </p>
                        <p class="cuisinetext mb-1 text-success" *ngIf="restaurantDetails?.offer_type==2 && restaurantDetails?.discount_type=='1'">
                            <span class="cuisinetext">
                                {{restaurantDetails?.offer_amount}}% on order above {{currency}}{{restaurantDetails?.offer_value}}.
                                </span>
                            </p>
                        <p class="cuisinetext mb-1 text-success" *ngIf="restaurantDetails?.offer_type==2 && restaurantDetails?.discount_type=='2'">
                            <span class="cuisinetext">
                                {{restaurantDetails?.offer_amount}}% on order above {{restaurantDetails?.offer_value}}.
                                <span *ngIf="restaurantDetails?.target_amount">&nbsp;•&nbsp;</span>
                                Max Discount {{currency}}{{restaurantDetails?.target_amount}}
                            </span>
                        </p>
                        <!--cuisines-->
                        <p class="cuisinetext mb-1" *ngIf="restaurantDetails?.cuisines?.length > 1">
                            <span class="cuisinetext" *ngFor="let key of restaurantDetails?.cuisines; let i = index">
                                <span *ngIf="i != 0 && i!=restaurantDetails?.cuisines?.length">&nbsp;•&nbsp;</span>
                                {{key.name | titlecase}}
                            </span>
                        </p>
                        <p *ngIf="!mobilecheck" class="mb-1 bold cuisinetext">{{'Delivery Within' | translate}} {{restaurantDetails.travel_time}} {{'Mins' | translate}} &nbsp;&nbsp;&nbsp; 
                            <span *ngIf="restaurantDetails.min_order_value>0">{{'Min Order' | translate}}:
                                {{currency}} {{restaurantDetails.min_order_value?.toFixed(2)}}</span>
                        </p>
                        <div *ngIf="mobilecheck" class="mb-1 bold">
                            <p class="m-0 cuisinetext">{{'Delivery Within' | translate}} {{restaurantDetails.travel_time}} mins</p>
                            <p class="m-0 cuisinetext" *ngIf="restaurantDetails.min_order_value>0">{{'Min Order' | translate}}: {{currency}} {{restaurantDetails.min_order_value?.toFixed(2)}}</p>
                        </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-end gap-3">
                        <img src="assets/images/common/paymentoptions_colored.png" alt="Eatzilla - Payment Options" title="Eatzilla - Payment Options" width="150">
                        <div class="restaurants_rating restaurants_rating1">
                            <span class="rating_icon"><span class="fa fa-star"></span></span>
                            <span class="ratingtext">{{restaurantDetails.rating}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-md-12 col-lg-3 filters hidden-xs">
                <div class="cuisineslist">
                    <h5 class="text-left">{{'Categories' | translate}}</h5>
                    <ul class="mt-3 catogrylistfix">
                        <li [id]="variety.category_id" class="categoryitem text-left" (click)="scrollTo(variety.category_id)" *ngFor="let variety of food_variety | sortBy: 'asc':'category_sort'" [ngClass]="{'active':current_variety==variety.category}">
                            <a href="javascript:void(0)">{{variety.category}} ({{variety.count}})</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-7 col-lg-6 listingwrap pr-4">
               <div class="rest-search">
                    <input class="m-b-md full-width form-control" id="restSearchBox" type="text" placeholder="{{'Search Menu' | translate}}" 
                    [(ngModel)]="term" (keyup)="getItemCount(allCategories?.food_list)">
                    <span class="search-icon">
                        <span class="fa fa-search"></span>
                    </span>
                    <span class="delete-icon" style="display: none;">
                        <span class="fa fa-close"></span>
                    </span>
                </div>
                <ul class="menulist mt-3" *ngFor="let category of allCategories?.food_list | foodlistFilter:term;let i = index;" id="foodlistbox{{category.category_id}}">
                    <h5 *ngIf="category?.items?.length>0" class="mt-3 text-left">{{category.category_name}}
                    </h5>
                    <ng-container *ngFor="let food of category.items">
                        <li class="rounded border text-left" *ngIf="restaurantDetails && (!food.alreadyFound) && category.items.length>0" (click)="addItem(food)">
                            
                            <div class="add-items">
                                <div class="position-relative first">
                                    <div class="imagebig shadow border" id="zoomContainer{{food.food_id}}{{food.category_id}}" style="display: none;">
                                        <img *ngIf="food.image" (error)="loadImage($event,'zoomItemImage'+food.food_id+food.category_id)" 
                                        id="zoomItemImage{{food.food_id}}{{food.category_id}}" alt="{{alttitle}} {{food.name}}" class="large" [src]="allCategories.image_base_url+food.image" title="{{alttitle}} {{food.name}}">
                                    </div>
                                    <div class="rest-logo card-rest-logo shadow" (mouseover)="showlargeimage(food.food_id+''+food.category_id)" (mouseout)="hidelargeimage(food.food_id+''+food.category_id)">
                                        <span class="valign-helper"></span>
                                        <img *ngIf="food.image" (error)="loadImage($event,'itemImage'+food.food_id+food.category_id)" id="itemImage{{food.food_id}}{{food.category_id}}" [src]="allCategories.image_base_url+food.image" alt="{{alttitle}} {{food.name}}" title="{{alttitle}} {{food.name}}">
                                        <img *ngIf="!food.image" src="assets/images/common/nofood.jpg" alt="{{alttitle}} {{food.name}}" title="{{alttitle}} {{food.name}}" />
                                    </div>
                                </div>
                                <div class="second">
                                    <h6 class="text-dark mb-1  mobilepad0">{{food.name}}</h6>
                                    <p class="cuisinetext mb-1  mobilepad0">{{food.description}} </p>
                                    <p class="cuisinetext mb-1  mobilepad0">{{currency}} {{food.price}} </p>
                                    <p class=" mobilepad0">
                                        <span class="pull-left text-primary pricetext" *ngIf="food.price > 0 && food.food_percentage_offer == 0">{{currency}}
                                            {{food.price.toFixed(2)}}</span>
                                        <span class="pull-left pricetextdiscount" *ngIf="food.price > 0 && food.food_percentage_offer > 0">{{currency}}
                                                {{food.price.toFixed(2)}}</span>
                                        <span class="pull-left text-primary pricetext pl-2" *ngIf="food.price > 0 && food.food_percentage_offer > 0">{{currency}}
                                                    {{(food.price - (food.price * food.food_percentage_offer/100)).toFixed(2)}}</span>
                                        <span class="pull-left text-primary pricetext" *ngIf="food.price == 0">{{'Price on selection' | translate}}</span>
                                       
                                        <span class="clearfix"></span>
                                    </p>
                                    <button class="btn btn-primary btn-sm text-uppercase arfont-16 float-right">{{'Add Item' | translate}}</button>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                 </ul>
                 <ul class="no-data" *ngIf="(allCategories? itemCount===0:false) || (term?term.trim().length>=3&&itemCount===0:false)">
                        <div class="text-center">
                            <img src="assets/images/no-data/no-food-data.png" alt="coupon-list">
                           </div>
                           <div class="text-center mt-4">
                               <p>
                                   No Food found
                               </p>
                           </div>
                </ul>
            </div>
            <div class="col-md-5 col-lg-3 ">
                <div class="cartempty cartbox" *ngIf="emptyCart && restaurantDetails">
                    <h5 class="text-left">{{'Cart Empty' | translate}}</h5>
                    <img src="assets/images/common/cartempty.png" class="img-fluid" alt="Eatzilla - Empty Cart" title="Eatzilla - Empty Cart">
                    <p class="mt-3 text-left">{{'Good food is always cooking! Go ahead, order some yummy items from the menu.' | translate}}</p>
                </div>
                <div class="cartwrap cartbox shadow bg-white rounded" *ngIf="!emptyCart && restaurantDetails">
                    <div class="head bg-primary text-light">
                        <h5 class="py-1 px-2 text-left">{{'Your Cart' | translate}}</h5>
                    </div>
                    <div class="content">
                        <div class="items">
                            <div class="item py-1 px-2 text-left" *ngFor="let item of cartItem">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="d-flex justify-content-between">
                                            <div class="w-70">
                                                <span class="cus-fw-700 text-capitalize">{{item.item_name}}</span>
                                                <span *ngIf="item?.choosen.length>0">
                                                    <span  class="d-block">
                                                        <span>Choice:</span><span  *ngFor="let k of item?.choosen; let i=index">
                                                            {{k.name | titlecase }}<span *ngIf="i+1 < item?.choosen.length">,</span>
                                                         </span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class=" w-40">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="qty">
                                                        <span class="minus bg-secondary" (click)="reduceQuantity(item)">-</span>
                                                        <input type="text" readonly class="count text-dark" name="qty" value={{item.quantity}}>
                                                        <span class="plus bg-secondary" (click)="addQuantity(item, 1)">+</span>
                                                    </div>
                                                    <div >
                                                        <span class="text-dark"><strong>{{item.price.toFixed(2)}}</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="summary px-2">
                                <h6>
                                    <span class="pull-left">{{'Sub Total' | translate}}</span>
                                    <span class="pull-right text-dark text-right">{{currency}}
                                        {{totalAmount.toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <h6 *ngIf="deliverycharge>0">
                                    <span class="pull-left">{{'Delivery Fee' | translate}}</span>
                                    <span class="pull-right text-dark text-right">{{currency}} {{deliverycharge.toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <h6 class="text-success bold" *ngIf="discount > 0">
                                    <span class="pull-left">{{'Discount' | translate}}</span>
                                    <span class="pull-right text-success text-right">{{currency}}
                                        {{discount.toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <hr>
                                <h6>
                                    <span class="pull-left text-dark bold">{{'Total' | translate}}</span>
                                    <span class="pull-right text-primary text-right bold">{{currency}}
                                        {{((deliverycharge + totalAmount)  - discount).toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <hr>
                                <button *ngIf="((cartItemRestaurant['is_open'] == 1 && cartItemRestaurant['is_busy'] == 0 && commonHelper.dataChangeToNumber(totalAmount) - commonHelper.dataChangeToNumber(discount)) >= commonHelper.dataChangeToNumber(cartItemRestaurant['min_order_value'])?1:0)" class="btn btn-primary btn-block text-uppercase" (click)="proceedtoCheckout()">{{'Proceed To Checkout' | translate}}</button>
                                <button disabled *ngIf="(commonHelper.dataChangeToNumber(totalAmount) - commonHelper.dataChangeToNumber(discount)) < commonHelper.dataChangeToNumber(cartItemRestaurant['min_order_value'])?1:0 && cartItemRestaurant['is_open'] == 1 && cartItemRestaurant['is_busy'] == 0" class="btn btn-primary btn-block text-uppercase">{{'Add' | translate}} {{currency}}
                                    {{(commonHelper.dataChangeToNumber(cartItemRestaurant['min_order_value']) - (commonHelper.dataChangeToNumber(totalAmount) - commonHelper.dataChangeToNumber(discount))).toFixed(2)}} {{'To Checkout' | translate}} <br><span class="font-12">({{'Minimum Order:' | translate}} {{currency}} {{cartItemRestaurant['min_order_value']?.toFixed(2)}})</span></button>
                                <button disabled *ngIf="cartItemRestaurant['is_open'] == 0" class="btn btn-primary btn-block text-uppercase">{{'Restaurant Closed' | translate}}</button>
                                <button disabled *ngIf="cartItemRestaurant['is_busy'] == 1" class="btn btn-primary btn-block text-uppercase">{{'Restaurant Busy' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<div class="cartmobile" *ngIf="mobilecheck && !emptyCart && restaurantDetails">
    <div class="btn btn-block btn-primary py-3">
        <div class="row" style="direction: ltr;">
            <div class="col-5">{{currency}} {{(totalAmount - discount).toFixed(2)}}</div>
            <div class="col-7 text-uppercase" *ngIf="(totalAmount - discount) >= minordervalue && is_open == 1" data-toggle="modal" data-target="#viewcartmodal">{{'View Cart' | translate}}</div>
            <div class="col-7 " *ngIf="(totalAmount - discount) < minordervalue && is_open == 1">(Min: {{currency}} {{restaurantDetails.min_order_value?.toFixed(2)}})</div>
            <div class="col-7 text-uppercase" *ngIf="is_open == 0">{{'Restaurant Closed' | translate}}</div>

        </div>
    </div>
</div>
<!-- choices modal -->
<div class="modal fade" id="choicesbox" tabindex="-1" *ngIf="choicesFood && selectedFood" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-left" id="exampleModalLabel">{{'Add Item Choices' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div  class="row">
                    <div class="col-md-7">
                        <h6 class="text-left">{{choicesFood.name}}</h6>
                        <p class="font-12 text-left">{{choicesFood.description}}</p>
                    </div>
                    <div class="col-md-3 col-7">
                        <div class="qty">
                            <span>
                             <span *ngIf="selectedFood?.choice_category?.length===0" (click)="removeNewCart(selectedFood, 1)">
                                <a class="minus bg-secondary" (click)="removeNewCart(selectedFood, 1)">-</a></span>
                            </span>
                            <span *ngIf="selectedFood?.choice_category?.length>0">
                               <a *ngIf="!selectedFood?.choice_category[0].canProceed" class="minus bg-secondary tooltip-selectchoice">-<span class="tooltip-custom-minus">Select minimum Choice!</span></a>
                               <a *ngIf="selectedFood?.choice_category[0].canProceed" (click)="removeNewCart(selectedFood, 1)" class="minus bg-secondary">-</a>
                             </span>
                            <input type="text" readonly class="count" name="qty" value={{selectedFood?.quantity}}>
                            <span>
                                <span *ngIf="selectedFood?.choice_category?.length<=0" (click)="addNewCart(selectedFood, 1)">
                                    <a  class="plus bg-secondary">+</a></span>
                            </span>
                                <span *ngIf="selectedFood?.choice_category?.length>0">
                                   <a *ngIf="!selectedFood?.choice_category[0].canProceed" class="plus bg-secondary tooltip-selectchoice">+<span class="tooltip-custom">Select minimum Choice!</span></a>
                                   <a *ngIf="selectedFood?.choice_category[0].canProceed" (click)="addNewCart(selectedFood, 1)" class="plus bg-secondary">+</a>
                                 </span>
                        </div>
                    </div>
                    <div class="col-md-2 col-5" *ngIf="selectedFood?.price && selectedFood.food_percentage_offer == 0">
                        <span class="text-primary pricetext">{{currency}}
                            {{selectedFood?.price.toFixed(2)}}</span>
                    </div>
                    <div class="col-md-2 col-5" *ngIf="selectedFood?.price && selectedFood.food_percentage_offer > 0">
                        <span class="text-primary pricetext" *ngIf="selectedFood?.price">{{currency}}addNewCart
                            {{(selectedFood?.price - (selectedFood?.price * selectedFood.food_percentage_offer/100)).toFixed(2)}}</span>

                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div id="accordion">
                            <div class="card" *ngFor="let choices of selectedFood.choice_category">
                                <div class="card-header p-0" id="{{choices.id}}">
                                    <h5 class="mb-0">
                                        <button class="btn btn-light btn-block font-14 text-left" data-toggle="collapse" [attr.data-target]="'#'+choices.id" aria-expanded="true" [attr.aria-controls]="choices.id">
                                            <span *ngIf="choices.canProceed === false" class="pos-icons"><img
                                                    src="assets/images/icons/alert.png" width="15"
                                                    class="mr-2"  alt="img"/></span>
                                            <span *ngIf="choices.canProceed === true" class="pos-icons"><img
                                                    src="assets/images/icons/success.png" width="15"
                                                    class="mr-2" alt="img"/></span>{{choices.name}}
                                            <div *ngIf="choices" class="description-small">
                                               <span class="my-1"><span class="fa fa-exclamation-circle" aria-hidden="true"></span>
                                                    Minimum of {{choices.min}} choices and Maximum of {{choices.max}} choices can be done.</span>
                                    
                                            </div>
                                        </button>
                                    </h5>
                                </div>
                                <div [id]="choices.id" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-12 p-b p-r-0" *ngFor="let choice of choices.choice">
                                                <ul>
                                                    <li>
                                                        <div>
                                                            <div class="row">
                                                                <div class="col-sm-6 col-4 my-1">
                                                                    <label class="m-0 radiobutton">
                                                                        <input name="same{{choices.id}}" type="radio"
                                                                            id="{{choice.name}}"
                                                                            *ngIf="choices.min <= 1 && choices.max == 1"
                                                                            value="{{choice.price}}"
                                                                            (click)="choiceRadioValue($event, choice)"
                                                                            class="check">
                                                                        <input type="checkbox" id="{{choice.name}}"
                                                                            *ngIf="choices.max > 1" value="{{choice.price}}"
                                                                            (click)="choiceCheckboxValue($event, choice)"
                                                                            class="check">
                                                                        <span class="checkbox"></span>
                                                                        <span class="pl-2">{{choice?.name}}</span>
                                                                    </label>
                                                                    <span class="pull-right text-primary" *ngIf="choice?.price > 0">{{choice?.price.toFixed(2)}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button class="btn btn-primary text-uppercase" style="margin: 0 auto;" (click)="addToCart()"><span
                        class="fa fa-shopping-cart"></span> &nbsp;&nbsp;&nbsp;{{'Add to Cart' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- Item exist modal -->
<div class="modal fade" id="itemExistModal" role="dialog">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{'Items already in cart' | translate}}</h4>
                <button type="button" class="close" data-dismiss="modal">
                    &times;
                </button>
            </div>
            <div class="modal-body">
                <p style="padding: 1em;">
                    {{'You already have items in your cart. Do you want to clear your cart to add new items?' | translate}}
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="forceInsertFood()">
                    {{'Add New Items' | translate}}
                </button>
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                    {{'Cancel' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="viewcartmodal" *ngIf="mobilecheck" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel">{{'View Cart' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="cartwrap cartbox shadow bg-white rounded" *ngIf="!emptyCart && restaurantDetails">
                    <div class="head bg-primary text-light">
                        <h5 class="py-1 px-2 text-left">{{'Your Cart' | translate}}</h5>
                    </div>
                    <div class="content">
                        <div class="items">
                            <div class="item py-1 px-2" *ngFor="let item of cartItem">
                                <div class="row p-0">
                                    <div class="col-md-7 col-6 text-left">{{item.item_name}}</div>
                                    <div class="col-md-3 col-3 p-0 text-left">
                                        <div class="qty">
                                            <span class="minus bg-secondary" (click)="reduceQuantity(item)">-</span>
                                            <input type="number" class="count text-dark" name="qty" value={{item.quantity}}>
                                            <span class="plus bg-secondary" (click)="addQuantity(item, 1)">+</span>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-3 p-0 text-left">
                                        <span class="text-dark"><strong>{{item.price.toFixed(2)}}</strong></span>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <hr>
                            <div class="summary px-2">
                                <h6>
                                    <span class="pull-left">{{'Sub Total' | translate}}</span>
                                    <span class="pull-right text-dark text-right">{{currency}}
                                        {{totalAmount.toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <h6>
                                    <span class="pull-left">{{'Delivery Fee' | translate}}</span>
                                    <span class="pull-right text-dark text-right">{{currency}} {{deliverycharge.toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <h6 class="text-success bold" *ngIf="discount > 0">
                                    <span class="pull-left">{{'Discount' | translate}}</span>
                                    <span class="pull-right text-success text-right">{{currency}}
                                        {{discount.toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <hr>
                                <h6>
                                    <span class="pull-left text-dark bold">{{'Total' | translate}}</span>
                                    <span class="pull-right text-primary text-right bold">{{currency}}
                                        {{((deliverycharge + totalAmount)  - discount).toFixed(2)}}</span>
                                    <div class="clearfix"></div>
                                </h6>
                                <hr>
                                <button *ngIf="cartItemRestaurant['is_open'] == 1 && commonHelper.dataChangeToNumber(totalAmount) - commonHelper.dataChangeToNumber(discount) >= commonHelper.dataChangeToNumber(cartItemRestaurant['min_order_value'])?1:0" class="btn btn-primary btn-block text-uppercase" (click)="proceedtoCheckout()">{{'Proceed To Checkout' | translate}}</button>
                                <button disabled *ngIf="(commonHelper.dataChangeToNumber(totalAmount) - commonHelper.dataChangeToNumber(discount)) >= commonHelper.dataChangeToNumber(cartItemRestaurant['min_order_value'])?1:0 && cartItemRestaurant['is_open'] == 1" class="btn btn-primary btn-block">{{'Add' | translate}} {{currency}}
                                    {{(commonHelper.dataChangeToNumber(cartItemRestaurant['min_order_value']) - (commonHelper.dataChangeToNumber(totalAmount) - commonHelper.dataChangeToNumber(discount))).toFixed(2)}} {{'To Checkout' | translate}}<br><span class="font-12">(Minimum Order: {{currency}} {{cartItemRestaurant['min_order_value']?.toFixed(2)}})</span></button>
                                <button disabled *ngIf="cartItemRestaurant['is_open'] == 0" class="btn btn-primary btn-block">{{'Restaurant Closed' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Map-->
<div class="modal fade" id="addressMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel">{{'Add new Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-googlemap [id]="'menu1'" [componenttype]="component" [setaddressinput]="setaddressinput" [latitudeinput]="latitudeinput" [longtitudeinput]="longtitudeinput"
                [title]="'Confirm'" (addressconfirmation)="addressConfirmation($event)"></app-googlemap>
            </div>
        </div>
    </div>
</div>
<!--Edit Address-->
<div class="modal fade" id="editaddressmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Add Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeuserAddressModal(addaddressform)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-left">
                <form name="form" (ngSubmit)="addaddressform.form.valid && addaddresssubmit(addaddressform)" #addaddressform="ngForm" novalidate>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Area' | translate}} <small class="text-danger">*</small></label>
                            <input type="text" class="form-control" placeholder="{{'Search for your location' | translate}}" autocomplete="off" name="location" [(ngModel)]="addaddress.location" #location="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && location.invalid }"
                            (focusin)="onChangeArea($event)" required>
                            <div *ngIf="addaddressform.submitted && location.invalid" class="invalid-feedback">
                                <div *ngIf="location.errors.required">{{'Location is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="adress">{{'Address Type' | translate}} <small class="text-danger">*</small></label>
                            <div class="flex justify-around item-center">
                                <ng-container *ngFor="let type of addressTypes; let i = index;">
                                    <label class="m-0 radiobutton">
                                    <input name="address" type="radio" id="address{{i}}" value="{{type}}" (click)="chooseAddressType($event, i)"
                                    class="check" autocomplete="off" [checked]="selectedAddressType==type">
                                    <span class="checkmark"></span>
                                    <span class="pl-2">{{type}}</span>
                                   </label>
                                    </ng-container>
                            </div>
                            <div *ngIf="addaddressform.submitted" class="addresstype-invalid">
                                <div *ngIf="selectedAddressType==''">{{'Address Type is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Block Number' | translate}} </label>
                            <input type="text" class="form-control" placeholder="{{'Enter your Block Number' | translate}}" autocomplete="off" name="blocknumber" [(ngModel)]="addaddress.blocknumber" #blocknumber="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && blocknumber.invalid }"
                               >
                            <div *ngIf="addaddressform.submitted && blocknumber.invalid" class="invalid-feedback">
                                <div *ngIf="blocknumber.errors.required">{{'Block Number is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="staticEmail">{{'Road Number' | translate}} </label>
                            <input type="text" class="form-control" placeholder="{{'Enter the Road Number' | translate}}" autocomplete="off" name="road_number" [(ngModel)]="addaddress.road_number" #road_number="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && road_number.invalid }"
                               >
                            <div *ngIf="addaddressform.submitted && road_number.invalid" class="invalid-feedback">
                                <div *ngIf="road_number.errors.required">{{'Road Number is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Building Number' | translate}}</label>
                            <input type="text" class="form-control" placeholder="{{'Enter the Building Number' | translate}}" autocomplete="off" name="building" [(ngModel)]="addaddress.building" #building="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && building.invalid }"
                               >
                            <div *ngIf="addaddressform.submitted && building.invalid" class="invalid-feedback">
                                <div *ngIf="building.errors.required">{{'Building Number is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="staticEmail">{{'Flat Number/Office Number' | translate}}</label>
                            <input type="text" class="form-control" placeholder="{{'Enter the Flat Number/Office Number' | translate}}" autocomplete="off" name="flat_no" [(ngModel)]="addaddress.flat_no" #flat_no="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && flat_no.invalid }"
                                >
                            <div *ngIf="addaddressform.submitted && flat_no.invalid" class="invalid-feedback">
                                <div *ngIf="flat_no.errors.required">{{'Flat or Office Number is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Address Directions' | translate}} <small
                                class="text-danger">*</small></label>
                            <input type="text" class="form-control" placeholder="{{'Directions to help the rider' | translate}}" autocomplete="off" name="landmark" [(ngModel)]="addaddress.landmark" #landmark="ngModel"
                             [ngClass]="{ 'is-invalid': addaddressform.submitted && landmark.invalid }" required minlength="5">
                            <div *ngIf="addaddressform.submitted && landmark.invalid" class="invalid-feedback">
                                <div *ngIf="landmark.errors.required">{{'Directions is required' | translate}}</div>
                                <div *ngIf="landmark.errors.minlength">{{'Direction must be at least 5 characters' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block text-uppercase" type="submit">{{'Save Address' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>