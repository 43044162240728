import { Component, OnInit, ViewChild, ElementRef, NgZone, Injector} from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title, Meta } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Globals } from "../../../globals";
import { CommonHelper } from 'src/app/common/helper';
import { ADDRESS_KEYS, USER_TYPE } from 'src/app/constants/common';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { DataEmitterService } from 'src/app/services/data-emit.service';
import { NUMBER_ONLY } from 'src/app/constants/pattern.constant';
declare var $: any;
declare var window: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

 /**
   * component
   */
export class HomeComponent implements OnInit {
  componenttype = 'home';
  popularBrands: any;
  latitude: number;
  longitude: number;
  latitudeinput: number=null;
  longtitudeinput: number=null;
  setaddressinput: string;
  zoom: number;
  private geoCoder;
  @ViewChild('searchlocation')
  public searchElementRef: ElementRef;
  loggedIn: boolean = false;
  profileResponse: any;
  userProfileDetails: any = {};
  userName: string;
  loginResponse: any = {}
  googleplaceresponse: any = {}
  allAvailableDeliveryAddress: any;
  setaddress: any;
  alttitle: string;
  searchlocationinput: any;
  devicetype: any;
  browser: any;
  slideConfig2: {};
  tranlsatedstr: string;
  tranlsatedtitle: any;
  tranlsateddescription: any;
  urilang: string;
  public offersAndPromotionsResponse:any;
  public slideOffersPromotionsConfig = {};
  private apiconnectService:ApiconnectService;
  private webStorageService:WebStorageService;
  private dataEmitter:DataEmitterService
  private titleService:Title;
  public router: Router;
  private commonHelper:CommonHelper;
  private deviceService: DeviceDetectorService;
  private meta: Meta;
  public getCurrentLocationTimer:any;
  public getCurLatLng: any;
  public addresskey = ADDRESS_KEYS;
  public userIs:any;
  public loaderLoading = true;

  /**
   * constructor
   * @param globals 
   * @param mapsAPILoader 
   * @param ngZone 
   */
  constructor(
    private globals: Globals,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private injector: Injector,
  ) {
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService); //SonarQube issue -> constructor params max. allowed 7.
    this.webStorageService = this.injector.get<WebStorageService>(WebStorageService);
    this.dataEmitter = this.injector.get<DataEmitterService>(DataEmitterService);
    this.titleService = this.injector.get<Title>(Title);
    this.router = this.injector.get<Router>(Router);
    this.commonHelper = this.injector.get<CommonHelper>(CommonHelper);
    this.deviceService = this.injector.get<DeviceDetectorService>(DeviceDetectorService);
    this.meta = this.injector.get<Meta>(Meta);
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.slideConfig2 = {
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        rtl: true,
        variableWidth: true,
        nextArrow: "<div class='popularbrands nav-btn next-slide'></div>",
        prevArrow: "<div class='popularbrands nav-btn prev-slide'></div>",
      }
    }
    else {
      this.urilang = '';
      this.slideConfig2 = {
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        rtl: false,
        variableWidth: true,
        nextArrow: "<div class='popularbrands nav-btn next-slide'></div>",
        prevArrow: "<div class='popularbrands nav-btn prev-slide'></div>",
      }
    }
    this.checkDevice();
    this.alttitle = this.globals.imagealt;
    this.slideOffersPromotionsConfig ={
      autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: false,
        // variableWidth: true,s
        dots:true,
    }
  }
  mobilecheck: boolean;
  deviceInfo = null;
  translatedstr: any;
  
  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.devicetype = this.deviceInfo.os;
    this.browser = this.deviceInfo.browser;
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice) {
      this.mobilecheck = true;
      window.scroll(0, 0);
    }
  }
  async submitSearch() {
    var bannerinput = $('.bannerinput').val();
    var lat = $('#lat').val();
    var lng = $('#lng').val();
    if (!bannerinput || !lat || !lng) {
      Swal.fire({
        text: await this.commonHelper.translateText('Please enter or choose your delivery address'),
        icon: 'error'
      });
    }
    else {
      //old code for ref.
      // localStorage.setItem('lat', JSON.stringify(lat));
      // localStorage.setItem('lng', JSON.stringify(lng));
      localStorage.setItem('lat', lat);
      localStorage.setItem('lng', lng);
      localStorage.setItem('address', JSON.stringify(bannerinput));
      this.router.navigate([this.urilang + 'search-results'], {
        queryParams: {
          lat: lat,
          lng: lng,
          address: bannerinput
        }
      })
    }
  }
  selectAddress(address) {
    $('.loading').show();
    $('.bannerinput').val(address.address);
    $('#lat').val(address.lat);
    $('#lng').val(address.lng);
    this.setdeliverableaddress(address);
  }
  set_delivery_address: any;
  setdeliverableaddress(data) {
    let datapost = {
      current_address: data.address,
      lat: data.lat,
      lng: data.lng,
      address_id: data.id,
      device_type: 'web'
    }
    this.apiconnectService.postRequest(datapost, '/set_delivery_address').subscribe(
      (dataResponse) => {
        $('.loading').hide();
        this.set_delivery_address = dataResponse;
        if (this.set_delivery_address.status) {
          localStorage.setItem('defaultaddress', JSON.stringify(dataResponse));
          this.webStorageService.storeLocalData({nodefualtlogginaddress:false, searchedaddress:false});
          this.hideSavedAddressOnBlur();
          this.submitSearch();
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  async ngOnInit(): Promise<void> {
    $('.modal-backdrop').remove();
    this.tranlsatedtitle = await this.commonHelper.translateText('Order Food Anywhere in Middle East - EATZILLA Free Food Delivery');
    this.tranlsateddescription = await this.commonHelper.translateText('Download EATZILLA free food delivery app on your iOS or Android device and order from your favorite restaurants.');
    this.titleService.setTitle(this.tranlsatedtitle);
    const address = localStorage.getItem('address');
    this.latitudeinput= JSON.parse(localStorage.getItem('lat'));
    this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem('lng')));
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription });
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
    window.scroll(0, 0);
    this.loadGooglePlaces();//load Places Autocomplete
     if (
      this.webStorageService.getAuthId() &&
      this.webStorageService.getAuthToken() && this.webStorageService.getUserType()== USER_TYPE.LOGGED_USER // check user type with auth token and id
    ) {
      this.getAllDeliveryAddress();
      this.loggedIn = true
      this.getProfileDetails();
      this.userName = this.webStorageService.getUserName()
      this.loginResponse.user_name = this.webStorageService.getUserName();
    } else if(this.webStorageService.getAuthId() &&
    this.webStorageService.getAuthToken() && this.webStorageService.getUserType()== USER_TYPE.GUEST_USER) { // check user type with auth token and id
     this.loggedIn = false
      this.webStorageService.removeData(['userPic']);
       if(address){
        this.webStorageService.storeLocalData({defaultaddress:1});
       }
     }
    if(this.latitudeinput && this.longtitudeinput){ //api call only if having lat and lng details
      this.getOffersPromotions();
      this.getPopularBrands();
    }

    //after login
    this.dataEmitter.getLogin().subscribe(res=>{
      this.gotLogin(res);
    }) 

      //after get lat & lng
      this.dataEmitter.getlatlng().subscribe(res=>{
        this.getLatLng(res);
      }) 

  
  this.getCurLatLng = setInterval(() => {
    const storageAddress= localStorage.getItem('address');
  if(!storageAddress){
      this.getCurrentLatLng();
      clearInterval(this.getCurLatLng);
    }else{
      clearInterval(this.getCurLatLng);
     }
    }, 1000);
   
   this.geoCoder = new google.maps.Geocoder();
   this.userIs = this.webStorageService.getUserType();
   localStorage.removeItem('isproceedtocheckout');
  }
  resetMap() {
    this.latitudeinput = 0;
    this.longtitudeinput = 0;
  }
 
  /**
   * get all deliverable address
   */
 public getAllDeliveryAddress() {
    const isTrue = !localStorage.getItem('authId') || !localStorage.getItem('authToken')
    if(isTrue){
      return
    }else{
      this.apiconnectService.getRequest("/get_delivery_address?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
        data => {
          if(data['status']){
            const tempData = data['data'].filter((x)=>x.is_default==1);
            localStorage.setItem('nodefualtlogginaddress','false');
            this.locationDetailsMap(tempData, data)
          }
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  /**
   * load google places
   */
  loadGooglePlaces() {
    $('.loading').show();
    this.mapsAPILoader.load().then(() => {
      $('.dropdownsavedlocations').hide();
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement,
      {
        componentRestrictions: { country: 'IN' }
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setaddress = place.formatted_address;
          this.latitudeinput = this.latitude;
          this.longtitudeinput = this.longitude;
          this.setaddressinput = this.setaddress;
          localStorage.setItem('lat', JSON.stringify(this.latitude))
          localStorage.setItem('lng', JSON.stringify(this.longitude))
          localStorage.setItem('address', JSON.stringify(this.setaddress))
          this.showMap(this.latitude, this.longitude, this.setaddress);
          this.zoom = 15;
        });
      });
      $('.loading').hide();
    });
  }
  showMap(latitude, longitude, address) {
    this.latitudeinput = latitude;
    this.longtitudeinput = longitude;
    this.setaddressinput = address;
    if (this.devicetype == 'iOS' || this.browser == 'Safari') {
      this.router.navigate([this.urilang +'search-results'], {
        queryParams: {
          lat: this.latitudeinput,
          lng: this.longtitudeinput,
          address: this.setaddressinput
        }
      });
    }
    $('.bannerinput').val('');
    $('#addressMap').modal('show');
  }

  /**
   * get popular brands
   */
 private getPopularBrands() {
  $('.loading').show();
    this.latitudeinput = JSON.parse(localStorage.getItem("lat"))?JSON.parse(localStorage.getItem("lat")):0;
    this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem("lng")))?parseFloat(JSON.parse(localStorage.getItem("lng"))):0;
   if(!this.latitudeinput ||!this.longtitudeinput){
    return;
   } else{
    this.apiconnectService.getRequest("/get_popular_brands?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken') + "&lat=" + this.latitudeinput + "&lng=" + this.longtitudeinput).subscribe(
      data => {
        if(data['status']){
          this.popularBrands = data;
        $.map(this.popularBrands.data, (brands, index) => {
          brands.image = `${this.popularBrands.base_url}${brands.image}`
        })
        $('.loading').hide();
        this.loaderLoading = false;
        }else{
          this.popularBrands = false;
          $('.loading').hide();
          this.loaderLoading = false;
        }
      },
      err => {
        console.log(err)
        $('.loading').hide();
        this.popularBrands = false;
        this.loaderLoading = false;
      }
    )
   }
  }

  /**
   * get current lat and lng
   */
 public getCurrentLatLng(){
   $('.loading').show();
    let curaddress =localStorage.getItem('address');
    if(curaddress){
       $('.loading').hide();
      }else{
       navigator.geolocation.getCurrentPosition(async(position) => {
        localStorage.setItem('lat', JSON.stringify(position.coords.latitude))
         localStorage.setItem('lng', JSON.stringify(position.coords.longitude))
         this.getAddressName(position.coords.latitude,position.coords.longitude)
         this.getOffersPromotions();
         this.getPopularBrands();
       })
       $('.loading').hide();
    }
  }

  /**
   * get address
   * @param latitude 
   * @param longitude 
   * @param address 
   * @returns 
   */
 public getAddressName(latitude, longitude, address?) {
  if(!address){
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
         if (results[0]) {
           this.zoom = 12
           localStorage.setItem('address', JSON.stringify(results[0].formatted_address));
           this.storeGuestArea(results[0])
         } else {
           console.log('No results found')
         }
       } else {
         console.log('Geocoder failed due to: ' + status)
       }
     })
  }
  }

 /**
  * get profile details
  */
  public getProfileDetails() {
    this.apiconnectService.getRequest("/get_profile?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.profileResponse = data;
        if (this.profileResponse.status) {
          this.userProfileDetails = this.profileResponse.data[0];
          this.webStorageService.storeUserName(this.profileResponse.data[0].name);
          this.userName = this.webStorageService.getUserName();
        }
      },
      err => {
        console.log(err)
      }
    )
  }
  showSavedAddressOnFocus() {
    $('.dropdownsavedlocations').show();
  }
  hideSavedAddressOnBlur() {
    $('.dropdownsavedlocations').hide();
  }
  onAddressChange(details) {
    console.log(details);
  }

  /**
   * redirect to near by restaurant
  */
 public redirectNearbyRestaurant(){
     const lat = JSON.parse(localStorage.getItem('lat'));
     const lng = parseFloat(JSON.parse(localStorage.getItem('lng')));
     const address = JSON.parse(localStorage.getItem('address'));
    this.router.navigate([this.urilang +'search-results'], {
      queryParams: {
        lat: lat,
        lng: lng,
        address: address
      }
    });
  }


   /**
   * get offers and promotions details
   */
   private getOffersPromotions(){
    $('.loading').show();
    this.latitudeinput = JSON.parse(localStorage.getItem("lat"))?JSON.parse(localStorage.getItem("lat")):0;
    this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem("lng")))?parseFloat(JSON.parse(localStorage.getItem("lng"))):0;
    const isTrue = (!this.latitudeinput || !this.longtitudeinput);
    if(isTrue)return
    this.apiconnectService.getRequest("/get_banners?device_type=web&lat=" + this.latitudeinput + "&lng=" + this.longtitudeinput).subscribe(
      (data) => {
        if (data['status']) {
          this.offersAndPromotionsResponse = data;
          $('.loading').hide();
          this.loaderLoading = false;
        }
        else {
          this.offersAndPromotionsResponse = false;
          $('.loading').hide();
          this.loaderLoading = false;
        }
      },
      err => {
        this.offersAndPromotionsResponse = false;
        $('.loading').hide();
        this.loaderLoading = false;
      }
    );
  }

  /**
   * login
   */
  public loginWithApp(){
    this.phonenumberdropdown('phoneusernamelogin', 'countrycode_login', 'error-msg', 'phonecode_login');
    $('#loginbox').modal('show');
  }

  /**
  * Phonenumberdropdowns // phone number element initialized
  */
 private async phonenumberdropdown(querySelector,countrycode,error, phonecode) {
  var phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
  var phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
  var input = document.querySelector("."+querySelector),
    hiddenintputfield = document.querySelector("#"+countrycode),
    errorMsg = document.querySelector("#"+error);
    $(".iti__flag-container").remove();
  var iti = window.intlTelInput(input, {
    excludeCountries: ["il"],
    hiddenInput: "full",
    preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
    separateDialCode: true,
    initialCountry: 'bh',
    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
    customPlaceholder: function (selectedCountryPlaceholder: any) {
      return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
    },
  });
  var reset = function () {
    input.classList.remove("error");
    errorMsg.innerHTML = "";
    errorMsg.classList.add("hide");
  };

  // on blur: validate
  input.addEventListener('blur', async function () {
    reset();
    this.values = input;
    const numberOnly = NUMBER_ONLY;
    if (this.values.value.trim()) {
      if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
        var countryData = iti.getSelectedCountryData();
        this.values = hiddenintputfield;
        this.values.value = countryData.iso2;
        this.selectedcountrycode = countryData.iso2;
        $('#'+countrycode).val(countryData.iso2);
        $('#'+phonecode).val(countryData.dialCode);
      } else {
        input.classList.add("error");
        errorMsg.innerHTML = phoneplaceholdererror;
        errorMsg.classList.remove("hide");
      }
    }
  });

  // on keyup / change flag: reset
  input.addEventListener('change', reset);
  input.addEventListener('keyup', reset);
}

/**
 * got login // functions further do
 * @param {any} res 
 */
public gotLogin(res){
  if(res != null && res){
    this.getPopularBrands();
    this.getOffersPromotions();
  }else if(res != null && !res){
    this.latitudeinput = null;
    this.longtitudeinput = null;
    this.popularBrands = false;
    this.offersAndPromotionsResponse = false;
  }
}

/**
 * get lat ad lng // functions further do
 * @param {any} res 
 */
public getLatLng(res){
  if(res != null && res){
    this.latitudeinput = res?.lat;
  this.longtitudeinput = res?.lng;
  }
}

/**
 * go to partner page
 */
public ongotoPartnerPage(){
 this.phonenumberdropdown('partnerphone', 'countrycode_partner', 'error-msg-phonepartner', 'phonecode_partner');
}

 /**
   * store guest area
   * @param name 
   */
 private storeGuestArea(address){
  const location = {};
  location['address'] = address.formatted_address
  if(address.address_components.length>0){
    address.address_components.forEach((area)=> { // get area
      this.addresskey.forEach(keys=>{
        if(area.types.includes(keys.TYPES_ARRAY)){
              this.storeLocation(area, keys.KEY_TO_SAVE, location);
            }
           })
       });
   }
}

/**
 * store local data
 * @param {any} data 
 * @param {string} key 
 */
private storeLocation(data, key, place){
  const location = place;
  location[key] = data.long_name;
  this.webStorageService.storeLocalData({'location':location});
}

/**
 * location details map
 * @param tempData 
 */
private locationDetailsMap(tempData, data){
  if(tempData.length>0){
    this.latitudeinput = tempData[0].lat;
    this.longtitudeinput = tempData[0].lng;
    this.allAvailableDeliveryAddress = data['status'] ? data : null;
    }else{
      if(this.userIs == USER_TYPE.LOGGED_USER){
        localStorage.setItem('nodefualtlogginaddress',JSON.stringify(true));
      }
    }
}
}