<div class="modal-dialog" role="document">
    <div class="modal-content p-3">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{'Create your Account' | translate}}</h5>
            <button type="button" class="close" (click)="onCloseRegisterbox(register, 'error-msg-register')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body text-left">
            <form name="form" (ngSubmit)="register.form.valid && otpverfication(register)" #register="ngForm" novalidate>
                <div class="form-group">
                    <label for="fullname">{{'Full Name' | translate}} <small class="text-danger">*</small></label>
                    <input id="fullname" type="text" name="fullname" class="form-control" [(ngModel)]="model.fullname" #fullname="ngModel" [ngClass]="{ 'is-invalid': register.submitted && fullname.invalid }" 
                    required placeholder="{{'Enter your Full Name' | translate}}" minlength="3" pattern="(?! )[A-Za-z\s]{1,}" maxlength="25" autocomplete="off">
                    <div *ngIf="register.submitted && fullname.invalid" class="invalid-feedback">
                        <div *ngIf="fullname.errors.required">{{'First Name is required' | translate}}</div>
                        <div *ngIf="fullname.errors.minlength && !fullname.errors.pattern">{{'Name must be at least 3 characters' | translate}}</div>
                        <div *ngIf="fullname.errors.pattern">{{'Enter valid name. Letters and space only allowed.' | translate}}</div>
                        <div *ngIf="fullname.errors.maxlength && !fullname.errors.pattern">{{'Maximum 25 letters allowed' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">{{'Email Address' | translate}} <small class="text-danger">*</small></label>
                    <input id="email" type="email" name="email" class="form-control" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': register.submitted && email.invalid }" 
                    required placeholder="{{'Enter your Email Address' | translate}}" pattern="{{emailPattern}}" autocomplete="off">
                    <div *ngIf="register.submitted && email.invalid" class="invalid-feedback">
                        <div *ngIf="email.errors.required">{{'Email Address is required' | translate}}</div>
                        <div *ngIf="email.errors.pattern">{{'Enter valid Email Id' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">{{'Password' | translate}} <small class="text-danger">*</small></label>
                    <div class="position-relative">
                        <input autocomplete="off" [type]="eyeView?'text':'password'" name="password" class="form-control" [(ngModel)]="model.password" #password="ngModel" [ngClass]="{ 'is-invalid': register.submitted && password.invalid }" required placeholder="{{'New Password' | translate}}"
                        minlength="8">
                        <div class="eye-view"  (click)="eyeView = !eyeView">
                            <i class="fa fa-eye" aria-hidden="true" *ngIf="!eyeView && !(register.submitted && password.invalid)"></i>
                            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="eyeView  && !(register.submitted && password.invalid)"></i>
                        </div>
                    </div>
                    <div *ngIf="register.submitted && password.invalid" class="phone-invalid">
                        <div *ngIf="password.errors.required">{{'Password is required' | translate}}</div>
                        <div *ngIf="password.errors.minlength">{{'Password must be at least 8 characters' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="confirmPassword">{{'Confirm Password' | translate}} <small class="text-danger">*</small></label>
                    <div class="position-relative">
                        <input autocomplete="off" [type]="eyeViewConfirm?'text':'password'" name="confirmPassword" class="form-control" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel" [ngClass]="{ 'is-invalid': register.submitted && (confirmPassword.invalid || isPasswordMatched==false) }" placeholder="{{'Confirm Password' | translate}}"
                        required>
                        <div class="eye-view"  (click)="eyeViewConfirm = !eyeViewConfirm">
                            <i class="fa fa-eye" aria-hidden="true" *ngIf="!eyeViewConfirm && !(register.submitted && (confirmPassword.invalid || isPasswordMatched==false))"></i>
                            <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="eyeViewConfirm && !(register.submitted && (confirmPassword.invalid || isPasswordMatched==false))"></i>
                        </div>
                    </div>
                    <div *ngIf="register.submitted && confirmPassword.invalid" class="phone-invalid">
                        <div *ngIf="confirmPassword.errors.required">{{'Confirm Password is required' | translate}}</div>
                     </div>
                    <div *ngIf="register.submitted && register.value.password != register.value.confirmPassword && register.value.confirmPassword" class="phone-invalid">
                        <div>{{'Password not matched' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="phonenumber">{{'Phone Number' | translate}} <small class="text-danger">*</small></label>
                    <input type="text" name="registernumber" class="form-control phoneusernameregister" [(ngModel)]="model.registernumber" #registernumber="ngModel" [ngClass]="{ 'is-invalid': register.submitted && registernumber.invalid }" required>
                    <input name="countrycode_register" id="countrycode_register" [(ngModel)]="selectedcountrycode" #countrycode_register="ngModel" type="hidden">
                    <input name="phonecode_register" id="phonecode_register" [(ngModel)]="selectedphonecode" #phonecode_register="ngModel" type="hidden">
                    <div id="error-msg-register" class="phone-invalid"></div>
                    <div *ngIf="register.submitted && registernumber.invalid" class="phone-invalid">
                        <div *ngIf="registernumber.errors.required">{{'Kindly write your phone number to continue' | translate}}</div>
                    </div>
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" name="accept" [(ngModel)]="model.accept" #accept="ngModel" [ngClass]="{ 'is-invalid': register.submitted && accept.invalid }" required>
                    <label class="form-text text-muted m-0" for="accept">{{'By creating your account, you agree to our' | translate}} <a routerLink="/{{urilang}}privacy" (click)="hideModalBackDrop()">{{'Privacy Policy' | translate}}</a> {{'&' | translate}} <a routerLink="/terms" (click)="hideModalBackDrop()">{{'Terms and Conditions' | translate}}</a></label>
                    <div *ngIf="register.submitted && accept.invalid" class="invalid-feedback">
                        <div *ngIf="accept.errors.required">{{'Please check the above' | translate}}</div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <br>
                <button type="submit" class="btn btn-primary btn-block">{{'Create my Account' | translate}}</button>
            </form>
        </div>
        <div class="modal-footer" style="display: block;">
            <h3 class="text-center">{{'Already have an account?' | translate}} <a (click)="showLoginbox(register)">{{'Login Here' | translate}}</a></h3>
        </div>
    </div>
</div>
