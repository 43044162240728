import { Component, OnInit,AfterContentInit, Injector } from '@angular/core';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { WebStorageService } from 'src/app/services/web-storage.service';
import _ from 'lodash';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from "../../../globals";
import { Meta, Title} from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
declare var $: any;

declare var window: any;
import { environment } from 'src/environments/environment.prod';
import { CommonHelper } from 'src/app/common/helper';
import { ADDRESS_KEYS, USER_TYPE } from 'src/app/constants/common';
import { FooditemFilter } from 'src/app/pipes/fooditem-filter.pipe';
import { NgForm } from '@angular/forms';
import { ADDRESS_TYPES } from 'src/app/constants/guest-user-login-page.constant';
import { NUMBER_ONLY } from 'src/app/constants/pattern.constant';


@Component({
  selector: 'app-menudetails',
  templateUrl: './menudetails.component.html',
  styleUrls: ['./menudetails.component.scss'],
})

/**
 * Component
*/
export class MenudetailsComponent implements OnInit, AfterContentInit {
  private userProfileDetails: any = {};
  private loggedIn: boolean = false;
  private userName: string;
  public title: any;
  public is_open = 1;
  private profileResponse: any;
  public mobilecheck:boolean;
  private deviceInfo = null;
  public term: string;
  public id: number;
  public minordervalue: any;
  public emptyCart = true;
  public restaurantname: string;
  public currency: string;
  public restaurantDetails: any = {};
  public foodList: any = {};
  public min_order: any;
  public alttitle: string;
  public allCategories: any;
  public food_variety: any = [];
  public current_variety: any = '';
  public choicesFood: any = {};
  public selectedFood: any = {};
  public cartItem: any = {};
  private tempCart: any = {};
  private canAddItem: boolean;
  public totalAmount: number = 0;
  public discount:number = 0;
  public is_busy:number = 0;
  private geoCoder;
  public zoom: number;
  public currentFood: any = {};
  private loginResponse: any = {}
  public deliverycharge: number = 0.000;
  private translatedstr: string;
  public urilang: string;
  public specialoffer: number;
  private baseUrl = environment.apiUrl;
  private foodItemPayload = {};
  private updated = false;
  public tempAllcategory: any;
  private perAllCategory:any
  private userIs='';
  private apiconnectService: ApiconnectService;
  private webStorageService: WebStorageService;
  private deviceService:DeviceDetectorService;
  private router:Router;
  private rout: ActivatedRoute;
  private titleService: Title;
  private meta: Meta;
  public userType = USER_TYPE;
  public itemCount = 0;
  public foodFilterService:FooditemFilter;
  public defaultaddress = 0;
  public component = 'menu-list';
  public latitudeinput:number;
  public longtitudeinput:number;
  public setaddressinput:string;
  public selectedAddressType = '';
  public addressTypeKey = 0;
  private addaddressresponse: any;
  public checkRestaurantAvailability:any ={};
  public addaddress:any={};
  public addressTypes = ADDRESS_TYPES;
  public addresskeys = ADDRESS_KEYS;
  public cartItemRestaurant = {};
  
  /**
   * Creates an instance of menudetails component.
   * @param globals 
   * @param location 
   * @param translate 
   * @param injector
   */
  constructor(
    private globals: Globals,
    private location: Location,
    public commonHelper: CommonHelper,
    private injector: Injector,
  ) {
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService); //SonarQube issue -> constructor params max. allowed 7.
    this.webStorageService = this.injector.get<WebStorageService>(WebStorageService);
    this.deviceService = this.injector.get<DeviceDetectorService>(DeviceDetectorService);
    this.router = this.injector.get<Router>(Router);
    this.rout = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.titleService = this.injector.get<Title>(Title);
    this.foodFilterService = this.injector.get<FooditemFilter>(FooditemFilter);
    this.meta = this.injector.get<Meta>(Meta);
    this.alttitle = this.globals.imagealt;
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    this.checkDevice();
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals.currency_ar;
    }
    else {
      this.urilang = '';
      this.currency = this.globals.currency;
    }
  }

  /**
   *set title 
   * @param title 
   */
  public setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  /**
   * go back
   */
  public goBack() {
    this.location.back();
  }

  /**
   * Checks device
   */
  private checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice){
      this.mobilecheck = true;
    }
  }

  /**
   * Get current lat lng
   */
  private getCurrentLatLng = () => {
    let curlati= JSON.parse(localStorage.getItem('lat'));
    let curlng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    let curaddress =localStorage.getItem('address');
    if(curlati && curlng){
      this.getAddressName(Number(curlati), Number(curlng), curaddress)
    }else{
    navigator.geolocation.getCurrentPosition(async position => {
      localStorage.setItem('lat', JSON.stringify(position.coords.latitude))
      localStorage.setItem('lng', JSON.stringify(position.coords.longitude))
      await this.getAddressName(Number(position.coords.latitude), Number(position.coords.longitude))
      // this.getNearByRestaurants()
    })
  }
  }

  /**
   * Get address name
   * @param {number} latitude 
   * @param {umber} longitude 
   * @returns {any} 
   */
  private getAddressName(latitude, longitude, address?) {
    return new Promise<void>((resolve, reject) => {
      this.geoCoder.geocode({
        location: {
          lat: latitude,
          lng: longitude
        }
      }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12
            localStorage.setItem('address', JSON.stringify(results[0].formatted_address));
            this.storeGuestArea(results[0]);
            resolve();
          } else {
            console.log('No results found')
          }
        } else {
          console.log('Geocoder failed due to: ' + status)
        }
      })
    })
  }

  /**
   * initial loader
   */
  ngOnInit(): void {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
    this.userIs = localStorage.getItem('user');
    const curlati= JSON.parse(localStorage.getItem('lat'));
    const curlng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    if(!curlati || !curlng){ // check presence of lat and lng -> redirect to home page if not having
      this.router.navigate([this.urilang+'home']);
    }
    const havAauth =this.webStorageService.getAuthId() && this.webStorageService.getAuthToken()
    if ( havAauth && this.userIs == USER_TYPE.LOGGED_USER ) {
      this.loggedIn = true;
      if(this.loggedIn){
        this.getProfileDetails();
      }
      this.userName = this.webStorageService.getUserName()
      this.loginResponse.user_name = this.webStorageService.getUserName()
    } else if(havAauth && this.userIs == USER_TYPE.GUEST_USER) {
      this.loggedIn = false
      this.webStorageService.removeData(['userPic'])
    }

    this.cartItem = JSON.parse(localStorage.getItem('cart'));
    this.emptyCart = this.cartItem === null ? true : false;
    this.checkCart();
    this.title = localStorage.getItem("address");
    if (!this.title) {
      this.getCurrentLatLng();
      this.title = JSON.parse(localStorage.getItem("address"));
    }
    this.id = this.rout.snapshot.params['id'];
    this.restaurantname = this.rout.snapshot.params['restaurantname'];
    $('.count').prop('disabled', true);
    if (this.id && this.restaurantname) {
      this.getRestaurantDetails();
      this.getMenuDetails();
    }
    this.geoCoder = new google.maps.Geocoder;
    this.getAddressDetails();
  }

  /**
   * Add items
   * @param food 
   */
 public async addItem(food) {
    this.choicesFood = Object.assign({}, food);
    this.selectedFood = Object.assign({}, this.choicesFood);
    this.selectedFood.price = parseFloat(this.selectedFood.price.toFixed(3));
    this.selectedFood.quantity = 1;
    this.selectedFood.choice_value_radio = 0;
    this.selectedFood.choice_value = 0;
    this.selectedFood.choosen = [];
    this.selectedFood.choosen_radio = [];
    this.resetChoices();
    let checkboxes = $('input[class="check"]')
    for (let checkbox of checkboxes) {
      checkbox.checked = false
    }
    let restaurant_id = JSON.parse(localStorage.getItem('restaurant_id'));
    if (restaurant_id == null || restaurant_id == this.id) {
      localStorage.setItem('cartItemRestaurant', JSON.stringify(this.restaurantDetails));
      this.cartItemRestaurant = this.restaurantDetails;
      $('#choicesbox').modal('show');
    } else {
      this.currentFood = food;
      Swal.fire({
        text: await this.commonHelper.translateText('You already have items in your cart. Do you want to clear your cart to add new items?'),
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: await this.commonHelper.translateText('No'),
        confirmButtonText: await this.commonHelper.translateText('Yes')
      }).then((result) => {
        if (result.isConfirmed) {
          this.forceInsertFood();
        }
      });
    }
  }

 /**
  * Get restaurant details
  */
 private getRestaurantDetails() {
   this.apiconnectService.postRequest({
      restaurant_id: this.id,
      device_type: 'web',
      veg_only: 0,
    }, "/single_restaurant?lat=" + localStorage.getItem('lat') + "&lng=" + localStorage.getItem('lng')).subscribe(async (data:any) => {
      if(data['status']){
      this.restaurantDetails = data['restaurants'][0];
      this.is_open = data['restaurants'][0].is_open;
      this.is_busy = data['restaurants'][0].is_busy;
      this.deliverycharge =  parseFloat(data['restaurants'][0].restaurant_delivery_charge);
      this.specialoffer = parseInt(this.restaurantDetails.is_food_offer_exist);
      this.minordervalue = this.restaurantDetails.min_order_value;
      var settitle = await this.commonHelper.translateText('Get Free delivery on all orders from ') + this.restaurantDetails.name + await this.commonHelper.translateText(' - EATZILLA');
      var sitedescription = await this.commonHelper.translateText('Want to order from ') + this.restaurantDetails.name + await this.commonHelper.translateText('? Click here to see their menu and get your favorite food delivered. Order through EATZILLA and pay zero delivery fee.');
      this.titleService.setTitle(settitle);
      localStorage.setItem('restaurantDetails', JSON.stringify(this.restaurantDetails));
      this.cartItemRestaurant = JSON.parse(localStorage.getItem('cartItemRestaurant'));
      this.checkSameRestaurant(this.restaurantDetails);
      this.meta.updateTag({
        name: 'description',
        content: sitedescription
      });
      this.meta.updateTag({
        property: 'og:title',
        content: settitle
      });
      this.meta.updateTag({
        property: 'og:description',
        content: sitedescription
      });
      this.meta.updateTag({
        property: 'og:image',
        content: this.restaurantDetails.image
      });
      $('.loading').hide();
      }

    }, err => {
      console.log(err);
      this.restaurantDetails =[];
      $('.loading').hide();
    });
    
  }

  /**
   * Get menu details
   */
  private getMenuDetails() {
     $('.loading').show();
    this.apiconnectService.postRequest({
      restaurant_id: this.id,
      device_type: 'web',
      is_veg: 0
    }, "/get_food_list").subscribe((data) => {
      this.min_order = data['food_list'][0]?.min_order_value;
      this.allCategories = data;
      if (this.allCategories.status) {
        this.getItemCount(this.allCategories?.food_list);
        this.food_variety = []
        this.allCategories.food_list.forEach(element => {
          element.items.forEach(food => {
            food?.add_ons?.map(item => {
              item.selected = false
            })
            food.itemTotal = food.price
          })
          element.category_name = element.category_name.replace(/ /g, '-')
          let json = {
            category: element.category_name,
            count: element.items.length,
            category_id: element.category_id,
            category_sort: element.category_sort
          }
          this.food_variety.push(json);
          $('.loading').hide();
        })
      } else {
        console.error("There was an error");
      }
    }, err => {
      console.log(err);
      $('.loading').hide();
    });
  }

  /**
   * Scrolls to
   * @param {number} id 
   */
 public scrollTo(id) {
    this.current_variety = id;
    $('.categoryitem').removeClass('active');
    $('#' + id).addClass('active');
    if (id) {
      $('html, body').animate({
        scrollTop: $('#foodlistbox' + id).offset().top
      }, 1000);
    }
  }

  /**
   * Show large image
   * @param {number} foodid 
   */
  public showlargeimage(foodid) {
    $('#zoomContainer' + foodid).show();
  }

   /**
   * Hide large image
   * @param {number} foodid 
   */
  public hidelargeimage(foodid) {
    $('#zoomContainer' + foodid).hide();
  }

  /**
   * proceed to checkout
   */
 public async proceedtoCheckout() {
  this.userIs = localStorage.getItem('user');
  const localkey = JSON.parse(localStorage.getItem('nodefualtlogginaddress'));
  const nodefualtlogginaddress = localkey;
    //dawar
    if (this.userIs && this.userIs == this.userType.LOGGED_USER ){
      if(!nodefualtlogginaddress){
        this.router.navigate([this.urilang+"cart/"]);
      }else{
        Swal.fire({
          text: await this.commonHelper.translateText('Oops!! Please Add your delivery address'),
          icon: 'error'
        }).then(result=>{
          if(result.isConfirmed){
            this.getAddressDetails();
            $('#addressMap').modal('show');
          }
        });
      }
    }else{
      localStorage.setItem('isproceedtocheckout',JSON.stringify(true));
      this.phonenumberdropdown();
      this.showLoginbox();
    }
  }

  /**
   * Add new cart
   * @param {any} food
   * @param {number} quantity
   */
  public addNewCart = (food, quantity) => {
    if(food?.choice_category.length>0){
      if(food?.choice_category[0].canProceed){
        this.increaseFoodCount(food, quantity);
      }
    }else{
      this.increaseFoodCount(food, quantity);
    }
   
  }

  /**
   * Add quantity
   * @param {any} food
   * @param {number} quantity
   */
  public addQuantity = (food, quantity) => {
    let index = _.findIndex(this.cartItem, ['itemUniqueId', food.itemUniqueId]);
    this.cartItem[index].quantity = quantity ? food.quantity + 1 : food.quantity;
    this.cartItem[index].price = (parseFloat(food.itemTotal) + parseFloat(food.choice_value_radio) + parseFloat(food.choice_value)) * food.quantity;
    this.cartItem[index].price = parseFloat(this.cartItem[index].price.toFixed(3));
    localStorage.setItem('cart', JSON.stringify(this.cartItem));
    this.checkCart();
  }

  /**
   * Remove new cart
   * @param {any} food
   * @param {number} quantity
   */
  public removeNewCart = (food, quantity) => {
    if(food?.choice_category.length>0){
      if(food?.choice_category[0].canProceed){
       this.removeNewCartMap(food, quantity);
      }
    }else{
      this.removeNewCartMap(food, quantity)
    }
   }
   
   /**
    * sub function
    * @param food 
    * @param quantity 
    */
   private removeNewCartMap(food, quantity){
    if(quantity) {
      if (food.quantity !== 1) {
        this.selectedFood.quantity = food.quantity - 1;
        this.selectedFood.price = (parseFloat(food.itemTotal) + parseFloat(food.choice_value_radio) + parseFloat(food.choice_value)) * food.quantity;
        this.selectedFood.price = parseFloat(this.selectedFood.price.toFixed(3));
      } else {
        $('#choicesbox').modal('hide');
      }
    } else {
      this.selectedFood.price = (parseFloat(food.itemTotal) + parseFloat(food.choice_value_radio) + parseFloat(food.choice_value)) * food.quantity;
      this.selectedFood.price = parseFloat(this.selectedFood.price.toFixed(3));
    }
   }

  /**
   * Reduce quantity
   * @param {any} food
   */
  public reduceQuantity = (food) => {
    let index = _.findIndex(this.cartItem, ['itemUniqueId', food.itemUniqueId])
    if (food.quantity !== 1) {
      this.cartItem[index].quantity = food.quantity - 1;
      this.cartItem[index].price = (parseFloat(food.itemTotal) + parseFloat(food.choice_value_radio) + parseFloat(food.choice_value)) * food.quantity;
      this.cartItem[index].price = parseFloat(this.cartItem[index].price.toFixed(3));
      localStorage.setItem('cart', JSON.stringify(this.cartItem));
      this.checkCart();
    } else {
      if (this.cartItem.length === 1) {
        this.cartItem.splice(index, 1);
        this.emptyCart = true;
        localStorage.removeItem('cart');
        localStorage.removeItem('restaurant_id');
      } else {
        this.cartItem.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(this.cartItem));
        this.checkCart();
      }
    }
  }

  /**
   * Choice radio - set choice radio value
   * @param {any} event
   * @param {any} choice
   */
  public choiceRadioValue = (event, choice) => {
    if (this.selectedFood.choosen_radio.length === 0) {
      this.setRadioChoice(event.target.value, choice);
    } else if (this.selectedFood.choosen_radio.length !== 0) {
      let filterArray = _.filter(this.selectedFood.choosen_radio, ['choice_category_id', choice.choice_category_id]);

      if (filterArray.length > 0) {
        _.remove(this.selectedFood.choosen_radio, (item) => item.choice_category_id === choice.choice_category_id);
        this.setRadioChoice(event.target.value, choice);
      } else {
        let choiceValue = parseFloat(event.target.value);
        let selectedChoiceValue = parseFloat(this.selectedFood.choice_value_radio);
        let count = selectedChoiceValue + choiceValue;
        this.selectedFood.choice_value_radio = parseFloat(count.toFixed(3));
        this.selectedFood.choosen_radio.push({ name: choice.name, id: choice.id, choice_category_id: choice.choice_category_id });
        let categoryIndex = _.findIndex(this.selectedFood.choice_category, ['id', choice.choice_category_id]);
        this.selectedFood.choice_category[categoryIndex].selected = !this.selectedFood.choice_category[categoryIndex].selected ?  [] : this.selectedFood.choice_category[categoryIndex].selected;
        this.selectedFood.choice_category[categoryIndex].selected.push(choice.name);
        this.selectedFood.choice_category[categoryIndex].canProceed = this.selectedFood.choice_category[categoryIndex].selected.length === Number(this.selectedFood.choice_category[categoryIndex].max) ?  true : false;
        this.addNewCart(this.selectedFood, null);
      }
    }
  }

  /**
   * Choice checkbox - set choice values
   * @param {any} event
   * @param {any} choice
   */
  public choiceCheckboxValue = (event, choice) => {
    let category = _.filter(this.selectedFood.choice_category, ['id', choice.choice_category_id]);
    let categoryIndex = _.findIndex(this.selectedFood.choice_category, ['id', choice.choice_category_id]);
    if (this.selectedFood.choosen.length > 0) {
      let categoryArray = _.filter(this.selectedFood.choosen, ['choice_category_id', choice.choice_category_id]);
      if (event.target.checked === true) {
         this.choiceCheckBoxChecked({category:category, categoryIndex:categoryIndex, categoryArray:categoryArray, event:event, choice:choice})
       } else {
        this.choiceCheckBoxUnchecked({category:category, categoryIndex:categoryIndex, categoryArray:categoryArray, event:event, choice:choice})
      }
    } else {
      if (Number(category[0].min) === 1) this.selectedFood.choice_category[categoryIndex].canProceed = true;
      this.setChoice(event.target.value, choice);
    }
  }

  /**
   * Set choice 
   * @param {any} event
   * @param {any} choice
   */
  private setChoice = (value, selectedChoice) => {
    let choiceValue = parseFloat(value);
    let selectedChoiceValue = parseFloat(this.selectedFood.choice_value);
    let categoryIndex = _.findIndex(this.selectedFood.choice_category, ['id', selectedChoice.choice_category_id]);
    let count = choiceValue + selectedChoiceValue;
    this.selectedFood.choice_value = parseFloat(count.toFixed(3));
    this.selectedFood.choosen.push({ name: selectedChoice.name, id: selectedChoice.id, choice_category_id: selectedChoice.choice_category_id });
    this.selectedFood.choice_category[categoryIndex].selected = !this.selectedFood.choice_category[categoryIndex].selected ? [] : this.selectedFood.choice_category[categoryIndex].selected;
    this.selectedFood.choice_category[categoryIndex].selected.push(selectedChoice.name);
    this.addNewCart(this.selectedFood, null);
  }

  /**
   * Set radio choice
   * @param {any} event
   * @param {any} selectedChoice
   */
  private setRadioChoice = (value, selectedChoice) => {
    let choiceValue = parseFloat(value);
    let categoryIndex = _.findIndex(this.selectedFood.choice_category, ['id', selectedChoice.choice_category_id]);
    this.selectedFood.choice_value_radio = parseFloat(choiceValue.toFixed(3));
    this.selectedFood.choosen_radio.push({ name: selectedChoice.name, id: selectedChoice.id, choice_category_id: selectedChoice.choice_category_id });
    this.selectedFood.choice_category[categoryIndex].selected = !this.selectedFood.choice_category[categoryIndex].selected ?  [] : this.selectedFood.choice_category[categoryIndex].selected;
    this.selectedFood.choice_category[categoryIndex].selected = [selectedChoice.name];
    this.selectedFood.choice_category[categoryIndex].canProceed = this.selectedFood.choice_category[categoryIndex].selected.length === Number(this.selectedFood.choice_category[categoryIndex].max) ?  true : false;
    this.addNewCart(this.selectedFood, null);
  }

  /**
   * Add to cart
   */
  public addToCart = () => {
    this.cartItem = JSON.parse(
      localStorage.getItem('cart')
    );
    this.canAddItem = true;
    if (this.selectedFood.choice_category.length > 0) {
       _.map(this.selectedFood.choice_category, (category, index) => {
        this.categoryArrange(category);
        if (index === this.selectedFood.choice_category.length - 1) {
          this.addItemToLocalCart()
        }
      })
    } else {
      this.addItemToLocalCart()
    }
  }

 /**
  * Add item to local cart
  */
 private addItemToLocalCart = () => {
    this.createItemPayload();
    if(!this.foodItemPayload)return;
    this.tempCart = this.foodItemPayload;
    if (this.canAddItem === true) {
      if (this.cartItem !== null) {
        this.localCartUpdate();
      } else {
        this.cartItem = [];
        this.cartItem.push(this.tempCart);
        this.emptyCart = false;
      }

      localStorage.setItem('cart', JSON.stringify(this.cartItem));
      localStorage.setItem('restaurant_id', JSON.stringify(this.id));
      this.checkCart();
      $('#choicesbox').modal('hide');
    }
  }

  /**
   * Check cart
   */
  private checkCart = () => {
    this.totalAmount = 0;
    this.discount = 0;
    this.cartItem = JSON.parse(localStorage.getItem('cart'));
    if (this.cartItem !== null) {
      this.cartItem.map((item) => {
        this.totalAmount += parseFloat(item.price);
        if(item.food_percentage_offer) this.discount +=  (item.price * item.food_percentage_offer) / 100;
      })
    }
  }

  /**
   * Reset choices
   */
  private resetChoices = () => {
    this.selectedFood.choice_category.map((item) => { delete item.canProceed; delete item.selected });
  }

  /**
   * Force insert food -> Add current food item to card
   * when food item added for different restaurant, cart items refreshed (existing food items removed) and add current food item for new restaurant
   */
  public forceInsertFood() {
    localStorage.removeItem('cart');
    localStorage.removeItem('restaurant_id');
    localStorage.removeItem('coupon');
    localStorage.setItem('cartItemRestaurant', JSON.stringify(this.restaurantDetails));
    this.cartItemRestaurant = this.restaurantDetails;
    this.checkCart();
    this.emptyCart = true;
    $('#itemExistModal').modal('hide');
    this.addItem(this.currentFood);
  }

  /**
   * Shows loginbox
   */
  private showLoginbox() {
    $('#registerbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    $('#loginbox').modal('show');
  }

  /**
   * Gets profile details
   */
  private getProfileDetails() {
    this.apiconnectService.getRequest("/get_profile?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.profileResponse = data;
        if (this.profileResponse.status) {
          this.userProfileDetails = this.profileResponse.data[0];
          this.webStorageService.storeUserName(this.profileResponse.data[0].name);
          this.userName = this.webStorageService.getUserName();
        }
      },
      err => {
        console.log(err)
      }
    )
  }

 /**
  * Phonenumberdropdowns menudetails component
  */
 private async phonenumberdropdown() {
    var phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
    var phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
    var input = document.querySelector(".phoneusernamelogin"),
      hiddenintputfield = document.querySelector("#countrycode_login"),
      errorMsg = document.querySelector("#error-msg");
      $(".iti__flag-container").remove();
    var iti = window.intlTelInput(input, {
      excludeCountries: ["il"],
      hiddenInput: "full",
      preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
      separateDialCode: true,
      initialCountry: 'bh',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
      customPlaceholder: function (selectedCountryPlaceholder: any) {
        return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
      },
    });
    var reset = function () {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', async function () {
      reset();
      this.values = input;
      const numberOnly = NUMBER_ONLY;
      if (this.values.value.trim()) {
        if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
          var countryData = iti.getSelectedCountryData();
          this.values = hiddenintputfield;
          this.values.value = countryData.iso2;
          this.selectedcountrycode = countryData.iso2;
          $('#countrycode_login').val(countryData.iso2);
        } else {
          input.classList.add("error");
          errorMsg.innerHTML = phoneplaceholdererror;
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }

  /**
   * load image
   * find error when image load
   * @param {string} id 
   * @param {any} data 
   */
  public loadImage(data, id){
  const image = document.getElementById(id) as HTMLImageElement;
  if(image){
    image.src = 'assets/images/common/nofood.jpg';
  }
  }

  /**
   * Arrange Choice values, if target checked
   * @param {object} data
   */
  private choiceCheckBoxChecked(data){
    if (data && data?.event.target.checked) {
      if (data?.categoryArray.length > 0) {
        if (data?.categoryArray.length >= Number(data?.category[0].min) - 1) this.selectedFood.choice_category[data?.categoryIndex].canProceed = true;
        if (data?.categoryArray.length < Number(data?.category[0].max)) {
          this.setChoice(data?.event.target.value, data?.choice);
        } else {
          this.selectedFood.choice_category[data?.categoryIndex].canProceed = false;
          this.setChoice(data?.event.target.value, data?.choice);
        }
      } else {
        this.setChoice(data?.event.target.value, data?.choice);
      }
      } 
  }

  /**
   * Arrange Choice values, if target unchecked
   * @param {object} data
   */
  private choiceCheckBoxUnchecked(data){
  if(data && !data?.event.target.checked) {
    let choiceValue = parseFloat(data?.event.target.value);
    let selectedChoiceValue = parseFloat(this.selectedFood.choice_value);
    let count = selectedChoiceValue - choiceValue;
    this.selectedFood.choice_value = parseFloat(count.toFixed(3));
    let choiceIndex = _.findIndex(this.selectedFood.choice_category[data?.categoryIndex].selected, (item) => item === data?.choice.name);
    this.selectedFood.choice_category[data?.categoryIndex].selected.splice(choiceIndex, 1);
    let filterArray = _.filter(this.selectedFood.choosen, (item) => item.id !== data?.choice.id);
    this.selectedFood.choosen = filterArray;
    let categoryArray = _.filter(this.selectedFood.choosen, ['choice_category_id', data?.choice.choice_category_id]);
    if (Number(data?.category[0].min) > 0 && categoryArray.length < Number(data?.category[0].min)) this.selectedFood.choice_category[data?.categoryIndex].canProceed = false;
    else if (Number(data?.category[0].min) === 0 && categoryArray.length === 0) this.selectedFood.choice_category[data?.categoryIndex].canProceed = false;
    else if (categoryArray.length <= Number(data?.category[0].max)) this.selectedFood.choice_category[data?.categoryIndex].canProceed = true;
    this.removeNewCart(this.selectedFood, null);
  }
  }

  /**
   * Category arrange
   * @param {any} category
   */
  private categoryArrange(category){
    let categoryArray = [];
    if (category.min == 1 && category.max == 1) {
      categoryArray = _.filter(this.selectedFood.choosen_radio, ['choice_category_id', category.id]);
    } else if (category.max > 1) {
      categoryArray = _.filter(this.selectedFood.choosen, ['choice_category_id', category.id]);
    }

    if (Number(category.min) >= 1 && Number(category.max) >= 1) {
      if (categoryArray.length < Number(category.min) || (Number(category.max) !== 0 && categoryArray.length > Number(category.max))) {
        category.canProceed = false;
        this.canAddItem = false;
        return;
      } else {
        category.canProceed = true;
      }
    } else {
      category.canProceed = true;
    }
  }

  /**
   * Creates item pay load
   */
  private createItemPayload(){
    if(this.selectedFood){
      this.foodItemPayload = {
        item_id: this.selectedFood.food_id,
        item_name: this.selectedFood.name,
        item_description: this.selectedFood.description,
        price: this.selectedFood.price,
        itemTotal: this.selectedFood.itemTotal,
        quantity: this.selectedFood.quantity,
        choice_value: this.selectedFood.choice_value,
        choice_value_radio: this.selectedFood.choice_value_radio,
        alreadyFound: true,
        is_veg: this.selectedFood.is_veg,
        discount_type: this.selectedFood.discount_type,
        offer_amount: this.selectedFood.offer_amount,
        item_tax: this.selectedFood.item_tax,
        itemUniqueId: Math.floor(Math.random() * 99980),
        choosen: this.selectedFood.choosen,
        choosen_radio: this.selectedFood.choosen_radio,
        food_percentage_offer: this.selectedFood.food_percentage_offer?this.selectedFood.food_percentage_offer:0,
      };
    }
  }

  /**
   * Local cart update
   */
  private localCartUpdate(){
    this.updated = false;
    _.map(this.cartItem, (item, index) => {
      let cartItem = Object.assign({}, item);
      let tempCartItem = Object.assign({}, this.tempCart);
      this.removeObjectKeys([tempCartItem, cartItem],['itemUniqueId', 'price', 'quantity','discount_type', 'item_description', 'offer_amount']);
      if (this.updated === false) {
       if (_.isEqual(cartItem, tempCartItem)) {
          this.itemQtyUpdate(index);
          this.updated = true;
        } else if (this.updated === false) {
          let cartItemChoosen = cartItem.choosen
          let tempCartItemChoosen = tempCartItem.choosen
          this.removeObjectKeys([cartItem, tempCartItem], ['choosen']);
          if (_.isEqual(cartItem, tempCartItem)) {
            this.itemChoiceQtyUpdate({cartItem:cartItem, tempCartItem:tempCartItem, cartItemChoosen:cartItemChoosen, tempCartItemChoosen:tempCartItemChoosen, index:index})
         }
        }
      }
      if (index === this.cartItem.length - 1) {
        if (this.updated !== true) {
          this.cartItem.push(this.tempCart);
          }
      }
    })
  }

  /**
   * Remove object keys
   * @param {Array} objArr ->Array of object
   * @param {Array} keyArr ->Array of string
   */
  private removeObjectKeys(objArr, keyArr){
    objArr.forEach((obj)=>{
      keyArr.forEach((key)=>{
        delete obj[key];
      })
    })
   }

   /**
    * Items qty update // when cart item same
    * @param {number} index 
    */
   private itemQtyUpdate(index){
    this.cartItem[index].quantity += this.tempCart.quantity || 1;
          this.cartItem[index].price = (parseFloat(this.cartItem[index].itemTotal) + parseFloat(this.cartItem[index].choice_value_radio) + parseFloat(this.cartItem[index].choice_value)) * this.cartItem[index].quantity;
          this.cartItem[index].price = parseFloat(this.cartItem[index].price.toFixed(3));
   }

   /**
    * Items qty update two
    * @param {number} index 
    */
   private itemQtyUpdateTwo(index){
    this.cartItem[index].quantity += this.tempCart.quantity || 1;
    this.cartItem[index].price = (Number(this.cartItem[index].itemTotal) + Number(this.cartItem[index].choice_value_radio) + Number(this.cartItem[index].choice_value)) * this.cartItem[index].quantity;
    this.cartItem[index].price = parseFloat(this.cartItem[index].price.toFixed(3));
   }

   /**
    * Items choice qty update
    * @param {object} data 
    */
  private itemChoiceQtyUpdate(data){
  if(data){
    if (data?.cartItemChoosen.length === data?.tempCartItemChoosen.length) {
      let finalArray = []
      for (let i = 0; i < data?.cartItemChoosen.length; i++) {
        let filterArray = _.find(data?.tempCartItemChoosen, ['id', data?.cartItemChoosen[i].id]);
        finalArray.push(filterArray);
        this.itemChoiceQtyUpdateTwo(data, finalArray, i);
      }
    } else {
      this.cartItemUpdate(data);
    }
  }
}

/**
 * after content init 
 */
ngAfterContentInit(): void {
  const curlati= JSON.parse(localStorage.getItem('lat'));
  const curlng = parseFloat(JSON.parse(localStorage.getItem('lng')));
  if(!curlati || !curlng){
    this.getCurrentLatLng();
   }
}

/**
 * cart item update
 * @param {object} data 
 */
public cartItemUpdate(data){
if(data){
  data.cartItem.choosen = data?.cartItemChoosen;
  data.tempCartItem.choosen = data?.tempCartItemChoosen;
}
}

/**
 * increase food count
 * @param food 
 * @param quantity 
 */
public increaseFoodCount(food, quantity){
  this.selectedFood.quantity = quantity ? food.quantity + 1 : food.quantity;
  this.selectedFood.price = (parseFloat(food.itemTotal) + parseFloat(food.choice_value_radio) + parseFloat(food.choice_value)) * food.quantity;
  this.selectedFood.price = parseFloat(this.selectedFood.price.toFixed(3));
}

/**
 * get item count
 * @param data 
 * @returns {number}
 */
public getItemCount(data){
  this.itemCount = 0;
  if(data){
    const filteredarray = this.foodFilterService.transform(data, this.term);
    filteredarray.forEach(category=>{
      this.itemCount += category.items.length;
    })
 }
}

/**
 * get address details
 */
public getAddressDetails(){
  this.defaultaddress = JSON.parse(localStorage.getItem('defaultaddress'))?JSON.parse(localStorage.getItem('defaultaddress')):0;
  this.latitudeinput = JSON.parse(localStorage.getItem('lat'));
  this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem('lng')));
  this.setaddressinput = JSON.parse(localStorage.getItem('address'));
}


  /**
   *address submit 
   * @param {NgForm} formdata 
   */
   public addaddresssubmit(formdata: NgForm) {
    if(this.selectedAddressType==''){
      return;
    }else{
      $('.loading').show();
      $('#addressMap').modal('hide');
      $('#editaddressmodal').modal('hide');
      let payload = {
        landmark: formdata.value.landmark,
        flat_no: formdata.value.flat_no,
        address: this.addaddress.addresstring,
        lat: localStorage.getItem('lat'),
        lng: localStorage.getItem('lng'),
        type: this.addressTypeKey,
        address_direction: formdata.value.landmark,
        block_number: formdata.value.blocknumber,
        building: formdata.value.building,
        road_number: formdata.value.road_number,
        device_type: 'web',
        address_title:this.selectedAddressType,
        area:formdata.value.location,
      }
      this.apiconnectService.postRequest(payload, '/add_delivery_address').subscribe(
        async (data) => {
          this.addaddressresponse = data;
           if (this.addaddressresponse.status) {
            $('.loading').hide();
            formdata.reset();
            this.selectedAddressType = '';
            this.defaultaddress = 1;
            localStorage.setItem("defaultaddress", '1');
            localStorage.setItem('nodefualtlogginaddress','false');
            Swal.fire({
              text: await this.commonHelper.translateText('You have successfully Added your Address'),
              icon: 'success'
            });
          }
          else {
            $('.loading').hide();
            Swal.fire({
              text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
              icon: 'error'
            });
          }
        },
        async err => {
          this.errorMessageOne(err);
        }
      );
    }
    }

    /**
   * address type choose
   * @param {any} event 
   * @param {number} key 
   */
 public chooseAddressType(event, key){
  if(event.target.checked){
    this.selectedAddressType = event.target.value
    this.addressTypeKey = key+1;
  }
}

/**
 * error response 
 * @param {any} err
 */
public async errorMessageOne(err){ //something went wrong message
  if(err){
  $('.loading').hide();
  Swal.fire({
    text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
    icon: 'error'
  });
  }
}

  /**
   * show edit address
   */
  public showEditAddress() {
    $('.loading').show();
    this.getCurrentLatLng();
    this.addaddress = {
        addresstring: JSON.parse(localStorage.getItem("address")),
        blocknumber: '',
        building: '',
        flat_no: '',
        landmark: '',
        address_title: '',
        road_number: ''
      }
      var lat = JSON.parse(localStorage.getItem("lat"));
      var lng = JSON.parse(localStorage.getItem("lng"));
      let datapost = {
        current_address: JSON.parse(localStorage.getItem("address")),
        lat: lat,
        lng: lng,
        restaurant_id: this.restaurantDetails.id,
        address_id: 0,
        device_type: 'web',
      }
  
      this.checkAvailbility(datapost);
  }

  /**
 * check availability of restaurant and further work
 * @param {object} datapost 
 */
public async checkAvailbility(datapost){
  await this.checkrestaurantavailability(datapost);
    if (!this.checkRestaurantAvailability.status){
      Swal.fire({
        text: await this.commonHelper.translateText('Oops!! The restaurant does not delivery in this location. Select another location'),
        icon: 'error'
      });
      return
    }
    if (this.checkRestaurantAvailability.status && this.userIs == this.userType.LOGGED_USER){
      const location = JSON.parse(localStorage.getItem('location'));
     if(location){
      await this.mapLocation(location);
      $('#addressMap').modal('hide');
      $('.loading').hide();
      this.selectedAddressType = this.addaddress.address_title;
        $('#editaddressmodal').modal('show');
      }
    }
}

/**
   * check restaturant availability
   * @param {any} datapost 
   * @returns {object}
   */
private checkrestaurantavailability(datapost){
  return new Promise((resolve, reject) => {
    this.apiconnectService.postRequest(datapost, '/check_restaurant_availability?lat=' + datapost.lat + '&lng=' + datapost.lng).subscribe(
      async (data) => {
        this.checkRestaurantAvailability = data;
        resolve(this.checkRestaurantAvailability);
        $('.loading').hide();
        return data;
      },
      async err => {
        this.errorMessageOne(err);
      }
    );
  })
}

/**
 * map location
 * @param location 
 */
public mapLocation(location): Promise<void>{
  return new Promise(resolve => {
  const str = ''|| undefined || null;
 switch(true){
     case location['area'] != str:
     this.addaddress['location'] =location['area'];
      break;
      case location['sublocality_level_3'] != str:
      this.addaddress['location'] =location['sublocality_level_3'];
      break;
      case location['sublocality_level_2'] != str:
      this.addaddress['location'] =location['sublocality_level_2'];
      break;
      case location['sublocality_level_1'] != str:
      this.addaddress['location'] =location['sublocality_level_1'];
      break;
      case location['sublocality'] != str:
      this.addaddress['location'] =location['sublocality'];
      break;
      case location['address'] != str:
      this.addaddress['location'] =location['address'];
      break;
      case location['neighborhood'] != str:
      this.addaddress['neighborhood'] =location['neighborhood'];
      break;
      case location['land_mark'] != str:
      this.addaddress['land_mark'] =location['land_mark'];
      break;
      case location['route'] != str:
      this.addaddress['route'] =location['route'];
      break;
      case location['postal_code'] != str:
      this.addaddress['postal_code'] =location['postal_code'];
      break;
      default:
        this.addaddress['location']  = location['address'];
  }
  resolve();
});
}

/**
   * change area
   * @param event 
   */
public onChangeArea(event){
  $('#editaddressmodal').modal('hide');
  $('.loading').show();
  this.getAddress();
   setTimeout(() => {
  $('.loading').hide();
  $('#addressMap').modal('show');
 }, 500);
 }

 /**
    * get address // initially loading function
    */
 private getAddress(){
  this.setaddressinput = localStorage.getItem("address");
    this.latitudeinput = JSON.parse(localStorage.getItem("lat"));
    this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem("lng")));
 }

 /**check same restaurant
  * 
  * @param newData 
  */
 public checkSameRestaurant(newData){
  if(newData?.id){
  const oldDetails = JSON.parse(localStorage.getItem('cartItemRestaurant'));
  const newDetail = newData;
  if(oldDetails?.id){
    if(oldDetails?.id==newDetail?.id){
      localStorage.setItem('cartItemRestaurant', JSON.stringify(this.restaurantDetails));
      this.cartItemRestaurant = this.restaurantDetails;
     }
  }
  }
 }

  /**
   * store guest area
   * @param name 
   */
  private storeGuestArea(address){
    const location = {};
    location['address'] = address.formatted_address
    if(address.address_components.length>0){
      address.address_components.forEach((area)=> { // get area
        this.addresskeys.forEach(keys=>{
          if(area.types.includes(keys.TYPES_ARRAY)){
                this.storeLocation(area, keys.KEY_TO_SAVE, location);
              }
             })
         });
     }
  }

  /**
 * store local data
 * @param {any} data 
 * @param {string} key 
 */
private storeLocation(data, key, place){
  const location = place;
  location[key] = data.long_name;
  this.webStorageService.storeLocalData({'location':location});
}

/**
 * close user address modal
 * @param formData 
 */
public closeuserAddressModal(formData){
 if(formData){
  formData.resetForm();
  this.selectedAddressType = '';
 }
}

/**
 * address confirmation
 * @param {boolean} confirmation
 */
public addressConfirmation(confirmation){
  if(confirmation){
     this.showEditAddress();
  }
 }

 /**
  * item choince quantity update 
  * @param {any} data 
  * @param {Array} finalArray
  * @param {number} i 
  */
 private itemChoiceQtyUpdateTwo(data, finalArray, i){
  if (data?.cartItemChoosen.length - 1 === i) {
    if (finalArray.length === data?.cartItemChoosen.length) {
      this.itemQtyUpdateTwo(data?.index);
      this.updated = true;
    } else {
      this.cartItemUpdate(data);
    }
  }
 }
}