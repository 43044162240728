import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, OnChanges, SimpleChanges, Injector, Output, EventEmitter } from '@angular/core';
import { MapsAPILoader, MouseEvent} from '@agm/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonHelper } from 'src/app/common/helper';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { ADDRESS_KEYS } from 'src/app/constants/common';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.scss']
})
export class GooglemapComponent implements OnInit, OnChanges {
  @Input() latitudeinput: any;
  @Input() longtitudeinput: any;
  @Input() setaddressinput: any;
  @Input() componenttype: any;
  @Input() id: any;
  @Input() restaurantname: any;
  public latitude: number;
  public longitude: number;
  public zoom = 17;
  public address: string;
  private check_restaurant_availability:any;
  private newaddress:any = {}
  private geoCoder;
  private mobilecheck:boolean;
  private deviceInfo = null;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  private urilang: string;
  private translatedstr: string;
  private apiconnectService: ApiconnectService;
  private deviceService: DeviceDetectorService;
  private router:Router;
  private webStorage:WebStorageService;
  private addressKeys = ADDRESS_KEYS;
  @Input() title = '';
  @Output() addressconfirmation:EventEmitter<any> = new EventEmitter(false);
  
  /**
   * Constructor
   * @param mapsAPILoader 
   * @param ngZone 
   * @param commonHelper
   * @param {Injector} injector
   */
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private commonHelper: CommonHelper,
    private injector: Injector,
    private translate: TranslateService,
  ) {
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService); //SonarQube issue -> constructor params max. allowed 7.
    this.deviceService = this.injector.get<DeviceDetectorService>(DeviceDetectorService);
    this.router = this.injector.get<Router>(Router);
    this.webStorage = this.injector.get<WebStorageService>(WebStorageService);
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
    this.checkDevice();
  }
  
  /**
   * Checks device
   */
  private checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice){
      this.mobilecheck = true;
    }
  }
  /**
   * on init
   */
  ngOnInit() {
    console.log('Map initiated')
  }

  /**
   * after content init
   */
  ngAfterContentInit(){
    window.scroll(0, 0);
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      this.setCurrentLocation();
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,
        {
          componentRestrictions: { country: 'IN' }
        });
       autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setaddressinput = place.formatted_address;
          //Later usage
          // localStorage.setItem('lat', JSON.stringify(this.latitude));
          // localStorage.setItem('lng', JSON.stringify(this.longitude));
          // localStorage.setItem('address', JSON.stringify(this.setaddressinput));
          // if (this.componenttype == 'profile') {
          //   this.storeDetails(this.componenttype)
          //   this.storeGuestArea(place);
          // }
          // if (this.componenttype == 'guest-user' ||this.componenttype == 'cart' || this.componenttype=='home') {
          //   this.storeDetails(this.componenttype)
          //   this.storeGuestArea(place);
          // }
        });
      });
    });
  }

  /**
   * Get Current Location Coordinates
   * Sets current location
   */
  private async setCurrentLocation() {
    if (this.mobilecheck){
      await this.getAddress(parseFloat(localStorage.getItem('lat')), parseFloat(localStorage.getItem('lng')), false);
    }
  navigator.permissions && navigator.permissions.query({ name: 'geolocation' }).then(async PermissionStatus => {
      await this.navigationDetailsMap(PermissionStatus);
    });
  }

  /**
   * marker drag end
   * @param $event 
   */
 public markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude, true);
  }

  /**
   * Gets address
   * @param latitude 
   * @param longitude 
   * 
   */
  private getAddress(latitude, longitude, key?): Promise<void> {
    return new Promise((resolve, reject) => {
     this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.hasAddress(results[0], key)
        } 
        else {
          console.error('No results found');
        }
      } else {
        console.error('Geocoder failed due to: ' + status);
      }

    });
    resolve()
  });
  }

  /**
   * address maping if has address
   * @param result 
   */
  private hasAddress(result, key){
   if(result){
    this.address = result.formatted_address;
    this.setaddressinput = this.address;
    const components = this.componenttype == 'profile' || this.componenttype == 'menu-list' || this.componenttype == 'cart' || this.componenttype == 'guest-user' || this.componenttype == 'home';
    if (components && key?false:true){
     this.storeDetails(this.componenttype);
     this.storeGuestArea(result);
    }
   }
  }

  /**
   * Goto search results
   */
  public async gotoSearchresults() {
    this.latitude = JSON.parse(localStorage.getItem('lat'));
     this.longitude = parseFloat(JSON.parse(localStorage.getItem('lng')));
    if (this.componenttype == 'home') {
      await this.getAddress(this.latitude, this.longitude, false);
      this.webStorage.storeLocalData({'defaultaddress':0,'address':this.setaddressinput,
     'searchedaddress':true});
      $('.loading').hide();
      $('#addressMap').modal('hide');
      this.router.navigate([this.urilang+ 'search-results'], {
        queryParams: {
          lat: this.latitude,
          lng: this.longitude,
          address: this.setaddressinput
        }
      });
    }else {
      let datapost = {
        current_address: this.setaddressinput,
        lat: this.latitude,
        lng: this.longitude,
        restaurant_id: Number(this.id),
        address_id: 0,
        device_type: 'web',
      }
      var response = await this.checkrestaurantavailability(datapost);
      if (response['status']){
        Swal.fire({
          text: await this.commonHelper.translateText('Oops!! The restaurant does not delivery in this location. Select another location'),
          icon: 'error'
        });
      }
      if (this.check_restaurant_availability.status){
        this.router.navigate([this.urilang+"menu-details/" + this.check_restaurant_availability.restaurant.id + "/" + this.restaurantname]);
      }      
    }
  }

  /**
   * Check restaurant availability
   * @param datapost 
   * @returns  
   */
  private checkrestaurantavailability(datapost){
    return new Promise((resolve, reject) => {
      this.apiconnectService.postRequest(datapost, '/check_restaurant_availability_by_parent?lat=' + datapost.lat + '&lng=' + datapost.lng).subscribe(
        async (data) => {
          if(data['status']){
            this.check_restaurant_availability = data;
          resolve(this.check_restaurant_availability);
          $('.loading').hide();
          return data;
          }else{
            $('.loading').hide();
          Swal.fire({
            text: await this.commonHelper.translateText(data['message']),
            icon: 'error'
          });
          }
        },
        async err => {
          $('.loading').hide();
          Swal.fire({
            text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
            icon: 'error'
          });
          console.error(err);
        }
      );
    })
  }

  /**
   * Sets current location // if navigation permission not granted or navigation denied
   */
  private setCurrentLocationTwo(){
    if (this.latitudeinput && this.longtitudeinput) {
      this.latitude = this.latitudeinput;
      this.longitude = this.longtitudeinput;
    }
  }

   /**
   * Sets current location // if no current location
   */
   private async setCurrentLocationIfNo(position){
    if (this.latitudeinput && this.longtitudeinput) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      await this.getAddress(this.latitude, this.longitude, false);
    }
  }

  /**
   * store address details
   * @param name 
   */
  private storeDetails(name){
    switch(name){
      case 'profile':
        this.webStorage.storeLocalData({'add_address_lat':this.latitude, 'add_address_lng':this.longitude, 'add_address_address':this.setaddressinput});
        break;
      case 'guest-user':
        this.webStorage.storeLocalData({'lat':this.latitude, 'lng':this.longitude, 'address':this.setaddressinput});
        break;
      case 'cart':
        this.webStorage.storeLocalData({'lat':this.latitude, 'lng':this.longitude, 'address':this.setaddressinput});
        break;
      case 'home':
        this.webStorage.storeLocalData({'lat':this.latitude, 'lng':this.longitude, 'address':this.setaddressinput});
      break;
    }
  }

 /**
   * store guest area
   * @param name 
   */
 private storeGuestArea(address){
  const location = {};
  location['address'] = address.formatted_address
  if(address.address_components.length>0){
    address.address_components.forEach((area)=> { // get area
      this.addressKeys.forEach(keys=>{
        if(area.types.includes(keys.TYPES_ARRAY)){
              this.storeLocation(area, keys.KEY_TO_SAVE, location);
            }
           })
       });
   }
}

/**
 * on changes
 * @param {SimpleChanges} changes 
 */
ngOnChanges(changes: SimpleChanges): void {
  this.latitude = changes.latitudeinput?.currentValue;
  this.longitude = changes.longtitudeinput?.currentValue;
  this.setaddressinput = changes?.setaddressinput?.currentValue;
}

/**
 * store local data
 * @param {any} data 
 * @param {string} key 
 */
private storeLocation(data, key, place){
  const location = place;
  location[key] = data.long_name;
  this.webStorage.storeLocalData({'location':location});
}

/**
 * checkout // param map 
 * @param {any} key 
 * @returns {boolean}
 */
async navigationDetailsMap(permissionStatus): Promise<void> {
  // Simulating an asynchronous operation
  return new Promise(resolve => {
    if (permissionStatus.state == 'granted') {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (this.latitudeinput && this.longtitudeinput) {
            this.setCurrentLocationTwo();
          }
          else {
            this.setCurrentLocationIfNo(position)
           }
       });
      }
    } else if (permissionStatus.state == 'prompt') {
      this.setCurrentLocationTwo() // when permission not granted
    } else {
      this.setCurrentLocationTwo() // when denied
    }
    resolve();
  });
}

/**
 * 
 */
public async addressConfirmation(){
  $('.loading').show();
  const restaurantId = JSON.parse(localStorage.getItem('restaurant_id'));
  this.latitude = JSON.parse(localStorage.getItem('lat'));
  this.longitude = parseFloat(JSON.parse(localStorage.getItem('lng')));
  if(restaurantId){
    let datapost = {
      current_address: this.setaddressinput,
      lat: this.latitude,
      lng: this.longitude,
      restaurant_id: restaurantId,
      address_id: 0,
      device_type: 'web',
    }
    var response = await this.checkrestaurantavailability(datapost);
    if (!response['status']){
      Swal.fire({
        text: await this.commonHelper.translateText('Oops!! The restaurant does not delivery in this location. Select another location'),
        icon: 'error'
      });
    }
    if (this.check_restaurant_availability.status){
     await this.getAddress(this.latitude, this.longitude, false);
     setTimeout(() => {
      this.addressconfirmation.emit(true);
      }, 1000);
      $('.loading').hide();
    } 
  }else{
   await this.getAddress(this.latitude, this.longitude, false);
   setTimeout(() => {
   this.addressconfirmation.emit(true);
   }, 1000);
   $('.loading').hide();
  }
 
}

}