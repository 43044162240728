export const USER_TYPE = {
    GUEST_USER:'guest',
    LOGGED_USER:'user',
}

export const URL = {
    HOME:'/home',
    EMPTY:'/',
    MENU:'/menu-details',
    CART:'/cart',
}

export const COMPONENTS = {
    REGISTER:'register',
    GUEST_LOGIN:'guest-user',
}

export const ADDRESS_KEYS = [
    { TYPES:'street_number',
      KEY_TO_SAVE:'street_number'
      },
      { TYPES_ARRAY:'sublocality_level_1',
      KEY_TO_SAVE:'sublocality_level_1'
      },
     { TYPES_ARRAY:'sublocality',
      KEY_TO_SAVE:'sublocality'
      },
      { TYPES_ARRAY:'sublocality_level_3',
      KEY_TO_SAVE:'sublocality_level_3'
      },
      { TYPES_ARRAY:'sublocality_level_2',
      KEY_TO_SAVE:'sublocality_level_2'
      },
      { TYPES_ARRAY:'land_mark',
      KEY_TO_SAVE:'land_mark'
      },
      { TYPES_ARRAY:'neighborhood',
      KEY_TO_SAVE:'neighborhood'
      },
      { TYPES_ARRAY:'postal_code',
      KEY_TO_SAVE:'postal_code'
      },
      { TYPES_ARRAY:'route',
      KEY_TO_SAVE:'route'
      },
      { TYPES_ARRAY:'premise',
      KEY_TO_SAVE:'premise'
      },
      { TYPES_ARRAY:'area',
      KEY_TO_SAVE:'area'
      }
 ]