<footer class="footer bg-dark text-light pt-5 pb-5">
    <div class="container">
        <!-- top footer statrs -->
        <div class="row top-footer">
            <div class="col-xs-12 col-sm-4 mb-3">
                <div class="footer-logo-block color-gray text-left">
                    <a href="#"><img src="assets/images/common/logo.png" height="44" alt="{{alttitle}}" title="{{alttitle}}"></a>
                    <p class="text-light" class="pt-3">{{'We are one family, a team of experts, who are passionate about food and on a mission to provide you with the easiest and fastest way to get your favorite dishes delivered at any time and from anywhere in Middle East.' | translate}}</p>   
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 mb-3">
                <div class="about text-left">
                    <h5>{{'Useful Links' | translate}}</h5>
                    <ul>
                        <li><a routerLink="/{{urilang}}aboutus">{{'About Us' | translate}}</a> </li>
                        <li><a routerLink="/{{urilang}}contactus">{{'Contact Us' | translate}}</a> </li>
                        <li><a routerLink="/{{urilang}}privacy">{{'Privacy Policy' | translate}}</a> </li>
                        <li><a routerLink="/{{urilang}}terms">{{'Terms and Conditions' | translate}}</a> </li>
                        <li><a routerLink="/{{urilang}}faqs">{{'Faqs' | translate}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-12 col-sm-3 mb-3">
                <div class="text-left mb-3">
                    <h5>{{'Restaurants' | translate}}</h5>
                    <ul>
                        <li *ngFor="let brands of popularBrands.data">
                            <a href="menu-details/{{brands.restaurant_id}}/{{brands.name.replace(' ', '-').replace('(', '-').replace(')', '-') | lowercase}}">{{brands.name}}</a>
                        </li><br>   
                        <li><a routerLink="/{{urilang}}restaurants">{{'View More Restaurants' | translate}}</a></li>
                    </ul>
                </div>
                <div class="popular-locations color-gray text-left">
                    <h5>{{'Popular Locations' | translate}}</h5>
                    <ul>
                        <li><a>{{'Saudi Arabia' | translate}}</a> </li>
                        <li><a>{{'Bharain' | translate}}</a> </li>
                        <li><a>{{'US' | translate}}</a> </li>
                        <li><a>{{'UK' | translate}}</a> </li>
                        <li><a>{{'Spain' | translate}}</a> </li>
                        <li><a>{{'India' | translate}}</a> </li>
                    </ul>
                </div>
                
            </div>
            <div class="col-xs-12 col-sm-2 pages color-gray text-left">
                <h5>{{'Follow Us' | translate}}</h5>
                <div class="p-t-md text-justify" style="font-size: 7px;">
                    <a href="https://www.facebook.com/2203722663276564/" class="mr-2" rel="noopener" target="_blank"><span class="fa fa-facebook fa-3x"></span></a>
                    <a href="https://instagram.com/eatzillafoodapp?igshid=1ijw8y4k3yoys" class="m-2" rel="noopener" target="_blank"><span class="fa fa-instagram fa-3x"></span></a>
                    <a href="https://www.linkedin.com/showcase/eatzilla" class="m-2" rel="noopener" target="_blank"><span class="fa fa-linkedin fa-3x"></span></a>
                    <span class="stretch"></span>
                </div>
            </div>
        </div>
        <!-- top footer ends -->
    </div>
</footer>