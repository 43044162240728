<app-header></app-header>
<section class="banner" style="background-image:url('assets/images/bg/partnerbg.jpg'); ">
    <div class="container ">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                <h1 class="text-center text-light glorytext">The Fastest way to get food to your customers</h1>
                <div class="bannerformcontainer">
                    <h4 class="text-center text-light my-3">Partner with Us!.</h4>
                    <form name="form" (ngSubmit)="r.form.valid && submitRestaurant(r)" #r="ngForm" novalidate>
                        <div class="row mt-3">
                            <div class="col-lg-6 col-12 mobilepadb mb-4">
                                <input type="text" name="fullname" class="form-control" [(ngModel)]="model.fullname" #fullname="ngModel" [ngClass]="{ 'is-invalid': r.submitted && fullname.invalid }" required placeholder="Enter your Full Name"
                                minlength="3" pattern="[A-Za-z]{1,}" maxlength="25">
                                <div *ngIf="r.submitted && fullname.invalid" class="invalid-feedback">
                                    <div *ngIf="fullname.errors.required">Full Name is required</div>
                                    <div *ngIf="fullname.errors.minlength && !fullname.errors.pattern">{{'Name must be at least 3 characters' | translate}}</div>
                                    <div *ngIf="fullname.errors.pattern">{{'Letters only' | translate}}</div>
                                    <div *ngIf="fullname.errors.maxlength && !fullname.errors.pattern">{{'Maximum 25 letters allowed' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12 mobilepadb mb-4">
                                <input id="phonenumber" type="text" name="phonenumber" class="form-control partnerphone" [(ngModel)]="model.phonenumber" #phonenumber="ngModel" [ngClass]="{ 'is-invalid': r.submitted && phonenumber.invalid }" required placeholder="Enter your Phone Number">
                                <span id="error-msg text-danger text-left" class="hide error"></span>
                                <input name="countrycode_partner" id="countrycode_partner" [(ngModel)]="selectedcountrycode" #countrycode_partner="ngModel" type="hidden">
                                <input name="phonecode_partner" id="phonecode_partner" [(ngModel)]="selectedphonecode" #phonecode_partner="ngModel" type="hidden">
                                <div id="error-msg-phonepartner" class="text-danger text-left"></div>
                                <div *ngIf="r.submitted && phonenumber.invalid" class="phone-invalid">
                                    <div *ngIf="phonenumber.errors.required">{{'Phone Number is required' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12 mobilepadb mb-3">
                                <input type="email" name="emailaddress" class="form-control" [(ngModel)]="model.emailaddress" #emailaddress="ngModel" [ngClass]="{ 'is-invalid': r.submitted && emailaddress.invalid }" required placeholder="Enter your Email Address"
                                pattern="{{emailPattern}}">
                                <div *ngIf="r.submitted && emailaddress.invalid" class="invalid-feedback">
                                    <div *ngIf="emailaddress.errors.required">Email Address is required</div>
                                    <div *ngIf="emailaddress.errors.pattern">{{'Enter valid Email Id' | translate}}</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12 mobilepadb mb-3">
                                <input type="text" name="restaurantname" class="form-control" [(ngModel)]="model.restaurantname" #restaurantname="ngModel" [ngClass]="{ 'is-invalid': r.submitted && restaurantname.invalid }" required placeholder="Enter your Restaurant Name"
                                minlength="3">
                                <div *ngIf="r.submitted && restaurantname.invalid" class="invalid-feedback">
                                    <div *ngIf="restaurantname.errors.required">Restaurant Name is required</div>
                                    <div *ngIf="restaurantname.errors.minlength">{{'Restaurant Name must be at least 3 characters' | translate}}</div>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="row my-3">
                            <div class="col-md-12 text-center">
                                <button type="submit" class="btn btn-primary">SUBMIT YOUR DETAILS</button>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </form>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="color1" style="background: white!important">
    <div class="container">
        <h2 class="my-5 text-center">Benefits of Working with Us!</h2>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="partner-img">
                    <img src="assets/images/common/gif-1.gif" height="230"   class="img-fluid mb-2" alt="Eatzilla - Benefit of Working with us" title="Eatzilla - Benefit of Working with us" />
                </div>
                <div class="para-1 cc cd dn do dp dq  ">
                    <h6 class="text-primary text-center my-3">Do More Business</h6>
                </div>
                <div class="para-width">
                    <p class="professional">
                        Eatzilla makes a real impact on your business. When your food is featured in the app, new customers can discover it and loyal customers can enjoy it more often. We've seen restaurants increase sales, lower marketing costs, and hire new employees to capitalize
                        on demand.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="partner-img">
                    <img src="assets/images/common/gif-2.gif" height="230" class="img-fluid mb-2" alt="Eatzilla - Deliver Faster" title="Eatzilla - Payment Options" />
                </div>
                <div class="para-1 cc cd dn do dp dq  ">
                    <h6 class="text-primary text-center my-3">Delivery Faster</h6>
                </div>
                <div class="para-width">
                    <p class="professional">
                        Eatzilla is the fast way to get food to your customers. With hundreds of delivery partners on the road, you can deliver in an average of 15 minutes and maintain the best possible food quality. You can also track orders from the floor, right to a customer's
                        door.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="partner-img">
                    <img src="assets/images/common/gif-3.gif" height="230" class="img-fluid mb-2" alt="Eatzilla - Parnter with Professionals" />
                </div>
                <div class="para-1 cc cd dn do dp dq  ">
                    <h6 class="text-primary text-center my-3">Partner with Professionals</h6>
                </div>
                <div class="para-width">
                    <p class="professional">
                        When you partner with eatzilla, we're in the weeds with you. We'll get you set up, promote your menu, and work with you to improve pickup and delivery times. We'll continue to test and learn together to boost volume, keep operations smooth, and impress
                        your customers.
                    </p>
                </div>
            </div>
        </div>
        <!-- End row -->
    </div>
    <!-- End container -->
</section>
<section class="how-it-works">
    <div class="container">
        <h2 class="text-center text-light">Easy 3 Step Order</h2>
        <p class="subtitle text-center text-light">Pay by Cash on delivery, Credit Card or Debit Card</p>
        <div class="text-xs-center">
            <!-- 3 block sections starts -->
            <div class="row how-it-works-solution">
                <div class="col-xs-12 col-sm-12 col-md-4 how-it-works-steps white-txt col1">
                    <div class="how-it-works-wrap">
                        <div class="step step-1">
                            <div class="icon" data-step="1">
                                <span class="fa fa-home ic"></span>
                            </div>
                            <h3>Choose a Restaurant</h3>
                            <p>We've got your covered with menus from Famous Restaurants in Middle East.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 how-it-works-steps white-txt col2">
                    <div class="step step-2">
                        <div class="icon" data-step="2">
                            <span class="fa fa-cutlery ic"></span>
                        </div>
                        <h3>Choose a Tasty Dish</h3>
                        <p>We've got your covered with menus from Famous Restaurants in Middle East.</p>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 how-it-works-steps white-txt col3">
                    <div class="step step-3">
                        <div class="icon" data-step="3">
                            <span class="fa fa-motorcycle ic"></span>
                        </div>
                        <h3>Pick up or Delivery</h3>
                        <p>Get your food delivered! And enjoy your meal! Pay online on pickup or delivery</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="app-section pt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-lg-4 hidden-xs">
                <div class="app-image">
                    <img src="assets/images/common/app.png" alt="Eatzilla - Mobile App" title="Eatzilla - Mobile App" class="img-fluid">
                </div>
            </div>
            <div class="col-md-8 col-lg-8 mb-5">
                <h2 class="text-light">The Best Food Delivery App</h2>
                <p class="text-light">Eatzilla app is now here! Discover hundreds of food outlet menus all in one place and get whatever you may be craving for delivered to your doorstep. From 5 star restaurants, fast food chains to street cafes, you have it all. </p>
                <p class="text-light">Browse through the various menus and get your order done in minutes and at no extra cost. Serving all of Middle East, you can now place your order through this friendly user app and rest assured your food will be delivered on time.</p>

                <div class="social-btns text-left">
                    <a href="https://apps.apple.com/us/app/id1525078798" rel="noopener" target="_blank" class="app-btn apple-button clearfix">
                        <div class="pull-left"><span class="fa fa-apple ic"></span> </div>
                        <div class="pull-right"> <span class="text">Available on the</span> <span class="text-2">App Store</span> </div>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.eatzillaV2.user" rel="noopener" target="_blank" class="app-btn android-button clearfix">
                        <div class="pull-left"><span class="fa fa-android ic"></span> </div>
                        <div class="pull-right"> <span class="text">Available on the</span> <span class="text-2">Play store</span> </div>
                    </a>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<app-footer></app-footer>