<div class="modal-dialog" role="document">
    <div class="modal-content p-3 px-4">
       <div class="modal-header">
           <h5 class="modal-title" id="exampleModalLabel">{{'Search Dish' | translate}}</h5>
             <button type="button" class="close"  aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <section class="pagecontent">
                <div class="container">
                    <div class="row my-4">
                        <div class="col-12">
                            <div class="rest-search">
                                <input class="m-b-md full-width form-control" id="restSearchBox" type="text" placeholder="{{'Search Dish' | translate}}" [(ngModel)]="dishname"
                                (keypress)="onTypeDish($event)" (input)="getRestaurants()">
                                <span class="search-icon">
                                    <span class="fa fa-search"></span>
                                </span>
                                <span class="delete-icon" style="display: none;">
                                    <span class="fa fa-close"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                   
                    <div class="row my-4">
                       <div class="col-12 listingwrap relative">
                        <div *ngIf="isSearching" class="absolute loader-center">
                            <span  class="data-loader"></span>
                           </div>
                           <ul class="restaurantlisting mt-3 px-4" *ngIf="restaurantlist?restaurantlist.restaurants.length>0:false">
                                <li class="rounded border mt-1" (click)="gotoMenudetails(key.name,key.id)" *ngFor="let key of restaurantlist.restaurants;">
                                    <div class="restaurant-item">
                                        <div class="media-left text-center">
                                            <div class="rest-logo card-rest-logo small-overlay-container">
                                                <span class="valign-helper"></span>
                                                <img src="{{key.image}}" alt="{{key.name}}" title="{{key.name}}">
                                                <div class="small-overlay" *ngIf="key?.is_open==0">Closed</div>
                                                <div class="small-overlay" *ngIf="key?.is_open==0?false:key?.is_busy==1">Busy</div>
                                            </div>
                                        </div>
                                        <div class="media-body pl-3 text-left cus-center-align">
                                            <h6 class="text-dark mb-1">{{key.name}}</h6>
                                            <p class="cuisinetext mb-2">
                                                <span class="cuisinetext" *ngFor="let cuisines of key?.cuisines; let i=index">
                                                    <span *ngIf="i != 0 && i!=key.cuisines.length">&nbsp;•&nbsp;</span>
                                                    {{cuisines.name | titlecase}}
                                                 </span>
                                            </p>
                                            <p class="mb-1">{{'Delivery Within' | translate}} : {{key.travel_time}} {{'Mins' | translate}} &nbsp;&nbsp;&nbsp; {{'Min Order' | translate}}:
                                                {{currency}} {{key.min_order_value.toFixed(3)}}
                                            </p>
                                            <p class="mb-1 text-success" *ngIf="key?.offer_type==1 && key?.discount_type=='1'">
                                                 {{currency}}{{key?.offer_amount}} flat offer.
                                                </p>
                                            <p class="mb-1 text-success" *ngIf="key?.offer_type==1 && key?.discount_type=='2'">
                                                 {{key?.offer_amount}}% offer upto {{currency}}{{key?.target_amount}}.
                                                </p>
                                            <p class="mb-1 text-success" *ngIf="key?.offer_type==2 && key?.discount_type=='1'">
                                                {{currency}} {{key?.offer_amount}} flat offer on order above {{currency}}{{key?.offer_value}}.
                                                 </p>
                                            <p class="mb-1 text-success" *ngIf="key?.offer_type==2 && key?.discount_type=='2'">
                                                    {{key?.offer_amount}}% on order above {{key?.offer_value}}.
                                                    <span *ngIf="!key?.max_discount">&nbsp;•&nbsp;</span>
                                                    Max Discount {{currency}}{{key?.max_discount}}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div  *ngIf="isSearched && !restaurantlist">
                                <div class="text-center">
                                    <img src="assets/images/no-data/no-food-data.png" alt="coupon-list">
                                </div>
                                <h6 class="text-center mt-4">{{'No Food Found' | translate}}</h6>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </section>
        </div>
     </div>
</div>



