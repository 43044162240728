<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Frequenly Asked Questions</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <h2>Frequenly Asked Questions</h2>
        <div class="row">
            <div class="col-lg-12 col-md-12 my-4">
                <div class="tab-content" id="faq-tab-content">
                    <div class="tab-pane" id="tab1" role="tabpanel" aria-labelledby="tab1" style="display: block;">
                        <div id="accordion" class="custom-accordion">
                            <div class="card" *ngFor="let faqs of faq; let i=index;">
                                <div class="card-header" id="headingOne">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link " data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="true" aria-controls="collapseOne">
                                        {{ faqs.question }}
                                        </button>
                                    </h5>
                                </div>
                                <div [attr.id]="'collapse'+i" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        {{ faqs.answer }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>