<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <h2>Terms and Conditions</h2>
        <div>
            <ul class="helplisting">
                <li>eatzilla.info offers its service through the web platform and mobile applications. We offer you the best possible way to interact with all the restaurants/outlets registered on our platform to place the desired order. Our main purpose is
                    to provide the simplest and smoothest way to connect our customers to the registered restaurants/outlets which provide food delivery on the doorstep. The customers can choose their favorite dishes from the diverse food menus provided/uploaded
                    by registered restaurants/outlets.</li>
                <li>eatzilla.info is an eCommerce-based platform on both web and mobile to provide food delivery from the restaurants/outlets in the customer’s area.</li>
                <li>eatzilla.info wants to clarify that we have no role in the production, manufacture or preparation or delivery of any food produced in the restaurants/outlets.</li>
                <li>eatzilla.info only offers our customers to search and order from the restaurants/outlets in the desired area which can deliver food to the requested address.</li>
                <li>eatzilla.info has no power in enforcing the laws and regulations of the country on the registered restaurants/outlets. Neither we endorse or guarantee in any way the quality of the ordered food. The restaurants/outlets should be responsible
                    for complying with all the country rules and regulations related to the preparation, sales, operations, safety, hygiene, etc.</li>
                <li>eatzilla.info does not guarantee or ensure that the food displayed on the menu matches the food delivered to the user by the restaurant/outlet. Moreover eatzilla.info is not responsible for any sort of undertakings by the restaurants/outlets
                    as our core purpose is just to connect the customers to a marketplace of restaurants.</li>
                <li>eatzilla.info is not responsible for the wrong addresses provided by the customers. Customers are solely responsible for that.</li>
                <li>eatzilla.info is not responsible for the delivery of the food. The restaurant/outlets are solely responsible for the preparation and the delivery of the food.</li>
                <li>eatzilla.info urges the customer to always check the order summary before placing an order as once the order has been submitted, the information submitted cannot be corrected. As soon as our platform receives the request, it immediately
                    goes into processing.</li>
                <li>eatzilla.info is not responsible if the request has been canceled by the restaurant. However, eatzilla.info will always refund the customer if it's paid by their debit card or credit card or Eatzilla Credits.</li>
                <li>eatzilla.info allows the customers to cancel their order free of charge within 5 minutes of order confirmation. The full amount will be charged for any cancellation made after 5 mins of placing an order.</li>
                <li>eatzilla.info expects the customers to accept and acknowledge that the information such as Name, Email, Phone Number, Address, GPS is correct. Any loss incurred due to false information is the sole responsibility of the customer.</li>
                <li>eatzilla.info is not responsible for any product return as in the restaurant/outlet terms and conditions are applied in that case. We also do not undertake any responsibility for any disputes which can occur from terms of the product returned.</li>
            </ul>

        </div>

    </div>
</section>
<app-footer></app-footer>