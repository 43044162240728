import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

/**
 * app component
 */
export class AppComponent {
 
}
