export const RATING_STAR = {
    FULL_STAR:'full-star',
    D_STAR_F:'d-star-f'
}
export const RATING_ERROR_TOASTER = {
    NO_RESTAURANT_RATING:'Restaurant rating should be valid',
    NO_DELIVERYBOY_RATING:'Delivery boy rating should be valid'
}
export const ORDER_DETAILS = {
    UPCOMING:0,
    DELIVERED:1,
    CANCELLED:2
}
export const ORDER_EVENTS = {
    VIEW:0,
    TRACK:1,
    CANCELLED:2
}