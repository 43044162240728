import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonHelper } from 'src/app/common/helper';
declare var $: any;
declare var window: any;
import { ADDRESS_TYPES } from 'src/app/constants/guest-user-login-page.constant';
import { DataEmitterService } from 'src/app/services/data-emit.service';
import {  Router } from '@angular/router';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { COMPONENTS, URL, USER_TYPE } from 'src/app/constants/common';
import { SocketService } from 'src/app/services/socket.service';
import { NUMBER_ONLY } from 'src/app/constants/pattern.constant';

enum StaticBoolean {
  True,
  False
}

@Component({
  selector: 'app-guest-user-login',
  templateUrl: './guest-user-login.component.html',
  styleUrls: ['./guest-user-login.component.scss']
})

/**
 * Component
 */
export class GuestUserLoginComponent implements OnInit {
  public model: any = {};
  public selectedphonecode:any;
  public selectedcountrycode: any;
  private guestUserLoginRespone: any;
  private translatedstr: string;
  public urilang: string;
  public addressTypes = ADDRESS_TYPES;
  public selectedAddressType = '';
  public componenttype = 'guest-user';
  public setaddressinput: any;
  public latitudeinput: any;
  public longtitudeinput: any;
  private geoCoder;
  private zoom = 0;
  public address = '';
  public formDetails:any = null;
  public registeringMobNum:any = null;
  public mappedArea = '';
  public userIs = '';
  public userType = USER_TYPE;
  public guestDetails:any;
  public addressTypeKey = 0;
  public selectedPhoneNumber:any;
  public pages= URL;
  public keyToFormefresh = StaticBoolean;
 
  /**
   * Constructor
   * @param apiconnectService 
   * @param translate 
   * @param commonHelper 
   */
  constructor(
    private apiconnectService: ApiconnectService,
     private commonHelper: CommonHelper,
     private dataEmit:DataEmitterService,
     private router: Router,
     private webStorageService: WebStorageService,
     private dataEmitter: DataEmitterService,
     private socketservice: SocketService,
     ) {
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
  }

/**
 * initial loader
 */
  ngOnInit(): void {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
    this.dataEmit.getDetails().subscribe(address => {
      if(address){
        this.address = address;
        this.mapLocation(this.address);
       }  
    })
    this.getAddress();
    this.mapGuestDetails();
   }

  /**
   * phone number dropdown
   */
 private async phonenumberdropdown() {
   var phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
    var phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
    var input = document.querySelector(".phoneguestuserlogin"),
      hiddenintputfield = document.querySelector("#countrycode_guestuserlogin"),
      errorMsg = document.querySelector("#error-msg-phoneguestuserlogin");
      $(".iti__flag-container").remove();
    var iti = window.intlTelInput(input, {
      excludeCountries: ["il"],
      hiddenInput: "full",
      preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
      separateDialCode: true,
      initialCountry:'bh',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
      customPlaceholder:function (selectedCountryPlaceholder: any) {
        return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
      },
    });
    var reset = function () {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', async function () {
      reset();
      this.values = input;
      const numberOnly = NUMBER_ONLY;
      if (this.values.value.trim()) {
        if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
          var countryData = iti.getSelectedCountryData();
          this.values = hiddenintputfield;
          this.values.value = countryData.iso2;
          this.selectedcountrycode = countryData.iso2;
          this.selectedphonecode = countryData.dialCode;
          $('#countrycode_guestuserlogin').val(countryData.iso2);
          $('#phonecode_guestuserlogin').val(countryData.dialCode);
          localStorage.setItem('chosen_country_code',JSON.stringify(countryData.iso2));
        } else {
          input.classList.add("error");
          errorMsg.innerHTML = phoneplaceholdererror;
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }

  /**
   * guest user login
   * @param formdata 
   */
 public async guestUserLoginSubmit(key:boolean, formdata?: NgForm) {
  const errorMsg = document.querySelector("#error-msg-phoneguestuserlogin");
  if(errorMsg.innerHTML !== '')return;
  $('.loading').show();
    let postdata = {
      name: formdata.value.name,
      phone: $('#phonecode_guestuserlogin').val()+formdata.value.phoneguestuserlogin,
      country_code: $('#countrycode_guestuserlogin').val(),
      device_type: 'web',
      address_title:this.selectedAddressType,
      block_number:formdata.value.block,
      building:formdata.value.building,
      flat_no:formdata.value.flat,
      road_number:formdata.value.road,
      address_direction:formdata.value.direction,
      customer_name:formdata.value.name,
      area:formdata.value.area,
    }
   
   await this.guestLoginApiConnect(postdata, formdata, key);
  }

  /**
   * address type choose
   * @param {any} event 
   * @param {number} key 
   */
  public chooseAddressType(event, key){
    if(event.target.checked){
      this.selectedAddressType = event.target.value
    }
  }

  /**
   * change area
   * @param event 
   */
  public onChangeArea(event){
    $('.modal-backdrop').remove();
    $('.loading').show();
    this.getAddress();
     setTimeout(() => {
    $('.loading').hide();
    // have two modals(#guestuserlogin and #editGuestAddress) //hide #editGuestAddress to #editGuestAddress open
    if(this.guestDetails){
      $('.guest-login-modal-edit').addClass('hide');
    }else{
      $('.guest-login-modal').addClass('hide');
    }
    $('#editGuestAddress').modal('show');
   }, 1000);
   }

    /**
  * get address
  */
 public getAddress(){
  if(localStorage.getItem('address')){
    this.setaddressinput = localStorage.getItem('address');
    this.latitudeinput=JSON.parse(localStorage.getItem('lat'));
    this.longtitudeinput =JSON.parse(localStorage.getItem('lng'));
   }
 }

 /**
  * confirm address
  * 
  */
 public confirmAddress(){
  $('.loading').show();
  this.refreshGuestDetails()
  this.address = JSON.parse(localStorage.getItem('location'));
  this.mapLocation(this.address);
  $('.guest-login-modal').removeClass('hide'); // remove hide class to show content
  $('#editGuestAddress').modal('hide');
  $('#guestuserlogin').modal('hide');
  setTimeout(() => {
  $('.loading').hide();
  $('#guestuserlogin').modal('show');
  this.selectedAddressType = this.guestDetails['address_title'];
  }, 1000);
 }

 /**
  * Arrange guest details
  * @param data 
  */
 public guestArrange(data){
  if(data){
  const removeData = ['userPic'];
  this.webStorageService.removeData(removeData); // remove user details
  this.webStorageService.storeAuthToken(data?.guestDetails.authToken,'guest');
  this.webStorageService.storeAuthId(data?.guestDetails?.guest_user_id);
  this.webStorageService.storeLocalData(data)
  localStorage.setItem('userName',JSON.stringify(data?.guestDetails?.name));
  this.dataEmitter.updateLogin(true);
  }
  }



    /**
   * navigate to near by restaurant page
  */
    public navigateToNearByRestaurant(){
        const lati = JSON.parse(localStorage.getItem('lat'));
        const lngi = parseFloat(JSON.parse(localStorage.getItem('lng')));
        const bannerinput = JSON.parse(localStorage.getItem('address'));
        if(lati && lngi && bannerinput){
        this.router.navigate([this.urilang + 'search-results'], {
          queryParams: {
            lat: lati,
            lng: lngi,
            address: bannerinput
          }
        })
        $('#guestuserlogin').modal('hide');
      }
      
    }

    /**
     * close guest login modal
     * @param {NgForm} formData 
     * @param key
     */
    public closeGuestLoginModal(formData:NgForm, phoneErr, key){
       if(key==this.keyToFormefresh.True){
        const errMsg = document.querySelector('#'+phoneErr);
        errMsg.innerHTML = "";
        errMsg.classList.add("hide");
        $('#guestuserlogin').modal('hide');
        formData.resetForm();
        this.selectedAddressType = '';
       }else{
        $('#guestuserlogin').modal('hide');
       }
    }

    /**
   * otp verifications
   * @param {NgForm} formdata 
   */
  public otpverfication(formdata: NgForm, key){
    if(this.selectedAddressType=='')return
    this.formDetails = formdata;
    this.registeringMobNum = $('#phonecode_guestuserlogin').val()+formdata.value.phoneguestuserlogin;
    this.guestUserLoginSubmit(key, formdata);
   }

  /**
   * Input setter --> after otp  verification
   */ 
  @Input() set mobVerified(data){
    if(data){
       if(data.verified && data.component==COMPONENTS.GUEST_LOGIN){
        const msg = 'Added Successfully..'
        const status = 'success';
       this.otpErrorMessage(msg, status);
       if(this.commonHelper.getRouteUrl()==URL.HOME || this.commonHelper.getRouteUrl()==URL.EMPTY){ // navigate to searchresult page , having nearby restaurant at guest-user login
        this.mapGuestDetails();
      }
      const isproceedtocheckout = JSON.parse(localStorage.getItem('isproceedtocheckout'));
      if(isproceedtocheckout){
       localStorage.removeItem('isproceedtocheckout');
        this.router.navigate([this.urilang+"cart/"]);
       }
     }
     setTimeout(() => {
      this.dataEmitter.otpverificationSet({});
     }, 1000);
  }
  }

  /**
 * map location
 * @param {any} location 
 */
public mapLocation(location){
  const notstr = ''|| undefined || null;
  switch(true){
    case location['area'] !=notstr:
      this.mappedArea =location['area'];
      break;
      case location['sublocality_level_3'] !=notstr:
      this.mappedArea =location['sublocality_level_3'];
      break;
      case location['sublocality_level_2'] !=notstr:
      this.mappedArea =location['sublocality_level_2'];
      break;
      case location['sublocality_level_1'] !=notstr:
      this.mappedArea =location['sublocality_level_1'];
      break;
      case location['sublocality'] !=notstr:
      this.mappedArea =location['sublocality'];
      break;
      case location['address'] !=notstr:
      this.mappedArea =location['address'];
      break;
      case location['neighborhood'] !=notstr:
      this.mappedArea =location['neighborhood'];
      break;
      case location['land_mark'] !=notstr:
      this.mappedArea=location['land_mark'];
      break;
      case location['route'] !=notstr:
      this.mappedArea =location['route'];
      break;
      case location['postal_code'] !=notstr:
      this.mappedArea =location['postal_code'];
      break;
      default:
      this.mappedArea= location['address'];
  }
}

/**
 * map guest details
 */
public mapGuestDetails(){
  this.guestDetails = JSON.parse(localStorage.getItem('guestDetails'));
  if(this.guestDetails){
    this.selectedAddressType = this.guestDetails['address_title'];    
    this.selectedcountrycode = this.guestDetails['country_code']; 
    this.selectedphonecode = JSON.parse(localStorage.getItem('phone_code'));  
    this.selectedPhoneNumber =  JSON.parse(localStorage.getItem('phone_no')); 
   }
}

/**
 * guest login api connect
 * @param postdata 
 * @param formdata 
 */
public guestLoginApiConnect(postdata, formdata, key): Promise<void>{
return new Promise(resolve => {
  this.apiconnectService.postRequestNoAuth(postdata, '/guest_login').subscribe(
    async (data) => {
      $('.loading').hide();
      this.guestUserLoginRespone = data;
      if (this.guestUserLoginRespone.status) {
          this.guestRegistrationResponse(postdata, data, formdata, key);
        }
      else {
        $('#guestuserlogin').modal('hide');
        $('.loading').hide();
        Swal.fire({
          text: this.guestUserLoginRespone.message,
          icon: 'error'
        }).then(()=>{
          $('#guestuserlogin').modal('show');
        });
        if(key){
          formdata.resetForm();
        }
      }
    },
    async err => {
      $('#guestuserlogin').modal('hide');
      $('.loading').hide();
      Swal.fire({
        text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
        icon: 'error'
      });
      if(key){
        formdata.resetForm();
      }
    }
  );
  resolve();
  })
}

 /**
   * get otp
   */
 public async getOTP(formdata, key){
  $('.loading').show();
  const postdata ={
    phone: $('#phonecode_guestuserlogin').val()+formdata.value.phoneguestuserlogin,
    device_type: 'web'
  }
  const mobno = $('#phonecode_guestuserlogin').val()+'-'+formdata.value.phoneguestuserlogin;
 if(postdata.phone){
  this.apiconnectService.postRequest(postdata, '/verify_guest_user').subscribe(
    async (data) => {
      if (data['status']) {
     $('.loading').hide();
     this.dataEmitter.otpverificationSet({regNum:postdata.phone, component:this.componenttype, formData:formdata, mobVerificationOTP:data['otp'], mobToShow:mobno?mobno:postdata.phone})
      }
    },
    async err => {
      Swal.fire({
        text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
        icon: 'error'
      });
      console.log(err);
    }
  );
}
 }

    /**
  * otp error message show
  * @param err 
  */
private async otpErrorMessage(err, status){
  if(err){
    $('#otpbox').modal('hide');
   $('.loading').hide();
   Swal.fire({
     text: await this.commonHelper.translateText(err),
     icon: status
   })
  }
  }

/**
 * refresh guest details
 */
  public refreshGuestDetails(){
    if(this.guestDetails){
    this.guestDetails['block_number'] ='';
    this.guestDetails['road_number'] ='';
    this.guestDetails['building'] ='';
    this.guestDetails['flat_no'] ='';
    this.selectedAddressType = this.guestDetails['address_title'];
    }
  }

  /**
   * guest resgisteration response
   * @param postdata 
   * @param data 
   * @param formdata 
   * @param key 
   */
  public guestRegistrationResponse(postdata, data, formdata, key){
    if(data['status']){
      const guestDetails = {
        ...postdata,
        ...this.guestUserLoginRespone,
      }
      this.guestDetails = guestDetails;
      this.formDetails = null;
      this.registeringMobNum = null;
      this.webStorageService.storeLocalData({defaultaddress:1});
      this.guestArrange({guestDetails:guestDetails, country_code:$('#countrycode_guestuserlogin').val(),
      phone_code:$('#phonecode_guestuserlogin').val(),phone_no:formdata.value.phoneguestuserlogin,});
      this.mapGuestDetails();
      this.getOTP(formdata, key);
       setTimeout(()=>{
        this.socketservice.connect();
      }, 1000);
    }
  }


  /**
 * address confirmation
 */
public addressConfirmation(confirmation){
  if(confirmation){
     this.confirmAddress();
  }
 }

}
