import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Globals } from '../../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { CommonHelper } from 'src/app/common/helper';
import { SocketService } from 'src/app/services/socket.service';
import { ERR_MESSAGE } from 'src/app/constants/thank-you-page.constant';
declare var $: any;

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  statusText: string;
  currency: any;
  orderdetails: any;
  orderid: any;
  order_status: any;
  now: any;
  timeInterval: any
  diff: any;
  cancelorder: any;
  cancel: boolean = false;
  translatedstr: string;
  urilang: string;
  public commonhelper:any;
  public errMsgs = ERR_MESSAGE;

  /**
   * Creates an instance of thankyou component.
   * @param globals 
   * @param rout 
   * @param apiconnectService 
   * @param router 
   * @param commonHelper 
   */
  constructor(
    private globals: Globals,
    private rout: ActivatedRoute,
    private apiconnectService: ApiconnectService,
    private router: Router,
    private commonHelper:CommonHelper,
    private socketservice: SocketService,
  ) {
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals.currency_ar;
    }
    else {
      this.urilang = '';
      this.currency = this.globals.currency;
    }
    
  }

  /**
   * initial loader
   */
  ngOnInit(): void {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
    this.now = new Date()
    this.orderid = this.rout.snapshot.params['id'];
    this.trackorder();
    setTimeout(() => {
      this.now = new Date()
    }, 20000);
    // this.timeInterval = setInterval(() => {
    //   if (this.order_status) {
    //     if (this.order_status?.status != 7) {
    //       this.trackorder();
    //     }

    //   }
    // }, 20000)

    this.commonhelper = this.commonHelper;

    //cancel event listener
   this.socketservice.cancelOrder().subscribe((res) => {
     if(res){
      const orderId = res['requestId'];
      const userId =  res['userId'];
      const same_order = this.orderdetails?.order_status[0].request_id == orderId;
      const same_userId = localStorage.getItem('authId') == userId;
      if(same_order && same_userId ){
        this.showErrToaster(res);
     }
      }
    })

     //update event listener
   this.socketservice.updateOrder().subscribe((res) => {
    if(res){
    const orderId = res['request_id'];
    const userId =  res['user_id'];
    const same_order = this.orderdetails?.order_status[0].request_id == orderId;
    const same_userId = localStorage.getItem('authId') == userId;
    if(same_order && same_userId ){
      this.order_status['status'] = +(res['status']);
      if(this.order_status['status']==10){
         this.showErrorModal(this.errMsgs.CANCELLED);
         console.log(this.errMsgs.CANCELLED)
      }
    }
    }
  })
  }

  trackorder() {
    $('.loading').show();
    let json = {
      'request_id': this.orderid,
      'device_type': 'web'
    }
    this.apiconnectService.postRequest(json, '/track_order_detail').subscribe(
      (data) => {
        this.orderdetails = data;
        console.log(this.orderdetails['order_status'][0])
        this.order_status = this.orderdetails['order_status'][0];
        
        this.diff = this.getDataDiff(new Date(this.order_status?.ordered_time), new Date());
        $('.loading').hide();
      },
      err => {
        this.router.navigate([this.urilang+ "home"]);
      }
    );
  }

  getDataDiff(startDate, endDate) {
    var startTime = startDate;
    var endTime = endDate;
    var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    this.diff = Math.round(difference / 60000);
    if (this.diff < 5) {
      this.cancel = true;
    }
  }
  async cancelmyOrder(request_id) {
    Swal.fire({
      text: await this.commonHelper.translateText('You are about to cancel your order. This cannot be reverted back. Are you sure you want to continue?'),
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: await this.commonHelper.translateText('No'),
      confirmButtonText: await this.commonHelper.translateText('Yes')
    }).then((result) => {
      if (result.isConfirmed) {
        $('.loading').show();
        let json = {
          request_id: request_id,
          device_type: 'web'
        }
        this.apiconnectService.postRequest(json, '/cancel_order').subscribe(
          async (data) => {
            this.cancelorder = data;
            if (this.cancelorder['status']) {
               $('.loading').hide();
              Swal.fire({
                text: await this.commonHelper.translateText('You have successfully cancelled your order'),
                icon: 'success'
              }).then(()=>{
                this.router.navigate([this.urilang+ "home"]);
              })
            }
            else {
              Swal.fire({
                text: await this.commonHelper.translateText('Something went wrong. Please contact us through chat or write an email to contact@eatzilla.info'),
                icon: 'error'
              });
            }
          },
          async err => {
            $('.loading').hide();
            Swal.fire({
              text: await this.commonHelper.translateText('Something went wrong. Please contact us through chat or write an email to contact@eatzilla.info'),
              icon: 'error'
            });
            console.log(err);
          }
        );
      }
    });
  }
  ngOnDestroy() {
    clearInterval(this.timeInterval)
  }

  /**
   * item total calculation
   * @param {any} data
   */
  public itemTotalPrice(data:any){
    if(!Object.keys(data).length)return 0;
    let total = 0;
    total = data?.item_price/data?.food_quantity;
    data?.add_ons.forEach((x)=>{
      if(x.price){
        total = total+x.price;
      }
    })
    return total;
  }

/**
 * navigate to home page
 */
 async showErrorModal(errMsg){
  Swal.fire({
      text: await this.commonHelper.translateText(errMsg),
      icon: 'error'
    }).then(()=>{
      this.router.navigate([this.urilang+ "home"]);
    });
  }
  
  /**
   * show err modal
   * @param data 
   */
  public showErrToaster(data){
   if(data?.type == "1"){
      this.showErrorModal(this.errMsgs.CANCELLED_BY_ADMIN);
    }else{
      this.showErrorModal(this.errMsgs.CANCELLED_BY_RESTAURANT);
  }
}
}