import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Meta, Title } from '@angular/platform-browser';
import { Globals } from "../../../globals";
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-allrestaurants',
  templateUrl: './allrestaurants.component.html',
  styleUrls: ['./allrestaurants.component.scss']
})
export class AllrestaurantsComponent implements OnInit {
  term:any;
  filtersInput:any = [];
  lat: any;
  loggedIn: boolean = false;
  lng: any;
  restaurantlist: Array<any> = [];
  alttitle:any;
  tranlsatedtitle: any;
  tranlsateddescription: any;
  urilang: string;
  public currency: string;
  constructor(
    public router: Router,
    private apiconnectService: ApiconnectService,
    private titleService: Title,
    private globals: Globals,
    private meta: Meta,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService
  ) {
    this.checkDevice();
    this.alttitle = this.globals.imagealt;
    this.translate.get('EATZILLA Food Delivery in Middle East').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Find out Multiple cuisines and your favorite restaurant on Eatzilla Now. Order your favorite American, Mexican, Pakistani, Bahraini, Indian, Italian, Lebanese or fast food with Eatzilla').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });
    if (localStorage.getItem('language') == 'ar'){
      this.urilang = 'ar/';
    }
    else{
      this.urilang = '';
    }
    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription });
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });

    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals.currency_ar;
    }
    else {
      this.urilang = '';
      this.currency = this.globals.currency;
    }
  }
  mobilecheck: boolean;
  deviceInfo = null;
  devicetype: any;
  browser: any;
  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.devicetype = this.deviceInfo.os;
    this.browser = this.deviceInfo.browser;
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice) {
      this.mobilecheck = true;
      window.scroll(0, 0);
    }
  }
  ngOnInit(): void {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
    const curlati= JSON.parse(localStorage.getItem('lat'));
    const curlng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    if(!curlati || !curlng){ // check presence of lat and lng -> redirect to home page if not having
      this.router.navigate([this.urilang+'home']);
    }
    this.getRestaurants();
    localStorage.removeItem('isproceedtocheckout');
  }
  getRestaurants() {
    $('.loading').show();
    
    var lati = JSON.parse(localStorage.getItem('lat'));
    var lng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    
    this.apiconnectService.postRequest({ // get_relevance_restaurant api url changed to get_nearby_restaurant
      'is_offer': 0,
      'lat': lati,
      'lng': lng,
      'device_type': 'web',
      'is_pureveg':0,
      'sort_type':0,
      'is_home_screen':0,
      'page':1,
    }, "/get_nearby_restaurant?lat=" + lati + "&lng=" + lng + "&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')+ "&is_pureveg=0").subscribe(
    (data) => {
      this.restaurantlist = data['restaurants'];
      this.sortRestaurants(data['restaurants']);
     if (data['status']) {
          $('.loading').hide();
        }
        else {
          this.restaurantlist = [];
          $('.loading').hide();
        }
      },
      err => {
        this.router.navigate([this.urilang+'/home']);
        console.error(err);
      }
    );
  }

  /**
   * redirect to restaurant
   * @param data 
   */
 public redirecttorestaurant(data){ 
    var restaurantname = data.name.toLowerCase();
    restaurantname = restaurantname.split(' ').join('-');
    this.router.navigate([this.urilang+"menu-details/"+data.id+"/"+restaurantname]);
  }

  /**
 * sort restaurants
 * @param data 
 */
public sortRestaurants(data){
  if(data?.length){
    if(data.length){
      this.restaurantlist.sort((a,b)=>{
        if (a['is_open'] === 1 && a['is_busy'] === 0 && (b['is_open'] !== 1 || b['is_busy'] !== 0)) {
          return -1;
      } else if (b['is_open'] === 1 && b['is_busy'] === 0 && (a['is_open'] !== 1 || a['is_busy'] !== 0)) {
          return 1;
      } else {
          return 0;
      }
     });
    }
  }
}
}