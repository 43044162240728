<div class="searchlocation">
    <input id="google-search" type="text" class="form-control rounded shadow" (keydown.enter)="$event.preventDefault()" placeholder="{{'Search Nearest Location' | translate}}" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search value="{{setaddressinput}}">
    <span class="fa fa-map-marker fa-lg position-absolute mapmarker text-primary"></span>
    <span class="fa fa-search fa-lg position-absolute search text-primary"></span>
</div>
<agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
    <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
</agm-map>
<div class="locationdetails text-dark">
    <div class="row mt-3">
        <div class="col-md-1 text-right col-2">
            <img src="assets/images/icons/location.png" alt="location">
        </div>
        <div class="col-md-11 p-0 col-10">
            <h6 class="text-left">{{setaddressinput}}</h6>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
<button class="btn btn-primary btn-block px-5 my-3 text-uppercase" (click)="gotoSearchresults()" *ngIf="componenttype == 'home' || componenttype == 'restaurant'">{{'Deliver Here' | translate}}</button>
<div class="modal-footer">
    <button class="btn btn-primary pull-right px-5 text-uppercase" (click)="addressConfirmation()" *ngIf="title">{{title | translate}}</button>
</div>
