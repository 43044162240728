import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

let baseUrl = environment.apiUrl;
let deviceToken = environment.deviceToken;
const headers = new HttpHeaders();
headers.append('Content-Type', 'multipart/form-data');
headers.append('Accept', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class ApiconnectService {
  values: any;
  headers: any;
  constructor(private http: HttpClient) {
    this.headers = new Headers();
    this.headers.append('authId', localStorage.getItem('authId'));
    this.headers.append('authToken', localStorage.getItem('authToken'));
  }
  postRequest(data: any, apiEndpoint){
    data.device_token = deviceToken;
    data.authId = localStorage.getItem('authId');
    data.authToken = localStorage.getItem('authToken');
    return this.http.post(`${baseUrl}` + apiEndpoint, data, {
      headers: headers
    });
  }
  getRequest(apiEndpoint){
    return this.http.get(`${baseUrl}` + apiEndpoint, this.headers);
  }

  //later usage
  // postRequestCredimax(data: any, apiEndpoint){
  //   data.device_token = deviceToken;
  //   data.authId = localStorage.getItem('authId');
  //   data.authToken = localStorage.getItem('authToken');
  //   return this.http.post(`${baseUrl}` + apiEndpoint, data, {
  //     headers: headers
  //   });
  // }

  /**
   * postRequestNoAuth
   * @param data 
   * @param apiEndpoint 
   * @returns 
   */
  postRequestNoAuth(data: any, apiEndpoint){
    data.device_token = deviceToken;
    return this.http.post(`${baseUrl}` + apiEndpoint, data);
  }
}
