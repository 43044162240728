<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}menu-details/{{restaurantDetails?.id}}/{{restaurantDetails?.name}}">{{restaurantDetails?.name}}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">{{'Checkout' | translate}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <div class="ordersummary rounded border mb-4 mt-4">
            <h5 class="border-bottom py-2 px-3 text-left">{{'Order Summary' |translate}} <small class="text-primary pull-right "><a class="custom-order-modify-botton"
                        routerLink="/{{urilang}}menu-details/{{restaurantDetails?.id}}/{{restaurantDetails?.name}}">{{'Modify Order' | translate}}</a></small></h5>
            <div class="box py-2 px-3">
                <h6 class="text-left">{{restaurantDetails?.name}}</h6>
                <table class="table font-14 text-left"  aria-describedby="summary">
                    <tbody>
                        <tr>
                            <th scope="row"><strong>{{'Item(s)' | translate}}</strong></th>
                            <th class="text-center" scope="col"><strong>{{'Qty' | translate}}</strong></th>
                            <th class="text-center item-price" scope="col"><strong>{{'Price' | translate}}</strong></th>
                            <th class="text-right total" scope="col"><strong>{{'Total' | translate}}</strong></th>
                        </tr>
                        <tr *ngFor="let key of cartItems">
                            <td class="v-middle">
                                <p class="p-0 m-0"><strong class="ng-binding">{{key.item_name}}</strong></p>
                                 <p class="p-0 m-0">
                                    <span class="cuisinetext" *ngFor="let row of key?.choosen_radio">{{row.name | titlecase}}, </span>
                                    <span class="cuisinetext">
                                        <p class="p-0 m-0" *ngIf="key?.choosen.length > 0">
                                            <span>Choice:</span><span *ngFor="let k of key?.choosen; let i=index">
                                               {{k.name | titlecase }}<span *ngIf="i+1 < key?.choosen.length">,</span>
                                            </span>
                                        </p>
                                    </span>
                                </p>
                            </td>
                            <td class="text-center v-middle">{{key.quantity}}</td>
                            <td class="text-center v-middle text-nowrap price">
                                {{currency}}&nbsp;{{(key.itemTotal + key.choice_value).toFixed(2)}}</td>
                            <td class="text-right v-middle text-nowrap">
                                {{currency}}&nbsp;{{(key.price).toFixed(2)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="ordersummary rounded border mb-4" style="min-height: 100px;">
            <h5 class="border-bottom py-2 px-3 text-left">{{'Delivery Address' | translate}}
                <span class="pull-right gridbuttonsabsolute" *ngIf="userIs==userType.LOGGED_USER">
                    <button class="btn btn-primary btn-sm text-uppercase arfont-14" data-toggle="modal" data-target="#addressMap">{{'Add new Address' | translate}}</button>&nbsp;
                    <button class="btn btn-primary btn-sm text-uppercase arfont-14" (click)="showexistingdeliveryaddress()">{{'Change address' | translate}}</button>
                </span>
                <!--Guest user-->
                <span class="pull-right gridbuttonsabsolute" *ngIf="userIs==userType.GUEST_USER">
                    <button class="btn btn-primary btn-sm text-uppercase arfont-14" data-toggle="modal" data-target="#guestuseredit" (click)="onShowEditModal()">{{'Change Address' | translate}}</button>&nbsp;
                </span>
            </h5>
            <div class="box py-2 px-3 dir" *ngIf="(defauladdressobject | json) != '{}' && userIs==userType.LOGGED_USER">
                <ng-container *ngIf="defaultaddress==1">
                <p class="text-left"><span class="text-dark text-uppercase" *ngIf="defauladdressobject?.address_title">{{'Address Type' | translate}}:</span> {{defauladdressobject?.address_title}}</p>
                <p class="text-left"><span class="text-dark text-uppercase" *ngIf="defauladdressobject?.address">{{'Address' | translate}}:</span> {{defauladdressobject?.address}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="defauladdressobject?.flat_no">{{'Flat' | translate}}:</span> {{defauladdressobject?.flat_no}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="defauladdressobject?.building"> {{'Building' | translate}}:</span>{{defauladdressobject?.building}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="defauladdressobject?.block_number"> {{'Block' | translate}}:</span>{{defauladdressobject?.block_number}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="defauladdressobject?.road_number"> {{'Road' | translate}}:</span>{{defauladdressobject?.road_number}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="userphone">{{'Mobile' | translate}}:</span> {{userphone}} </p>
                </ng-container>
                <ng-container *ngIf="defaultaddress==0">
                    <p class="text-left"><span class="text-dark text-uppercase">{{'Address' | translate}}:</span> {{defauladdressobject}}</p>
               </ng-container>
            </div>
            <!--Guest User-->
            <div class="box py-2 px-3 dir" *ngIf="(defauladdressobject | json) != '{}' && userIs==userType.GUEST_USER">
                <ng-container *ngIf="defaultaddress==1">
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.address_title">{{'Address Type' | translate}}:</span> {{guestDetails?.address_title}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.flat_no">{{'Flat' | translate}}:</span> {{guestDetails?.flat_no}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.building"> {{'Building' | translate}}:</span>{{guestDetails?.building}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.block_number"> {{'Block' | translate}}:</span>{{guestDetails?.block_number}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.road_number"> {{'Road' | translate}}:</span>{{guestDetails?.road_number}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.area">{{'Area' | translate}}:</span> {{guestDetails?.area}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.address_direction">{{'Directions' | translate}}:</span> {{guestDetails?.address_direction}}</p>
                    <p class="text-left"><span class="text-dark text-uppercase" *ngIf="guestDetails?.phone">{{'Mobile' | translate}}:</span> {{guestDetails?.phone}} </p>
                </ng-container>
                <ng-container *ngIf="defaultaddress==0">
                    <p class="text-left"><span class="text-dark text-uppercase">{{'Address' | translate}}:</span> {{defauladdressobject}}</p>
               </ng-container>
            </div>
            <div class="box py-2 px-3 text-center" *ngIf="(defauladdressobject | json) == '{}' && userIs==userType.LOGGED_USER">
                <button class="btn-primary btn mobilebuttonaddanaddress text-uppercase" data-toggle="modal" data-target="#addressMap">{{'Please Add an Address to Continue' | translate}}</button>
            </div>
            
        </div>
        <!--Delivery type-->
        <div class="ordersummary rounded border mb-4" style="min-height: 100px;">
            <h5 class="border-bottom py-2 px-3 text-left">{{'Delivery Type' | translate}}</h5>
            <div class="col col-md-6 my-1 text-left dir">
                <label class="m-0 radiobutton col-md-12">
                    <input class="filterCheckBox1 filterCheckBox2" 
                        type="radio" name="door-delivery"
                        value="1" [checked]="true" readonly>
                    <span class="checkmark"></span>
                    <span class="pl-2 text-dark">{{'Door Delivery' | translate}}</span>
                </label>
            </div>
        </div>
        <!--Coupon-->
        <div class="ordersummary rounded border mb-4" style="min-height: 100px;">
            <h5 class="border-bottom py-2 px-3 text-left">{{'Apply Coupon' | translate}}
                <span class="pull-right gridbuttonsabsolute">
                    <button class="btn btn-primary btn-sm text-uppercase arfont-14" (click)="viewAllCoupons()">{{'View all coupons code' | translate}}</button>&nbsp;
                </span>
            </h5>
            <div class="col col-md-6 my-1 text-left dir mb-5">
                <div class="custom-input">
                    <input id="coupon-input" type="text" class="coupon-input" [(ngModel)]="couponCode" placeholder="Enter Coupon Code">
                    <button class="custom-apply-botton" type="button" (click)="applyCode()" [disabled]="couponCode==''">Apply</button>
                    <button class="custom-remove-botton" type="button" (click)="removeCoupon(couponCode, staticbooleanKey.True)" *ngIf="couponOfferAmount" >Remove</button> 
                  </div>                    
             </div>
        </div>
        <!--Delivery instructions-->
        <div class="ordersummary rounded border mb-4" style="min-height: 100px;">
            <h5 class="border-bottom py-2 px-3 text-left">{{'Delivery Instructions' | translate}}</h5>
            <div class="row my-1 text-left dir p-2">
                <ng-container *ngFor="let instruction of restaurantcharges['delivery_instruction']; let i = index;">
                <div class="col-3 col-md-3 col-sm-6"  (click)="selectUserInstruction(instruction)">
                    <div class="cus-instruction-card card card{{instruction.id}}"
                        [ngClass]="userInstructions?.indexOf(instruction?.id)!=-1?'cus-card-border':''">
                         <div class="card-body">
                            <div>
                                <div *ngIf="instructions['LEAVE_THE_DOOR'] == instruction?.instruction">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                                        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5"/>
                                      </svg>
                                </div>
                                <div *ngIf="instructions['AVOID_RINGING_BELL'] == instruction?.instruction">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-slash-fill" viewBox="0 0 16 16">
                                        <path d="M5.164 14H15c-1.5-1-2-5.902-2-7 0-.264-.02-.523-.06-.776zm6.288-10.617A4.988 4.988 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113l9.73-9.73M10 15a2 2 0 1 1-4 0zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75z"/>
                                      </svg>
                                </div>
                                <div *ngIf="instructions['AVOID_CALLING'] == instruction.instruction" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-minus-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5"/>
                                      </svg>
                                </div>
                                <div *ngIf="instructions['LEAVE_WITH_SECURITY'] == instruction?.instruction">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                                        <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                      </svg>
                                </div>
                            </div>
                                <p>{{instruction?.instruction}}</p>
                        </div>
                            
                    </div>
                </div>
                        
                    </ng-container>
             </div>
        </div>
        <div class="ordersummary rounded border mb-4">
            <h6 class="border-bottom py-2 px-3 text-left">{{'Add cooking Instructions' | translate}}</h6>
            <div class="row py-2 px-3">
                <div class="col-md-6 ">
                    <textarea class="form-control" placeholder="{{'Anything you would like to request the restaurant or the rider' | translate}}" id="additionalcomments"></textarea>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="ordersummary rounded border mb-4">
                    <h5 class="border-bottom py-2 px-3 pl-3 text-left">{{'Choose Payment Method' | translate}}</h5>
                    <div class="row p-4  mobilepad">
                        <div class="nav flex-column nav-pills col-md-4 col-4" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a class="nav-link active paymentoptionnav text-left" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">{{'Pay on Delivery' | translate}}</a>
                        </div>
                        <div class="tab-content col-md-8 col-8 text-left" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <div class="row">
                                    <div class="col-md-3 hidden-xs">
                                        <img src="assets/images/common/cashpaymenticon.png" width="80" class="img-fluid" alt="Eatzilla - Payment Options" title="Eatzilla - Payment Options">
                                    </div>
                                    <div class="col-md-9">
                                        <h6>{{'Cash Payment on Delivery' | translate}}</h6>
                                        <p>{{'Please keep exact change handy to help us serve you better' | translate}}</p>
                                        <button class="btn btn-primary btn-block text-uppercase" (click)="paybyCash()">{{'Place Order' | translate}}
                                            {{currency}} {{total.toFixed(2)}}</button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <div class="row">
                                    <div class="col-md-3 hidden-xs">
                                        <img src="assets/images/common/benefit1.png" width="80" alt="Eatzilla - Benefit Payment Gateway">
                                    </div>
                                    <div class="col-md-9">
                                        <h6 class="text-success"><strong><span class="fa fa-lock"></span> HTTPS://</strong></h6>
                                        <p>{{'You will be redirected to a secure HTTP(s) page where you will be able to complete the BENEFIT payment for your order.' | translate}}</p>
                                        <button class="btn btn-primary btn-block" (click)="paywithBenefit()">{{'Place Order' | translate}} {{currency}}
                                            {{total.toFixed(2)}}</button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">

                                <div class="row">
                                    <div class="col-md-3 hidden-xs">
                                        <img src="assets/images/common/paymentoptions_vertical.png" width="80" alt="Eatzilla - Credit Card Options">
                                    </div>
                                    <div class="col-md-9">
                                        <h6 class="text-success"><strong><span class="fa fa-lock"></span> HTTPS://</strong></h6>
                                        <p>{{'You will be redirected to a secure HTTP(s) page where you will be able to complete the CREDIT CARD payment for your order.' | translate}}</p>
                                        <button class="btn btn-primary btn-block" (click)="paybyCreditCard()">{{'Place Order' | translate}} {{currency}}
                                            {{total.toFixed(2)}}</button>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="ordersummary rounded border mb-4 text-left">
                    <h6 class="border-bottom py-2 px-3">{{'Payment Summary' | translate}}</h6>
                    <div class="py-2 px-3">
                        <div class="summary px-2">
                            <h6>
                                <span class="pull-left">{{'Item Total' | translate}}</span>
                                <span class="pull-right text-dark text-right"> {{currency}}
                                    {{commonhelper.dataChangeToNumber(subtotal)?.toFixed(2)}}</span>
                                <div class="clearfix"></div>
                            </h6>
                            <h6 *ngIf="discountamount>0">
                                <span class="pull-left">{{'Discount' | translate}}</span>
                                <span class="pull-right text-success text-right">- {{currency}}
                                    {{commonhelper.dataChangeToNumber(discountamount)?.toFixed(2)}}</span>
                                <div class="clearfix"></div>
                            </h6>
                            <h6 *ngIf="deliverycharge>0">
                                <span class="pull-left">{{'Delivery Fee' | translate}}</span>
                                <span class="pull-right text-dark text-right"> {{currency}} {{commonhelper.dataChangeToNumber(deliverycharge)?.toFixed(2)}}</span>
                                <div class="clearfix"></div>
                            </h6>
                            <h6 *ngIf="packagingcharge>0">
                                <span class="pull-left">{{'Packaging charge' | translate}}</span>
                                <span class="pull-right text-dark text-right"> {{currency}}
                                    {{commonhelper.dataChangeToNumber(packagingcharge)?.toFixed(2)}}</span>
                                <div class="clearfix"></div>
                            </h6>
                            <h6 *ngIf="restaurantcommision>0">
                                <!-- restaurant commission->taxes -->
                                <span class="pull-left">{{'Taxes' | translate}}</span> 
                                <span class="pull-right text-dark text-right"> {{currency}}
                                    {{commonhelper.dataChangeToNumber(restaurantcommision)?.toFixed(2)}}</span>
                                <div class="clearfix"></div>
                            </h6>
                            <h6 *ngIf="couponOfferAmount>0">
                                <!-- restaurant commission->taxes -->
                                <span class="pull-left"> {{'Coupon discount' | translate}}</span> 
                                <span class="pull-right text-success text-right">- {{currency}}
                                    {{commonhelper.dataChangeToNumber(couponOfferAmount)?.toFixed(2)}}</span>
                                <div class="clearfix"></div>
                            </h6>
                            <hr>
                            <h6 class="bold">
                                <span class="pull-left text-dark bold">{{'Total' | translate}}</span>
                                <span class="pull-right text-dark text-right bold"> {{currency}}
                                    {{total.toFixed(2)}}</span>
                                <div class="clearfix"></div>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<div class="modal fade" id="editaddressmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Add Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-left">
                <form name="form" (ngSubmit)="addaddressform.form.valid && addaddresssubmit(addaddressform)" #addaddressform="ngForm" novalidate>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Area' | translate}} <small class="text-danger">*</small></label>
                            <input type="text" class="form-control" placeholder="{{'Search for your location' | translate}}" autocomplete="off" name="location" [(ngModel)]="addaddress.area" #location="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && location.invalid }"
                            (focusin)="onChangeArea($event)" required>
                            <div *ngIf="addaddressform.submitted && location.invalid" class="invalid-feedback">
                                <div *ngIf="location.errors.required">{{'Location is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="adress">{{'Address Type' | translate}} <small class="text-danger">*</small></label>
                            <div class="flex justify-around item-center">
                                <ng-container *ngFor="let type of addressTypes; let i = index;">
                                    <label class="m-0 radiobutton">
                                    <input name="address" type="radio" id="address{{i}}" value="{{type}}" (click)="chooseAddressType($event, i)"
                                    class="check" autocomplete="off" [checked]="selectedAddressType==type" [ngClass]="{'checked':selectedAddressType==type}">
                                    <span class="checkmark"></span>
                                    <span class="pl-2">{{type}}</span>
                                   </label>
                                    </ng-container>
                            </div>
                            <div *ngIf="addaddressform.submitted" class="addresstype-invalid">
                                <div *ngIf="selectedAddressType==''">{{'Address Type is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Block Number' | translate}} </label>
                            <input type="text" class="form-control" placeholder="{{'Enter your Block Number' | translate}}" autocomplete="off" name="blocknumber" [(ngModel)]="addaddress.blocknumber" #blocknumber="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && blocknumber.invalid }"
                               >
                            <div *ngIf="addaddressform.submitted && blocknumber.invalid" class="invalid-feedback">
                                <div *ngIf="blocknumber.errors.required">{{'Block Number is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="staticEmail">{{'Road Number' | translate}} </label>
                            <input type="text" class="form-control" placeholder="{{'Enter the Road Number' | translate}}" autocomplete="off" name="road_number" [(ngModel)]="addaddress.road_number" #road_number="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && road_number.invalid }"
                               >
                            <div *ngIf="addaddressform.submitted && road_number.invalid" class="invalid-feedback">
                                <div *ngIf="road_number.errors.required">{{'Road Number is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Building Number' | translate}}</label>
                            <input type="text" class="form-control" placeholder="{{'Enter the Building Number' | translate}}" autocomplete="off" name="building" [(ngModel)]="addaddress.building" #building="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && building.invalid }"
                               >
                            <div *ngIf="addaddressform.submitted && building.invalid" class="invalid-feedback">
                                <div *ngIf="building.errors.required">{{'Building Number is required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label for="staticEmail">{{'Flat Number/Office Number' | translate}}</label>
                            <input type="text" class="form-control" placeholder="{{'Enter the Flat Number/Office Number' | translate}}" autocomplete="off" name="flat_no" [(ngModel)]="addaddress.flat_no" #flat_no="ngModel" [ngClass]="{ 'is-invalid': addaddressform.submitted && flat_no.invalid }"
                                >
                            <div *ngIf="addaddressform.submitted && flat_no.invalid" class="invalid-feedback">
                                <div *ngIf="flat_no.errors.required">{{'Flat or Office Number is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="staticEmail">{{'Address Directions' | translate}} <small
                                class="text-danger">*</small></label>
                            <input type="text" class="form-control" placeholder="{{'Directions to help the rider' | translate}}" autocomplete="off" name="landmark" [(ngModel)]="addaddress.landmark" #landmark="ngModel"
                             [ngClass]="{ 'is-invalid': addaddressform.submitted && landmark.invalid }" required minlength="5">
                            <div *ngIf="addaddressform.submitted && landmark.invalid" class="invalid-feedback">
                                <div *ngIf="landmark.errors.required">{{'Directions is required' | translate}}</div>
                                <div *ngIf="landmark.errors.minlength">{{'Direction must be at least 5 characters' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-block text-uppercase" type="submit">{{'Save Address' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addressMap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel" *ngIf="defaultaddress==1">{{'Add new Address' | translate}}</h5>
                <h5 class="modal-title text-uppercase" id="exampleModalLabel" *ngIf="defaultaddress==0">{{'Location' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-googlemap [id]="'cart1'" [componenttype]="componenttype" [setaddressinput]="setaddressinput" [latitudeinput]="latitudeinput" [longtitudeinput]="longtitudeinput"
                [title]="'Confirm'" (addressconfirmation)="addressConfirmation($event)"></app-googlemap>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="existingdeliveryaddress" *ngIf="allAvailableDeliveryAddress.length>0" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2 text-left">
            <form name="form" (ngSubmit)="selectadd.form.valid && selectedexistingaddform(selectadd)" #selectadd="ngForm" novalidate>
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{'Choose Existing Delivery Address' | translate}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="allAvailableDeliveryAddress.length>0">
                        <div style="width: 80%; margin: 0 auto;" >
                            <label>{{'Select from Existing Address' | translate}}</label>
                            <select class="form-control" name="selectedaddress" [(ngModel)]="allAvailableDeliveryAddress[0]" #selectedaddress="ngModel" [ngClass]="{ 'is-invalid': selectadd.submitted && selectedaddress.invalid }" required>
                                <option [ngValue]="daddress" selected
                                    *ngFor="let daddress of allAvailableDeliveryAddress">{{daddress.address_title}}
                                    ({{daddress.address}})</option>
                            </select>
                            <div *ngIf="selectadd.submitted && selectedaddress.invalid" class="invalid-feedback">
                                <div *ngIf="selectedaddress.errors.required">Please select an address to continue</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary pull-right px-5 text-uppercase">{{'Confirm' | translate}}</button>
                    <button type="button" class="btn btn-light pull-right px-5 text-uppercase" data-dismiss="modal">{{'Cancel' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
<!--Coupon code list-->
<div class="modal fade" id="couponsList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-success" id="exampleModalLabel">{{'EATZILLA' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-left">
                <div *ngIf="couponsList.length>0">
                   <ng-container *ngFor="let coupon of couponsList">
                        <div class="ordersummary rounded border mb-4" style="min-height: 100px;" (click)="addCoupon(coupon?.code)">
                            <h5 class="border-bottom py-2 px-3 text-left">{{coupon?.code | translate}}
                                <span class="pull-right gridbuttonsabsolute" >
                                    <button class="cus-text-bolder cus-font-size btn btn-primary text-uppercase arfont-14">{{'Apply' | translate}}</button>
                                </span>
                            </h5>
                            <div class="box py-2 px-3 dir color-black">
                                <p *ngIf="coupon?.coupon_type===1 && coupon?.offer_type===0">Use code {{coupon?.code}} & get {{coupon?.value}}% up to {{currency}}{{coupon?.max_amount}}.</p>
                                <p *ngIf="coupon?.coupon_type===1 && coupon?.offer_type===1">Use code {{coupon?.code}} & get {{currency}}{{coupon?.value}} flat off.</p>
                                <p *ngIf="coupon?.coupon_type===2 && coupon?.offer_type===1">Use code {{coupon?.code}} & get {{currency}}{{coupon?.value}} flat off on orders above {{currency}}{{coupon?.coupon_value}}.</p>
                                <p *ngIf="coupon?.coupon_type===2 && coupon?.offer_type===0">Use code {{coupon?.code}} & get {{coupon?.value}}% off an order above {{currency}}{{coupon?.coupon_value}}<span>&nbsp;•&nbsp;</span>Maximum Discount {{currency}} {{coupon?.max_amount}}</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="couponsList.length<=0" class="no-coupons">
                    <div class="text-center">
                        <img src="assets/images/no-data/no-coupon-data.png" alt="coupon-list">
                    </div>
                    <div class="text-center mt-4">
                        <p>
                            Exciting New Offers are coming your way!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Guest user edit-->
<div class="modal fade" id="guestuseredit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="guestDetails">
    <div class="modal-dialog guest-login-modal" role="document">
        <div class="modal-content p-3">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'Edit Address' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body text-left">
                <form name="form" (ngSubmit)="guestUseredit.form.valid && guestUserAddresschange(guestUseredit)" #guestUseredit="ngForm" novalidate>
                    <div class="form-group">
                        <label for="name">{{'Name' | translate}} <small class="text-danger">*</small></label>
                        <input type="text" name="name" class="form-control" [(ngModel)]="guestDetails['name']" #name="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && name.invalid }" required placeholder="{{'Enter your Name' | translate}}"
                        minlength="3" pattern="(?! )[A-Za-z\s]{1,}" maxlength="25">
                        <div *ngIf="guestUseredit.submitted && name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{'Name is required' | translate}}</div>
                            <div *ngIf="name.errors.minlength && !name.errors.pattern">{{'Name must be at least 3 characters' | translate}}</div>
                        <div *ngIf="name.errors.pattern">{{'Enter valid name. Letters and space only allowed.' | translate}}</div>
                        <div *ngIf="name.errors.maxlength && !name.errors.pattern">{{'Maximum 25 letters allowed' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="phonenumber">{{'Phone Number' | translate}} <small class="text-danger">*</small></label>
                        <input type="text" name="phoneguestuseredit" class="form-control phoneguestuseredit" [(ngModel)]="guestDetails['phone']" #phoneguestuseredit="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && phoneguestuseredit.invalid }" required readonly>
                    </div>
                    <div class="form-group">
                        <label for="adress">{{'Address Type' | translate}} <small class="text-danger">*</small></label>
                        <div class="flex justify-around item-center">
                        <ng-container *ngFor="let type of addressTypes; let i = index;">
                        <label class="m-0 radiobutton">
                        <input name="address" type="radio" id="guestaddress{{i}}" value="{{type}}" (click)="chooseAddressType($event, i)"
                        class="check" [checked]="type==selectedAddressType" #addressTypeChecks>
                        <span class="checkmark"></span>
                        <span class="pl-2">{{type}}</span>
                       </label>
                        </ng-container>
                        </div>
                        <div *ngIf="guestUseredit.submitted" class="addresstype-invalid">
                            <div *ngIf="selectedAddressType==''">{{'Address Type is required' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="area">{{'Area' | translate}} <small class="text-danger">*</small></label>
                        <input autocomplete="off" type="text" name="area" class="form-control" [(ngModel)]="guestDetails['area']" #area="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && area.invalid }" required placeholder="{{'Area' | translate}}"
                           (focusin)="onChangeArea($event)" >
                        <div *ngIf="guestUseredit.submitted && area.invalid" class="invalid-feedback">
                            <div *ngIf="area.errors.required">{{'Area is required' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="block-number">{{'Block Number' | translate}}</label>
                        <input autocomplete="off" type="text" name="block" class="form-control" [(ngModel)]="guestDetails['block_number']" #block="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && block.invalid }" placeholder="{{'Block number' | translate}}"
                          >
                    </div>
                    <div class="form-group">
                        <label for="road-number">{{'Road Number' | translate}}</label>
                        <input autocomplete="off" type="text" name="road" class="form-control" [(ngModel)]="guestDetails['road_number']" #road="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && road.invalid }" placeholder="{{'Road Number' | translate}}"
                          >
                    </div>
                    <div class="form-group">
                        <label for="building-number">{{'Building Number' | translate}}</label>
                        <input autocomplete="off" type="text" name="building" class="form-control" [(ngModel)]="guestDetails['building']" #building="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && building.invalid }" placeholder="{{'Building Number' | translate}}"
                          >
                    </div>
                    <div class="form-group">
                        <label for="flat-number">{{'Flat Number' | translate}}</label>
                        <input autocomplete="off" type="text" name="flat" class="form-control" [(ngModel)]="guestDetails['flat_no']" #flat="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && flat.invalid }" placeholder="{{'Flat Number' | translate}}"
                          >
                    </div>
                    <div class="form-group">
                        <label for="direction">{{'Address Directions' | translate}} <small class="text-danger">*</small></label> 
                        <input autocomplete="off" type="text" name="direction" class="form-control" [(ngModel)]="guestDetails['address_direction']" #direction="ngModel" [ngClass]="{ 'is-invalid': guestUseredit.submitted && direction.invalid }" required placeholder="{{'Address directions' | translate}}"
                          minlength="5">
                        <div *ngIf="guestUseredit.submitted && direction.invalid" class="invalid-feedback">
                            <div *ngIf="direction.errors.required">{{'Address Direction is required' | translate}}</div>
                            <div *ngIf="direction.errors.minlength">{{'Direction must be at least 5 characters' | translate}}</div>
                        </div>
                    </div>
                    
                    <div class="clearfix"></div>
                    <br>
                    <button type="submit" class="btn btn-primary btn-block">{{'Continue' | translate}}</button>
                </form>
    
            </div>
         </div>
    </div>
</div>
<div class="modal fade" id="addressConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel">{{'Confirmation' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to place your order on this Address?</p>
                <p>{{defauladdressobject}}</p>
            </div>
            <div class="modal-footer flex justify-around item-center">
                <button class="btn btn-primary pull-right px-5 text-uppercase" (click)="confirmDefaultAddress()" >{{'Yes' | translate}}</button>
                <button class="btn btn-primary pull-right px-5 text-uppercase" type="button" data-dismiss="modal" type="button" >{{'No' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>