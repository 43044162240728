import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Meta, Title } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
  restaurantlist: any = {};
  term:any;
  startloop = 1;
  translatedstr: string;
  tranlsatedtitle: any;
  tranlsateddescription: any;
  urilang: string;
  constructor(
    public router: Router,
    private apiconnectService: ApiconnectService,
    private titleService: Title,
    private meta: Meta,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService
  ) {
    this.checkDevice();
    this.translate.get('EATZILLA Food Delivery in Middle East').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Find out Multiple cuisines and your favorite restaurant on Eatzilla Now. Order your favorite American, Mexican, Pakistani, Bahraini, Indian, Italian, Lebanese or fast food with Eatzilla').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });
    
    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription});
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
  }
  mobilecheck: boolean;
  deviceInfo = null;
  devicetype: any;
  browser: any;
  checkDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.devicetype = this.deviceInfo.os;
    this.browser = this.deviceInfo.browser;
    const isDesktopDevice = this.deviceService.isDesktop();
    if (!isDesktopDevice) {
      this.mobilecheck = true;
      window.scroll(0, 0);
    }
  }
  ngOnInit(): void {
    window.scroll(0, 0);
    this.getRestaurants();
  }

  getRestaurants() {
    $('.loading').show();
    this.apiconnectService.getRequest("/get_banners?device_type=web").subscribe(
    (data) => {
        this.restaurantlist = data;
        if (this.restaurantlist.status) {
          $('.loading').hide();
        }
        else {
          this.restaurantlist = false;
          $('.loading').hide();
        }
      },
      err => {
        this.router.navigate([this.urilang+'home']);
        console.error(err);
      }
    );
  }
  redirecttorestaurant(data){
    console.log(data);
    var restaurantname = data.restaurant_name.toLowerCase();
    restaurantname = restaurantname.split(' ').join('-');
    this.router.navigate([this.urilang+ "restaurant/"+data.restaurant_id+"/"+restaurantname]);
  }
}
