import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from "../../../globals";
import { ApiconnectService } from 'src/app/services/apiconnect.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  popularBrands: any = {}
  alttitle:any;
  urilang: string;
  constructor(public router:Router,private apiconnectService: ApiconnectService,private globals: Globals,) { 
    this.alttitle = this.globals.imagealt;
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getPopularBrands();
  }
  getPopularBrands() {
    this.popularBrands = JSON.parse(localStorage.getItem("popularBrands")) != null ? JSON.parse(localStorage.getItem("popularBrands")) : [];
    const lati = JSON.parse(localStorage.getItem('lat'));
    const lng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    if(lati && lng)return
    this.apiconnectService.getRequest("/get_popular_brands?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken') + "&lat=" + lati + "&lng=" + lng).subscribe(
      data => {
        this.popularBrands = data;
      },
      err => {
        console.log(err)
      }
    )
  }
}
