<div class="modal-dialog user-login" role="document">
    <div class="modal-content p-3">
        <div class="guest-user-btn-container"><button type="button" class="btn btn-primary guest-user-btn" (click)="openMapBox()">{{'Guest User' | translate}}</button></div>
       <div class="modal-header">
           <h5 class="modal-title" id="exampleModalLabel">{{'Login or Register' | translate}}</h5>
             <button type="button" class="close"  aria-label="Close" (click)="closeLoginBox(login,'error-msg')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <form name="form" (ngSubmit)="login.form.valid && loginUser(login)" #login="ngForm" novalidate>
                <div class="form-group dir text-left">
                    <label for="phone">{{'Phone Number' | translate}}<small class="text-danger">*</small></label>
                    <div class="clearfix"></div>
                    <input type="text" name="phone" class="form-control phoneusernamelogin" [(ngModel)]="model.phone" #phone="ngModel" [ngClass]="{ 'is-invalid': login.submitted && phone.invalid }" required autocomplete="off">
                    <small id="phoneHelp" class="form-text text-muted">{{'We will never share your phone number with anyone else.' | translate}}</small>
                    <span id="error-msg text-danger text-left" class="hide error"></span>
                    <input name="countrycode_login" id="countrycode_login" [(ngModel)]="selectedcountrycode" #countrycode_login="ngModel" type="hidden">
                    <input name="phonecode_login" id="phonecode_login" [(ngModel)]="selectedphonecode_login" #phonecode_login="ngModel" type="hidden">
                    <div id="error-msg" class="text-danger text-left"></div>
                    <div *ngIf="login.submitted && phone.invalid" class="phone-invalid">
                        <div *ngIf="phone.errors.required">{{'Kindly write your phone number to continue' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">{{'Password' | translate}}<small class="text-danger">*</small></label>
                    <div class="position-relative">
                        <input autocomplete="off" [type]="eyeView?'text':'password'" name="password" class="form-control" [(ngModel)]="model.password" #password="ngModel" [ngClass]="{ 'is-invalid': login.submitted && password.invalid }" 
                    required placeholder="{{'New Password' | translate}}" minlength="8">
                    <div class="eye-view"  (click)="eyeView = !eyeView">
                        <i class="fa fa-eye" aria-hidden="true" *ngIf="!eyeView && !(login.submitted && password.invalid)"></i>
                        <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="eyeView && !(login.submitted && password.invalid)"></i>
                    </div>
                    </div>
                    <div *ngIf="login.submitted && password.invalid" class="phone-invalid">
                        <div *ngIf="password.errors.required">{{'Password is required' | translate}}</div>
                        <div *ngIf="password.errors.minlength">{{'Password must be at least 8 characters' | translate}}</div>
                    </div>
                </div>
                <div class="form-check float-left dir">
                    <input type="checkbox" class="form-check-input" name="keeploggedin">
                    <label class="form-text text-muted m-0" for="keeploggedin">{{'Keep me Logged in' | translate}}</label>
                </div>
                <div class="float-right">
                    <a (click)="showForgotpasswordbox(login, 'error-msg')">{{'Forgot Password?' | translate}}</a>
                </div>
                <div class="clearfix"></div>
                <br>
                <button type="submit" class="btn btn-primary btn-block">{{'Login' | translate}}</button>
            </form>
        </div>
        <div class="modal-footer" style="display: block;">
            <h3 class="text-center">{{'Dont have an account?' | translate}} <a (click)="showRegisterbox(login, 'error-msg')">{{'Create an account' | translate}}</a></h3>
        </div>
    </div>
</div>

<div class="modal-dialog addressmap-guest" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel">{{'Select Your Area' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closemapBox()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-googlemap [id]="'login1'" [componenttype]="componenttype" [setaddressinput]="setaddressinput" [latitudeinput]="latitudeinput" [longtitudeinput]="longtitudeinput"
                [title]="'Confirm'" (addressconfirmation)="addressConfirmation($event)"></app-googlemap>
            </div>
        </div>
    </div>

<div class="testytesty getareatoaster" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">
    <div class="toast-body">
     Select Area
   </div>
 </div>


