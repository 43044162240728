import { Component, OnInit, NgZone } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { EMAIL, NUMBER_ONLY } from 'src/app/constants/pattern.constant';
import { CommonHelper } from 'src/app/common/helper';
declare var window: any;
declare var $: any;
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  tranlsatedtitle: any;
  tranlsateddescription: any;
  urilang: string;
  public model: any = {};
  public emailPattern = EMAIL;
  public selectedcountrycode: any;
  public selectedphonecode:any;

  /**
   * constructor
   * @param mapsAPILoader 
   * @param ngZone 
   * @param titleService 
   * @param meta 
   * @param translate 
   * @param commonHelper 
   */
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService,
    private commonHelper: CommonHelper,
  ) {
    this.translate.get('Contact Us | EATZILLA Free Food Delivery anywhere in Middle East').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Have questions? Want to talk to support? We are a team of experts, who are passionate to make your Free food deliveries fast & easy | EATZILLA Middle East').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });
    
    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription  });
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.phonenumberdropdown('contactusphone','countrycode_contact','error-msg-contact');
  }

  /**Not completed
   * contact us form submit
   * @param {NgForm} formData 
   */
  public formSubmit(formData:NgForm){
   const payload = {
     ...formData['form'].value,
     phone:$('#phonecode_contact').val() + formData.value.phone,
   }
  }

    /**
   * Phonenumberdropdown
   * @param {string} querySelector 
   * @param {any} countrycode 
   * @param {string} error 
   * @returns {any} phonenumberdropdown 
   */
    private async phonenumberdropdown(querySelector,countrycode,error) : Promise<void> {
      const phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
      const phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
      const input = document.querySelector("."+querySelector),
        hiddenintputfield = document.querySelector("#"+countrycode),
        errorMsg = document.querySelector("#"+error);
        $(".iti__flag-container").remove();
      const iti = window.intlTelInput(input, {
        excludeCountries: ["il"],
        hiddenInput: "full",
        preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
        separateDialCode: true,
        initialCountry: 'bh',
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
        customPlaceholder: function (selectedCountryPlaceholder: any) {
        return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
      },
      });
      const reset = function () {
        input.classList.remove("error");
        errorMsg.innerHTML = "";
        errorMsg.classList.add("hide");
      };
  
      // on blur: validate
      input.addEventListener('blur', async function () {
        reset();
        this.values = input;
        const numberOnly = NUMBER_ONLY;
        if (this.values.value.trim()) {
          if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
            var countryData = iti.getSelectedCountryData();
            this.values = hiddenintputfield;
            this.values.value = countryData.iso2;
            this.selectedcountrycode = countryData.iso2;
            this.selectedphonecode = countryData.dialCode;
            $('#countrycode_contact').val(countryData.iso2);
            $('#phonecode_contact').val(countryData.dialCode);
            this.phonecode = countryData.dialCode;
  
          } else {
            input.classList.add("error");
            errorMsg.innerHTML = phoneplaceholdererror;
            errorMsg.classList.remove("hide");
          }
        }
      });
  
      // on keyup / change flag: reset
      input.addEventListener('change', reset);
      input.addEventListener('keyup', reset);
    }
}
