import { Component, ElementRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { CommonHelper } from 'src/app/common/helper';
import { ORDER_DETAILS, RATING_ERROR_TOASTER, RATING_STAR } from 'src/app/constants/profile-page.constant';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from 'src/globals';
declare var $: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-order-detail-list',
  templateUrl: './order-detail-list.component.html',
  styleUrls: ['./order-detail-list.component.scss']
})

/**
 * Component
 */
export class OrderDetailListComponent implements OnInit, OnChanges{
public orderHistoryResponse: any;
public orderdetailspopup: any;
public urilang:string;
public currency:string;
public reorderFoodDetail = {};
public restaurantDetails = {};
public selectedFood = {};
public localCartItem = [];
@Input() tabKey:any = 0;
public tab = ORDER_DETAILS;
public restaurantData = '';
starWidth = 0;
public restaurantRating = 0;
public suggestions = '';
public deliveryBoyRating = 0;
public ratingStars = RATING_STAR;
public ratingToasterErrorMsg = '';
@ViewChildren("ratingStarInputs") ratingStarInputs: QueryList<ElementRef>;
public ratingOrderRequestId = '';
@ViewChildren("addressTypeChecks") addressTypeChecks: QueryList<ElementRef>;
@Input() loggedIn = false;

  constructor(
    private apiconnectService: ApiconnectService,
    private router: Router,
    private globals: Globals,
    public commonhelper:CommonHelper,
  ) { 
    const lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals.currency_ar;
    }
    else {
      this.urilang = '';
      this.currency = this.globals.currency;
    }
  }

  ngOnInit(): void {
    $('.modal-backdrop').remove();
    this.orderHistory();
    $('.loading').show();
    }

/**
 * order history
 */
  public orderHistory(){
    this.apiconnectService.getRequest("/order_history?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
      this.orderHistoryResponse = data;
       $('.loading').hide();
      },
      err => {
        console.log(err)
      }
    );
  }

  /**
   * view order details
   * @param {object} orders 
   */
  public viewOrderDetails(orders) {
    this.orderdetailspopup = orders;
    $('#orderDetailsdiv').modal('show');
  }

 /**
  * Track orders
  * @param {id} request_id 
  */
 public trackmyorder(request_id){
    this.router.navigate([this.urilang+'trackorder/'+request_id]);
  }

  /**
 * re order
 * @param data 
 */
public reorder(data:any){
  if(!data?.restaurant_id)return;
  this.reorderFoodDetail = data;
  localStorage.setItem('restaurant_id',JSON.stringify(data?.restaurant_id))
  localStorage.removeItem('cart');
  this.getRestaurantDetails(data?.restaurant_id);
  this.mapReorderedFood();
}

/**
 * get single restaurant details
 * @param {number} id 
 */
getRestaurantDetails(id) {
  this.apiconnectService.postRequest({
    restaurant_id: id,
    device_type: 'web',
    veg_only: 0,
  }, "/single_restaurant?lat=" + localStorage.getItem('lat') + "&lng=" + localStorage.getItem('lng')).subscribe(async (data:any) => {
    this.restaurantDetails = data['restaurants'][0];
    localStorage.setItem('restaurantDetails',JSON.stringify(this.restaurantDetails))
    }, err => {
    console.log(err);
  });
  
}

/**
 * map reordered food
 * find with current details
 */
public mapReorderedFood(){
   this.reorderFoodDetail['item_list'].forEach((orderedItem)=>{
      this.addItem(orderedItem);
     })
 }

 /**
 * add item 
 * @param {object} item 
 * @param {object} orderedItem 
 */
public addItem(item) {
  this.selectedFood = Object.assign({}, item);
  this.selectedFood['price'] = 0;
  this.selectedFood['quantity'] = this.selectedFood['food_quantity'];
  this.selectedFood['choice_value_radio'] = 0;
  this.selectedFood['choice_value'] = 0;
  this.selectedFood['choosen'] = [];
  this.selectedFood['choosen_radio'] = [];
  if(item['add_ons'].length>0){
  item['add_ons']?.forEach((choice)=>{
    if(choice){
      this.selectedFood['choosen'].push({ name: choice.name, id: choice.addons_id, choice_category_id: choice.choice_category_id });
      this.selectedFood['choice_value'] = this.selectedFood['choice_value'] + choice?.price;
     }
   });
  this.addItemToLocalCart()
}else{
  this.addItemToLocalCart()
}
}

 /**
  * add item to local
  */
public addItemToLocalCart = () => {
  let payLoad = {
    item_id: this.selectedFood['food_id'],
    item_name: this.selectedFood['food_name'],
    item_description: this.selectedFood['description'],
    price: (this.selectedFood['original_item_price'] + this.selectedFood['choice_value'])*this.selectedFood['quantity'],
    itemTotal: this.selectedFood['original_item_price'],
    quantity: this.selectedFood['quantity'],
    choice_value: this.selectedFood['choice_value'],
    choice_value_radio: this.selectedFood['choice_value_radio'],
    alreadyFound: true,
    is_veg: this.selectedFood['is_veg'],
    discount_type: this.selectedFood['discount_type'],
    offer_amount: this.selectedFood['offer_amount'],
    item_tax: this.selectedFood['tax'],
    itemUniqueId: Math.floor(Math.random() * 99980),
    choosen: this.selectedFood['choosen'],
    choosen_radio: this.selectedFood['choosen_radio'],
    food_percentage_offer: this.selectedFood['food_percentage_offer']?this.selectedFood['food_percentage_offer']:0,
  };
  this.localCartItem.push(payLoad);
  localStorage.setItem('cart', JSON.stringify(this.localCartItem));
  this.router.navigate(["menu-details/" + this.reorderFoodDetail['restaurant_id'] + "/" + this.reorderFoodDetail['restaurant_name']]);
}

/**
   * view rating modal
   * @param {string} data 
   */
public viewRatingModal(data){
  this.restaurantData = data;
  this.restaurantRating = 0;
  this.deliveryBoyRating = 0;
  this.suggestions = '';
  this.removeRatings();
  $('#rating').modal('show');
  this.ratingOrderRequestId = data?.request_id;
}

/**
 * rating value fetching
 * @param {number} rate 
 * @param {string} key 
 */
public starRating(rate, key){
  switch(key){
   case this.ratingStars.FULL_STAR:
     this.restaurantRating = rate;
     break;
   case this.ratingStars.D_STAR_F:
     this.deliveryBoyRating = rate;
     break;
     default:
       this.restaurantRating = rate;
       this.deliveryBoyRating = rate;
 
  }
 }

 /**
 * confirm rating
 * @param {any} id
 */
public confirmRating(id:any){
  this.ratingToasterMessages(this.restaurantRating, this.deliveryBoyRating);
  if(!id || !this.restaurantRating || !this.deliveryBoyRating){
    return;
  }
  $('.loading').show();
   let datapost = {
      delivery_boy_rating: this.deliveryBoyRating.toString(),
      request_id: id,
      restaurant_feedback: this.suggestions,
      restaurant_rating: this.restaurantRating.toString(),
    }
    this.apiconnectService.postRequest(datapost, '/order_ratings').subscribe(
      async (data) => {
        if (data['status']) { // hide rate my order button
          if(this.ratingOrderRequestId){
            const item = this.orderHistoryResponse?.past_orders.find((x)=>x.request_id==this.ratingOrderRequestId)
            if(item){
             item['is_rated'] = 1;
            }
       }
          $('.loading').hide();
          $('#rating').modal('hide');
          Swal.fire({
            text: await this.commonhelper.translateText('You ratings successfully Added'),
            icon: 'success'
          });
         this.ratingOrderRequestId = '';
        }
        else {
          $('.loading').hide();
          Swal.fire({
            text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
            icon: 'error'
          });
        }
      },
      async err => {
        $('.loading').hide();
        Swal.fire({
          text: await this.commonhelper.translateText('Oops!! Something went wrong with your request. Please try again'),
          icon: 'error'
        });
        console.log(err);
      }
    );
  }

  /**
 * toaster message show
 * @param {number} restaurant 
 * @param {number} deliverboy 
 */
public ratingToasterMessages(restaurant, deliverboy){
  switch(false){
    case(restaurant>0):
    this.ratingToasterErrorMsg = RATING_ERROR_TOASTER.NO_RESTAURANT_RATING;
    $('.ratingToaster').toast('show');
    break;
    case(deliverboy>0):
    this.ratingToasterErrorMsg = RATING_ERROR_TOASTER.NO_DELIVERYBOY_RATING;
    $('.ratingToaster').toast('show');
    break;
    default:
      this.ratingToasterErrorMsg = '';
      break;
  }
}

/**
 * remove ratings
 * refresh rating stars
 */
public removeRatings(){
  this.ratingStarInputs.forEach((element) => {
    element.nativeElement.checked = false;
  });
}

/**
 * load when changes
 * @param {SimpleChanges} changes 
 */
ngOnChanges(changes: SimpleChanges): void {
  if(this.loggedIn){
    this.orderHistory();
  }
}
}
