import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  tranlsatedtitle: any;
  tranlsateddescription: any;
  urilang: string;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService
  ) { 
    this.translate.get('Terms and Conditions - EATZILLA Food Delivery in Middle East').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Eatzilla provides Food Delivery service in Middle East. From 5 star restaurants, fast food chains to street cafes, we’ve got it all for you. Click to know about us!').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });
    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription });
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
