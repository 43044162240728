import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  tranlsatedtitle: string;
  tranlsateddescription: string;
  urilang: string;
  constructor(
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService
  ) {
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
    
    this.translate.get('What is EATZILLA? - Checkout more About US').subscribe((data: any) => {
      this.tranlsatedtitle = data;
    });
    this.translate.get('Download EATZILLA free food delivery app on your iOS or Android device and order from your favorite restaurants.').subscribe((data: any) => {
      this.tranlsateddescription = data;
    });
    this.titleService.setTitle(this.tranlsatedtitle);
    this.meta.updateTag({ name: 'description', content: this.tranlsateddescription });
    this.meta.updateTag({
      property: 'og:title',
      content: this.tranlsatedtitle
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.tranlsateddescription
    });
  }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}
