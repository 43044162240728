import { Component, Input, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonHelper } from 'src/app/common/helper';
import { EMAIL, NUMBER_ONLY } from 'src/app/constants/pattern.constant';
import { DataEmitterService } from 'src/app/services/data-emit.service';
import { COMPONENTS } from 'src/app/constants/common';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  model: any = {};
  selectedphonecode:any;
  selectedcountrycode: any;
  registerResponse: any;
  translatedstr: string;
  urilang: string;
  public emailPattern = EMAIL;
  public isPasswordMatched = null;
  public registeringMobNum:any = null;
  public component = 'register';
  public registerDetails:any = null;
  public formDetails:any= null;
  public eyeView = false;
  public eyeViewConfirm = false;
  public alreadyVerifiedMobileNumber = null;

  /**
   * Constructor
   * @param apiconnectService 
   * @param translate 
   * @param commonHelper 
   */
  constructor(
    private apiconnectService: ApiconnectService,
     private commonHelper: CommonHelper,
     private dataEmitter: DataEmitterService,
     ) {
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
  }

  ngOnInit(): void {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
    localStorage.removeItem('registerCountry');
   }
  async phonenumberdropdown(queryselector,countryCode, error, phoneCode) {
    var phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
    var phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
    var input = document.querySelector("."+queryselector),
      hiddenintputfield = document.querySelector("#"+countryCode),
      errorMsg = document.querySelector("#"+error);
      $(".iti__flag-container").remove();
    var iti = window.intlTelInput(input, {
      excludeCountries: ["il"],
      hiddenInput: "full",
      preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
      separateDialCode: true,
      initialCountry: 'bh',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
      customPlaceholder: function (selectedCountryPlaceholder: any) {
        return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
      },
    });
    var reset = function () {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', async function () {
       reset();
      this.values = input;
      const numberOnly = NUMBER_ONLY;
      if (this.values.value.trim()) {
        if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
          var countryData = iti.getSelectedCountryData();
          this.values = hiddenintputfield;
          this.values.value = countryData.iso2;
          this.selectedcountrycode = countryData.iso2;
          this.selectedphonecode = countryData.dialCode;
          $("#"+countryCode).val(countryData.iso2);
          $('#'+phoneCode).val(countryData.dialCode);
        } else {
          input.classList.add("error");
          errorMsg.innerHTML = phoneplaceholdererror;
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }
  showRegisterbox() {
    $('#loginbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    $('#registerbox').modal('show');
  }
  /**
   * form data
   * @param formData 
   */
  showLoginbox(formData:NgForm) {
    $('#registerbox').modal('hide');
    formData.resetForm();
    $('#forgotpasswordbox').modal('hide');
    this.phonenumberdropdown('phoneusernamelogin','countrycode_login', 'error-msg', 'phonecode_login')
    $('#loginbox').modal('show');
  }
  showForgotpasswordbox() {
    $('#loginbox').modal('hide');
    $('#registerbox').modal('hide');
    $('#forgotpasswordbox').modal('show');
  }

  /**
   * register
   * @param {NgForm} formdata 
  */
  public registerUser(formdata?: NgForm) {
   $('.loading').show();
   let postdata = {
      first_name: formdata.value.fullname,
      phone: $('#phonecode_register').val()+formdata.value.registernumber,
      country_code: $('#countrycode_register').val(),
      email: formdata.value.email,
      password: formdata.value.password,
      confirm_password: formdata.value.confirmPassword,
      device_type: 'web',
      login_type:0,
    }
    if(postdata){
       this.registration(postdata, formdata)
    }
  }

  /**
   * otp verifications
   * @param {NgForm} formdata 
   */
  public otpverfication(formdata: NgForm){
    this.formDetails = formdata;
    this.registeringMobNum = $('#phonecode_register').val()+formdata.value.registernumber;
    if(this.registeringMobNum == this.alreadyVerifiedMobileNumber){
      this.registerUser(formdata);
    }else{
      this.getOTP(formdata);
    }
  }

  /**
   * Input setter --> after otp  verification
   */
  @Input() set mobVerified(data){
    if(data){
      if(data.verified && data.component==COMPONENTS.REGISTER && this.formDetails){
        this.registerUser(this.formDetails);
       }
    }
  }
 
  /**
   * hide modal back drop
   */
  public hideModalBackDrop(){
    $('.modal-backdrop').remove();
  }

  /**
   * close register box
   */
  public onCloseRegisterbox(form, phoneErr){
    const errMsg = document.querySelector('#'+phoneErr);
    errMsg.innerHTML = "";
    errMsg.classList.add("hide");
     $('.modal-backdrop').remove();
     $('#registerbox').modal('hide');
     $('#forgotpasswordbox').modal('hide');
     form.resetForm();
  }

  /**
   * get otp
   */
  public async getOTP(formdata){
    const postdata ={
      phone: this.registeringMobNum,
      device_type: 'web'
    }
    const errorMsg = document.querySelector("#error-msg-register");
    const mobno = $('#phonecode_register').val()+'-'+formdata.value.registernumber;
   if(formdata.value.password != formdata.value.confirmPassword || errorMsg.innerHTML !== '')return;
   if(this.registeringMobNum != null){
    $('.loading').show();
    this.apiconnectService.postRequestNoAuth(postdata, '/send_otp').subscribe(
      async (data) => {
        if (data['status'] && data['is_new_user'] == 1) {
          $('.loading').hide();
          this.dataEmitter.otpverificationSet({regNum:this.registeringMobNum, component:this.component, formData:formdata, mobVerificationOTP:data['otp'], mobToShow:mobno?mobno:postdata.phone});
          localStorage.setItem('registerCountry', JSON.stringify($('#countrycode_register').val()));
         }else if(data['status'] && data['is_new_user'] == 0){
          this.otpErrorMessage('Oops!! Mobile Number already exist', 'error')
         }else {
          this.otpErrorMessage(data['message'], 'error');
        }
      },
      async err => {
        Swal.fire({
          text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
          icon: 'error'
        });
        console.log(err);
      }
    );
  }
   }

      /**
    * otp error message show
    * @param err 
    */
  private async otpErrorMessage(err, status){
    if(err){
     $('#otpbox').modal('hide');
     $('.loading').hide();
     Swal.fire({
       text: await this.commonHelper.translateText(err),
       icon: status
     })
    }
    }

    /**
     * registration
     * @param postdata 
     * @param formdata 
     */
    public registration(postdata, formdata){
      this.apiconnectService.postRequestNoAuth(postdata, '/register').subscribe(
        async (data) => {
          $('.loading').hide();
          this.registerResponse = data;
          if (this.registerResponse.status) {
           this.registrationResponse(data, formdata);
          }
          else {
            $('.loading').hide();
            Swal.fire({
              text: this.registerResponse.message,
              icon: 'error'
            });
            $('#registerbox').modal('show');
            this.alreadyVerifiedMobileNumber = this.registeringMobNum;
          }
        },
        async err => {
          $('.loading').hide();
          Swal.fire({
            text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
            icon: 'error'
          });
          console.log(err);
          this.formDetails = null;
        }
      );
    }

    /**
     * registration
     */
    public async registrationResponse(data, formdata){
      if(data['status']){
        this.alreadyVerifiedMobileNumber = null;
      this.formDetails = null;
      this.registeringMobNum = null;
      formdata.resetForm();
      this.eyeView = false;
      $('#registerbox').modal('hide');
      $('#forgotpasswordbox').modal('hide');
      localStorage.removeItem('registerCountry');
      Swal.fire({
        text: await this.commonHelper.translateText('You have been successfully registered. Please provide your Phone number and password in order to login.'),
        icon: 'success'
      }).then((result) => {
        this.phonenumberdropdown('phoneusernamelogin','countrycode_login','error-msg', 'phonecode_login')
        $('#loginbox').modal('show');
      });
      this.isPasswordMatched = null;
      }
    }
}