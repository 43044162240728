import { Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import {} from 'intl-tel-input/build/js/intlTelInput.min.js';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonHelper } from 'src/app/common/helper';
import { NUMBER_ONLY } from 'src/app/constants/pattern.constant';
declare var $: any;
declare var window:any;
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  model: any = {};
  selectedcountrycode: any;
  selectedphonecode_forgot:any;
  forgetPasswordOTP:any;
  fullnumber:any;
  forgotpasswordresponse:any;
  translatedstr:string;
  public isPasswordMatched = false;
  public eyeView = false;
  public eyeViewConfirm = false;
  public otpTimer = 60;
  public otpIntervalTimer: any;
  public changedOtp:any = null;
  public otpError = false;
  
  /**
   * constructor
   * @param {ApiconnectService} apiconnectService 
   * @param {CommonHelper} commonHelper 
   */
  constructor(
    private apiconnectService: ApiconnectService, 
    private commonHelper: CommonHelper,) { }
  @ViewChild('ngOtpInput') ngOtpInputRef:any;
  
  ngOnInit(): void {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
   }
    
    /**
     * phone number dropdown
     * @param {string} queryselector 
     * @param {string} countryCode 
     * @param {string} error 
     * @param {string} phoneCode 
     */
    async phonenumberdropdown(queryselector,countryCode, error, phoneCode) {
      var phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
      var phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
      var input = document.querySelector("."+queryselector),
        hiddenintputfield = document.querySelector("#"+countryCode),
        errorMsg = document.querySelector("#"+error);
        $(".iti__flag-container").remove();
      var iti = window.intlTelInput(input, {
        excludeCountries: ["il"],
        hiddenInput: "full",
        preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
        separateDialCode: true,
        initialCountry: 'bh',
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
        customPlaceholder: function (selectedCountryPlaceholder: any) {
          return `${phoneplaceholder} ${selectedCountryPlaceholder}`;
        },
      });
      var reset = function () {
        input.classList.remove("error");
        errorMsg.innerHTML = "";
        errorMsg.classList.add("hide");
      };
  
      // on blur: validate
      input.addEventListener('blur', async function () {
        reset();
        this.values = input;
        const numberOnly = NUMBER_ONLY;
        if (this.values.value.trim()) {
          if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
            var countryData = iti.getSelectedCountryData();
            this.values = hiddenintputfield;
            this.values.value = countryData.iso2;
            this.selectedcountrycode = countryData.iso2;
            this.selectedphonecode_forgot = countryData.dialCode;
            $("#"+countryCode).val(countryData.iso2);
            $('#'+phoneCode).val(countryData.dialCode);
          } else {
            input.classList.add("error");
            errorMsg.innerHTML = phoneplaceholdererror;
            errorMsg.classList.remove("hide");
          }
        }
      });
  
      // on keyup / change flag: reset
      input.addEventListener('change', reset);
      input.addEventListener('keyup', reset);
    }

  showRegisterbox(){
    $('#loginbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    $('#registerbox').modal('show');
  }
  showLoginbox(forgotpassword, changepass, phoneError){
    const errMsg = document.querySelector('#'+phoneError);
    errMsg.innerHTML = "";
    errMsg.classList.add("hide");
    forgotpassword.resetForm();
    changepass.resetForm();
    $('.changepassform').hide();
    $('.otpform').hide();
    $('.forgotform').show();
    $('#registerbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    this.phonenumberdropdown('phoneusernamelogin','countrycode_login', 'error-msg', 'phonecode_login');
    $('#loginbox').modal('show');
  }
  showForgotpasswordbox(){
    $('#loginbox').modal('hide');
    $('#registerbox').modal('hide');
    $('#forgotpasswordbox').modal('show');
  }

  forgotPassword(formdata:NgForm){
    const errorMsg = document.querySelector("#error-message");
    if(errorMsg.innerHTML !== '')return;
    $('.loading').show();
    formdata.value.countrycode = $('#countrycode_forgot').val();
    formdata.value.phonecode = $('#phonecode_forgot').val();
    this.fullnumber = formdata.value.phonecode+formdata.value.registernumber;
    let dataPayload = {
      phone: this.fullnumber,
      device_type: 'web'
    }
    this.apiconnectService.postRequest(dataPayload, '/forgot_password').subscribe(
      async (data) => {
        this.forgotpasswordresponse = data;
        if (this.forgotpasswordresponse.status){
          this.forgetPasswordOTP = this.forgotpasswordresponse.otp;
          this.ngOtpInputRef.setValue(this.forgetPasswordOTP);
          this.eyeView = false;
          this.changedOtp =this.forgetPasswordOTP
           this.otpTimer = 60;
          this.otpSessionTimer(data['otp']);
          $('.forgotform').hide();
          $('.otpform').show();
          $('.loading').hide();
        }
        else{
          $('.loading').hide();
          Swal.fire({
            text: await this.commonHelper.translateText(data['message']),
            icon: 'error'
          });
        }
      },
      async err => {
        this.errorMessageOne(err);
      }
    );
  }
  onOtpChange (event) {
    this.forgetPasswordOTP = event;
    this.changedOtp = Number(event);
  this.otpError = false;
  }
  async submitOtp () {
    if (this.forgotpasswordresponse.otp == this.forgetPasswordOTP) {
      $('.otpform').hide();
      $('.forgotform').hide();
      $('.changepassform').show();
      this.otpError = false;
    } else {
      this.otpError = true;
      Swal.fire({
        text: await this.commonHelper.translateText('The one time password is incorrect. Please try again.'),
        icon: 'error'
      });
    }
  }
  public changepassSubmit(formdata:NgForm){
     if(!this.isPasswordMatched)return;
    $('.loading').show();
    let dataPayload = {
      phone: this.fullnumber,
      password: formdata.value.password,
      device_type: 'web'
    }
    this.apiconnectService.postRequest(dataPayload, '/reset_password').subscribe(
      async (data) => {
        this.forgotpasswordresponse = data;
        if (this.forgotpasswordresponse.status){
          this.afterChangePassword(formdata);
        }
        else{
          $('.loading').hide();
          Swal.fire({
            text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
            icon: 'error'
          });
        }
      },
      async err => {
        this.errorMessageOne(err);
      }
    );
  }

    /**
   * match password
   * @param {NgForm} form 
   * @param {any} input
   */
    public matchPassword(input,form){
      if(input?.target.value == form.value.password) {
       this.isPasswordMatched = true;
      }else {
        this.isPasswordMatched = false;
      }
    }

    /**
 * error response 
 * @param {any} err
 */
public async errorMessageOne(err){ //something went wrong message
  $('.loading').hide();
  Swal.fire({
    text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
    icon: 'error'
  });
}

 /**
   * otp timer // 60seconds
   * @param {string} data
   */
 otpSessionTimer(data?: string) {
  if(data){
     this.otpIntervalTimer = setInterval(() => {
     this.otpTimer -= 1; //seconds
     if (this.otpTimer <= 0) {
      clearInterval(this.otpIntervalTimer);
     }
   }, 1000);
  }
   }

   /**
    * page destroy
    */
  ngOnDestroy(): void {
   clearInterval(this.otpIntervalTimer);
  } 

  /**
   * resend otp
   */
  public resendOtp(formdata){
   this.forgotPassword(formdata);
  }

  /**
   * close forgotpassword box // form reset
   * @param {NgForm} form 
   */
  public onCloseForgotpasswordbox(form, phoneError, passwordform){
    const errMsg = document.querySelector('#'+phoneError);
    this.phonenumberdropdown('phoneusernamelogin','countrycode_login', 'error-msg', 'phonecode_login')
    errMsg.innerHTML = "";
    errMsg.classList.add("hide");
     $('.modal-backdrop').remove();
     $('#forgotpasswordbox').modal('hide');
     $('.forgotform').show();
     $('.otpform').hide();
     $('.changepassform').hide();
     form.resetForm();
     passwordform.resetForm();
    clearInterval(this.otpIntervalTimer);
    this.otpTimer = 60;
    this.changedOtp = null;
    this.otpError = false;
  }

  /**
   * changes after changa password
   */
  private async afterChangePassword(form){
          form.resetForm();
         $('.forgotform').show();
          $('.otpform').hide();
          $('.changepassform').hide();
          $('#forgotpasswordbox').modal('hide');
          $('.loading').hide();
          $('#countrycode_forgot').val('');
          $('#phonecode_forgot').val('');
          $('.phoneusernameforgot').val('');
          Swal.fire({
            text: await this.commonHelper.translateText('Your password has been changed successfully. Kindly login with new password.'),
            icon: 'success'
          }).then((result) => {
            $('#registerbox').modal('hide');
            this.phonenumberdropdown('phoneusernamelogin','countrycode_login', 'error-msg', 'phonecode_login');
            $('#loginbox').modal('show');
          });
          this.isPasswordMatched = false;
  }
}