import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgOtpInputComponent } from 'ng-otp-input';
import { CommonHelper } from 'src/app/common/helper';
import { COMPONENTS, USER_TYPE } from 'src/app/constants/common';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { DataEmitterService } from 'src/app/services/data-emit.service';
declare var $: any;
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})

/**
 * Component
 */
export class OtpVerificationComponent implements OnChanges, OnDestroy {
  @Input() mobileNumber:any = null;
  @Input() component = 'otp';
  @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput:NgOtpInputComponent;
  public mobOtp:any=null;
  public otpError = false;
  @Input() formDetails:any = null; // form data ->if mob number verified, again send to respective form
  public otpTimer = 60;
  public otpIntervalTimer: any;
  public changedOtp:any = null;
  @Input() counterId = 0;
  @Input() mobOTP = null;
  public userType = USER_TYPE;
  public pages = COMPONENTS;
  @Input() mobnoToShow:any = null

  /**
   * constructor
   */
  constructor(
    private apiconnectService: ApiconnectService,
    private commonHelper: CommonHelper,
    private dataEmiter: DataEmitterService,
  ) { }


  
  /**
   * on change
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['counterId']){
      this.getOTP();
    }
  }


  /**
   * get otp
   */
  public getOTP(){
        if(this.mobileNumber != null && this.mobOTP !==null){
          this.mobOtp = this.mobOTP;
           this.changedOtp =this.mobOTP
           this.otpTimer = 60;
          this.ngOtpInput.setValue(this.mobOTP);
          this.otpSessionTimer(this.mobOTP);
  }
   }

   /**
    * on change otp field
    * @param data 
    */
public onOtpChange(data){
  this.changedOtp = Number(data);
  this.otpError = false;
}

/**
 * verify otp
 */
public async verifyOtp(){
  if(this.changedOtp != this.mobOtp){
    this.otpError = true;
    Swal.fire({
      text: await this.commonHelper.translateText('The one time password is incorrect. Please try again.'),
      icon: 'error'
    });
    return;
  }
  this.otpError = false;
  this.dataEmiter.otpverificationSet({component:this.component, verified:true, verifiedNum:this.mobileNumber, formData: this.formDetails});
  this.mobileNumber = null;
  this.component = 'otp';
  this.mobOtp = null;
  this.formDetails= null; 
  clearInterval(this.otpIntervalTimer);
}


  /**
   * otp timer // 60seconds
   * @param {string} data
   */
  otpSessionTimer(data?: string) {
   if(data){
    this.otpIntervalTimer = setInterval(() => {
      this.otpTimer -= 1; //seconds
      if (this.otpTimer <= 0) {
        clearInterval(this.otpIntervalTimer);
      }
    }, 1000);
   }
    }

    /**
     * page destroy
     */
   ngOnDestroy(): void {
    clearInterval(this.otpIntervalTimer);
    this.otpTimer = 60;
    this.mobOtp = null;
    this.changedOtp = null;
    this.otpError = false;
   } 

   /**
    * resend otp
    */
   public resendOtp(){
    $('.loading').show();
  const postdata ={
    phone: this.mobileNumber,
    device_type: 'web'
  }
  const apiurl = this.component==this.pages.GUEST_LOGIN?'/verify_guest_user':'/send_otp';
 if(postdata.phone){
  this.apiconnectService.postRequest(postdata, apiurl).subscribe(
    async (data) => {
      if (data['status']) {
      $('.loading').hide();
           this.mobOTP = data['otp'];
           this.mobOtp = data['otp'];
           this.changedOtp =data['otp']
           this.otpTimer = 60;
           this.ngOtpInput.setValue(data['otp']);
           this.otpSessionTimer(data['otp']);
      }
    },
    async err => {
      Swal.fire({
        text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
        icon: 'error'
      });
      console.log(err);
    }
  );
}
   }

   /**
    * close otp modal
    * @param id 
    */
   public closeOtpModal(id){
    clearInterval(this.otpIntervalTimer);
    this.otpTimer = 60;
    this.mobOtp = null;
    this.changedOtp = null;
    this.otpError = false;
    this.formDetails= null; 
    $('#'+id).modal('hide');
    this.dataEmiter.otpverificationSet({component:this.component, verified:false, verifiedNum:this.mobileNumber, formData: this.formDetails});
   }
}
