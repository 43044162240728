import { Component, OnInit, ViewChild, ElementRef, NgZone, Injector } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebStorageService } from 'src/app/services/web-storage.service'
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from "../../../../globals";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Meta, Title } from '@angular/platform-browser';
import { CommonHelper } from 'src/app/common/helper';
import { USER_TYPE } from 'src/app/constants/common';
declare var $: any;

@Component({
  selector: 'app-singlerestaurant',
  templateUrl: './singlerestaurant.component.html',
  styleUrls: ['./singlerestaurant.component.scss']
})
export class SinglerestaurantComponent implements OnInit {
  filtersInput: any = [];
  lat: number;
  loggedIn: boolean = false;
  lng: number;
  restaurantlist: any = {};
  singleRestaurant: any;
  id: number;
  restaurantname: string;
  componenttype = 'restaurant';
  popularBrands: any = {}
  latitude: number;
  longitude: number;
  latitudeinput: number;
  longtitudeinput: number;
  setaddressinput: string;
  zoom: number;
  private geoCoder;
  @ViewChild("agmSearch", { static: false }) public searchElement: ElementRef;
  profileResponse: any;
  userProfileDetails: any = {};
  userName: string;
  loginResponse: any = {}
  googleplaceresponse: any = {}
  allAvailableDeliveryAddress: any;
  setaddress: any;
  searchlocationinput: any;
  alttitle:any;
  currency:any;
  heading:any;
  check_restaurant_availability: any;
  translatedstr: string;
  urilang: string;
  freedelivery: number;
  private apiconnectService:ApiconnectService;
  private webStorageService:WebStorageService;
  private titleService:Title;
  public router: Router;
  private commonHelper:CommonHelper;
  private meta: Meta;
  public userIs: any;
  
  /**
   * constructor
   * @param mapsAPILoader 
   * @param ngZone 
   * @param route 
   * @param globals 
   * @param injector 
   */
  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private globals: Globals,
    private injector: Injector,
  ) {
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService); // //SonarQube issue -> constructor params max. allowed 7.
    this.webStorageService = this.injector.get<WebStorageService>(WebStorageService);
    this.titleService = this.injector.get<Title>(Title);
    this.router = this.injector.get<Router>(Router);
    this.commonHelper = this.injector.get<CommonHelper>(CommonHelper);
    this.meta = this.injector.get<Meta>(Meta);
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
    }
    else {
      this.urilang = '';
    }
    this.alttitle = this.globals.imagealt;
    this.currency = this.globals.currency;
    this.heading = 'Get Free delivery on all orders from '+this.restaurantname+' - EATZILLA';
    this.titleService.setTitle('Get Free delivery on all orders from '+this.restaurantname+' - EATZILLA');
    this.meta.updateTag({ name: 'description', content: 'Want to order from ' + this.restaurantname + '? Click here to see their menu and get your favorite food delivered. Order through EATZILLA and pay zero delivery fee.' });
  }
  showSavedAddressOnFocus() {
    $('.dropdownsavedlocations').show();
  }
  hideSavedAddressOnBlur() {
    $('.dropdownsavedlocations').hide();
  }
  getAllDeliveryAddress() {
    this.apiconnectService.getRequest("/get_delivery_address?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.allAvailableDeliveryAddress = data;
        this.allAvailableDeliveryAddress = this.allAvailableDeliveryAddress.status ? this.allAvailableDeliveryAddress : null;
        localStorage.setItem('nodefualtlogginaddress','false');
        if(!data['status']){
          if(this.userIs == USER_TYPE.LOGGED_USER){
            localStorage.setItem('nodefualtlogginaddress',JSON.stringify(true));
          }
        }
      },
      err => {
        console.error(err)
      }
    )
  }
  
  resetMap() {
    this.latitudeinput = 0;
    this.longtitudeinput = 0;
  }
  showMap(latitude, longitude, address) {
    this.latitudeinput = latitude;
    this.longtitudeinput = longitude;
    this.setaddressinput = address;
    $('#addressMap').modal('show');
  }
  getProfileDetails() {
    this.apiconnectService.getRequest("/get_profile?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.profileResponse = data;
        if (this.profileResponse.status) {
          this.userProfileDetails = this.profileResponse.data[0];
          this.webStorageService.storeUserName(this.profileResponse.data[0].name);
          this.userName = this.webStorageService.getUserName();
        }
      },
      err => {
        console.error(err)
      }
    )
  }
  submitSearch(formdata) {
    var bannerinput = $('.bannerinput').val();
    var lat = $('#lat').val();
    var lng = $('#lng').val();
    if (!bannerinput || !lat || !lng) {
      Swal.fire({
        text: 'Please enter or choose your delivery address',
        icon: 'error'
      });
    }
    else {
      localStorage.setItem('lat', JSON.stringify(lat));
      localStorage.setItem('lng', JSON.stringify(lng));
      localStorage.setItem('address', JSON.stringify(bannerinput));
      this.router.navigate(["menu-details/" + this.singleRestaurant.id + "/" + this.singleRestaurant.name]);
    }
  }
  ngOnInit(): void {
    $('.modal-backdrop').remove();
    window.scroll(0, 0);
    this.id = this.route.snapshot.params['id'];
    this.restaurantname = this.route.snapshot.params['restaurantname'];
    var str = this.restaurantname.split('-').join(' ');
    str = str.toUpperCase();
    this.restaurantname = str;
    this.loadGooglePlaces();
    this.getCurrentLatLng();
    if (
      this.webStorageService.getAuthId() &&
      this.webStorageService.getAuthToken()
    ) {
      this.getAllDeliveryAddress();
      this.loggedIn = true
      this.getProfileDetails();
      this.userName = this.webStorageService.getUserName()
      this.loginResponse.user_name = this.webStorageService.getUserName()
    } else {
      this.loggedIn = false
      this.webStorageService.removeData([['userPic']])
    }
    this.getSingleRestaurant(this.id);
    this.userIs = this.webStorageService.getUserType();
   }
  getCurrentLatLng = () => {
    let curlati= JSON.parse(localStorage.getItem('lat'));
    let curlng = parseFloat(JSON.parse(localStorage.getItem('lng')));
    let curaddress =localStorage.getItem('address');
    if(curlati && curlng){
      this.getAddressName(Number(curlati), Number(curlng), curaddress)
    }else{
    navigator.geolocation.getCurrentPosition(async position => {
      localStorage.setItem('lat', JSON.stringify(position.coords.latitude))
      localStorage.setItem('lng', JSON.stringify(position.coords.longitude))
      await this.getAddressName(Number(position.coords.latitude), Number(position.coords.longitude))
      //this.getNearByRestaurants()
    })
  }
  }
  getAddressName(latitude, longitude, address?) {
    if(address)return;
    return new Promise<void>((resolve, reject) => {
      this.geoCoder.geocode({
        location: {
          lat: latitude,
          lng: longitude
        }
      }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12
            localStorage.setItem('address', results[0].formatted_address);
            resolve();
          } else {
            console.error('No results found')
          }
        } else {
          console.error('Geocoder failed due to: ' + status)
        }
      })
    })
  }
  loadGooglePlaces() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement,
        {
          componentRestrictions: { country: 'IN' }
        });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.setaddress = place.formatted_address;
          this.showMap(this.latitude, this.longitude, this.setaddress);
          this.zoom = 15;
        });
      });
    });
  }
  getSingleRestaurant(id) {
    $('.loading').show();
    this.apiconnectService.postRequest({
      restaurant_id: id,
      device_type: 'web',
      veg_only: 0
    }, "/single_restaurant?&lat=" + localStorage.getItem('lat') + "&lng=" + localStorage.getItem('lng')).subscribe(
      (data) => {
        $('.loading').hide();
        this.singleRestaurant = data['restaurants'][0];
        this.freedelivery = this.singleRestaurant.restaurant_delivery_charge;
        this.titleService.setTitle('Get Free delivery on all orders from '+this.singleRestaurant.name+' - EATZILLA');
        this.meta.updateTag({ name: 'description', content: 'Want to order from ' + this.singleRestaurant.name + '? Click here to see their menu and get your favorite food delivered. Order through EATZILLA and pay zero delivery fee.' });
      },
      err => {
        console.error(err);
      }
    );
  }
  getRestaurants() {
    $('.loading').show();
    this.apiconnectService.postRequest({
      'is_offer': 0,
      'device_type': 'web',
      'is_pureveg': 0,
      'cuisines': this.filtersInput
    }, "/get_nearby_restaurant?lat=" + localStorage.getItem('lat') + "&lng=" + localStorage.getItem('lng') + "&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      (data) => {
        this.restaurantlist = data;
        if (this.restaurantlist.status) {
          $('.loading').hide();
        }
        else {
          this.restaurantlist = false;
          $('.loading').hide();
        }
      },
      err => {
        this.router.navigate(['/home']);
        console.error(err);
      }
    );
  }
  set_delivery_address: any;
  setdeliverableaddress(data) {
    let datapost = {
      current_address: data.address,
      lat: data.lat,
      lng: data.lng,
      address_id: data.id,
      device_type: 'web',
      restaurant_id: Number(this.id)
    }
    this.apiconnectService.postRequest(datapost, '/set_delivery_address').subscribe(
      async (dataResponse) => {
        $('.loading').hide();
        this.set_delivery_address = dataResponse;
        if (this.set_delivery_address.status) {
          localStorage.setItem('defaultaddress', JSON.stringify(dataResponse));
          this.hideSavedAddressOnBlur();
          await this.checkrestaurantavailability(datapost);
          if (!this.check_restaurant_availability.status){
            Swal.fire({
              text: await this.commonHelper.translateText('Oops!! The restaurant does not delivery in this location. Select another location'),
              icon: 'error'
            });
          }
          if (this.check_restaurant_availability.status){
            this.router.navigate([this.urilang+"menu-details/" + this.check_restaurant_availability.restaurant.id + "/" + this.restaurantname]);
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  async selectAddress(address) {
    $('.loading').show();
    $('#lat').val(address.lat);
    $('#lng').val(address.lng);
    this.setdeliverableaddress(address);
  }

  private checkrestaurantavailability(datapost){
    return new Promise((resolve, reject) => {
      this.apiconnectService.postRequest(datapost, '/check_restaurant_availability_by_parent?lat=' + datapost.lat + '&lng=' + datapost.lng).subscribe(
        async (data) => {
          this.check_restaurant_availability = data;
          resolve(this.check_restaurant_availability);
          $('.loading').hide();
          return data;
        },
        async err => {
          $('.loading').hide();
          Swal.fire({
            text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
            icon: 'error'
          });
          console.error(err);
        }
      );
    })
  }
  redirecttorestaurant(data) {
    this.router.navigate(["menu-details/" + data.id + "/" + data.name]);
  }

  /**
   * load image
   * find error when image load
   * @param {string} id 
   */
  public loadImage(data, id){
    const image = document.getElementById(id) as HTMLImageElement;
    if(image){
      image.src = 'assets/images/common/nofood.jpg';
    }
    }
}