import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgForm } from '@angular/forms';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { WebStorageService } from 'src/app/services/web-storage.service'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CommonHelper } from 'src/app/common/helper';
import { DataEmitterService } from 'src/app/services/data-emit.service';
import { Router } from '@angular/router';
import { URL, USER_TYPE } from 'src/app/constants/common';
import { SocketService } from 'src/app/services/socket.service';
import { NUMBER_ONLY } from 'src/app/constants/pattern.constant';
declare var $: any;
declare var window: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Component
 */
export class LoginComponent implements OnInit {
 public model: any = {};
 public selectedcountrycode: any;
 private loginResponse: any = {}
 public loggedIn: boolean = false;
 private phonecode:any;
 public selectedphonecode_login:any;
 private translatedstr: string;
 public componenttype = 'guest-user';
 public setaddressinput: any;
 public latitudeinput: any;
 public longtitudeinput: any;
 private geoCoder;
 public urilang ='';
 public eyeView = false;
 public userIs:any;
 public checkRestaurantAvailability:any ={};

  /**
   * Creates an instance of login component.
   * @param apiconnectService 
   * @param webStorageService 
   * @param _document 
   * @param translate 
   * @param commonHelper
   */
  constructor(
    private router: Router,
    private apiconnectService: ApiconnectService,
    private webStorageService: WebStorageService,
    @Inject(DOCUMENT) private _document: Document,
    private commonHelper: CommonHelper,
    private dataEmitter: DataEmitterService,
    private socketservice: SocketService,
  ) { 
    const lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
   }
    else {
      this.urilang = '';
    }
  }


  /**
   * on init
   */
  ngOnInit() {
     this.getAddress();
     this.userIs = this.webStorageService.getUserType();
    }

  /**
   * Refreshs page
   */
  private refreshPage() {
    this._document.defaultView.location.reload();
  }


  /**
   * Phonenumberdropdown login component
   * @param {string} querySelector 
   * @param {any} countrycode 
   * @param {string} error 
   * @returns {any} phonenumberdropdown 
   */
  private async phonenumberdropdown(querySelector,countrycode,error, phoneCode, intcountrycode?) : Promise<void> {
    const phoneplaceholder = await this.commonHelper.translateText('Enter your Phone Number e.g.');
    const phoneplaceholdererror = await this.commonHelper.translateText('Your Phone Number is Invalid');
    const input = document.querySelector("."+querySelector),
      hiddenintputfield = document.querySelector("#"+countrycode),
      errorMsg = document.querySelector("#"+error);
      $(".iti__flag-container").remove();
    const iti = window.intlTelInput(input, {
      excludeCountries: ["il"],
      hiddenInput: "full",
      preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
      separateDialCode: true,
      initialCountry: intcountrycode?intcountrycode:'bh',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
      customPlaceholder: function (selectedCountryPlaceholder: any){
        return `${phoneplaceholder} ${selectedCountryPlaceholder}`
      }
    })
    const reset = function () {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', async function () {
      reset();
      this.values = input;
      const numberOnly = NUMBER_ONLY;
      if (this.values.value.trim()) {
        if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
          var countryData = iti.getSelectedCountryData();
          this.values = hiddenintputfield;
          this.values.value = countryData.iso2;
          this.selectedcountrycode = countryData.iso2;
          this.phonecode = countryData.dialCode;
          $('#'+countrycode).val(countryData.iso2);
          $('#'+phoneCode).val(countryData.dialCode)
          

        } else {
          input.classList.add("error");
          errorMsg.innerHTML = phoneplaceholdererror;
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }

 /**
  * Shows registerbox
  * @param {NgForm} formData
  */
 public showRegisterbox(formData:NgForm, phoneErr) {
  const errMsg = document.querySelector('#'+phoneErr);
    errMsg.innerHTML = "";
    errMsg.classList.add("hide");
   $('.modal-backdrop').remove();
    formData.resetForm();
    $('#loginbox').modal('hide');
  const registerCountry = JSON.parse(localStorage.getItem('registerCountry'));
    this.phonenumberdropdown('phoneusernameregister','countrycode_register','error-msg-register', 'phonecode_register', registerCountry);
    $('#forgotpasswordbox').modal('hide');
    $('#registerbox').modal('show');
  }

  /**
   * Shows loginbox
   */
  private showLoginbox() {
    $('#registerbox').modal('hide');
    $('#forgotpasswordbox').modal('hide');
    $('#loginbox').modal('show');
  }

 /**
  * Shows forgotpasswordbox
  */
 public showForgotpasswordbox(formData:NgForm, phoneErr) {
  const errMsg = document.querySelector('#'+phoneErr);
    errMsg.innerHTML = "";
    errMsg.classList.add("hide");
   $('.modal-backdrop').remove();
    formData.resetForm();
    $('#loginbox').modal('hide');
    $('#registerbox').modal('hide');
    this.phonenumberdropdown('phoneusernameforgot','countrycode_forgot','error-message', 'phonecode_forgot');
    $('#forgotpasswordbox').modal('show');
  }

  /**
   * Logins user
   * @param {NgForm} formdata 
   */
  public async loginUser(formdata: NgForm) {
    const errorMsg = document.querySelector("#error-msg");
    if(errorMsg.innerHTML !== '')return;
    $('.loading').show();
    formdata.value.countrycode = $('#countrycode_login').val();
    formdata.value.login_type = 0;
    formdata.value.device_type = 'web';
    formdata.value.phone = $('#phonecode_login').val() + formdata.value.phone;
    formdata.value.device_type = 'web';
    await this.onLogin(formdata);
  }

  /**
   * open map
   */
  public openMapBox(){
    $('.user-login').hide();
    $('.loading').show();
    this.getAddress();
   setTimeout(() => {
     $('.loading').hide();
      $('.addressmap-guest').show();
   }, 1000);
  }

   /**
    * confirm area selected by user
    */
   public confirmGuestUserArea(){
    const  guestAddress = localStorage.getItem('address');
      if(guestAddress == undefined){
        $('.getareatoaster').toast('show');
        return;
      }
      $('.loading').show();
    const restaurantId = JSON.parse(localStorage.getItem('restaurant_id'));
      if(restaurantId){
        const lat = JSON.parse(localStorage.getItem("lat"));
        const lng = JSON.parse(localStorage.getItem("lng"));
        const datapost = {
          current_address: JSON.parse(localStorage.getItem("address")),
          lat: lat,
          lng: lng,
          restaurant_id: restaurantId,
          address_id: 0,
          device_type: 'web',
        }
        this.checkAvailbility(datapost)
      }else{
        $('.loading').show();
       this.setGuestUserLogin();
      }
  }

 /**
  * get address
  */
 public getAddress(){
 if(localStorage.getItem('address')){
    this.setaddressinput = localStorage.getItem('address');
    this.latitudeinput=JSON.parse(localStorage.getItem('lat'));
    this.longtitudeinput =parseFloat(JSON.parse(localStorage.getItem('lng')));
  }
 }

 /**
  * remove guest details when user login
  */
 public removeGuestDetails(){
  this.webStorageService.removeData(['guestDetails','location','phone_code','country_code','phone_no']); 
  }

  /**
   * close login box // form refresh
   */
  public closeLoginBox(form:NgForm, phoneErr){
   const errMsg = document.querySelector('#'+phoneErr);
   errMsg.innerHTML = "";
   errMsg.classList.add("hide");
    $('.modal-backdrop').remove();
    $('#loginbox').modal('hide');
    form.resetForm();
  }

 /**
   * get deliverable address // To set default address
   */
 public getAllDeliveryAddress () {
  this.apiconnectService.getRequest("/get_delivery_address?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
    data => {
      if(data['status']){
        const tempData = data['data'].filter((x)=>x.is_default==1);
        localStorage.setItem('nodefualtlogginaddress',JSON.stringify(false));
        $('.loading').hide();
        if(tempData.length>0){
        this.webStorageService.storeLocalData({lat:tempData[0].lat, lng:tempData[0].lng, address:tempData[0].address, defaultaddress:1})
        if(this.commonHelper.getRouteUrl()==URL.HOME){ // navigate to searchresult page , having nearby restaurant at guest-user login
          this.navigateToNearByRestaurant();
         } 
       }
       }else{
        this.userIs =localStorage.getItem('user');
        if(this.userIs == USER_TYPE.LOGGED_USER){
          localStorage.setItem('nodefualtlogginaddress',JSON.stringify(true));
        }
        $('.loading').hide();
      }
      },
    err => {
      $('.loading').hide();
    }
  )
}

/**
   * navigate to near by restaurant page
   */
public navigateToNearByRestaurant(){
  const lati = JSON.parse(localStorage.getItem('lat'));
  const lngi = parseFloat(JSON.parse(localStorage.getItem('lng')));
  const bannerinput = JSON.parse(localStorage.getItem('address'));
  if(lati && lngi && bannerinput){
  this.router.navigate([this.urilang + 'search-results'], {
    queryParams: {
      lat: lati,
      lng: lngi,
      address: bannerinput
    }
  })
}
}

/**
 * login api works
 * @param formdata 
 */
public onLogin(formdata):Promise<void>{
  return new Promise((resolve)=>{
    this.apiconnectService.postRequest(formdata.value, '/login').subscribe(
      async (data) => {
        $('.loading').hide();
        this.loginResponse = data;
        if (this.loginResponse.status) {
         this.removeGuestDetails();
          this.eyeView = false;
          formdata.resetForm();
          this.webStorageService.storeAuthId(this.loginResponse.authId, 'user');
          this.webStorageService.storeAuthToken(this.loginResponse.authToken);
          this.webStorageService.storeUserName(this.loginResponse.user_name);
          this.webStorageService.storeUserPic(this.loginResponse.profile_image);
          localStorage.setItem("searchedaddress", JSON.stringify(false));
          this.loggedIn = true;
          this.dataEmitter.updateLogin(true);
          $('body').css('overflow', 'unset');
          $('.loading').show();
          this.socketservice.connect();
          $('#loginbox').modal('hide');
          Swal.fire({
            text: await this.commonHelper.translateText('You have been successfully logged in'),
            icon: 'success'
          });
          this.getAllDeliveryAddress();
        }
        else {
          this.loggedIn = false;
          this.webStorageService.removeData(['userPic']);
          Swal.fire({
            text: await this.commonHelper.translateText('Your username or password is incorrect'),
            icon: 'error'
          });
        }
      },
      async err => {
        Swal.fire({
          text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
          icon: 'error'
        });
        console.log(err);
      }
    );
    resolve();
  })
}

    /**
 * check availability of restaurant and further work
 * @param {object} datapost 
 */
    public async checkAvailbility(datapost){
      await this.checkrestaurantavailability(datapost);
        if (!this.checkRestaurantAvailability.status){
          $('.modal-backdrop').remove();
          $('#loginbox').modal('hide');
          Swal.fire({
            text: await this.commonHelper.translateText('Oops!! The restaurant does not delivery in this location. Select another location'),
            icon: 'error'
          }).then(()=>{
            $('#loginbox').modal('show');
          });
         }else{
          $('.loading').show();
          this.setGuestUserLogin();
        }
    }
    
    /**
       * check restaturant availability
       * @param {any} datapost 
       * @returns {object}
       */
    private checkrestaurantavailability(datapost){
      return new Promise((resolve, reject) => {
        this.apiconnectService.postRequest(datapost, '/check_restaurant_availability?lat=' + datapost.lat + '&lng=' + datapost.lng).subscribe(
          async (data) => {
            this.checkRestaurantAvailability = data;
            resolve(this.checkRestaurantAvailability);
            $('.loading').hide();
            return data;
          },
          async err => {
            this.errorMessageOne(err);
          }
        );
      })
    }

    /**
     * after check restaurant availability
     * @returns 
     */
    public setGuestUserLogin(){
      const area =JSON.parse(localStorage.getItem('location'))
     if(area){
      this.dataEmitter.setDetails(area); // guest area emit
     }
      $('.user-login').show();
      $('#loginbox').modal('hide');
      $('.addressmap-guest').hide();
      $('#guestuserlogin').modal('show');
      const intcountryCode = JSON.parse(localStorage.getItem('country_code'));
      this.phonenumberdropdown('phoneguestuserlogin','countrycode_guestuserlogin','error-msg-phoneguestuserlogin','phonecode_guestuserlogin',intcountryCode?intcountryCode:'');
      $('.loading').hide();
    }

    /**
 * error response 
 * @param {any} err
 */
public async errorMessageOne(err){ //something went wrong message
  if(err){
  $('.loading').hide();
  Swal.fire({
    text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
    icon: 'error'
  });
  }
}

/**
 * close map box
 */
public closemapBox(){
  $('.modal-backdrop').remove();
  $('.user-login').show();
  $('.addressmap-guest').hide();
}

/**
 * address confirmation
 */
public addressConfirmation(confirmation){
  if(confirmation){
     this.confirmGuestUserArea();
  }
 }
}