import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'foodlistFilter' })

/**
 * food item filter pipe
 */
export class FooditemFilter implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @return {any[]}
   */
 transform(items: any[], searchText: string): any[] {
   if (!items || items.length==0) {
      return [];
    }
    if (!searchText) {
       return items;
    }
     if(searchText.trim().length<3){
         return items
    }
    searchText = searchText.toString().toLowerCase();
    
    return items.map((item) =>{ //mapping array of objects ->filter item.items array by searchText 
      // item.items = [...this.hasNestedArrKeyValue(item, searchText)];
      return {...item, items:this.hasNestedArrKeyValue(item, searchText)}; //return 
    });
}

  /**
 * filter array -> nested array
 * @param {object} obj 
 * @param {string} term 
 * @returns {Array}
 */
public hasNestedArrKeyValue(obj, term) {
 return obj.items.filter((it)=>this.hasNestedKeyValue(it, term));
  
}

/**
 * check obj have value
 * @param {object} itemArr 
 * @param {string} key 
 * @returns {boolean}
 */
public hasNestedKeyValue(obj, key) {
  if(JSON.stringify(obj.name).toLowerCase().includes(key) ){
    return true
  }
  return false;
}
 }
