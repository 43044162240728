<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <agm-map [latitude]="11.0507884" [longitude]="76.9954283" [zoom]="17">
                    <agm-marker [latitude]="11.0507884" [longitude]="76.9954283" [markerDraggable]="false"></agm-marker>
                </agm-map>
            </div>
            <div class="col-md-6">
                <h4>Contact Eatzilla</h4>
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="text-holder">
                                <span class="title">Address</span>
                                <strong>Sparkout Tech Solutions, CM Nagar, Sathy Rd, Ramakrishnapuram, Coimbatore, Tamil Nadu</strong>
                            </div>
                        </li>
                    </ul>
                </div>
                <h4>Get In Touch</h4>
                <form name="form" (ngSubmit)="contactus.form.valid && formSubmit(contactus)" #contactus="ngForm" novalidate>
                    <div class="row mb-2">
                        <div class="col">
                            <label>Full Name <small class="text-danger">*</small></label>
                            <input type="text" id="name" name="name" class="form-control" placeholder="Enter your Full Name" [(ngModel)]="model.name" #name="ngModel" [ngClass]="{ 'is-invalid': contactus.submitted && name.invalid }" required
                            minlength="3" pattern="[A-Za-z]{1,}" maxlength="25">
                            <div *ngIf="contactus.submitted && name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">{{'Name is required' | translate}}</div>
                                <div *ngIf="name.errors.minlength && !name.errors.pattern">{{'Name must be at least 3 characters' | translate}}</div>
                                <div *ngIf="name.errors.pattern">{{'Letters only' | translate}}</div>
                                <div *ngIf="name.errors.maxlength && !name.errors.pattern">{{'Maximum 25 letters allowed' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">
                            <label>Email Address <small class="text-danger">*</small></label>
                            <input type="text" class="form-control" placeholder="Enter your Email Address" id="email" name="email" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': contactus.submitted && email.invalid }" required
                            pattern="{{emailPattern}}">
                            <div *ngIf="contactus.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">{{'Email is required' | translate}}</div>
                                <div *ngIf="email.errors.pattern">{{'Enter valid Email Id' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <label>Phone Number <small class="text-danger">*</small></label>
                           <input type="text" name="phone" class="form-control contactusphone" [(ngModel)]="model.phone" #phone="ngModel" [ngClass]="{ 'is-invalid': contactus.submitted && phone.invalid }" required placeholder="Enter your Phone Number">
                            <span id="error-msg text-danger text-left" class="hide error"></span>
                            <input name="countrycode_contact" id="countrycode_contact" [(ngModel)]="selectedcountrycode" #countrycode_partner="ngModel" type="hidden">
                            <input name="phonecode_contact" id="phonecode_contact" [(ngModel)]="selectedphonecode" #phonecode_partner="ngModel" type="hidden">
                            <div id="error-msg-contact" class="text-danger text-left"></div>
                            <div *ngIf="contactus.submitted && phone.invalid" class="phone-invalid">
                                <div *ngIf="phone.errors.required">{{'Phone Number is required' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <label>Message <small class="text-danger">*</small></label>
                            <textarea rows="4" class="form-control" placeholder="Enter your Message" id="msg" name="msg" [(ngModel)]="model.msg" #msg="ngModel" [ngClass]="{ 'is-invalid': contactus.submitted && msg.invalid }" required
                            minlength="5"></textarea>
                            <div *ngIf="contactus.submitted && msg.invalid" class="invalid-feedback">
                                <div *ngIf="msg.errors.required">{{'Message is required' | translate}}</div>
                                <div *ngIf="msg.errors.minlength">{{'Message must be at least 5 characters' | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <button type="submit" class="btn btn-primary pull-right">SUBMIT YOUR MESSAGE</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<app-footer></app-footer>