import { Component, ElementRef, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { } from 'intl-tel-input/build/js/intlTelInput.min.js';
import { WebStorageService } from 'src/app/services/web-storage.service';
import { ApiconnectService } from 'src/app/services/apiconnect.service';
import { Globals } from '../../../globals';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Router,ActivatedRoute } from '@angular/router';
declare var $: any;
declare var window: any;
import { CommonHelper } from 'src/app/common/helper';
import { ORDER_DETAILS} from 'src/app/constants/profile-page.constant';
import { ADDRESS_TYPES } from 'src/app/constants/guest-user-login-page.constant';
import { ADDRESS_KEYS, USER_TYPE } from 'src/app/constants/common';
import { DataEmitterService } from 'src/app/services/data-emit.service';
import { SocketService } from 'src/app/services/socket.service';
import { EMAIL, NUMBER_ONLY } from 'src/app/constants/pattern.constant';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  loggedIn: boolean = false;
  componenttype = 'profile';
  addaddress: any = {};
  profileResponse: any;
  userProfileDetails: any = {};
  selectedcountrycode: any;
  userName: string;
  orderHistoryResponse: any;
  loginResponse: any = {}
  model: any = {};
  allAvailableDeliveryAddress: any;
  orderdetailspopup: any;
  currency: any;
  activetab = 'orders';
  deletorder:any;
  latitudeinput = 26.2235;
  longtitudeinput = 50.58;
  translatedstr: string;
  urilang: string;
  public commonhelper:any;
  public tab = ORDER_DETAILS;
  public tabKey:number = 0;
  public addressTypes = ADDRESS_TYPES;
  public selectedAddressType = '';
  public addressTypeKey = 0;
  public addressFormSubmitted = false;
  @ViewChildren("addressTypeChecks") addressTypeChecks: QueryList<ElementRef>;
  public userIs:string = '';
  public userType= USER_TYPE;
  public emailPattern = EMAIL;
  private apiconnectService: ApiconnectService;
  private webStorageService: WebStorageService;
  private router:Router;
  private rout: ActivatedRoute;
  private dataEmitter: DataEmitterService;
  public eyeView = false;
  public setaddressinput:any;
  private geoCoder;
  public addresskey = ADDRESS_KEYS;

 /**
  * constructor
  * @param globals 
  * @param commonHelper 
  * @param SocketService
  * @param injector 
  */
  constructor(
    private globals: Globals,
    private commonHelper:CommonHelper,
    private socketservice: SocketService,
    private injector: Injector,
  ) { 
    this.apiconnectService = this.injector.get<ApiconnectService>(ApiconnectService); //SonarQube issue -> constructor params max. allowed 7.
    this.webStorageService = this.injector.get<WebStorageService>(WebStorageService);
    this.router = this.injector.get<Router>(Router);
    this.rout = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.dataEmitter = this.injector.get<DataEmitterService>(DataEmitterService);
    var lang = localStorage.getItem('language');
    if (lang == 'ar') {
      this.urilang = 'ar/';
      this.currency = this.globals.currency_ar;
    }
    else {
      this.urilang = '';
      this.currency = this.globals.currency;
    }
  }
  
  ngOnInit(): void {
    $('.modal-backdrop').remove();
     window.scroll(0, 0);
    var menuitem = this.rout.snapshot.params['id'];
    if (menuitem){
      this.activetab = menuitem;
    }
    if (
      this.webStorageService.getAuthId() &&
      this.webStorageService.getAuthToken()
    ) {
      this.loggedIn = true
      this.getProfileDetails()
      this.orderHistory();
      this.getAllDeliveryAddress();
      this.userName = this.webStorageService.getUserName()
      this.loginResponse.user_name = this.webStorageService.getUserName()
    } else {
      this.loggedIn = false
      this.webStorageService.removeData(['userPic'])
      this.router.navigate([this.urilang+'home']);
    }
    this.commonhelper = this.commonHelper;

    //set lat & lng
    this.latitudeinput = JSON.parse(localStorage.getItem('lat'));
    this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem('lng')));
    this.setaddressinput = JSON.parse(localStorage.getItem('address'));
    this.userIs = localStorage.getItem('user');

    //update login
    this.dataEmitter.getLogin().subscribe(res=>{
      if(res){
        
        this.userIs = localStorage.getItem('user');
        if(this.userIs == this.userType.LOGGED_USER){
          this.getProfileDetails();
        this.getAllDeliveryAddress();
        }
        this.loggedIn = true
      }else{
        this.loggedIn = false;
       }
     });
     this.geoCoder = new google.maps.Geocoder();
  }
  
  phonenumberdropdown() {
    var input = document.querySelector(".phonenumber"),
      hiddenintputfield = document.querySelector("#countrycode_addaddress"),
      errorMsg = document.querySelector("#error-msg_address");
      $(".iti__flag-container").remove();
    var iti = window.intlTelInput(input, {
      excludeCountries: ["il"],
      hiddenInput: "full",
      preferredCountries: ['bh', 'sa', 'kw', 'ae', 'om'],
      separateDialCode: true,
      initialCountry: 'bh',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.0/js/utils.js",
      customPlaceholder: async function (selectedCountryPlaceholder: any) {
        return await this.commonHelper.translateText('Enter your Phone Number e.g.') + ` ${selectedCountryPlaceholder}`;
      },
    });
    var reset = function () {
      input.classList.remove("error");
      errorMsg.innerHTML = "";
      errorMsg.classList.add("hide");
    };

    // on blur: validate
    input.addEventListener('blur', async function () {
      reset();
      this.values = input;
      const numberOnly = NUMBER_ONLY;
      if (this.values.value.trim()) {
        if (iti.isValidNumber() && numberOnly.test(this.values.value.replace(/\s/g, ''))) {
          var countryData = iti.getSelectedCountryData();
          this.values = hiddenintputfield;
          this.values.value = countryData.iso2;
          this.selectedcountrycode = countryData.iso2;
          $('#countrycode_login').val(countryData.iso2);
        } else {
          input.classList.add("error");
          errorMsg.innerHTML = await this.commonHelper.translateText('Your Phone Number is Invalid');
          errorMsg.classList.remove("hide");
        }
      }
    });

    // on keyup / change flag: reset
    input.addEventListener('change', reset);
    input.addEventListener('keyup', reset);
  }
  address_id:number;
  showEditAddress(data) {
    this.address_id = data.id;
    this.addaddress = {
      addresstring: data.address,
      blocknumber: data.block_number,
      building: data.building,
      flat_no: data.flat_no,
      landmark: data.landmark,
      address_title: data.address_title,
      road_number: data.road_number,
      address: data.address,
    }
    $('#addressMap').modal('hide');
    $('#editaddressmodal').modal('show');
  }
  async deleteAddress(id) {
    Swal.fire({
      text: await this.commonHelper.translateText('Are you sure you want to delete this address'),
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: await this.commonHelper.translateText('Cancel'),
      confirmButtonText: await this.commonHelper.translateText('Confirm')
    }).then((result) => {
      if (result.value) {
        this.apiconnectService.getRequest("/delete_delivery_address/" + id +"?device_type=web").subscribe(
          async dataResponse => {
            this.deletorder = dataResponse;
            if (this.deletorder.status) {
              this.getAllDeliveryAddress();
              Swal.fire({
                text: await this.commonHelper.translateText('Your address has been deleted successfully'),
                icon: 'success'
              });
            }
            else{
              Swal.fire({
                text: await this.commonHelper.translateText(dataResponse['message']),
                icon: 'error'
              });
            }
          },
          err => {
            console.log(err)
          }
        )
      }
    });
  }
  // viewOrderDetails(orders) {
  //   this.orderdetailspopup = orders;
  //    $('#orderDetailsdiv').modal('show');
  // }
  getProfileDetails() {
     this.apiconnectService.getRequest("/get_profile?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.profileResponse = data;
        if (this.profileResponse.status) {
          this.userProfileDetails = this.profileResponse.data[0];
          this.webStorageService.storeUserName(this.profileResponse.data[0].name);
          this.userName = this.webStorageService.getUserName();
        }
        else{
          this.loggedIn = false;
          this.webStorageService.removeData(['userName','userPic']);
          this.router.navigate([this.urilang+'home']);
        }
      },
      err => {
        console.log(err)
      }
    )
  }
  

  getAllDeliveryAddress () {
    this.apiconnectService.getRequest("/get_delivery_address?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      data => {
        this.allAvailableDeliveryAddress = data;
        this.allAvailableDeliveryAddress = this.allAvailableDeliveryAddress.status? this.allAvailableDeliveryAddress:null;
        localStorage.setItem('nodefualtlogginaddress','false');
        if(!data['status']){
          if(this.userIs == this.userType.LOGGED_USER){
            localStorage.setItem('nodefualtlogginaddress',JSON.stringify(true));
          }
        }
        this.selectedAddressType = '';
      },
      err => {
        console.log(err)
      }
    )
  }
  updateSettings(formdata:NgForm){
    $('.loading').show();
    formdata.value.authId = localStorage.getItem('authId');
    formdata.value.authToken = localStorage.getItem('authToken');
    formdata.value.id = localStorage.getItem('authId');
    formdata.value.device_type = 'web';
    this.apiconnectService.postRequest(formdata.value, '/update_profile').subscribe(
      async (data) => {
        this.loginResponse = data;
        if (this.loginResponse.status) {
          this.eyeView = false;
          $('.loading').hide();
          Swal.fire({
            text: await this.commonHelper.translateText('Your profile has been updated successfully'),
            icon: 'success'
          });
          $('body').css('overflow', 'unset');
        }
        else {
          this.loggedIn = false;
          this.webStorageService.removeData(['userName','userPic']);
          Swal.fire({
            text: await this.commonHelper.translateText('Your username or password is incorrect'),
            icon: 'error'
          });
        }
        $('.loading').hide();
      },
      async err => {
        Swal.fire({
          text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
          icon: 'error'
        });
        console.log(err);
        $('.loading').hide();
      }
    );
  }
  logout(){
    this.apiconnectService.getRequest("/logout?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
      async data => {
        localStorage.clear()
        this.router.navigate([this.urilang+'home']);
        this.dataEmitter.updateLogin(false);
        this.socketservice.disconnect();
        Swal.fire({
          text: await this.commonHelper.translateText('You have been successfully logged out'),
          icon: 'success'
        });
      },
      err => {
        console.log(err)
      }
    )
  }

  /**
   * show address form modal
   */
  public async showAddAddress(){
    const location = JSON.parse(localStorage.getItem("location"));
    const addaddress = JSON.parse(localStorage.getItem("add_address_address"));
    this.latitudeinput = JSON.parse(localStorage.getItem('lat'));
    this.longtitudeinput = parseFloat(JSON.parse(localStorage.getItem('lng')));
    if (location && addaddress){
    $('#addressMap').modal('hide');
    this.addaddress.addresstring = addaddress;
    $('#editaddressmodal').modal('show');
    await this.mapLocation(location);
    }else{
       this.getAddressName(this.latitudeinput, this.longtitudeinput);
    }
   }

  /**
   * store address
   * @param {NgForm} formdata 
   * @returns 
   */
public addaddresssubmit(formdata:NgForm){
  if(this.selectedAddressType==''){
    return;
  }else{
    $('.loading').show();
    let data = {
      landmark: formdata.value.landmark,
      flat_no: formdata.value.flat_no,
      address: this.addaddress.addresstring,
      lat: localStorage.getItem('add_address_lat'),
      lng: localStorage.getItem('add_address_lng'),
      type: this.addressTypeKey,
      address_direction: formdata.value.landmark,
      block_number: formdata.value.blocknumber,
      building: formdata.value.building,
      road_number:formdata.value.road_number,
      address_title:this.selectedAddressType,
      device_type: 'web',
      customer_name: this.userName,
      area: formdata.value.location
    }
    this.addaddresssubmitApi(data, formdata);
  }
   
  }

/**
 * orders details show // tab 
 * @param key 
 */
public showOrdersDetails(key){
 if(key !== null){
  this.tabKey = key;
 }
}

  /**
   * address type choose
   * @param {any} event 
   * @param {number} key 
   */
  public chooseAddressType(event, key){
    if(event.target.checked){
      this.selectedAddressType = event.target.value
      this.addressTypeKey = key+1;
    }
  }

  /**
   * change area
   * @param event 
   */
  public onChangeArea(event){
    $('#editaddressmodal').modal('hide');
    this.getAddress();
     setTimeout(() => {
    $('#addressMap').modal('show');
   }, 1000);
   }

     /**
  * get address
  */
 public getAddress(){
  if(localStorage.getItem('add_address_address')){
    this.latitudeinput=JSON.parse(localStorage.getItem('add_address_lat'));
    this.longtitudeinput =JSON.parse(localStorage.getItem('add_address_lng'));
  }
 }

 /**
 * form reset
 * @param {NgForm} form
 */
public formReset(form:NgForm){
  form.resetForm();
  // form - address type checks remove
  this.addressTypeChecks.forEach((element) => {
    element.nativeElement.checked = false;
  });
}

/**
 * order history
 */
public orderHistory(){
  this.apiconnectService.getRequest("/order_history?device_type=web&authId=" + localStorage.getItem('authId') + "&authToken=" + localStorage.getItem('authToken')).subscribe(
    data => {
      this.orderHistoryResponse = data;
     $('.loading').hide();
    },
    err => {
      console.log(err)
      $('.loading').hide();
    }
  );
}

/**
 * addaddresssubmit // api works
 * @param {object} data 
 */
public addaddresssubmitApi(datapost, formdata){
  this.apiconnectService.postRequest(datapost, '/add_delivery_address').subscribe(
    async (data) => {
      this.loginResponse = data;
      if (this.loginResponse.status) {
        $('#addressMap').modal('hide');
        $('#editaddressmodal').modal('hide');
        $('.loading').hide();
        this.formReset(formdata);
        this.webStorageService.removeData(['add_address_lat','add_address_lng','add_address_address']);
        this.getAllDeliveryAddress();
        Swal.fire({
          text: await this.commonHelper.translateText('You have successfully Added your Address'),
          icon: 'success'
        });
      }
      else {
        $('.loading').hide();
        Swal.fire({
          text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
          icon: 'error'
        });
      }
    },
    async err => {
      $('.loading').hide();
      Swal.fire({
        text: await this.commonHelper.translateText('Oops!! Something went wrong with your request. Please try again'),
        icon: 'error'
      });
      console.log(err);
    }
  );
}

/**
 * map location
 * @param location 
 */
public mapLocation(location): Promise<void>{
  if(!location){return}
  return new Promise(resolve => {
const str = ''|| undefined || null;
 switch(true){
     case location['area'] != str:
     this.addaddress['location'] =location['area'];
      break;
      case location['sublocality_level_3'] != str:
      this.addaddress['location'] =location['sublocality_level_3'];
      break;
      case location['sublocality_level_2'] != str:
        this.addaddress['location'] =location['sublocality_level_2'];
        break;
        case location['sublocality_level_1'] != str:
          this.addaddress['location'] =location['sublocality_level_1'];
          break;
          case location['sublocality'] != str:
          this.addaddress['location'] =location['sublocality'];
          break;
          case location['address'] != str:
          this.addaddress['location'] =location['address'];
          break;
      case location['neighborhood'] != str:
      this.addaddress['neighborhood'] =location['neighborhood'];
      break;
      case location['land_mark'] != str:
      this.addaddress['land_mark'] =location['land_mark'];
      break;
      case location['route'] != str:
      this.addaddress['route'] =location['route'];
      break;
      case location['postal_code'] != str:
      this.addaddress['postal_code'] =location['postal_code'];
      break;
      default:
        this.addaddress['location']  = location['address'];
  }
  resolve();
})
}

  /**
   * get address
   * @param latitude 
   * @param longitude 
   * @param address 
   */
  public getAddressName(latitude, longitude){
    if(latitude && longitude){
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, async (results, status) => {
        if (status === 'OK') {
           if (results[0]) {
            this.webStorageService.storeLocalData({'add_address_address':results[0].formatted_address,'add_address_lat':latitude, 'add_address_lng':longitude}) // store a add address
            await this.storeGuestArea(results[0]);
            this.showAddAddress();
           } else {
             console.log('No results found')
           }
         } else {
           console.log('Geocoder failed due to: ' + status)
         }
       })
    }
    }

       /**
   * store guest area
   * @param name 
   */
 private storeGuestArea(address):Promise<void>{
  return new Promise(resolve => {
  const location = {};
  location['address'] = address.formatted_address
  if(address.address_components.length>0){
    address.address_components.forEach((area)=> { // get area
      this.addresskey.forEach(keys=>{
        if(area.types.includes(keys.TYPES_ARRAY)){
              this.storeLocation(area, keys.KEY_TO_SAVE, location);
            }
           })
       });
   }
  resolve();
})
}

/**
 * store local data
 * @param {any} data 
 * @param {string} key 
 */
private storeLocation(data, key, place){
  const location = place;
  location[key] = data.long_name;
  this.webStorageService.storeLocalData({'location':location});
}

/**
 * close modal
 * @param formData 
 */
public onCloseAddressModal(formData){
  if(formData){
    formData.resetForm();
  }
  this.selectedAddressType = '';
}

/**
 * address confirmation
 */
public addressConfirmation(confirmation){
  if(confirmation){
     this.showAddAddress();
  }
 }
}
