<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{'All Restaurants' | translate}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <h2 class="text-left mb-2">{{'List of All Restaurants' | translate}}
            <div class="rest-search pull-right col-md-4 pr-0" *ngIf="!mobilecheck">
                <input class="m-b-md full-width form-control" id="restSearchBox" type="text" placeholder="{{'Search Restaurants Below' | translate}}" [(ngModel)]="term">
                <span class="search-icon">
                    <span class="fa fa-search"></span>
                </span>
                <span class="delete-icon" style="display: none;">
                    <span class="fa fa-close"></span>
                </span>
            </div>
        </h2>
        <div class="rest-search " *ngIf="mobilecheck">
            <input class="m-b-md full-width form-control" id="restSearchBox" type="text" placeholder="{{'Search Restaurants Below' | translate}}" [(ngModel)]="term">
            <span class="search-icon">
                <span class="fa fa-search"></span>
            </span>
            <span class="delete-icon" style="display: none;">
                <span class="fa fa-close"></span>
            </span>
        </div>
        <div class="row" *ngIf="restaurantlist.length>0">
            <div (click)="redirecttorestaurant(key)" class="pointer col-lg-3 6 col-sm-6  my-3" *ngFor="let key of restaurantlist | filter:term">
                <div class="rounder border restraunt-detail">
                    <div class="restaurantcontainerimage p-a b-b text-center ellipsis overlay-container">
                        <img src="{{key.image}}" alt="{{alttitle}} {{key.name}}" title="{{alttitle}} {{key.name}}" class=" img-fluid">
                        <div class="overlay" *ngIf="key?.is_open==0">Closed</div>
                        <div class="overlay" *ngIf="key?.is_open==0?false:key?.is_busy==1">Busy</div>
                    </div>
                    <h6 class="text-center text-dark pt-3 ellipsis px-2 allrestaurantstitleh6">{{key.name | titlecase}}</h6>
                    <p class="text-center text-secondary ellipsis px-2" *ngIf="key.cuisines.length > 1">
                        <span class="cuisinetext" *ngFor="let cuisines of key?.cuisines; let i = index">
                            <span *ngIf="i != 0 && i!=key.cuisines.length">&nbsp;•&nbsp;</span>
                            {{cuisines.name | titlecase}}
                        </span>
                    </p>
                    <p class="text-center text-secondary ellipsis px-2"  *ngIf="key?.travel_time">
                        <span class="cuisinetext">
                            Delivery within: {{key?.travel_time}}mins.
                         </span>
                        </p>
                    <p class="text-center text-secondary ellipsis px-2"  *ngIf="key?.min_order_value">
                            <span class="cuisinetext">
                                Min Order: {{currency}} {{key?.min_order_value}}.
                             </span>
                            </p>
                        <p class="text-center text-secondary ellipsis text-success px-2" *ngIf="key?.offer_type==1 && key?.discount_type=='1'">
                            <span class="cuisinetext">
                                {{currency}}{{key?.offer_amount}} flat offer.
                             </span>
                            </p>
                        <p class="text-center text-secondary ellipsis text-success px-2" *ngIf="key?.offer_type==1 && key?.discount_type=='2'">
                            <span class="cuisinetext">
                                {{key?.offer_amount}}% offer upto {{currency}}{{key?.target_amount}}.
                              </span>
                            </p>
                        <p class="text-center text-secondary ellipsis text-success px-2" *ngIf="key?.offer_type==2 && key?.discount_type=='1'">
                            <span class="cuisinetext">
                                {{currency}} {{key?.offer_amount}} flat offer on order above {{currency}}{{key?.offer_value}}.
                             </span>
                            </p>
                        <p class="text-center text-secondary ellipsis text-success px-2 mb-3" *ngIf="key?.offer_type==2 && key?.discount_type=='2'">
                            <span class="cuisinetext d-block">
                                {{key?.offer_amount}}% on order above {{currency}} {{key?.offer_value}}.
                             </span>
                            <span class="cuisinetext d-block">
                                Max Discount {{currency}}{{key?.target_amount}}
                            </span>
                        </p>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="no-data"  *ngIf="(restaurantlist | filter:term).length===0">
            <div class="text-center">
                <img src="assets/images/no-data/no-restaurant-data.png" alt="no-restaurant">
            </div>
            <h6 *ngIf="term" class="text-center py-3">{{'No Restaurants Found' | translate}}</h6>
        </div>
        <div class="flex justify-center">
            <h5 class="text-center mt-5" *ngIf="restaurantlist && restaurantlist.length===0">{{'No Restaurants Found' | translate}}</h5>
        </div>
    </div>
</section>
<app-footer></app-footer>