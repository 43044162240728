//Filters list
/**
 * sort list
 */
export const SORT_LIST = [
   'Delivery Time',
    'Rating',
   'Cost-Low to High',
    'Cost-High to Low'
]
/**
 * filter list
 */
export const FILTER_LIST = [
    'Offers',
   'Pure Veg',
    'Non Veg',
]
