<div class="modal-dialog guest-login-modal" role="document" *ngIf="!guestDetails">
    <div class="modal-content p-3 px-4">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{'Add Address' | translate}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeGuestLoginModal(guestUserLogin, 'error-msg-phoneguestuserlogin', keyToFormefresh.True)">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body text-left">
            <form name="form" (ngSubmit)="guestUserLogin.form.valid && otpverfication(guestUserLogin, keyToFormefresh.True)" #guestUserLogin="ngForm" novalidate>
                <div class="form-group">
                    <label for="name">{{'Name' | translate}} <small class="text-danger">*</small></label>
                    <input type="text" name="name" class="form-control" [(ngModel)]="model.name" #name="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && name.invalid }" required placeholder="{{'Enter your Name' | translate}}"
                    minlength="3" maxlength="25" pattern="(?! )[A-Za-z\s]{1,}" autocomplete="off">
                    <div *ngIf="guestUserLogin.submitted && name.invalid" class="invalid-feedback">
                        <div *ngIf="name.errors.required">{{'Name is required' | translate}}</div>
                        <div *ngIf="name.errors.minlength && !name.errors.pattern">{{'Name must be at least 3 characters' | translate}}</div>
                        <div *ngIf="name.errors.pattern">{{'Enter valid name. Letters and space only allowed.' | translate}}</div>
                        <div *ngIf="name.errors.maxlength && !name.errors.pattern">{{'Maximum 25 letters allowed' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="phonenumber">{{'Phone Number' | translate}} <small class="text-danger">*</small></label>
                    <input type="text" name="phoneguestuserlogin" class="form-control phoneguestuserlogin" [(ngModel)]="model.phoneguestuserlogin" #phoneguestuserlogin="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && phoneguestuserlogin.invalid }" required autocomplete="off"> 
                    <input name="countrycode_guestuserlogin" id="countrycode_guestuserlogin" [(ngModel)]="selectedcountrycode" #countrycode_guestuserlogin="ngModel" type="hidden">
                    <input name="phonecode_guestuserlogin" id="phonecode_guestuserlogin" [(ngModel)]="selectedphonecode" #phonecode_guestuserlogin="ngModel" type="hidden">
                    <div id="error-msg-phoneguestuserlogin" class="phone-invalid"></div>
                    <div *ngIf="guestUserLogin.submitted && phoneguestuserlogin.invalid" class="phone-invalid">
                        <div *ngIf="phoneguestuserlogin.errors.required">{{'Kindly write your phone number to continue' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="adress">{{'Address Type' | translate}} <small class="text-danger">*</small></label>
                    <div class="flex justify-around item-center">
                        <ng-container *ngFor="let type of addressTypes; let i = index;" >
                            <label class="m-0 radiobutton">
                                   <input name="address" type="radio" id="address{{type}}" value="{{type}}" (click)="chooseAddressType($event, i)"
                                   class="check" autocomplete="off" [checked]="selectedAddressType==type">
                                   <span class="checkmark"></span>
                                   <span class="pl-2">{{type}}</span>
                                  </label>
                           </ng-container>
                    </div>
                    <div *ngIf="guestUserLogin.submitted" class="addresstype-invalid">
                        <div *ngIf="selectedAddressType==''">{{'Address Type is required' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="area">{{'Area' | translate}} <small class="text-danger">*</small></label>
                    <input autocomplete="off" type="text" name="area" class="form-control" [(ngModel)]="this.mappedArea" #area="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && area.invalid }" required placeholder="{{'Area' | translate}}"
                       (focusin)="onChangeArea($event)" >
                    <div *ngIf="guestUserLogin.submitted && area.invalid" class="invalid-feedback">
                        <div *ngIf="area.errors.required">{{'Area is required' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="block-number">{{'Block Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="block" class="form-control" [(ngModel)]="model.block" #block="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && block.invalid }" placeholder="{{'Block number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="road-number">{{'Road Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="road" class="form-control" [(ngModel)]="address['route']" #road="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && road.invalid }" placeholder="{{'Road Number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="building-number">{{'Building Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="building" class="form-control" [(ngModel)]="model.building" #building="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && building.invalid }" placeholder="{{'Building Number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="flat-number">{{'Flat Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="flat" class="form-control" [(ngModel)]="model.flat" #flat="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && flat.invalid }" placeholder="{{'Flat Number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="direction">{{'Address Directions' | translate}} <small class="text-danger">*</small></label> 
                    <input autocomplete="off" type="text" name="direction" class="form-control" [(ngModel)]="model.direction" #direction="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && direction.invalid }" required placeholder="{{'Address directions' | translate}}"
                      minlength="5">
                    <div *ngIf="guestUserLogin.submitted && direction.invalid" class="invalid-feedback">
                        <div *ngIf="direction.errors.required">{{'Address Direction is required' | translate}}</div>
                        <div *ngIf="direction.errors.minlength && !direction.errors.pattern">{{'Direction must be at least 5 characters' | translate}}</div>
                    </div>
                </div>
                
                <div class="clearfix"></div>
                <br>
                <button type="submit" class="btn btn-primary btn-block">{{'Login as Guest user' | translate}}</button>
            </form>

        </div>
    </div>
</div>

<div class="modal-dialog guest-login-modal-edit" role="document" *ngIf="guestDetails">
    <div class="modal-content p-3 px-4">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{'Edit Address' | translate}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="closeGuestLoginModal(guestUserLogin, 'error-msg-phoneguestuserlogin', keyToFormefresh.False)">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body text-left">
            <form name="form" (ngSubmit)="guestUserLogin.form.valid && otpverfication(guestUserLogin,keyToFormefresh.False)" #guestUserLogin="ngForm" novalidate>
                <div class="form-group">
                    <label for="name">{{'Name' | translate}} <small class="text-danger">*</small></label>
                    <input autocomplete="off" type="text" name="name" class="form-control" [(ngModel)]="guestDetails['name']" #name="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && name.invalid }" required placeholder="{{'Enter your Name' | translate}}"
                    minlength="3" maxlength="25" pattern="(?! )[A-Za-z\s]{1,}" >
                    <div *ngIf="guestUserLogin.submitted && name.invalid" class="invalid-feedback">
                        <div *ngIf="name.errors.required">{{'Name is required' | translate}}</div>
                        <div *ngIf="name.errors.minlength && !name.errors.pattern">{{'Name must be at least 3 characters' | translate}}</div>
                        <div *ngIf="name.errors.pattern">{{'Enter valid name. Letters and space only allowed.' | translate}}</div>
                        <div *ngIf="name.errors.maxlength && !name.errors.pattern">{{'Maximum 25 letters allowed' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="phonenumber">{{'Phone Number' | translate}} <small class="text-danger">*</small></label>
                    <input type="text" name="phoneguestuserlogin" class="form-control phoneguestuserlogin" [(ngModel)]="selectedPhoneNumber" #phoneguestuserlogin="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && phoneguestuserlogin.invalid }" required autocomplete="off">
                    <input name="countrycode_guestuserlogin" id="countrycode_guestuserlogin" [(ngModel)]="selectedcountrycode" #countrycode_guestuserlogin="ngModel" type="hidden">
                    <input name="phonecode_guestuserlogin" id="phonecode_guestuserlogin" [(ngModel)]="selectedphonecode" #phonecode_guestuserlogin="ngModel" type="hidden">
                    <div id="error-msg-phoneguestuserlogin" class="phone-invalid"></div>
                    <div *ngIf="guestUserLogin.submitted && phoneguestuserlogin.invalid" class="phone-invalid">
                        <div *ngIf="phoneguestuserlogin.errors.required">{{'Kindly write your phone number to continue' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="adress">{{'Address Type' | translate}} <small class="text-danger">*</small></label>
                    <div class="flex justify-around item-center">
                        <ng-container *ngFor="let type of addressTypes; let i = index;" >
                            <label class="m-0 radiobutton">
                                   <input name="editaddress" type="radio" id="editaddress{{type}}" value="{{type}}" (click)="chooseAddressType($event, i)"
                                   class="check" [checked]="selectedAddressType==type" [ngClass]="{'checked':selectedAddressType==type}">
                                   <span class="checkmark"></span>
                                   <span class="pl-2">{{type}}</span>
                                  </label>
                            </ng-container>
                    </div>
                    <div *ngIf="guestUserLogin.submitted" class="addresstype-invalid">
                        <div *ngIf="selectedAddressType==''">{{'Address Type is required' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="area">{{'Area' | translate}} <small class="text-danger">*</small></label>
                    <input autocomplete="off" type="text" name="area" class="form-control" [(ngModel)]="this.mappedArea?this.mappedArea:guestDetails['area']" #area="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && area.invalid }" required placeholder="{{'Area' | translate}}"
                       (focusin)="onChangeArea($event)" autocomplete="off">
                    <div *ngIf="guestUserLogin.submitted && area.invalid" class="invalid-feedback">
                        <div *ngIf="area.errors.required">{{'Area is required' | translate}}</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="block-number">{{'Block Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="block" class="form-control" [(ngModel)]="guestDetails['block_number']" #block="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && block.invalid }" placeholder="{{'Block number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="road-number">{{'Road Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="road" class="form-control" [(ngModel)]="guestDetails['road_number']" #road="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && road.invalid }" placeholder="{{'Road Number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="building-number">{{'Building Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="building" class="form-control" [(ngModel)]="guestDetails['building']" #building="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && building.invalid }" placeholder="{{'Building Number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="flat-number">{{'Flat Number' | translate}}</label>
                    <input autocomplete="off" type="text" name="flat" class="form-control" [(ngModel)]="guestDetails['flat_no']" #flat="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && flat.invalid }" placeholder="{{'Flat Number' | translate}}"
                      >
                </div>
                <div class="form-group">
                    <label for="direction">{{'Address Directions' | translate}} <small class="text-danger">*</small></label> 
                    <input autocomplete="off" type="text" name="direction" class="form-control" [(ngModel)]="guestDetails['address_direction']" #direction="ngModel" [ngClass]="{ 'is-invalid': guestUserLogin.submitted && direction.invalid }" required placeholder="{{'Address directions' | translate}}"
                      minlength="5">
                    <div *ngIf="guestUserLogin.submitted && direction.invalid" class="invalid-feedback">
                        <div *ngIf="direction.errors.required">{{'Address Direction is required' | translate}}</div>
                        <div *ngIf="direction.errors.minlength && !direction.errors.pattern">{{'Direction must be at least 5 characters' | translate}}</div>
                    </div>
                </div>
                
                <div class="clearfix"></div>
                <br>
                <button type="submit" class="btn btn-primary btn-block">{{'Continue' | translate}}</button>
            </form>

        </div>
    </div>
</div>

<div class="modal fade" id="editGuestAddress" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">
            <div class="modal-header">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel">{{'Area' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-googlemap [id]="'guest1'" [componenttype]="componenttype" [setaddressinput]="setaddressinput" [latitudeinput]="latitudeinput" [longtitudeinput]="longtitudeinput"
                [title]="'Confirm'" (addressconfirmation)="addressConfirmation($event)"></app-googlemap>
            </div>
        </div>
    </div>
</div>