<app-header></app-header>
<section class="breadcrumbs m-3">
    <nav aria-label="breadcrumb">
        <div class="container">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/{{urilang}}">{{'Home' | translate}}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{'Hot Offers' | translate}}</li>
            </ol>
        </div>
    </nav>
</section>
<section class="pagecontent">
    <div class="container">
        <h2 class="text-left">{{'List of All Hot Offers' | translate}}
            <div class="rest-search pull-right col-md-4 pr-0" *ngIf="!mobilecheck">
                <input class="m-b-md full-width form-control" id="restSearchBox" type="text" placeholder="{{'Search Offers Below' | translate}}" [(ngModel)]="term">
                <span class="search-icon">
                    <span class="fa fa-search"></span>
                </span>
                <span class="delete-icon" style="display: none;">
                    <span class="fa fa-close"></span>
                </span>
            </div>
        </h2>
        <div class="minheight600">
            <div class="row">
                <div (click)="redirecttorestaurant(key)" class="pointer col-lg-4 col-12" *ngFor="let key of restaurantlist.data | sortBy: 'desc':'id' | filter:term">
                    <div class="p-a b-b">
                        <img class="rounded" src="{{restaurantlist.base_url}}{{key.image1}}" width="100%" alt="Eatzilla - {{key.restaurant_name | titlecase}}" title="Eatzilla - {{key.restaurant_name | titlecase}}">
                        <h6 class="text-center my-3 ellipsis">{{key.restaurant_name | titlecase}}</h6>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</section>
<app-footer></app-footer>