<div class="modal-dialog" role="document">
    <div class="modal-content p-3 px-4">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{'Forgot Password' | translate}}</h5>
            <button type="button" class="close" aria-label="Close" (click)="onCloseForgotpasswordbox(forgotpassword,'error-message', changepass)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body text-left">
            <div class="forgotform">
                <form name="form" (ngSubmit)="forgotpassword.form.valid && forgotPassword(forgotpassword)" #forgotpassword="ngForm" novalidate>
                    <div class="form-group">
                        <label for="phonenumber">{{'Phone Number' | translate}} <small class="text-danger">*</small></label>
                        <input type="text" name="registernumber" class="form-control phoneusernameforgot" [(ngModel)]="model.registernumber" #registernumber="ngModel" [ngClass]="{ 'is-invalid': forgotpassword.submitted && registernumber.invalid }" 
                         required>
                        <input name="countrycode_forgot" id="countrycode_forgot" [(ngModel)]="selectedcountrycode" #countrycode_forgot="ngModel" type="hidden">
                        <input name="phonecode_forgot" id="phonecode_forgot" [(ngModel)]="selectedphonecode_forgot" #phonecode_forgot="ngModel" type="hidden">
                        <div id="error-message"  class="text-danger text-left"></div>
                        <div *ngIf="forgotpassword.submitted && registernumber.invalid" class="phone-invalid">
                            <div *ngIf="registernumber.errors.required">{{'Kindly write your phone number to continue' | translate}}</div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <br>
                    <button type="submit" class="btn btn-primary btn-block">{{'Submit' | translate}}</button>
                </form>
            </div>
            <div class="otpform" style="display: none;">
                <div class="form-group">
                    <label for="phonenumber">{{'Enter your One Time Password' | translate}} <small class="text-danger">*</small></label>
                    <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{length:5}" #ngOtpInput></ng-otp-input>
                    <div id="error-msg"></div>
                    <div class="mt-2 mb-2">
                        <div>
                           <p class="text-success bold cursor-pointer" *ngIf="otpTimer !==0 && otpTimer !==60">Resend otp in {{otpTimer}} s</p>
                           <p class="text-success bold cursor-pointer" (click)="resendOtp(forgotpassword)" *ngIf="otpTimer ==0">Resend otp</p>
                        </div>
                        <div *ngIf="otpError">
                            <p class="text-danger otp-validation"></p>
                           </div>
                      </div>
                </div>
                <div class="clearfix"></div>
                <br>
                <button type="button" (click)="submitOtp()" class="btn btn-primary btn-block">{{'Submit' | translate}}</button>
            </div>
            <div class="changepassform" style="display: none;">
                <form name="form" (ngSubmit)="changepass.form.valid && changepassSubmit(changepass)" #changepass="ngForm" novalidate>
                    <div class="form-group">
                        <label for="password">{{'Password' | translate}} <small class="text-danger">*</small></label>
                        <input autocomplete="off" [type]="eyeView?'text':'password'" name="password" class="form-control" [(ngModel)]="model.password" #password="ngModel" [ngClass]="{ 'is-invalid': changepass.submitted && password.invalid }" required placeholder="{{'New Password' | translate}}"
                            minlength="8">
                            <div class="eye-view"  (click)="eyeView = !eyeView">
                                <i class="fa fa-eye" aria-hidden="true" *ngIf="!eyeView && !(changepass.submitted && password.invalid)"></i>
                                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="eyeView && !(changepass.submitted && password.invalid)"></i>
                            </div>
                        <div *ngIf="changepass.submitted && password.invalid" class="invalid-feedback">
                            <div *ngIf="password.errors.required">{{'Password is required' | translate}}</div>
                            <div *ngIf="password.errors.minlength">{{'Password must be at least 8 characters' | translate}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="confirmPassword">{{'Confirm Password' | translate}} <small class="text-danger">*</small></label>
                        <input autocomplete="off" [type]="eyeViewConfirm?'text':'password'" name="confirmPassword" class="form-control" [(ngModel)]="model.confirmPassword" #confirmPassword="ngModel" [ngClass]="{ 'is-invalid': changepass.submitted && (confirmPassword.invalid || changepass.value.password != changepass.value.confirmPassword)}" placeholder="{{'Confirm Password' | translate}}"
                            required (keyup)="matchPassword($event, changepass)">
                            <div class="eye-view"  (click)="eyeViewConfirm = !eyeViewConfirm">
                                <i class="fa fa-eye" aria-hidden="true" *ngIf="!eyeViewConfirm && !(changepass.submitted && (confirmPassword.invalid || changepass.value.password != changepass.value.confirmPassword))"></i>
                                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="eyeViewConfirm && !(changepass.submitted && (confirmPassword.invalid || changepass.value.password != changepass.value.confirmPassword))"></i>
                            </div>
                        <div *ngIf="changepass.submitted && confirmPassword.invalid" class="invalid-feedback">
                            <div *ngIf="confirmPassword.errors.required">{{'Confirm Password is required' | translate}}</div>
                        </div>
                        <div *ngIf="changepass.submitted && changepass.value.password != changepass.value.confirmPassword && changepass.value.confirmPassword" class="invalid-feedback">
                            <div>{{'Password not matched' | translate}}</div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <br>
                    <button type="submit" class="btn btn-primary btn-block">{{'Submit' | translate}}</button>
                </form>
            </div>
        </div>
        <div class="modal-footer" style="display: block;">
            <h3 class="text-center"><a (click)="showLoginbox(forgotpassword, changepass, 'error-message')">{{'Back to login' | translate}}</a></h3>
        </div>
    </div>
</div>